<h1 mat-dialog-title>{{ 'Datei hinzufügen' | transloco }}</h1>
<div mat-dialog-content>
    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>{{ 'Dateiart' | transloco }}</mat-label>
        <mat-select
            [(ngModel)]="data.docType"
            placeholder="{{ 'Bitte wählen' | transloco }}"
        >
            <mat-option value="image"> {{ 'Bild' | transloco }} </mat-option>
            <mat-option value="education">
                {{ 'Aus - und Weiterbildung' | transloco }}
            </mat-option>
            <mat-option value="competence">
                {{ 'Kompetenz' | transloco }}
            </mat-option>
            <mat-option value="personalia">
                {{ 'Personalie' | transloco }}
            </mat-option>
            <mat-option value="workContract">
                {{ 'Arbeitsvertrag' | transloco }}
            </mat-option>
            <mat-option value="profileImage">
                {{ 'Profilbild' | transloco }}
            </mat-option>
            <mat-option value="misc">
                {{ 'Sonstiges' | transloco }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>{{ 'Dateiname' | transloco }}</mat-label>
        <input
            matInput
            placeholder="{{ 'Dateiname' | transloco }}"
            [(ngModel)]="data.displayedName"
        />
    </mat-form-field>
</div>
<div class="sys--align-center">
    <div *ngIf="data?.file?.name">
        {{ data.file.name }}
        <!--            <img-->
        <!--                class="h-200 sys&#45;&#45;rounded"-->
        <!--                [src]="fileurl"-->
        <!--                alt="Unternehmens-Logo"-->
        <!--                *ngIf="fileurl"-->
        <!--            />-->
        <!--            TODO: Show preview if image-->
    </div>
    <label
        *ngIf="!data?.file?.name"
        for="fileUploadInput"
        class="ionic-button favorite-button--basic"
    >
        <a mat-raised-button class="mt-12 mb-12">
            {{ 'Datei hinzufügen' | transloco }}
        </a>
    </label>
    <input
        accept=".jpg,.jpeg,.png,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx"
        type="file"
        id="fileUploadInput"
        class="sys--display-none"
        (change)="getFileFromInput($event)"
    />
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">
        {{ 'Abbrechen' | transloco }}
    </button>
    <button
        mat-button
        [mat-dialog-close]="data"
        [disabled]="!data.docType || !data.file"
    >
        {{ 'Speichern' | transloco }}
    </button>
</div>
