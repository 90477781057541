import { NgModule } from '@angular/core';

import { FileUploadComponent } from './file-upload.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    declarations: [FileUploadComponent],
    exports: [FileUploadComponent],
    imports: [
        MatDialogModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        MatButtonModule,
        CommonModule,
        MatSelectModule,
        TranslocoModule,
    ],
})
export class FileUploadModule {}
