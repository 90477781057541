import { Keyy } from './keyy.model';
import { ListOptionsType, KeyySortType } from '../warehouse/list-option.types';

export function sortKeyys(keyys: Keyy[], sortType: KeyySortType): Keyy[] {
    const collator = new Intl.Collator('de', {
        numeric: true,
        sensitivity: 'base',
    });

    switch (sortType) {
        case 'name':
            return keyys.sort((a, b) => collator.compare(a.name, b.name));
        case 'home':
            return keyys.sort((a, b) =>
                collator.compare(a.home || '', b.home || '')
            );
        case 'itemNumber':
            return keyys.sort((a, b) =>
                collator.compare(a.itemNumber || '', b.itemNumber || '')
            );
    }
    return keyys;
}

export function filterKeyys(
    keyys: Keyy[],
    filter: string,
    listOptions: ListOptionsType,
    currentUserName: string
): Keyy[] {
    let filteredKeyys = [...keyys];

    if (
        listOptions.keyyFilters.onlyMine &&
        listOptions.keyyFilters.onlyWithMe
    ) {
        filteredKeyys = filteredKeyys.filter(
            (keyy) =>
                keyy.home === currentUserName || keyy.owner === currentUserName
        );
    } else if (listOptions.keyyFilters.onlyWithMe) {
        filteredKeyys = filteredKeyys.filter(
            (keyy) => keyy.owner === currentUserName
        );
    } else if (listOptions.keyyFilters.onlyMine) {
        filteredKeyys = filteredKeyys.filter(
            (keyy) => keyy.home === currentUserName
        );
    }

    if (!filter) {
        return filteredKeyys;
    }
    return filteredKeyys.filter((entity) => {
        let haystack;
        switch (listOptions.keyySearchType) {
            case 'name':
                haystack = entity.name;
                break;
            case 'location':
                haystack = entity.owner || '' + entity.home || '';
                break;
            case 'itemNumber':
                haystack = entity.itemNumber || '';
                break;
            case 'address':
                haystack = entity.address ?? '';
                break;
            default:
                haystack =
                    entity.name +
                    entity.note +
                    entity.itemNumber +
                    entity.address +
                    entity.owner +
                    entity.home;
        }

        const position = haystack.toLowerCase().indexOf(filter);
        return position > -1;
    });
}
