<h1 mat-dialog-title>{{ 'Fehler melden' | transloco }}</h1>
<div mat-dialog-content>
    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>{{ 'Bereich' | transloco }}</mat-label>
        <input matInput [(ngModel)]="data.topic" />
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>{{ 'Details' | transloco }}</mat-label>
        <textarea rows="5" matInput [(ngModel)]="data.details"></textarea>
        <mat-hint align="start">
            <strong>{{
                'Wie kann der Fehler reproduziert werden?' | transloco
            }}</strong>
        </mat-hint>
    </mat-form-field>
</div>
<div class="sys--align-center">
    <div>
        <img
            class="h-200 sys--rounded"
            [src]="bugurl"
            alt="Unternehmens-Logo"
            *ngIf="bugurl"
        />
    </div>
    <label for="bugReportInput" class="ionic-button favorite-button--basic">
        <a mat-raised-button class="mt-12 mb-12">
            {{ 'Bild hinzufügen' | transloco }}
        </a>
    </label>
    <input
        accept=".jpg,.jpeg,.png"
        type="file"
        id="bugReportInput"
        class="sys--display-none"
        (change)="getFileFromInput($event)"
    />
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">
        {{ 'Abbrechen' | transloco }}
    </button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>
        {{ 'Senden' | transloco }}
    </button>
</div>
