import { Component, Inject, OnInit } from '@angular/core';
import {
    API_BASE_FILE_SERVER,
    TransportModel,
    TransportEntityService,
    transportProtocolUpdated,
} from '@sansys/crosslib';
import { Store } from '@ngrx/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'sys-transport-protocol',
    templateUrl: './transport-protocol.component.html',
    styleUrls: ['./transport-protocol.component.scss'],
})
export class TransportProtocolComponent implements OnInit {
    id = 'schedulingProtocolTemplates';
    transport?: TransportModel;

    constructor(
        private transportEntityService: TransportEntityService,
        private store: Store<any>,
        private dialogRef: MatDialogRef<TransportProtocolComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { transport: TransportModel },
        @Inject(API_BASE_FILE_SERVER) private fileServer: string
    ) {}

    ngOnInit(): void {
        if (!this.data?.transport) {
            this.onCloseModal();
        }
        this.transport = JSON.parse(JSON.stringify(this.data.transport));
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }

    noTransportProtocolItems(): boolean {
        return (
            !this.transport?.protocolSections ||
            this.transport.protocolSections.length === 0
        );
    }

    onSaveTransport(): void {
        if (this.transport) {
            if (this.transport.id) {
                this.transportEntityService
                    .update(this.transport)
                    .subscribe(() => {
                        if (this.transport) {
                            this.store.dispatch(
                                transportProtocolUpdated({
                                    transport: this.transport,
                                })
                            );
                            this.onCloseModal();
                        }
                    });
            } else {
                this.store.dispatch(
                    transportProtocolUpdated({ transport: this.transport })
                );
                this.onCloseModal();
            }
        }
    }

    printPdf(): void {
        if (!this.transport) {
            return;
        }
        this.transportEntityService.update(this.transport).subscribe(() => {
            if (this.transport) {
                this.transportEntityService
                    .getTransportProtocolUrl(this.transport)
                    .subscribe((data) =>
                        window.open(this.fileServer + data.url, '_blank')
                    );
            }
        });
    }
}
