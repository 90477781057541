import { Component, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LocalStorageService } from '@sansys/crosslib';

@Component({
    selector: 'sys-auth-page',
    templateUrl: './auth-page.component.html',
    styleUrls: ['./auth-page.component.scss'],
})
export class AuthPageComponent {
    @Input() title = '';
    @Input() mainText = '';
    @Input() logoUrl = 'assets/images/logos/sansys.png';

    constructor(
        private readonly translocoService: TranslocoService,
        private readonly localStorageService: LocalStorageService
    ) {}

    switchLanguage(): void {
        const currentLanguage = this.translocoService.getActiveLang();
        if (currentLanguage === 'medical_de-CH') {
            this.translocoService.setActiveLang('medical_fr-PF');
            this.localStorageService.setItem('admin-language', 'fr');
            this.localStorageService.setItem('customer-language', 'fr');
        } else {
            this.translocoService.setActiveLang('medical_de-CH');
            this.localStorageService.setItem('admin-language', 'de');
            this.localStorageService.setItem('customer-language', 'de');
        }
        window.location.reload();
    }
}
