import { Inject, Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, of, OperatorFunction } from 'rxjs';
import { map, catchError, filter, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CurrentUser } from '../features/user';
import { API_BASE_URL_TOKEN } from '../injection-tokens';
import { select, Store } from '@ngrx/store';
import { LocalStorageService } from '../common-services/local-storage.service';
import { userLogin } from './auth.actions';
import { AccessRights } from './access-right.type';
import { Worktime } from '../features/worktime/worktime.model';
import { currentWorktime, currentUser } from './auth.selectors';
import { errorOccured } from '../error-handling';
import { NgxPermissionsService } from 'ngx-permissions';
import { VehicleEntityService } from '../features/vehicles/vehicle-entity.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private currentUserSubject: BehaviorSubject<CurrentUser | undefined>;
    public currentUser: Observable<CurrentUser | undefined>;

    constructor(
        private http: HttpClient,
        private router: Router,
        private localStorageService: LocalStorageService,
        private store: Store<any>,
        private permissionsService: NgxPermissionsService,
        private injector: Injector,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        this.currentUserSubject = new BehaviorSubject<CurrentUser | undefined>(
            this.localStorageService.getItem('sansys_user')
        );
        this.currentUser = this.currentUserSubject.asObservable();
    }

    currentVehicle$ = this.store.pipe(
        select(currentUser),
        filter((currentU) => !!currentU),
        switchMap(() => {
            return this.injector
                .get(VehicleEntityService)
                .getVehicleOfCurrentUser();
        })
    );

    currentFacility$ = this.store.pipe(
        select(currentWorktime),
        filter((currentW) => !!currentW),
        switchMap((currentW) => {
            if (currentW?.facility?.name && currentW?.facility?.id) {
                return of({
                    name: currentW.facility.name,
                    id: currentW.facility.id,
                });
            } else {
                return of(undefined);
            }
        })
    );

    checkAuthentication(): Observable<boolean> {
        if (!this.currentUserValue) {
            return EMPTY;
        }
        return this.http.get(`${this.server}api/check`).pipe(
            catchError(() => of(false)),
            map(() => {
                return true;
            })
        );
    }

    getTransportCancelLimitDays(): Observable<number> {
        return this.http.get<number>(
            `${this.server}api/companies/transportcancellimitdays`
        );
    }

    get currentUserValue(): CurrentUser | undefined {
        return this.currentUserSubject.value;
    }

    register(model: any): Observable<null> {
        return this.http.post<null>(this.server + 'auth/register', model);
    }

    softLogin(): Observable<CurrentUser | null> {
        const refreshToken = this.localStorageService.getItem<CurrentUser>(
            'sansys_user'
        )?.refreshtoken;
        if (!refreshToken) {
            return of(null);
        }

        const data = {
            refreshToken: refreshToken,
        };

        return this.http
            .post<CurrentUser>(`${this.server}auth/refresh`, data)
            .pipe(
                this.storeUser(),
                catchError(() => of(null))
            );
    }

    nfcLogin(id: string): Observable<CurrentUser | null> {
        const data = {
            id,
        };

        return this.http
            .post<CurrentUser>(`${this.server}auth/nfclogin`, data)
            .pipe(
                this.storeUser(true),
                catchError(() => of(null))
            );
    }

    apiTokenLogin(params: any): Observable<CurrentUser | null> {
        const data = {
            id: params.token,
        };

        return this.http
            .post<CurrentUser>(`${this.server}auth/apitokenlogin`, data)
            .pipe(
                this.storeUser(true),
                catchError(() => of(null))
            );
    }

    login(username: string, password: string): Observable<CurrentUser> {
        return this.http
            .post<CurrentUser>(`${this.server}auth/login`, {
                username,
                password,
            })
            .pipe(this.storeUser(true));
    }

    getPermissions(): Observable<AccessRights> {
        return this.http.get<AccessRights>(`${this.server}api/permissions`);
    }

    getMobilePermissions(): Observable<AccessRights> {
        return this.http.get<AccessRights>(
            `${this.server}api/mobilepermissions`
        );
    }

    forgotPassword(username: string): Observable<null> {
        return this.http.post<null>(`${this.server}auth/forgotpassword`, {
            username,
        });
    }

    forgotUsername(email: string): Observable<null> {
        return this.http.post<null>(`${this.server}auth/forgotusername`, {
            email,
        });
    }

    resetPassword(token: string, newPassword: string): Observable<CurrentUser> {
        return this.http
            .post<CurrentUser>(`${this.server}auth/resetpassword`, {
                token,
                newPassword,
            })
            .pipe(this.storeUser(true));
    }

    confirmRegistration(token: string): Observable<CurrentUser> {
        return this.http
            .post<CurrentUser>(`${this.server}auth//confirmregistration`, {
                token,
            })
            .pipe(this.storeUser(true));
    }

    private storeUser(
        fromLoginPage = false
    ): OperatorFunction<CurrentUser, CurrentUser> {
        return map((data) => {
            const currentU: CurrentUser = {
                token: data.token,
                refreshtoken: data.refreshtoken,
                user: data.user,
                exp: data.exp,
                hasMobileCode: data.hasMobileCode,
                transportLocation: data.transportLocation || undefined,
                type: data.type,
                station: data.station,
            };
            this.localStorageService.setItem('sansys_user', currentU);
            this.currentUserSubject.next(currentU);
            this.store.dispatch(
                userLogin({
                    currentUser: currentU,
                    redirectToHome: fromLoginPage,
                })
            );
            return currentU;
        });
    }

    logout(): void {
        this.currentUserSubject.next(undefined);
        this.localStorageService.removeItem('sansys_user');
        this.localStorageService.removeItem('shortcuts');
        this.permissionsService.flushPermissions();
        this.router.navigate(['/anmelden']);
        if (this.currentUser) {
            const refreshToken = this.localStorageService.getItem<CurrentUser>(
                'sansys_user'
            )?.refreshtoken;
            const data = {
                refreshToken: refreshToken,
            };
            this.http.post(`${this.server}auth/logout`, data).subscribe();
        }
    }

    getCurrentWorkTime(): Observable<Worktime | undefined> {
        return this.http.get<Worktime | undefined>(
            `${this.server}api/worktimes/active`
        );
    }

    checkMobileCode(code: string): Observable<boolean> {
        return this.http.post<boolean>(
            `${this.server}api/companies/checkmobilecode`,
            { code }
        );
    }

    resetMobileCode(): void {
        this.localStorageService.removeItem('mobileCode');
        this.logout();
        this.store.dispatch(
            errorOccured({
                message: 'Gerätecode zurückgesetzt.',
                title: 'Info',
            })
        );
    }

    getUrgentTimeFrameMinutes(): Observable<number> {
        return this.http.get<number>(
            `${this.server}api/companies/urgenttimeframeminutes`
        );
    }

    updateAppVersion(appVersion: number | string): Observable<null> {
        return this.http.post<null>(`${this.server}api/appversion`, {
            appVersion,
        });
    }
}
