import { NgModule } from '@angular/core';

import { NavbarComponent } from './navbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FuseNavigationModule, SharedModule } from '@sansys/webapputils';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    declarations: [NavbarComponent],
    imports: [
        FuseNavigationModule,
        SharedModule,
        MatButtonModule,
        MatIconModule,
        CommonModule,
        FlexLayoutModule,
    ],
    exports: [NavbarComponent],
})
export class NavbarModule {}
