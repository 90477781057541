import { Training } from './training.model';

export function createTraining(training: Training): Training {
    return {
        id: training.id || undefined,
        trainingName: training.trainingName,
        minutes: training.minutes,
        startDate: training.startDate,
        endDate: training.endDate,
        trainingParticipants: training.trainingParticipants,
    };
}
