import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { listOptionsDefaultValue } from './list-options.default';
import { ListOptionsType } from './list-option.types';
import { ListOptionsActions } from './list-options.action-types';

export const listOptionsFeatureKey = 'listOptions';

export interface ListOptionsState {
    listOptions: ListOptionsType;
}

export const selectListOptionsState = createFeatureSelector<ListOptionsState>(
    'listOptions'
);

export const initialListOptionsState: ListOptionsState = {
    listOptions: listOptionsDefaultValue,
};

export const listOptionsReducer = createReducer(
    initialListOptionsState,
    on(
        ListOptionsActions.setListOptions,
        (state, action): ListOptionsState => {
            return {
                listOptions: action.listOptions,
            };
        }
    )
);
