import { Injectable } from '@angular/core';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { refresh } from '../../state/app.actions';
import { CustomerAdministration } from './customer-adminitration-entity.model';

@Injectable({ providedIn: 'root' })
export class CustomerAdministrationEntityService extends EntityCollectionServiceBase<
    CustomerAdministration
> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions
    ) {
        super('CustomerAdministration', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );
}
