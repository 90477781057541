import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { MatListModule } from '@angular/material/list';
import { LoginComponent } from './login.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthPageModule, SharedModule } from '@sansys/webapputils';
import { FormlyModule } from '@ngx-formly/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    declarations: [LoginComponent],
    imports: [
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        ReactiveFormsModule,
        SharedModule,
        MatListModule,
        RouterModule,
        AuthPageModule,
        FormlyModule,
        ExtendedModule,
        FlexModule,
        TranslocoModule,
    ],
})
export class LoginModule {}
