import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { setCompanyData } from './company.actions';

@Injectable()
export class CompanyEffects {
    constructor(private action$: Actions) {}

    setCompany$ = createEffect(
        () => this.action$.pipe(ofType(setCompanyData)),
        { dispatch: false }
    );
}
