import { Inject, Injectable } from '@angular/core';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { refresh } from '../../state';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';
import { NotificationCategory } from './notification-category.model';

@Injectable({ providedIn: 'root' })
export class NotificationCategoryEntityService extends EntityCollectionServiceBase<
    NotificationCategory
> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions,
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('NotificationCategory', serviceElementsFactory);
    }

    refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    getChannels(): Observable<any> {
        return this.http.get(
            this.server + 'api/notificationcategories/channels'
        );
    }

    getTypes(): Observable<any> {
        return this.http.get(this.server + 'api/notificationcategories/types');
    }
}
