import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { translate } from '@ngneat/transloco';
import { MatDialog } from '@angular/material/dialog';
import {
    Passenger,
    TransportEntityService,
    getPassengerAddress,
    OrderConfirmationResponse,
    getPassengerNameWithBirthday,
} from '@sansys/crosslib';
import { SelectDialogComponent } from '../../components/select-dialog/select-dialog.component';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TransportService {
    constructor(private injector: Injector, private dialog: MatDialog) {}

    showDuplicatePassengerSelect(
        orderConfirmationResponse: OrderConfirmationResponse
    ): Observable<OrderConfirmationResponse | undefined> {
        const passengerButtons: { title: string; value?: Passenger }[] = [];
        orderConfirmationResponse.foundDuplicatePassengers.forEach(
            (foundPassenger) => {
                if (!foundPassenger.id) {
                    return;
                }
                passengerButtons.push({
                    title: 'ÜBERNEHMEN: ' + getPassengerAddress(foundPassenger),
                    value: foundPassenger,
                });
            }
        );

        const currentPassengerAddress = getPassengerAddress(
            orderConfirmationResponse.transport.passenger
        );
        const currentPassengerNameWithBirthday = getPassengerNameWithBirthday(
            orderConfirmationResponse.transport.passenger
        );

        passengerButtons.push({
            title: 'NEU ANLEGEN: ' + currentPassengerAddress,
        });

        const dialogRef = this.dialog.open(SelectDialogComponent, {
            width: '400px',
            data: {
                title:
                    translate('passenger') +
                    ' mit gleichem Namen/Geburtstag bereits vorhanden. Bitte übernehmen oder neu anlegen.',
                subtitle: 'Name: ' + currentPassengerNameWithBirthday,
                buttons: passengerButtons,
            },
        });

        return dialogRef.afterClosed().pipe(
            switchMap((result) => {
                if (!result) {
                    return of(undefined);
                }
                if (result.title.includes('NEU ANLEGEN')) {
                    return this.injector
                        .get(TransportEntityService)
                        .confirmOrder(
                            orderConfirmationResponse.transport,
                            true
                        );
                } else {
                    orderConfirmationResponse.transport.passenger =
                        result.value;
                    return this.injector
                        .get(TransportEntityService)
                        .confirmOrder(
                            orderConfirmationResponse.transport,
                            true
                        );
                }
            })
        );
    }
}
