import { Injectable } from '@angular/core';
import { Dialog, DialogUtility } from '@syncfusion/ej2-angular-popups';
import {
    BugReportData,
    ErrorType,
    refresh,
    TrelloService,
} from '@sansys/crosslib';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { BugReportComponent } from '../components';
import { translate } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class ErrorMessageService {
    private currentErrorDialog?: Dialog;
    private alertIsActive = false;

    constructor(
        private http: HttpClient,
        private dialog: MatDialog,
        private store: Store<any>,
        private trelloService: TrelloService
    ) {}

    private askForDetails(message: string): void {
        const dialogRef = this.dialog.open(BugReportComponent, {
            width: '400px',
            data: {},
        });

        dialogRef.afterClosed().subscribe((result: BugReportData) => {
            if (result) {
                result.details += '   |   ' + message;
                this.trelloService.sendErrorToTrello(result).subscribe();
            }
        });
    }

    public displayErrorMessage(error: ErrorType): void {
        if (this.alertIsActive) {
            return;
        }

        this.alertIsActive = true;

        setTimeout(() => {
            this.alertIsActive = false;
        }, 4000);

        const message =
            error.message === 'Unknown Error'
                ? '<h4>' +
                  translate('Der Server nicht erreichbar.') +
                  '</h4>' +
                  translate(
                      'Bitte überprüfen Sie Ihre Internetverbindung oder wenden Sie sich an '
                  ) +
                  '<a href="mailto:support@sansys.ch">support@sansys.ch</a>'
                : translate(error.message);

        if (error.title === 'Info') {
            this.currentErrorDialog = DialogUtility.alert({
                title: error.title ? error.title : translate('Fehler'),
                content: translate(message),
                okButton: {
                    text: translate('Ok'),
                    click: () => this.currentErrorDialog?.hide(),
                },
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' },
            });
        } else {
            this.currentErrorDialog = DialogUtility.confirm({
                title: error.title ? error.title : 'Fehler',
                content: translate(message),
                okButton: {
                    text: translate('An SANSYS schicken'),
                    click: () => {
                        this.currentErrorDialog?.hide();
                        this.askForDetails(message);
                    },
                },
                cancelButton: {
                    text: translate('Ok'),
                    click: () => this.currentErrorDialog?.hide(),
                },
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' },
            });
        }
    }

    public askForConfirmation(
        request: HttpRequest<any>,
        options: string,
        requestType: 'duplicate' | 'transportInvoice' | 'worktime' = 'duplicate'
    ): void {
        if (requestType === 'duplicate') {
            this.currentErrorDialog = DialogUtility.confirm({
                title: translate('Mögliche Duplikat(e) gefunden: ') + options,
                content: translate('Trotzdem speichern?'),
                okButton: {
                    text: translate('Speichern'),
                    click: () => {
                        request = request.clone({
                            body: {
                                ...request.body,
                                ignoreExistingTransportLocations: true,
                            },
                        });
                        this.http.request(request).subscribe(() => {
                            this.store.dispatch(refresh());
                            this.currentErrorDialog?.hide();
                        });
                    },
                },
                cancelButton: {
                    text: translate('Abbrechen'),
                    click: () => {
                        this.store.dispatch(refresh());
                        this.currentErrorDialog?.hide();
                    },
                },
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' },
            });
        }
        if (requestType === 'transportInvoice') {
            this.currentErrorDialog = DialogUtility.confirm({
                title: translate('Änderung Rechnungsbetrag'),
                content:
                    '<h4>' +
                    translate('Rechnung stornieren und neu erstellen?') +
                    '</h4>',
                okButton: {
                    text: translate('Neu Erstellen'),
                    click: () => {
                        request = request.clone({
                            body: {
                                ...request.body,
                                cancelAndRecreateInvoice: true,
                            },
                        });
                        this.http.request(request).subscribe(() => {
                            this.store.dispatch(refresh());
                            this.currentErrorDialog?.hide();
                        });
                    },
                },
                cancelButton: {
                    text: translate('Abbrechen'),
                    click: () => {
                        this.store.dispatch(refresh());
                        this.currentErrorDialog?.hide();
                    },
                },
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' },
            });
        }
    }
}
