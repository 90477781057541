import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    ClothEntityService,
    EmployeeEntityService,
    FacilityEntityService,
    KeyyEntityService,
    refresh,
    refreshColumns,
    EntityBaseService,
    setAccessRights,
} from '@sansys/crosslib';
import {
    EditSettingsModel,
    GridComponent,
    SaveEventArgs,
    ToolbarItems,
    CommandClickEventArgs,
    ToolbarItem,
    RecordDoubleClickEventArgs,
    PageService,
    EditService,
    FilterService,
    SortService,
    CommandColumnService,
    SelectionService,
    SearchService,
    ForeignKeyService,
    ToolbarService,
    ExcelExportService,
    RowDataBoundEventArgs,
    ColumnModel,
    FilterSettingsModel,
    SortSettingsModel,
} from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { EColumnInterface } from '../../syncfusion/e-column.interface';
import {
    ECommandInterfaceArray,
    ECommandInterfaceObject,
} from '../../syncfusion/e-command-interface';
import {
    gridGlobalizationDe,
    gridGlobalizationFr,
    TableDefaults,
    tableDefaults,
} from '../../syncfusion';
import { Observable, Subscription } from 'rxjs';
import { ItemModel } from '@syncfusion/ej2-navigations';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'sys-feature-table',
    templateUrl: './feature-table.component.html',
    styleUrls: ['./feature-table.component.scss'],
    providers: [
        FacilityEntityService,
        EmployeeEntityService,
        ClothEntityService,
        KeyyEntityService,
        FilterService,
        PageService,
        SelectionService,
        SortService,
        ToolbarService,
        SearchService,
        EditService,
        ExcelExportService,
        CommandColumnService,
        ForeignKeyService,
    ],
})
export class FeatureTableComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('grid') public grid!: GridComponent;

    @Input() columns: EColumnInterface[] = [];
    @Input() entities$: Observable<{}[]> = new Observable<{}[]>();
    @Input() entities?: {}[];
    @Input() dataSourceEntities$?: Observable<{}[]>;
    @Input() asyncColumn?: EColumnInterface;
    @Input() commands?: object[];
    @Input() continueActions = false;
    @Input() customToolbar?: (
        | ToolbarItems
        | string
        | ItemModel
        | ToolbarItem
    )[];
    @Input() customEditSettings?: EditSettingsModel;
    @Input() customActionBegin?: (args: SaveEventArgs) => void;
    @Input() cancelEditClick = false;
    @Input() cancelAddClick = false;
    @Input() cancelDeleteClick = false;
    @Input() cancelDoubleClick = false;
    @Input() refresh = true;
    @Input() listenToRefresh = true;
    @Input() entityType?: string;
    @Input() customFilterSettings?: FilterSettingsModel;
    @Input() openNewInDetailView = false;
    @Input() permission?: string;
    @Input() additionalEntityValues?: object;
    @Input() sortSettings?: SortSettingsModel;

    @Output() commandTriggered = new EventEmitter<
        ECommandInterfaceObject | ECommandInterfaceArray
    >();
    @Output() rowSelected = new EventEmitter<any[] | null>();
    @Output() rowDataBound = new EventEmitter<RowDataBoundEventArgs | null>();

    private subscription = new Subscription();

    tableDefaults: TableDefaults = JSON.parse(JSON.stringify(tableDefaults));
    toolbar: (ToolbarItems | string | ItemModel | ToolbarItem)[] = [
        'ExcelExport',
        'Search',
    ];

    constructor(
        private entityService: EntityBaseService<any>,
        private store: Store<any>,
        private router: Router,
        private route: ActivatedRoute,
        private action$: Actions,
        private permissionService: NgxPermissionsService,
        private translocoService: TranslocoService
    ) {}

    private addEntity(entity: any): void {
        let ent = JSON.parse(JSON.stringify(entity));
        if (this.additionalEntityValues) {
            ent = { ...entity, ...this.additionalEntityValues };
        }
        this.entityService.add(ent);
    }

    private fetchAllDataAndUpdate(entity: any): void {
        this.entityService.getByKey(entity.id).subscribe((fetchedEntity) => {
            const entityToUpdate = JSON.parse(JSON.stringify(fetchedEntity));
            this.grid.columns.forEach((col: any) => {
                if (col.field) {
                    if ((col.field as string).includes('.')) {
                        // In case it is a nested object, e.g. in qm document
                        const firstSegment = col.field.split('.')[0];
                        if (entityToUpdate[firstSegment]) {
                            entityToUpdate[firstSegment] = entity[firstSegment];
                        }
                    } else {
                        entityToUpdate[col.field] = entity[col.field];
                    }
                }
            });
            this.entityService.update(entityToUpdate);
        });
    }

    private deleteEntity(entity: any): void {
        this.entityService.delete(entity.id);
    }

    private setPermission(): void {
        if (this.customToolbar) {
            this.toolbar = this.customToolbar;
        }
        if (this.customEditSettings) {
            this.tableDefaults.editSettings = this.customEditSettings;
        }

        if (this.customToolbar) {
            return;
        }

        const permissions = this.permissionService.getPermissions();
        const editPerm = this.permission + '|EDIT';
        const createPerm = this.permission + '|CREATE';
        const deletePerm = this.permission + '|DELETE';

        this.tableDefaults.editSettings = {
            allowEditing: false,
            allowAdding: false,
            allowDeleting: false,
            showDeleteConfirmDialog: true,
            mode: 'Normal',
        };

        if (permissions[editPerm]) {
            this.toolbar = [
                'Edit',
                'Update',
                'Cancel',
                'ExcelExport',
                'Search',
            ];
            this.tableDefaults.editSettings = {
                allowEditing: true,
                allowAdding: false,
                allowDeleting: false,
                showDeleteConfirmDialog: true,
                mode: 'Normal',
            };
        }

        if (permissions[createPerm]) {
            this.toolbar = [
                'Add',
                'Edit',
                'Update',
                'Cancel',
                'ExcelExport',
                'Search',
            ];
            this.tableDefaults.editSettings = {
                allowEditing: true,
                allowAdding: true,
                allowDeleting: false,
                showDeleteConfirmDialog: true,
                mode: 'Normal',
            };
        }

        if (permissions[deletePerm] || permissions['admin']) {
            this.toolbar = [
                'Add',
                'Edit',
                'Update',
                'Cancel',
                'Delete',
                'ExcelExport',
                'Search',
            ];
            this.tableDefaults.editSettings = {
                allowEditing: true,
                allowAdding: true,
                allowDeleting: true,
                showDeleteConfirmDialog: true,
                mode: 'Normal',
            };
        }
    }

    changeLocale(): void {
        let activeLang = this.translocoService.getActiveLang();
        activeLang = activeLang.split('_')[1];
        this.grid.locale = activeLang; // need to change the locale dynamically for grid
        L10n.load({
            gridGlobalizationDe,
            gridGlobalizationFr,
        }); // load corresponding culture text
        setCulture(activeLang); // Change the Grid culture
    }

    ngOnInit(): void {
        this.setPermission();

        this.subscription.add(
            this.action$
                .pipe(
                    ofType(refreshColumns),
                    tap((data) => {
                        if (
                            !this.entityType ||
                            this.entityType === data.entityType
                        ) {
                            this.initializeColumns(data.columns);
                        }
                    })
                )
                .subscribe()
        );

        this.subscription.add(
            this.action$.pipe(ofType(setAccessRights)).subscribe(() => {
                setTimeout(() => {
                    this.setPermission();
                }, 200);
            })
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['customToolbar']) {
            this.toolbar = changes['customToolbar']['currentValue'];
        }
    }

    private initializeColumns(columns: ColumnModel[]): void {
        this.grid.columns = JSON.parse(JSON.stringify(columns));
    }

    private initialize(): void {
        if (this.asyncColumn) {
            this.asyncColumn = { ...this.asyncColumn };
        }
        if (this.listenToRefresh) {
            this.subscription.add(this.entityService.refresh$.subscribe());
        }
        if (this.refresh) {
            this.store.dispatch(refresh());
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onCreated(): void {
        this.initialize();
    }

    onActionBegin(args: SaveEventArgs): void {
        if (this.cancelDoubleClick && args.requestType === 'beginEdit') {
            args.cancel = true;
            return;
        }
        if (this.customActionBegin) {
            this.customActionBegin(args);
            if (!this.continueActions) {
                return;
            }
        }
        if (args.requestType === 'delete') {
            const entities = args.data as [];
            entities.forEach((e) => this.deleteEntity(e));
            return;
        }

        if (!args.action) {
            return;
        }

        const entity = { ...args.data } as any;

        switch (args.action) {
            case 'add':
                this.addEntity(entity);
                break;
            case 'edit':
                this.fetchAllDataAndUpdate(entity);
                break;
        }
    }

    onToolbarClick(args: ClickEventArgs): void {
        if (this.openNewInDetailView && args.item.id?.includes('add')) {
            this.router.navigate(['new'], { relativeTo: this.route });
            args.cancel = true;
        }
        if (this.cancelEditClick && args.item.id?.includes('edit')) {
            args.cancel = true;
        }
        if (this.cancelAddClick && args.item.id?.includes('add')) {
            args.cancel = true;
        }
        if (this.cancelDeleteClick && args.item.id?.includes('delete')) {
            args.cancel = true;
        }
        if (!args.item.id) {
            return;
        }
        if (args.item.id.indexOf('excelexport') > 0) {
            this.grid.excelExport();
        }
        this.commandTriggered.emit({
            name: args.item.id,
            entity: this.grid.getSelectedRecords(),
        });
    }

    onCommandClick(args: CommandClickEventArgs): void {
        if (!args.target || !args.target.innerHTML || !args.rowData) {
            return;
        }
        this.grid.clearRowSelection();
        this.commandTriggered.emit({
            name: args.target.innerHTML,
            entity: args.rowData,
        });
    }

    onDoubleClick(args: RecordDoubleClickEventArgs): void {
        if (!args.rowData) {
            return;
        }
        this.commandTriggered.emit({
            name: 'doubleClicked',
            entity: args.rowData,
        });
    }

    onRowSelected(): void {
        this.rowSelected.emit(this.grid.getSelectedRecords());
    }

    onRowDataBound(args: RowDataBoundEventArgs): void {
        this.rowDataBound.emit(args);
    }

    actionComplete(args: any): void {
        if (args.requestType === 'filterafteropen') {
            const gridElement: Element = this.grid.element;
            // You can customize the filter dialog options for each datatype here
            if (args.columnType === 'number') {
                const ele = gridElement.getElementsByClassName(
                    'e-autocomplete'
                )[0] as any;
                if (ele) {
                    ele.ej2_instances[0].format = '0'; // You can customize decimal value here
                }
                // (gridElement.getElementsByClassName(
                //     'e-numerictextbox'
                // )[0] as any).ej2_instances[0].placeholder =
                //     'Wert eintragen'; // You can customize placeholder value here
                // (gridElement.getElementsByClassName(
                //     'e-numerictextbox'
                // )[0] as any).ej2_instances[0].cssClass =
                //     'e-custom-numeric-style'; // You can customize css styles value here
            }
            if (args.columnType === 'string') {
                const ele = gridElement.getElementsByClassName(
                    'e-autocomplete'
                )[0] as any;
                if (ele) {
                    ele.ej2_instances[0].minLength = 200;
                }
                // You can customize query Length here
            }
            // if (args.columnType === 'datetime') {
            //     (gridElement.getElementsByClassName(
            //         'e-datetimepicker'
            //     )[0] as any).ej2_instances[0].cssClass = 'e-custom-style';
            // }
        }
    }
}
