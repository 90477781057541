import { NgModule } from '@angular/core';

import { LoadingComponent } from './loading.component';
import { SharedModule } from '../../shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    declarations: [LoadingComponent],
    imports: [SharedModule, FlexLayoutModule, CommonModule, TranslocoModule],
    exports: [LoadingComponent],
})
export class LoadingModule {}
