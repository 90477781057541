import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthPageComponent } from './auth-page.component';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    declarations: [AuthPageComponent],
    imports: [
        CommonModule,
        FlexModule,
        ExtendedModule,
        MatButtonModule,
        MatIconModule,
        RouterModule,
        TranslocoModule,
    ],
    exports: [AuthPageComponent],
})
export class AuthPageModule {}
