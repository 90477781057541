import { Injectable } from '@angular/core';
import {
    EntityActionOptions,
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { map, tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Training } from './training.model';
import { refresh } from '../../state/app.actions';

@Injectable({ providedIn: 'root' })
export class TrainingEntityService extends EntityCollectionServiceBase<Training> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions
    ) {
        super('Training', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    public entitiesForList$ = this.entities$.pipe(
        map((trainings) =>
            trainings.map((training) => {
                const def: Training = JSON.parse(JSON.stringify(training));
                if (def.startDate) {
                    def.startDate = new Date(def.startDate);
                }
                if (def.endDate) {
                    def.endDate = new Date(def.endDate);
                }
                return def;
            })
        )
    );

    public add(entity: Training): Observable<Training> {
        if (entity.startDate) {
            entity.startDate = moment(entity.startDate).format('YYYY-MM-DD');
        }
        if (entity.endDate) {
            entity.endDate = moment(entity.endDate).format('YYYY-MM-DD');
        }
        return super.add(entity);
    }

    public update(
        entity: Partial<Training>,
        options?: EntityActionOptions
    ): Observable<Training> {
        if (entity.startDate) {
            entity.startDate = moment(entity.startDate).format('YYYY-MM-DD');
        }
        if (entity.endDate) {
            entity.endDate = moment(entity.endDate).format('YYYY-MM-DD');
        }
        return super.update(entity, options);
    }
}
