<sys-feature-container>
    <sys-feature-header
        [id]="id"
        [title]="transport?.transportNumber + ': ' + transport?.protocolName"
    >
        <div feature-header-right>
            <button mat-raised-button (click)="printPdf()" class="mr-12">
                Pdf anzeigen
            </button>
            <button
                mat-raised-button
                (click)="onSaveTransport()"
                class="mr-12"
                color="accent"
            >
                {{ 'Speichern' | transloco }}
            </button>
            <button mat-raised-button (click)="onCloseModal()">
                {{ 'Abbrechen' | transloco }}
            </button>
        </div>
    </sys-feature-header>

    <ng-container page-content>
        <div class="p-12">
            <div *ngFor="let protocolSection of transport?.protocolSections">
                <h1>{{ protocolSection.sectionName }}</h1>
                <mat-list>
                    <mat-list-item
                        *ngFor="
                            let protocolItem of protocolSection.sectionItems
                        "
                        class="h-100-p"
                    >
                        <div
                            fxLayout="row"
                            fxLayoutAlign="space-between none"
                            class="w-100-p h-56"
                        >
                            <div class="w-100-p">
                                <mat-form-field
                                    *ngIf="protocolItem.inputType !== 'check'"
                                    class="h-48 w-100-p"
                                    appearance="outline"
                                    floatLabel="always"
                                >
                                    <mat-label
                                        >{{ protocolItem.title
                                        }}<span
                                            *ngIf="
                                                protocolItem.mandatoryBeforeInvoice
                                            "
                                            >&nbsp;({{
                                                'Pflichtfeld vor Rechnungsstellung'
                                                    | transloco
                                            }})</span
                                        ></mat-label
                                    >
                                    <input
                                        matInput
                                        type="text"
                                        [(ngModel)]="protocolItem.value"
                                        *ngIf="
                                            protocolItem.inputType === 'text'
                                        "
                                    />
                                    <mat-select
                                        [(ngModel)]="protocolItem.value"
                                        *ngIf="
                                            protocolItem.inputType === 'select'
                                        "
                                    >
                                        <mat-option value=""></mat-option>
                                        <mat-option
                                            *ngFor="
                                                let option of protocolItem.options
                                            "
                                            [value]="option"
                                        >
                                            {{ option }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-checkbox
                                    *ngIf="protocolItem.inputType === 'check'"
                                    labelPosition="before"
                                    [(ngModel)]="protocolItem.value"
                                    >{{ protocolItem.title }}</mat-checkbox
                                >
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-list-item *ngIf="noTransportProtocolItems()">
                        Keine Einträge gefunden
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
    </ng-container>
</sys-feature-container>
