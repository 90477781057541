import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
    selector: 'sys-formly-repeat-section',
    template: `
        <div
            *ngFor="let field of field.fieldGroup; let i = index"
            class="display-flex"
        >
            <formly-field class="flex-75 pr-12" [field]="field"></formly-field>
            <div>
                <button
                    mat-raised-button
                    color="warn"
                    type="button"
                    (click)="remove(i)"
                    [disabled]="getDeleteDisabled()"
                >
                    {{ 'Löschen' | transloco }}
                </button>
            </div>
        </div>
        <div>
            <button
                mat-raised-button
                color="primary"
                type="button"
                (click)="add()"
                [disabled]="field.templateOptions?.disabled"
            >
                {{ to.addText }}
            </button>
        </div>
    `,
})
export class TariffRepeaterFormlyTypeComponent extends FieldArrayType {
    getDeleteDisabled(): boolean {
        return !!this.field.templateOptions?.disabled;
    }
}
