import { TransportModel } from '@sansys/crosslib';

export function getTransportAddress(
    type: 'start' | 'target',
    transport?: TransportModel
): string {
    if (!transport) {
        return 'Transport nicht gefunden';
    }
    let passengerAddress = '';
    if (transport.passenger) {
        passengerAddress =
            (transport.passenger.street
                ? transport.passenger.street + ', '
                : '') +
            (transport.passenger.zip ? transport.passenger.zip + ' ' : '') +
            (transport.passenger.city ? transport.passenger.city : '');
    }
    if (type === 'start') {
        if (transport.passengerIsStart && transport.passenger) {
            return passengerAddress;
        } else if (transport.startLocation) {
            return (
                transport.startLocation.street +
                ', ' +
                transport.startLocation.zip +
                ' ' +
                transport.startLocation.city
            );
        }
    }
    if (type === 'target') {
        if (transport.passengerIsTarget && transport.passenger) {
            return passengerAddress;
        } else if (transport.targetLocation) {
            return (
                transport.targetLocation.street +
                ', ' +
                transport.targetLocation.zip +
                ' ' +
                transport.targetLocation.city
            );
        }
    }
    return 'Adresse nicht gefunden';
}
