import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ErrorMessageService } from './error-message.service';
import {
    errorOccured,
    LoggingService,
    notRefresh,
    refresh,
    showRequestConfirmation,
} from '@sansys/crosslib';

@Injectable({ providedIn: 'root' })
export class ErrorEffects {
    constructor(
        private actions$: Actions,
        private errorMessageService: ErrorMessageService,
        private loggingService: LoggingService
    ) {}

    private refreshed = false;

    errorOccured$ = createEffect(() =>
        this.actions$.pipe(
            ofType(errorOccured),
            tap((error) => {
                this.errorMessageService.displayErrorMessage(error);
                this.loggingService.logError(error.message, error.stackTrace);
            }),
            map((error) => {
                if (!this.refreshed && error.refresh) {
                    this.refreshed = true;
                    setTimeout(() => {
                        this.refreshed = false;
                    }, 5000);
                    return refresh();
                }
                return notRefresh();
            })
        )
    );

    showRequestConfirmation$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(showRequestConfirmation),
                tap((data) => {
                    this.errorMessageService.askForConfirmation(
                        data.originalRequest,
                        data.foundResults,
                        data.requestType
                    );
                })
            ),
        { dispatch: false }
    );
}
