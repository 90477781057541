import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns/src/drop-down-base/drop-down-base-model';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { InsuranceEntityService } from '@sansys/crosslib';
import { filter, first, switchMapTo, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';

@Component({
    selector: 'sys-health-insurance-autocomplete-type',
    template: `
        <ng-container #fieldComponent></ng-container>
        <div class="mb-20">
            <ejs-dropdownlist
                [showClearButton]="true"
                cssClass="e-outline"
                floatLabelType="Always"
                [dataSource]="data"
                [fields]="fields"
                [formControl]="formControl"
                [placeholder]="to.placeholder ? to.placeholder : ''"
                [allowFiltering]="true"
                #keyyDropdown
                (filtering)="onFiltering($event)"
                data-testid="insuranceAutocomplete"
                [ngClass]="{
                    'required-field':
                        to.required && formControl.status !== 'VALID'
                }"
            >
            </ejs-dropdownlist>
            <div
                class="mat-error"
                *ngIf="
                    formControl.status !== 'VALID' &&
                    formControl.touched &&
                    to.required
                "
            >
                Pflichtfeld, bitte ausfüllen.
            </div>
        </div>
    `,
})
export class InsuranceAutocompleteWrapperComponent
    extends FieldWrapper
    implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('keyyDropdown') keyyDropdown!: DropDownListComponent;
    private subscription = new Subscription();
    public data: {
        [key: string]: object;
    }[] = [];
    public formControl!: FormControl;
    // maps the appropriate column to fields property
    public fields: FieldSettingsModel = { text: 'listName', value: 'id' };

    // Bind the filter event
    public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
        let query = new Query();
        query =
            e.text !== ''
                ? query.where('name', 'contains', e.text, true)
                : query;
        e.updateData(this.data, query);
    };

    constructor(private insuranceEntityService: InsuranceEntityService) {
        super();
    }

    ngOnInit(): void {
        this.subscription.add(
            this.insuranceEntityService.loaded$
                .pipe(
                    filter((isLoaded) => isLoaded),
                    switchMapTo(this.insuranceEntityService.entities$),
                    first()
                )
                .subscribe((insurances) => {
                    this.data.length = 0;
                    insurances.forEach((insurance) => {
                        const value = JSON.parse(JSON.stringify(insurance));
                        value['listName'] =
                            insurance.insuranceType + ': ' + insurance.name;
                        this.data.push(value);
                    });
                })
        );

        this.insuranceEntityService.loaded$
            .pipe(
                filter((isLoaded) => !isLoaded),
                first(),
                tap(() => {
                    this.insuranceEntityService.load();
                })
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        const dropdownInput = this.keyyDropdown.element.getElementsByTagName(
            'input'
        )[0];
        if (dropdownInput && this.to.placeholder) {
            if (this.to.required) {
                dropdownInput.placeholder =
                    this.to.placeholder + ' wählen (Pflichtfeld)';
            } else {
                dropdownInput.placeholder = this.to.placeholder + ' wählen';
            }
        }
    }

    // public onClick(): void {
    //     const newVal = (this.keyyDropdown as any).filterInput.value;
    //
    //     const newInsurance = createInsurance({
    //         name: newVal,
    //         insuranceType: 'UV',
    //     });
    //     this.insuranceEntityService.add(newInsurance).subscribe((insurance) => {
    //         this.keyyDropdown.addItem(insurance as any);
    //         this.keyyDropdown.value = insurance as any;
    //     });
    // }
    //
    // noDropDownValue(): boolean {
    //     return !!(this.keyyDropdown as any).filterInput?.value;
    // }
}
