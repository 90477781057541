import * as moment from 'moment';
import { TransportModel } from '../transport-entity.model';

export function stringifyDate(date: any): string {
    return date instanceof Object ? moment(date).toISOString(true) : date;
}

export function stringifyTransportDates(
    transport: TransportModel
): TransportModel {
    const t = JSON.parse(JSON.stringify(transport));
    t.createdAt = stringifyDate(transport.createdAt);
    t.plannedStart = stringifyDate(transport.plannedStart);
    t.plannedEnd = stringifyDate(transport.plannedEnd);
    t.transportStart = stringifyDate(transport.transportStart);
    t.arrivalStart = stringifyDate(transport.arrivalStart);
    t.departureStart = stringifyDate(transport.departureStart);
    t.arrivalDestination = stringifyDate(transport.arrivalDestination);
    t.departureDestination = stringifyDate(transport.departureDestination);
    t.arrivalStartReturn = stringifyDate(transport.arrivalStartReturn);
    t.transportFinished = stringifyDate(transport.transportFinished);
    t.teamReady = stringifyDate(transport.teamReady);
    t.orderedStart = stringifyDate(transport.orderedStart);
    return t;
}
