import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
    selector: 'sys-formly-repeat-section',
    template: `
        <div *ngFor="let field of field.fieldGroup; let i = index">
            <div class="display-flex">
                <formly-field
                    class="flex-75 pr-12"
                    [field]="field"
                ></formly-field>
                <div>
                    <button
                        mat-raised-button
                        color="warn"
                        type="button"
                        (click)="remove(i)"
                        [disabled]="getDeleteDisabled() || to.disabled"
                    >
                        {{ 'Löschen' | transloco }}
                    </button>
                </div>
            </div>
            <div *ngIf="this.model[i].type === 'select'" class="mb-20">
                <div
                    *ngFor="
                        let selectOption of model[i]['selectOptions'];
                        let j = index
                    "
                >
                    <div class="display-flex">
                        <mat-form-field
                            appearance="outline"
                            floatLabel="always"
                            class="flex-50 pr-12 pl-32"
                        >
                            <mat-label>{{
                                'Name Auswahlfeld' | transloco
                            }}</mat-label>
                            <input
                                matInput
                                placeholder="{{
                                    'Name Auswahlfeld' | transloco
                                }}"
                                [value]="selectOption.name"
                                [disabled]="
                                    field.templateOptions?.disabled ||
                                    to.disabled
                                "
                                (change)="
                                    changeValue(i, j, $event.target, 'name')
                                "
                            />
                        </mat-form-field>
                        <mat-form-field
                            appearance="outline"
                            floatLabel="always"
                            class="w-160 pr-12"
                        >
                            <mat-label>{{
                                'Betrag in CHF' | transloco
                            }}</mat-label>
                            <input
                                matInput
                                type="number"
                                placeholder="{{ 'Betrag in CHF' | transloco }}"
                                [value]="selectOption.price"
                                [disabled]="
                                    field.templateOptions?.disabled ||
                                    to.disabled
                                "
                                (change)="
                                    changeValue(i, j, $event.target, 'price')
                                "
                            />
                        </mat-form-field>
                        <div>
                            <button
                                mat-raised-button
                                color="warn"
                                type="button"
                                class="mr-40"
                                (click)="removeOption(i, j)"
                                [disabled]="
                                    field.templateOptions?.disabled ||
                                    to.disabled
                                "
                            >
                                {{ 'Auswahlfeld löschen' | transloco }}
                            </button>
                        </div>
                    </div>
                </div>
                <button
                    mat-raised-button
                    color="primary"
                    type="button"
                    class="ml-32"
                    (click)="addSelectOption(i)"
                    [disabled]="field.templateOptions?.disabled || to.disabled"
                >
                    {{ 'Auswahlmöglichkeit hinzufügen' | transloco }}
                </button>
            </div>
        </div>

        <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="add()"
            class="w-100-p"
            [disabled]="field.templateOptions?.disabled"
        >
            {{ to.addText || to.label + ' ' }} {{ 'hinzufügen' | transloco }}
        </button>
    `,
})
export class OptionRepeaterFormlyTypeComponent extends FieldArrayType {
    addSelectOption(i: number): void {
        if (!this.model[i]['selectOptions']) {
            this.model[i]['selectOptions'] = [];
        }
        this.model[i]['selectOptions'].push({ name: null, price: null });
    }

    removeOption(i: number, j: number): void {
        this.model[i]['selectOptions'].splice(j, 1);
    }

    changeValue(
        i: number,
        j: number,
        target: any,
        type?: 'name' | 'price'
    ): void {
        if (!target) {
            return;
        }
        if (type === 'name') {
            this.model[i]['selectOptions'][j]['name'] = target['value'];
        } else if (type === 'price') {
            this.model[i]['selectOptions'][j]['price'] = parseFloat(
                target['value']
            );
        } else {
            this.model[i]['selectOptions'][j] = target['value'];
        }
        this.formControl.markAsDirty();
    }

    getDeleteDisabled(): boolean {
        return !!this.field.templateOptions?.disabled;
    }
}
