<ejs-grid
    [dataSource]="entities ? entities : (entities$ | async)!"
    [allowPaging]="true"
    [allowSorting]="true"
    [allowFiltering]="true"
    [allowExcelExport]="true"
    [pageSettings]="tableDefaults.pageSettings"
    [editSettings]="tableDefaults.editSettings"
    [filterSettings]="
        customFilterSettings
            ? customFilterSettings
            : tableDefaults.filterOptions
    "
    [sortSettings]="sortSettings"
    [selectionSettings]="tableDefaults.selectionOptions"
    [toolbar]="toolbar"
    (actionBegin)="onActionBegin($event)"
    (toolbarClick)="onToolbarClick($event)"
    (commandClick)="onCommandClick($event)"
    (recordDoubleClick)="onDoubleClick($event)"
    (created)="onCreated()"
    (rowSelected)="onRowSelected()"
    (rowDeselected)="onRowSelected()"
    [allowTextWrap]="true"
    (rowDataBound)="onRowDataBound($event)"
    (actionComplete)="actionComplete($event)"
    [textWrapSettings]="{ wrapMode: 'Content' }"
    (load)="changeLocale()"
    height="100%"
    #grid
>
    <e-columns>
        <e-column
            type="checkbox"
            [customAttributes]="{ class: 'nowrap' }"
            field="checkbox"
            width="50"
            [allowFiltering]="false"
        ></e-column>
        <e-column
            *ngIf="commands"
            headerText="{{ 'Funktionen' | transloco }}"
            width="130"
            [commands]="commands"
        ></e-column>
        <e-column
            *ngFor="let column of columns"
            clipMode="EllipsisWithTooltip"
            [width]="column.width ? column.width : 180"
            [minWidth]="column.minWidth ? column.minWidth : 180"
            [edit]="column.edit ? column.edit : null"
            [editType]="column.editType ? column.editType : null"
            [allowEditing]="column.allowEditing"
            [allowFiltering]="column.allowFiltering"
            [type]="column.type ? column.type : 'string'"
            [sortComparer]="column.sortComparer ? column.sortComparer : null"
            [field]="column.field"
            [disableHtmlEncode]="
                column.disableHtmlEncode ? column.disableHtmlEncode : false
            "
            [headerText]="column.headerText"
            [filter]="column.filter ? column.filter : { params: {} }"
            [validationRules]="
                column.validationRules ? column.validationRules : null
            "
            [foreignKeyValue]="
                column.foreignKeyValue ? column.foreignKeyValue : null
            "
            [foreignKeyField]="
                column.foreignKeyField ? column.foreignKeyField : null
            "
            [dataSource]="column.dataSource ? column.dataSource : null"
            [visible]="column.visible !== undefined ? column.visible : true"
            [commands]="column.commands ? column.commands : null"
            [customAttributes]="
                column.customAttributes ? column.customAttributes : null
            "
            [format]="column.format"
            [valueAccessor]="column.valueAccessor ? column.valueAccessor : null"
        ></e-column>
        <e-column
            *ngIf="asyncColumn"
            headerText="{{ asyncColumn.headerText }}"
            field="{{ asyncColumn.field }}"
            [dataSource]="dataSourceEntities$ | async"
            foreignKeyField="{{ asyncColumn.foreignKeyField }}"
            foreignKeyValue="{{ asyncColumn.foreignKeyValue }}"
            width="{{ asyncColumn.width }}"
            minWidth="{{ asyncColumn.width ? asyncColumn.width : 180 }}"
            editType="{{ asyncColumn.editType }}"
            [filter]="asyncColumn.filter"
            type="{{ asyncColumn.type }}"
        >
        </e-column>
    </e-columns>
</ejs-grid>
