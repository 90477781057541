import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldWrapper } from '@ngx-formly/core';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns/src/drop-down-base/drop-down-base-model';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import {
    passengerCreated,
    PassengerEntityService,
    passengerUpdated,
    showEntityAddOrEditPage,
} from '@sansys/crosslib';
import { tap } from 'rxjs/operators';
import {
    ChangeEventArgs,
    DropDownListComponent,
} from '@syncfusion/ej2-angular-dropdowns';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';
import { getSearchQuery } from '../../syncfusion/query.functions';
import { DataManager, Query } from '@syncfusion/ej2-data';

@Component({
    selector: 'sys-passenger-autocomplete-type',
    template: `
        <div class="mb-20">
            <ejs-dropdownlist
                [showClearButton]="true"
                cssClass="e-outline"
                floatLabelType="Always"
                [dataSource]="data"
                [query]="query"
                [fields]="fields"
                [formControl]="formControl"
                [placeholder]="getPlaceholder()"
                [allowFiltering]="true"
                (change)="onChange($event)"
                (filtering)="onFiltering($event)"
                [itemTemplate]="itemTemplate"
                data-testid="passengerAutocomplete"
                id="{{ field.key }}"
                #dropdown
                [ngClass]="{
                    'required-field':
                        to.required &&
                        formControl.status !== 'VALID' &&
                        !to.disabled
                }"
            >
                <ng-template #itemTemplate="" let-data="">
                    <!--set the value to itemTemplate property-->
                    <span
                        ><span
                            [ngClass]="{
                                'sys--danger-text-color-dark': !!data.warningMessage
                            }"
                        >
                            {{ data.fullNameWithBirthday }}</span
                        ></span
                    >
                </ng-template>
            </ejs-dropdownlist>
            <span class="sys--danger-text-color-dark">
                {{ this.to.originalPassenger?.warningMessage }}
            </span>
            <button
                mat-button
                color="primary"
                class="float-right"
                (click)="showAddPassengerDialog()"
                *ngIf="!to.disabled"
            >
                {{ ('passenger' | transloco) + ' hinzufügen' | transloco }}
            </button>
            <div
                class="mat-error"
                *ngIf="
                    formControl.status !== 'VALID' &&
                    formControl.touched &&
                    to.required
                "
            >
                {{ 'Pflichtfeld, bitte ausfüllen.' | transloco }}
            </div>
        </div>
    `,
})
export class PassengerAutocompleteWrapperComponent
    extends FieldWrapper
    implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('dropdown') public dropdownObject!: DropDownListComponent;

    private subscription = new Subscription();

    public query: Query = new Query().from('passenger').take(100);
    data: DataManager = new DataManager();
    public formControl!: FormControl;
    // maps the appropriate column to fields property
    public fields: FieldSettingsModel = {
        text: 'fullNameWithBirthday',
        value: 'id',
    };

    // Bind the filter event
    public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
        e.updateData(this.data, getSearchQuery('fullNameWithBirthday', e.text));
    };

    constructor(
        private passengerEntityService: PassengerEntityService,
        public dialog: MatDialog,
        private action$: Actions,
        private store: Store<any>
    ) {
        super();
    }

    ngAfterViewInit(): void {
        this.passengerEntityService
            .getDropdownListDataSource()
            .subscribe((manager) => {
                this.data = manager;
            });
        const dropdownInput = this.dropdownObject.element.getElementsByTagName(
            'input'
        )[0];
        if (dropdownInput && this.to.placeholder) {
            if (this.to.required) {
                dropdownInput.placeholder =
                    this.to.placeholder + ' wählen (Pflichtfeld)';
            } else {
                dropdownInput.placeholder = this.to.placeholder + ' wählen';
            }
        }
    }

    ngOnInit(): void {
        this.subscription.add(
            this.action$
                .pipe(
                    ofType(passengerCreated),
                    tap((data) => {
                        const fullNameWithBirthday =
                            data.passenger.firstName +
                            ' ' +
                            data.passenger.lastName +
                            ' ' +
                            moment(data.passenger.birthday).format(
                                'DD.MM.YYYY'
                            );
                        const pass = {
                            ...data.passenger,
                            ...{ fullNameWithBirthday: fullNameWithBirthday },
                        };
                        this.data.insert(pass);
                    })
                )
                .subscribe()
        );

        if (this.to.originalPassenger) {
            const fullNameWithBirthday =
                this.to.originalPassenger.firstName +
                ' ' +
                this.to.originalPassenger.lastName +
                ' ' +
                moment(this.to.originalPassenger.birthday).format('DD.MM.YYYY');
            const pass = {
                ...this.to.originalPassenger,
                ...{ fullNameWithBirthday: fullNameWithBirthday },
            };
            this.data.insert(pass);
        }

        this.subscription.add(
            this.action$
                .pipe(
                    ofType(passengerUpdated),
                    tap((data) => {
                        const fullNameWithBirthday =
                            data.passenger.firstName +
                            ' ' +
                            data.passenger.lastName +
                            ' ' +
                            moment(data.passenger.birthday).format(
                                'DD.MM.YYYY'
                            );
                        const pass = {
                            ...data.passenger,
                            ...{ fullNameWithBirthday: fullNameWithBirthday },
                        };

                        if (pass.id) {
                            this.data.remove(pass.id, pass);
                            this.data.insert(pass);
                        }
                    })
                )
                .subscribe()
        );

        // this.subscription.add(
        //     this.passengerEntityService.loaded$
        //         .pipe(
        //             filter((isLoaded) => isLoaded),
        //             switchMapTo(this.passengerEntityService.entities$),
        //             first()
        //         )
        //         .subscribe((passengers) => {
        //             this.data.length = 0;
        //             passengers.forEach((passenger) => {
        //                 const fullNameWithBirthday =
        //                     passenger.firstName +
        //                     ' ' +
        //                     passenger.lastName +
        //                     ' ' +
        //                     moment(passenger.birthday).format('DD.MM.YYYY');
        //                 const pass = {
        //                     ...passenger,
        //                     ...{ fullNameWithBirthday: fullNameWithBirthday },
        //                 };
        //                 this.data.push(pass as any);
        //             });
        //         })
        // );

        // this.subscription.add(
        //     combineLatest([
        //         this.passengerEntityService.loaded$,
        //         this.passengerEntityService.loading$,
        //     ])
        //         .pipe(
        //             filter(([isLoaded, isLoading]) => !isLoaded && !isLoading),
        //             first(),
        //             tap(() => {
        //                 this.passengerEntityService.load();
        //             })
        //         )
        //         .subscribe()
        // );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onChange(args: ChangeEventArgs): void {
        if (
            this.field &&
            this.field.templateOptions &&
            this.field.templateOptions.changeCallback
        ) {
            this.field.templateOptions.changeCallback(
                this.dropdownObject.getDataByValue(args.value)
            );
        }
    }

    showAddPassengerDialog(): void {
        this.store.dispatch(
            showEntityAddOrEditPage({
                entityType: 'passenger',
            })
        );
    }

    getPlaceholder(): string {
        if (this.to.placeholder) {
            if (this.to.required) {
                return this.to.placeholder + ' *';
            } else {
                return this.to.placeholder;
            }
        }
        return '';
    }
}
