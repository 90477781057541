import * as moment from 'moment';

export function getYearsArray(startYear?: number, endYear?: number): number[] {
    const startY = startYear || 2021;
    const endY = endYear || moment().year();
    const years = [startY];
    if (startY === endY) {
        return years;
    }
    for (let i = startY + 1; i <= endY; i++) {
        years.push(i);
    }
    return years;
}

export function getMonthsArray(): number[] {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
}

export function getDateStringFromDate(currentDate: Date): string {
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-based
    const day = ('0' + currentDate.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
}

export function getDaysInYear(year: number): number {
    const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    return isLeapYear ? 366 : 365;
}
