import { Component, Input, Inject } from '@angular/core';
import { FEATURE_INFOS } from '@sansys/crosslib';
import { fuseAnimations } from '../../animations';

@Component({
    selector: 'sys-feature-header[id]',
    templateUrl: './feature-header.component.html',
    styleUrls: ['./feature-header.component.scss'],
    animations: fuseAnimations,
})
export class FeatureHeaderComponent {
    @Input() icon?: string;
    @Input() title?: string;
    @Input() id!: string;

    constructor(@Inject(FEATURE_INFOS) private featureInfos: string) {}

    public getItemInfo(id: string, type: string): string {
        const infos = this.featureInfos as any;
        if (infos[id] && infos[id][type]) {
            return infos[id][type];
        } else {
            return 'Nicht gefunden: ' + id;
        }
    }
}
