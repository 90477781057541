import { NavigationType } from '@sansys/webapputils';

export const navigation: NavigationType[] = [
    {
        id: 'features',
        type: 'group',
        children: [
            {
                id: 'scheduling',
                type: 'collapsable',
                children: [
                    {
                        id: 'schedulingOverview',
                        type: 'item',
                        url: '/features/scheduler',
                    },
                    {
                        id: 'schedulingGeoLocations',
                        type: 'item',
                        url: '/features/geo-locations',
                    },
                    {
                        id: 'schedulingOffer',
                        type: 'item',
                        url: '/features/offers',
                    },
                    {
                        id: 'schedulingOrders',
                        type: 'item',
                        url: '/features/orders',
                    },
                    {
                        id: 'schedulingTransports',
                        type: 'item',
                        url: '/features/transports',
                    },
                    {
                        id: 'schedulingVehicles',
                        type: 'item',
                        url: '/features/vehicles',
                    },
                    {
                        id: 'schedulingPassengers',
                        type: 'item',
                        url: '/features/passengers',
                    },
                    // {
                    //     id: 'schedulingCustomerAdministrations',
                    //     type: 'item',
                    //     url: '/features/customer-administrations',
                    // },
                    {
                        id: 'schedulingTransportLocations',

                        type: 'item',
                        url: '/features/transport-locations',
                    },
                    {
                        id: 'schedulingInsurances',
                        type: 'item',
                        url: '/features/insurances',
                    },
                    {
                        id: 'schedulingProtocolTemplates',
                        type: 'item',
                        url: '/features/protocol-templates',
                    },
                ],
            },
            {
                id: 'personell',
                type: 'collapsable',
                children: [
                    {
                        id: 'personellEmployees',
                        type: 'item',
                        url: '/features/employees',
                    },
                    {
                        id: 'personellShiftSchedule',
                        type: 'item',
                        url: 'features/shift-schedule',
                    },
                    // {
                    //     id: 'personellShiftScheduleBeta',
                    //     type: 'item',
                    //     url: 'features/shift-schedule-beta',
                    // },
                    {
                        id: 'personellShiftTemplates',
                        type: 'item',
                        url: 'features/shift-templates',
                    },
                    {
                        id: 'personellEmployeeDeductions',
                        type: 'item',
                        url: 'features/employee-deductions',
                    },
                    {
                        id: 'personellClassifications',
                        type: 'item',
                        url: 'features/employee-classifications',
                    },
                    {
                        id: 'personellWorktimeTypes',
                        type: 'item',
                        url: 'features/employee-worktime-types',
                    },
                    {
                        id: 'personellWorktimeRules',
                        type: 'item',
                        url: 'features/employee-worktime-rules',
                    },
                    {
                        id: 'personellTrainings',
                        type: 'item',
                        url: 'features/trainings',
                    },
                ],
            },
            {
                id: 'qm',
                type: 'collapsable',
                children: [
                    {
                        id: 'qmChecklists',
                        type: 'item',
                        url: '/features/checklists',
                    },
                    {
                        id: 'qmChecklistTemplates',
                        type: 'item',
                        url: '/features/checklist-templates',
                    },
                    {
                        id: 'qmComplaints',
                        type: 'item',
                        url: '/features/complaints',
                    },
                    {
                        id: 'qmDefects',
                        type: 'item',
                        url: '/features/defects',
                    },
                    {
                        id: 'qmDocumentCategories',
                        type: 'item',
                        url: '/features/qm-document-categories',
                    },
                    {
                        id: 'qmDocuments',
                        type: 'item',
                        url: '/features/qm-documents',
                    },
                    // {
                    //     id: 'qmStatistics',
                    //     type: 'item',
                    //     url: '/features/qm-statistics',
                    // },
                ],
            },
            {
                id: 'invoicing',
                type: 'collapsable',
                children: [
                    {
                        id: 'invoicingInvoices',
                        type: 'item',
                        url: '/features/invoices',
                    },
                    {
                        id: 'invoicingDebtcollections',
                        type: 'item',
                        url: '/features/debtcollections',
                    },
                    {
                        id: 'invoicingLosses',
                        type: 'item',
                        url: '/features/invoices/losses',
                    },
                    {
                        id: 'invoicingTariffs',
                        type: 'item',
                        url: '/features/tariffs',
                    },
                    // {
                    //     id: 'invoicingStatistics',
                    //     type: 'item',
                    //     url: '/features/finance-statistics',
                    // },
                ],
            },
            {
                id: 'warehouse',
                type: 'collapsable',
                children: [
                    {
                        id: 'warehouseMaterials',
                        type: 'item',
                        url: '/features/materials',
                    },
                    {
                        id: 'warehouseDevices',
                        type: 'item',
                        url: '/features/devices',
                    },
                    {
                        id: 'warehouseKeyys',
                        type: 'item',
                        url: '/features/keys',
                    },
                    {
                        id: 'warehouseOrderSuggestions',
                        type: 'item',
                        url: '/features/warehouse-order-suggestions',
                    },
                    // {
                    //     id: 'warehouseStatistics',
                    //     type: 'item',
                    //     url: '/features/warehouse-statistics',
                    // },
                ],
            },
            {
                id: 'administration',
                type: 'collapsable',
                children: [
                    {
                        id: 'administrationFacilities',
                        type: 'item',
                        url: '/features/facilities',
                    },
                    {
                        id: 'administrationCompany',
                        type: 'item',
                        url: '/features/company',
                    },
                    {
                        id: 'notificationCategories',
                        type: 'item',
                        url: '/features/notification-categories',
                    },
                    {
                        id: 'administrationHistory',
                        type: 'item',
                        url: '/features/change-logs',
                    },
                ],
            },
        ],
    },
];
