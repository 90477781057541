import { Injectable } from '@angular/core';

import { FuseSidebarComponent } from './sidebar.component';

@Injectable({
    providedIn: 'root',
})
export class SidebarService {
    private _registry: { [key: string]: FuseSidebarComponent } = {};

    register(key: string, sidebar: FuseSidebarComponent): void {
        if (this._registry[key]) {
            console.error(
                `The sidebar with the key '${key}' already exists. Either unregister it first or use a unique key.`
            );

            return;
        }

        this._registry[key] = sidebar;
    }

    unregister(key: string): void {
        if (!this._registry[key]) {
            console.warn(
                `The sidebar with the key '${key}' doesn't exist in the registry.`
            );
        }

        delete this._registry[key];
    }

    getSidebar(key: string): FuseSidebarComponent | void {
        if (!this._registry[key]) {
            console.warn(
                `The sidebar with the key '${key}' doesn't exist in the registry.`
            );

            return;
        }

        // Return the sidebar
        return this._registry[key];
    }
}
