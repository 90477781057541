<mat-toolbar class="p-0 mat-elevation-z1">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <button
                mat-icon-button
                class="navbar-toggle-button"
                (click)="toggleSidebarOpen('navbar')"
                fxHide.gt-md
            >
                <mat-icon
                    class="secondary-text"
                    fontSet="fa"
                    fontIcon="fa-bars"
                ></mat-icon>
            </button>

            <div class="toolbar-separator" fxHide.gt-md></div>

            <div class="px-8 px-md-16">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div>
        </div>

        <div
            class=""
            fxFlex="0 1 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
        >
            <div class="toolbar-separator"></div>

            <button
                mat-button
                class="language-button"
                routerLink="/features/rapports"
            >
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon
                        *ngIf="unreadRapportsCount > 0"
                        fontSet="fa"
                        fontIcon="fa-info-circle"
                        [matBadge]="unreadRapportsCount.toString()"
                        matBadgeColor="warn"
                    ></mat-icon>
                    <mat-icon
                        *ngIf="unreadRapportsCount === 0"
                        fontSet="fa"
                        fontIcon="fa-info-circle"
                    ></mat-icon>
                </div>
            </button>

            <div class="toolbar-separator"></div>

            <button mat-button class="language-button" (click)="refresh()">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon
                        fontSet="fa"
                        fontIcon="fa-sync"
                        *ngIf="!showSpinner"
                    ></mat-icon>
                    <mat-icon
                        fontSet="fa"
                        fontIcon="fa-spinner-third"
                        class="sys-spin"
                        *ngIf="showSpinner"
                    ></mat-icon>
                </div>
            </button>

            <div class="toolbar-separator"></div>

            <button
                mat-button
                class="toolbar-button"
                (click)="switchLanguage()"
            >
                <mat-icon fontSet="fa" fontIcon="fa-language"></mat-icon>
            </button>

            <div class="toolbar-separator"></div>

            <button
                mat-button
                [matMenuTriggerFor]="userMenu"
                class="user-button"
                data-testid="userMenu"
            >
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img
                        class="avatar mr-0 mr-sm-16"
                        src="{{
                            (currentUser$ | async)?.user?.profileImage
                                ? fileServer +
                                  (currentUser$ | async)?.user?.profileImage
                                : 'assets/images/avatars/profile.jpg'
                        }}"
                        alt=""
                    />
                    <span
                        class="username mr-12"
                        fxHide
                        fxShow.gt-sm
                        data-testid="username"
                    >
                        {{ (currentUser$ | async)?.user?.firstName }}
                        {{ (currentUser$ | async)?.user?.lastName }}
                    </span>
                    <mat-icon
                        fxHide.xs
                        class="s-16"
                        fontSet="fa"
                        fontIcon="fa-ellipsis-v"
                    ></mat-icon>
                </div>
            </button>

            <mat-menu
                #userMenu="matMenu"
                xPosition="before"
                [overlapTrigger]="false"
            >
                <button mat-menu-item (click)="showMyData()">
                    <mat-icon fontSet="fa" fontIcon="fa-user-circle"></mat-icon>
                    <span>{{ 'Mein Profil' | transloco }}</span>
                </button>

                <button
                    mat-menu-item
                    (click)="showBugDialog()"
                    data-testid="bugReportButton"
                >
                    <mat-icon fontSet="fa" fontIcon="fa-bug"></mat-icon>
                    <span>{{ 'Fehler melden' | transloco }}</span>
                </button>

                <button
                    mat-menu-item
                    class=""
                    (click)="onLogout()"
                    data-testid="logout"
                >
                    <mat-icon fontSet="fa" fontIcon="fa-sign-out"></mat-icon>
                    <span>{{ 'Abmelden' | transloco }}</span>
                </button>
            </mat-menu>
        </div>
    </div>
</mat-toolbar>
