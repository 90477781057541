import { ValueType } from '@syncfusion/ej2-grids/src/grid/base/type';

function getEnString(germanDateString: ValueType): string {
    const yearRef = germanDateString.toString().substr(6, 4);
    const monthRef = germanDateString.toString().substr(3, 2);
    const dayRef = germanDateString.toString().substr(0, 2);

    return yearRef + monthRef + dayRef;
}

export function germanDateSortComparer(
    reference: ValueType,
    comparer: ValueType
): number {
    if (getEnString(reference) < getEnString(comparer)) {
        return -1;
    }
    if (getEnString(reference) > getEnString(comparer)) {
        return 1;
    }
    return 0;
}
