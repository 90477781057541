import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { FieldWrapper } from '@ngx-formly/core';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns/src/drop-down-base/drop-down-base-model';
import { FormControl } from '@angular/forms';
import {
    errorOccured,
    Station,
    StationEntityService,
    stationSelected,
    TransportLocation,
} from '@sansys/crosslib';
import { Subscription } from 'rxjs';
import {
    ChangeEventArgs,
    DropDownListComponent,
} from '@syncfusion/ej2-angular-dropdowns';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getSearchQuery } from '../../syncfusion/query.functions';
import { Actions } from '@ngrx/effects';

@Component({
    selector: 'sys-station-autocomplete-type',
    template: `
        <ng-container #fieldComponent></ng-container>
        <ejs-dropdownlist
            [showClearButton]="true"
            cssClass="e-outline"
            floatLabelType="Always"
            [dataSource]="data"
            [fields]="fields"
            [formControl]="formControl"
            [placeholder]="getPlaceholder()"
            [allowFiltering]="true"
            (change)="onChange($event)"
            (filtering)="onFiltering($event)"
            id="{{ field.key }}"
            #dropdown
            [ngClass]="{
                'required-field':
                    to.required &&
                    formControl.status !== 'VALID' &&
                    !to.disabled,
                'sys--display-none': data.length === 0
            }"
        ></ejs-dropdownlist>
        <div
            class="mat-error"
            *ngIf="
                formControl.status !== 'VALID' &&
                formControl.touched &&
                to.required
            "
        >
            Pflichtfeld, bitte ausfüllen.
        </div>
    `,
})
export class StationAutocompleteWrapperComponent extends FieldWrapper
    implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('dropdown') public dropdownObject!: DropDownListComponent;
    private subscription = new Subscription();

    public data: {
        [key: string]: string;
    }[] = [];
    public formControl!: FormControl;
    // maps the appropriate column to fields property
    public fields: FieldSettingsModel = { text: 'stationName', value: 'id' };
    // set the placeholder to the DropDownList input
    public text = 'Station auswählen';
    // Bind the filter event
    public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
        e.updateData(this.data, getSearchQuery('stationName', e.text));
    };

    constructor(
        private stationEntityService: StationEntityService,
        private action$: Actions,
        private store: Store<any>
    ) {
        super();
    }

    private setData(transportLocation?: TransportLocation): void {
        // if (!transportLocation?.id) {
        //     return;
        // }
        // this.stationEntityService
        //     .getWithQuery({ transportLocationId: transportLocation.id })
        //     .subscribe((stations) => {
        //         stations.forEach((station) => {
        //             this.data.push(JSON.parse(JSON.stringify(station)));
        //         });
        //     });

        this.subscription.add(
            this.stationEntityService.entities$
                .pipe(
                    map((stations) =>
                        stations.filter(
                            (station) =>
                                station.transportLocation.id ===
                                transportLocation?.id
                        )
                    )
                )
                .subscribe((stations) => {
                    this.data.length = 0;
                    stations.forEach((station) => {
                        this.data.push(JSON.parse(JSON.stringify(station)));
                    });
                })
        );
    }

    ngOnInit(): void {
        // this.setData();
        const transportLocation = this.to.transportLocation;
        if (transportLocation) {
            this.setData(transportLocation);
        }
        // this.stationEntityService.load();
        //
        // this.subscription.add(
        //     this.action$
        //         .pipe(
        //             ofType(transportLocationSelected),
        //             tap((data) => {
        //                 this.setData(data.transportLocation);
        //             })
        //         )
        //         .subscribe()
        // );
    }

    ngAfterViewInit(): void {
        const dropdownInput = this.dropdownObject.element.getElementsByTagName(
            'input'
        )[0];
        if (dropdownInput && this.to.placeholder) {
            if (this.to.required) {
                dropdownInput.placeholder =
                    this.to.placeholder + ' wählen (Pflichtfeld)';
            } else {
                dropdownInput.placeholder = this.to.placeholder + ' wählen';
            }
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onChange(args: ChangeEventArgs): void {
        const station = (this.dropdownObject.getDataByValue(
            args.value
        ) as unknown) as Station;
        const stationType = this.to.stationType;
        if (!stationType) {
            this.store.dispatch(
                errorOccured({ message: 'stationType not provided' })
            );
            return;
        }
        this.store.dispatch(
            stationSelected({ station, stationType: stationType })
        );
    }

    getPlaceholder(): string {
        if (this.to.placeholder) {
            if (this.to.required) {
                return this.to.placeholder + ' *';
            } else {
                return this.to.placeholder;
            }
        }
        return '';
    }
}
