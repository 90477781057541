import { createAction, props } from '@ngrx/store';
import { Employee, Vehicle } from '@sansys/crosslib';

export const employeeAddedToVehicle = createAction(
    '[Vehicle] employee added',
    props<{ vehicle: Vehicle; employee?: Employee }>()
);

export const employeeRemovedFromVehicle = createAction(
    '[Vehicle] employee removed',
    props<{ vehicle: Vehicle }>()
);
