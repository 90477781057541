import { Inject, Injectable, Injector } from '@angular/core';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { FacilityEntityService } from '../facilities/facility-entity.service';
import { refresh } from '../../state/app.actions';
import { EmployeeEntityService } from '../employees/employee-entity.service';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';
import { select } from '@ngrx/store';
import { currentWorktime } from '../../auth/auth.selectors';
import { Vehicle } from './vehicle-entity.model';
import { WorktimeBreak } from '../worktime/worktime.model';
import { translate } from '@ngneat/transloco';
import { getChecklistsAreCompleted } from '../checklists/checklist-helper.functions';
import { Checklist } from '../checklists/checklist.model';

@Injectable({ providedIn: 'root' })
export class VehicleEntityService extends EntityCollectionServiceBase<Vehicle> {
    public readonly allLoaded$ = combineLatest([
        this.injector.get(EmployeeEntityService).loaded$,
        this.injector.get(FacilityEntityService).loaded$,
    ]).pipe(filter((loadedStates) => loadedStates.every((s) => s)));

    refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    public entitesForLists$ = this.entities$.pipe(
        map((vehicles) =>
            vehicles.map((vehicle) => {
                const vehicl = JSON.parse(JSON.stringify(vehicle));
                const teamPositions = [];
                if (vehicle.employee1) {
                    teamPositions.push(
                        vehicle.employee1.firstName +
                            ' ' +
                            vehicle.employee1?.lastName
                    );
                }
                if (vehicle.employee2) {
                    teamPositions.push(
                        vehicle.employee2.firstName +
                            ' ' +
                            vehicle.employee2.lastName
                    );
                }
                if (vehicle.intern) {
                    teamPositions.push(
                        vehicle.intern.firstName + ' ' + vehicle.intern.lastName
                    );
                }
                vehicl.currentTeam = teamPositions.join('<br>');
                const checklistStatus = getChecklistsAreCompleted(vehicle)
                    ? translate('Ok')
                    : translate('Offen');
                return { ...vehicl, checklistStatus };
            })
        )
    );

    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private injector: Injector,
        private action$: Actions,
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('Vehicle', serviceElementsFactory);
    }

    public getVehicleOfCurrentUser(): Observable<Vehicle | undefined> {
        return this.store.pipe(select(currentWorktime)).pipe(
            map((currentW) => {
                if (!currentW?.vehicle) {
                    return undefined;
                }
                return currentW.vehicle;
            })
        );
    }

    getVehicleChecklists(vehicleId: string): Observable<Checklist[]> {
        return this.http.get<Checklist[]>(
            this.server + `api/vehicles/${vehicleId}/checklists`
        );
    }

    updateTeam(vehicle: Vehicle): Observable<Vehicle> {
        return this.http.put<Vehicle>(
            `${this.server}api/vehicles/${vehicle.id}/team`,
            vehicle
        );
    }

    updateTeamBreak(
        vehicle: Vehicle,
        type: 'start' | 'end'
    ): Observable<WorktimeBreak> {
        return this.http.put<WorktimeBreak>(
            `${this.server}api/vehicles/${vehicle.id}/break/${type}`,
            vehicle
        );
    }

    leaveTeam(): Observable<null> {
        return this.http.get<null>(`${this.server}api/employees/leaveteam`);
    }

    getForShiftScheduler(): Observable<Vehicle[]> {
        return this.http.get<Vehicle[]>(
            `${this.server}api/vehicles/forshiftscheduler`
        );
    }

    getForSlect(): Observable<Vehicle[]> {
        return this.http.get<Vehicle[]>(`${this.server}api/vehicles/forselect`);
    }
}
