import { Injectable } from '@angular/core';
import {
    EntityActionOptions,
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { refresh, searchQueryChanged } from '../../state';
import { Device } from './device-entity.model';
import { currentUser } from '../../auth';
import { select } from '@ngrx/store';
import { currentListOptions } from '../warehouse';
import { filterDevices } from './device-helper.functions';

@Injectable({ providedIn: 'root' })
export class DeviceEntityService extends EntityCollectionServiceBase<Device> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions
    ) {
        super('Device', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    public filteredDevices$ = combineLatest([
        this.entities$,
        this.action$.pipe(ofType(searchQueryChanged), startWith({ query: '' })),
        this.store.pipe(select(currentListOptions)),
        this.store.select(currentUser),
    ]).pipe(
        map(([entities, filter, listOptions, currentU]) => {
            if (!currentU) {
                return [];
            }
            return filterDevices(
                entities,
                filter.query,
                listOptions.listOptions,
                currentU.user.firstName + ' ' + currentU.user.lastName
            );
        })
    );

    findDevicesOfEmployee(fullName: string): Observable<Device[]> {
        return this.entities$.pipe(
            map((devices: Device[]) => {
                return devices.filter((device) => device.home === fullName);
            })
        );
    }
}
