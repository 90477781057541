<sys-auth-page [title]="title">
    <ng-container right-content>
        <div class="logo" fxHide.gt-xs>
            <img src="assets/images/logos/sansys.png" alt="Sansys Logo" />
        </div>

        <div class="title">{{ 'Registrieren' | transloco }}</div>

        <form class="mt-12 mb-20" [formGroup]="form" (ngSubmit)="onSubmit()">
            <formly-form
                [form]="form"
                [fields]="fields"
                [model]="model"
            ></formly-form>
            <button
                mat-raised-button
                color="accent"
                class="form__submit-button"
                aria-label="CREATE AN ACCOUNT"
                [disabled]="form.invalid || !model.agbAccepted"
                data-testid="registrationButton"
            >
                {{ 'Registrieren' | transloco }}
            </button>
        </form>

        <div class="register" fxLayout="column" fxLayoutAlign="center center">
            <span class="text">{{ 'Bereits registriert' | transloco }}?</span>
            <a class="link ml-8" [routerLink]="'/anmelden'">{{
                'Anmelden' | transloco
            }}</a>
        </div>
    </ng-container>
</sys-auth-page>
