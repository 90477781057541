import { Vehicle } from './vehicle-entity.model';

export function createVehicle(vehicle: Vehicle): Vehicle {
    return {
        id: vehicle.id || undefined,
        name: vehicle.name,
        numberPlate: vehicle.numberPlate || undefined,
        serialNumber: vehicle.serialNumber || undefined,
        chassisNumber: vehicle.chassisNumber || undefined,
        typeGen: vehicle.typeGen || undefined,
        alarmSignal: vehicle.alarmSignal || false,
        buildYear: vehicle.buildYear || undefined,
        checklistCompletion: vehicle.checklistCompletion || 0,
        notWorking: vehicle.notWorking || false,
        facility: vehicle.facility || undefined,
        checklistTemplates: vehicle.checklistTemplates || undefined,
        checklistTemplateIds: vehicle.checklistTemplateIds || undefined,
        checklist: vehicle.checklist || undefined,
        serviceBookEntries: vehicle.serviceBookEntries || [],
        generalDetails: vehicle.generalDetails || undefined,
        contactDetails: vehicle.contactDetails || undefined,
        statusDetails: vehicle.statusDetails || undefined,
        measurementDetails: vehicle.measurementDetails || undefined,
        tireDetails: vehicle.tireDetails || undefined,
        employee1: vehicle.employee1 || undefined,
        employee2: vehicle.employee2 || undefined,
        intern: vehicle.intern || undefined,
        damages: vehicle.damages || undefined,
        abbreviation: vehicle.abbreviation || undefined,
        enabledForShiftScheduler: vehicle.enabledForShiftScheduler || undefined,
        insuranceName: vehicle.insuranceName || undefined,
        insurancePoliceNr: vehicle.insurancePoliceNr || undefined,
    };
}
