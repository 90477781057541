import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { Observable } from 'rxjs';

export abstract class EntityBaseService<T> extends EntityCollectionServiceBase<
    T
> {
    protected constructor(
        entityString: string,
        serviceElementsFactory: EntityCollectionServiceElementsFactory
    ) {
        super(entityString, serviceElementsFactory);
    }
    abstract refresh$: Observable<any>;
}
