import { Inject, Injectable } from '@angular/core';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { Actions, ofType } from '@ngrx/effects';
import { refresh } from '../../state';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';
import { WorktimeNet } from './worktime-net.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WorktimeNetEntityService extends EntityCollectionServiceBase<WorktimeNet> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions,
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('WorktimeNet', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    getForEmployeeId(employeeId: string): Observable<WorktimeNet[]> {
        return this.http.get<WorktimeNet[]>(
            `${this.server}api/worktimenets/employee/${employeeId}`
        );
    }
}
