import { Tariff } from './tariff.model';

export function createTariff(tariff: Tariff): Tariff {
    return {
        id: tariff.id || undefined,
        name: tariff.name,
        active: !!tariff.active,
        invoicePrefix: tariff.invoicePrefix,
    };
}
