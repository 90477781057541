import { Predicate, Query } from '@syncfusion/ej2-data';

export function getSearchQuery(fieldName: string, searchText: string): Query {
    const textpieces = searchText.split(' ');

    let predicate: Predicate = new Predicate(
        fieldName,
        'contains',
        textpieces[0],
        true
    );
    textpieces.forEach((piece, index) => {
        if (index !== 0) {
            predicate = predicate.and(fieldName, 'contains', piece, true);
        }
    });

    return new Query().where(predicate);
}
