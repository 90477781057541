import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Tariff } from '../../tariffs/tariff.model';
import { TariffEntityService } from '../../tariffs/tariff-entity.service';
import { TransportModel } from '../transport-entity.model';
import { TransportLocation } from '../../transport-locations/transport-location-entity.model';
import { Insurance } from '../../insurances';

export function getTransportTariff(
    transportModel: TransportModel,
    tariffEntityService: TariffEntityService
): Observable<Tariff | null> {
    let customer: TransportLocation | null | undefined | Insurance;

    switch (transportModel.customerType) {
        case 'insurance':
            customer = transportModel.customerInsurance;
            break;
        case 'manual':
            customer = transportModel.customerLocation;
            break;
        case 'startLocation':
            customer = transportModel.startLocation;
            break;
        case 'targetLocation':
            customer = transportModel.targetLocation;
            break;
        case 'passenger':
            return tariffEntityService.getAll().pipe(
                map(
                    (tariffs) => tariffs[0] // TODO: Welchen nehmen?
                )
            );
    }
    if ((customer as any).tariffs && (customer as any).tariffs.length === 1) {
        return tariffEntityService.getByKey(
            (customer as TransportLocation).tariffs[0].id
        );
    }
    return of(null);
}
