import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns/src/drop-down-base/drop-down-base-model';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { showEntityAddOrEditPage, TariffEntityService } from '@sansys/crosslib';
import {
    ChangeEventArgs,
    DropDownListComponent,
} from '@syncfusion/ej2-angular-dropdowns';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { translate } from '@ngneat/transloco';

@Component({
    selector: 'sys-tariff-autocomplete-type',
    template: `
        <div class="mb-20">
            <ejs-dropdownlist
                [showClearButton]="true"
                cssClass="e-outline"
                floatLabelType="Always"
                [dataSource]="data"
                [fields]="fields"
                [formControl]="formControl"
                [placeholder]="getPlaceholder()"
                [allowFiltering]="true"
                (change)="onChange($event)"
                (filtering)="onFiltering($event)"
                data-testid="tariffAutocomplete"
                id="{{ field.key }}"
                #dropdown
                [ngClass]="{
                    'required-field':
                        to.required &&
                        formControl.status !== 'VALID' &&
                        !to.disabled
                }"
            ></ejs-dropdownlist>
            <button
                mat-button
                color="primary"
                class="float-right"
                (click)="showAddTariffDialog()"
                *ngIf="to.allowIndividualTariff && !to.disabled"
            >
                {{ 'Individueller Tarif' | transloco }}
            </button>
            <div
                class="mat-error"
                *ngIf="
                    formControl.status !== 'VALID' &&
                    formControl.touched &&
                    to.required
                "
            >
                {{ 'Pflichtfeld, bitte ausfüllen.' | transloco }}
            </div>
        </div>
    `,
})
export class TariffAutocompleteWrapperComponent
    extends FieldWrapper
    implements OnInit, OnDestroy, AfterViewInit {
    private subscription = new Subscription();
    @ViewChild('dropdown') public dropdownObject!: DropDownListComponent;

    public data: {
        [key: string]: object;
    }[] = [];
    public formControl!: FormControl;
    // maps the appropriate column to fields property
    public fields: FieldSettingsModel = { text: 'name', value: 'id' };
    // set the placeholder to the DropDownList input
    public text = 'Tarif auswählen';
    // Bind the filter event
    public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
        let query = new Query();
        // frame the query based on search string with filter type.
        query =
            e.text !== ''
                ? query.where('name', 'contains', e.text, true)
                : query;
        // pass the filter data source, filter query to updateData method.
        e.updateData(this.data, query);
    };

    constructor(
        private tariffEntityService: TariffEntityService,
        private store: Store<any>
    ) {
        super();
    }

    ngAfterViewInit(): void {
        const dropdownInput = this.dropdownObject.element.getElementsByTagName(
            'input'
        )[0];
        if (dropdownInput && this.to.placeholder) {
            if (this.to.required) {
                dropdownInput.placeholder =
                    this.to.placeholder + translate(' wählen (Pflichtfeld)');
            } else {
                dropdownInput.placeholder =
                    this.to.placeholder + translate(' wählen');
            }
        }
    }

    ngOnInit(): void {
        this.subscription.add(
            this.tariffEntityService.entities$.subscribe((tariffs) => {
                tariffs = JSON.parse(JSON.stringify(tariffs));
                this.data.length = 0;
                tariffs.forEach((tariff) => {
                    this.data.push(tariff as any);
                });
            })
        );

        this.subscription.add(
            this.tariffEntityService.loaded$
                .pipe(
                    filter((isLoaded) => !isLoaded),
                    first(),
                    tap(() => {
                        this.tariffEntityService.load();
                    })
                )
                .subscribe()
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onChange(args: ChangeEventArgs): void {
        if (
            this.field &&
            this.field.templateOptions &&
            this.field.templateOptions.changeCallback
        ) {
            this.field.templateOptions.changeCallback(
                this.dropdownObject.getDataByValue(args.value)
            );
        }
    }

    showAddTariffDialog(): void {
        this.store.dispatch(
            showEntityAddOrEditPage({
                entityType: 'tariffVersion',
                manual: true,
            })
        );
    }

    getPlaceholder(): string {
        if (this.to.placeholder) {
            if (this.to.required) {
                return this.to.placeholder + ' *';
            } else {
                return this.to.placeholder;
            }
        }
        return '';
    }
}
