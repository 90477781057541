import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { navigation } from '../../navbar/navigation';
import {
    CurrentUser,
    currentUser,
    logout,
    refresh,
    getContrastColorOfHexColor,
    CompanyService,
    BugReportData,
    TrelloService,
    API_BASE_FILE_SERVER,
    currentUnreadNotifications,
    hideSpinner,
    showSpinner,
    LocalStorageService,
} from '@sansys/crosslib';
import {
    SidebarService,
    NavigationItem,
    BugReportComponent,
} from '@sansys/webapputils';
import { MatDialog } from '@angular/material/dialog';
import { first, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'sys-signed-in',
    templateUrl: './signed-in.component.html',
    styleUrls: ['./signed-in.component.scss'],
})
export class SignedInComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();

    public showSpinner = false;
    public navigation: NavigationItem[];
    public currentUser$: Observable<
        CurrentUser | undefined
    > = new Observable<CurrentUser>();
    unreadRapportsCount = 0;

    constructor(
        private _fuseSidebarService: SidebarService,
        private store: Store<any>,
        private companyService: CompanyService,
        private dialog: MatDialog,
        private trelloService: TrelloService,
        private router: Router,
        private translocoService: TranslocoService,
        private localStorageService: LocalStorageService,
        private action$: Actions,
        @Inject(API_BASE_FILE_SERVER) public fileServer: string
    ) {
        this.navigation = navigation;
    }

    showBugDialog(): void {
        const dialogRef = this.dialog.open(BugReportComponent, {
            width: '400px',
            data: {},
        });

        dialogRef.afterClosed().subscribe((result: BugReportData) => {
            if (result) {
                this.trelloService.sendErrorToTrello(result).subscribe();
            }
        });
    }

    ngOnInit(): void {
        this.subscription.add(
            this.store
                .select(currentUnreadNotifications)
                .pipe(
                    tap((data) => {
                        this.unreadRapportsCount = data ? data : 0;
                    })
                )
                .subscribe()
        );
        this.currentUser$ = this.store.pipe(select(currentUser));
        this.companyService
            .getPrimaryColor()
            .subscribe((primaryColor: string) => {
                if (!primaryColor) {
                    primaryColor = '#075380';
                }
                const textColor = getContrastColorOfHexColor(primaryColor);
                document.body.style.setProperty(
                    '--primary-color',
                    primaryColor
                );
                document.body.style.setProperty(
                    '--primary-color-text',
                    `${textColor}`
                );
            });

        this.subscription.add(
            this.action$
                .pipe(ofType(showSpinner))
                .subscribe(() => (this.showSpinner = true))
        );
        this.subscription.add(
            this.action$
                .pipe(ofType(hideSpinner))
                .subscribe(() => (this.showSpinner = false))
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    toggleSidebarOpen(key: string): void {
        const sideBar = this._fuseSidebarService.getSidebar(key);
        if (sideBar) {
            sideBar.toggleOpen();
        }
    }

    onLogout(): void {
        this.store.dispatch(logout());
    }

    refresh(): void {
        this.store.dispatch(refresh());
    }

    showMyData(): void {
        this.currentUser$.pipe(first()).subscribe((currentU) => {
            if (!currentU) {
                return;
            }
            this.router.navigate(['features', 'employees', currentU.user.id]);
        });
    }

    switchLanguage(): void {
        const currentLanguage = this.translocoService.getActiveLang();
        if (currentLanguage === 'medical_de-CH') {
            this.translocoService.setActiveLang('medical_fr-PF');
            this.localStorageService.setItem('admin-language', 'fr');
        } else {
            this.translocoService.setActiveLang('medical_de-CH');
            this.localStorageService.setItem('admin-language', 'de');
        }
        window.location.reload();
    }
}
