import { FormlyFieldConfig } from '@ngx-formly/core';
import { Worktime, WorktimeBreak, WorktimeType } from '@sansys/crosslib';
import { translate } from '@ngneat/transloco';

export function getWorktimeHourlyFormConfig(
    worktimeTypes: WorktimeType[],
    currentWorktime: Partial<Worktime>,
    onChangeWorktimeType: (field: FormlyFieldConfig) => void
): FormlyFieldConfig[] {
    const worktimeTypeOptions: { value: string; label: string }[] = [];
    worktimeTypes.forEach((worktimeType) =>
        worktimeTypeOptions.push({
            value: worktimeType.name,
            label: worktimeType.name,
        })
    );

    worktimeTypeOptions.push({
        value: 'Ausbezahlt Lohn Pikett',
        label: translate('Ausbezahlt Lohn Pikett'),
    });
    worktimeTypeOptions.push({
        value: 'Ausbezahlt Lohn',
        label: translate('Ausbezahlt Lohn'),
    });

    if (currentWorktime?.worktimeType) {
        let currentWorktimeFound = false;

        worktimeTypeOptions.forEach((wT) => {
            if (wT.value === currentWorktime.worktimeType) {
                currentWorktimeFound = true;
            }
        });

        if (!currentWorktimeFound) {
            worktimeTypeOptions.push({
                value: currentWorktime.worktimeType,
                label: currentWorktime.worktimeType,
            });
        }
    }

    return [
        {
            key: 'worktimeType',
            type: 'select',
            templateOptions: {
                label: translate('Arbeitstyp'),
                placeholder: translate('Arbeitstyp'),
                appearance: 'outline',
                floatLabel: 'always',
                required: true,
                options: worktimeTypeOptions,
                change: onChangeWorktimeType,
            },
        },
        {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
                {
                    className: 'flex-50 pr-12',
                    key: 'start',
                    type: 'dateTimePicker',
                    templateOptions: {
                        label: translate('Start'),
                        placeholder: translate('Start'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        required: true,
                    },
                },
                {
                    className: 'flex-50',
                    key: 'end',
                    type: 'dateTimePicker',
                    templateOptions: {
                        label: translate('Ende'),
                        placeholder: translate('Ende'),
                        appearance: 'outline',
                        floatLabel: 'always',
                    },
                },
            ],
        },
        {
            key: 'facility.id',
            wrappers: ['facilityAutocompleteWrapper'],
            templateOptions: {
                placeholder: translate('Standort'),
                required: true,
                appearance: 'outline',
                floatLabel: 'always',
            },
        },
        {
            key: 'worktimeBreaks',
            type: 'optionRepeater',
            wrappers: ['panelWrapper'],
            templateOptions: {
                addText: translate('Pause hinzufügen'),
                label: translate('Pausen'),
            },
            fieldArray: {
                fieldGroupClassName: 'display-flex',
                fieldGroup: [
                    {
                        className: 'flex-50 pr-12',
                        key: 'start',
                        type: 'dateTimePicker',
                        templateOptions: {
                            label: translate('Start'),
                            required: true,
                            appearance: 'outline',
                            floatLabel: 'always',
                            attributes: {
                                'data-testid': 'breakStartInput',
                            },
                        },
                    },
                    {
                        className: 'flex-50',
                        key: 'end',
                        type: 'dateTimePicker',
                        templateOptions: {
                            label: translate('Ende'),
                            required: true,
                            appearance: 'outline',
                            floatLabel: 'always',
                            attributes: {
                                'data-testid': 'breakEndInput',
                            },
                        },
                    },
                ],
            },
            hideExpression: (worktimeBreaks: WorktimeBreak[]) => {
                let hideBreakArray = false;
                worktimeBreaks.forEach((worktimeBreak) => {
                    if (worktimeBreak.minutes) {
                        hideBreakArray = true;
                    }
                });
                return (
                    hideBreakArray || !!currentWorktime.worktimeTypeBreakMinutes
                );
            },
        },
        {
            className: 'flex-50',
            key: 'worktimeTypeBreakMinutes',
            type: 'input',
            templateOptions: {
                type: 'number',
                label: translate('Feste Pause in Minuten'),
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'worktimeTypeBreakMinutesInput',
                },
            },
            hideExpression: () => currentWorktime.worktimeBreaks?.length !== 0,
        },
    ];
}
