// tslint:disable:quotemark
export const gridGlobalizationFr = {
    'fr-PF': {
        grid: {
            DateTimeFilter: 'Filtrer les dates',
            SelectAll: 'Tout sélectionner',
            NumberFilter: 'Filtrer les numéros',
            ClearFilter: 'Effacer le filtre',
            Matchs: 'Aucune entrée trouvée',
            NoResult: 'Aucune entrée trouvée',
            Between: 'Entre',
            CustomFilter: 'Filtre personnalisé',
            Placeholder: 'Veuillez choisir la date',
            ChooseDate: 'Veuillez choisir la date',
            CustomFilterDatePlaceHolder: 'Veuillez choisir la date',
            TextFilter: 'Filtre texte',
            MatchCase: 'Majuscules/minuscules',
            ShowRowsWhere: 'Afficher les entrées pour',
            EnterValue: 'Saisir une valeur',
            CustomFilterPlaceHolder: 'Saisir la valeur',
            AND: 'ET',
            OR: 'OU',
            EmptyRecord: "Aucune entrée n'est disponible",
            Item: 'Article',
            Items: 'Articles',
            True: 'V',
            False: 'Faux',
            Blanks: '[Vide',
            StartsWith: 'Commencer avec',
            EndsWith: 'Terminer avec',
            Contains: 'Contient',
            Equal: 'Égal',
            NotEqual: 'Pas de parité',
            LessThan: 'Moins que',
            LessThanOrEqual: 'Moins que ou égal',
            GreaterThan: 'Pls que',
            GreaterThanOrEqual: 'Plus que ou égal',
            FilterButton: 'Fi',
            ClearButton: 'Supp',
            All: 'Tout',
            Search: 'Chercher',
            Add: 'Ajouter',
            Edit: 'Modifier',
            Delete: 'Supprimer',
            Update: 'Mettre à jour',
            Cancel: 'Annuler',
            Excelexport: ' Export Excel',
            ConfirmDelete: 'Confirmer suppression',
            CancelButton: "Bouton d'annulation",
            SaveButton: 'Bouton de sauvegarde',
            DateFilter: 'Filtre',
            EditOperationAlert: "Veuillez d'abord sélectionner l'entrée",
            DeleteOperationAlert: " Veuillez d'abord sélectionner l'entrée",
        },
        pager: {
            currentPageInfo: '{0} sur {1} Page(s)',
            totalItemsInfo: '({0} Inscription)',
            firstPageTooltip: 'Vers la première page',
            lastPageTooltip: 'Vers la dernière page',
            nextPageTooltip: 'Aller à la prochaine page',
            previousPageTooltip: 'Retour à la dernière page',
            nextPagerTooltip: 'Vers la page suivante',
            previousPagerTooltip: 'Aller à la page précédente',
            pagerDropDown: "Nombre d'inscriptions par page",
            All: 'Tout',
        },
        uploader: {
            invalidMinFileSize: ' Taille minimale du fichier',
            invalidMaxFileSize: ' Taille maximale du fichier ',
            invalidFileType: 'format de fichier non pris en charge',
            Browse: 'Sélectionner',
            Clear: 'Supprimer',
            Upload: 'Télécharger',
            dropFilesHint: 'Conseil pour déposer les fichiers',
            uploadFailedMessage: "Message d'échec de téléchargement",
            uploadSuccessMessage: 'Téléchargement réussi',
            removedSuccessMessage: 'Le fichier a été retiré',
            removedFailedMessage: "Le fichier n'a pas pu être supprimé",
            inProgress: 'En cours',
            readyToUploadMessage: 'Prêt pour le téléchargement',
            remove: 'Retirer',
            cancel: 'Annuler',
            delete: 'Supprimer',
        },
        datetimepicker: {
            today: "Aujourd'hui",
            placeholder: 'Date/heure au format JJ.MM.AAAA HH:mm',
        },
        datepicker: {
            today: "Aujourd'hui",
            placeholder: 'Veuillez choisir la date',
        },
        excelFilter: {
            True: 'Vrai',
            False: 'Faux',
        },
        calendar: { today: "Aujourd'hui" },
        spreadsheet: {
            EditAlert: 'La cellule ne peut pas être modifiée ',
            ValidationError: "La valeur n'est pas autorisée.",
            Retry: 'Éditer',
            Cancel: 'Ok',
        },
        schedule: {
            addTitle: 'Titre',
            agenda: 'Agenda',
            alert: 'Alerte',
            allDay: 'Tous les jours',
            cancel: 'Annuler',
            cancelButton: 'Annuler',
            close: 'Fermer',
            createError:
                "La durée de l'événement doit être plus courte que la fréquence à laquelle il se produit. Réduisez la durée ou modifiez le modèle de récurrence dans l'éditeur d'événements de récurrence.",
            createEvent: 'créer un événement',
            day: 'J',
            delete: 'Supprimer',
            deleteButton: 'Bouton de suppression',
            deleteContent: 'Supprimer le contenu?',
            deleteEvent: "Supprimer l'événement",
            deleteMultipleContent:
                'Êtes-vous sûr de vouloir supprimer les événements sélectionnés ?',
            deleteMultipleEvent: ' Suppression de Plusieurs Evénements ',
            deleteRecurrenceContent:
                'Voulez-vous supprimer uniquement cet événement ou toute la série ?',
            deleteSeries: 'Supprimer les séries',
            description: 'Description',
            edit: 'Editer',
            editContent:
                'Voulez-vous éditer seulement cet événement ou toute la série ?',
            editEvent: "Editer l'événement",
            editRecurrence: 'Editer la Récurrence',
            editSeries: 'Editer les Séries',
            emptyContainer: "Aucun événement n'est prévu ce jour",
            end: 'FIN',
            endTimezone: 'Heure de fin',
            invalidDateError: "La valeur de la date saisie n'est pas valide.",
            location: 'Localisation',
            month: 'Mois',
            monthAgenda: 'Agenda du mois',
            more: 'plus',
            moreDetails: 'Détails',
            newEvent: 'Nouvel événement',
            next: 'Suivant',
            noEvents: "Pas d'événement",
            noTitle: '(Pas de titre)',
            occurrence: 'Occurrence',
            ok: 'Ok',
            previous: 'Précédent',
            recurrence: 'Recurrence',
            recurrenceDateValidation:
                " Certains mois ont moins de dates que la date sélectionnée. Pour ces mois, l'événement tombera le dernier jour du mois.",
            repeat: 'Répéter',
            repeats: 'Répétitions',
            sameDayAlert:
                "Deux occurrences d'un même événement ne peuvent se produire le même jour.",
            save: 'Sauver',
            saveButton: 'Sauver',
            selectedItems: 'Éléments sélectionnés',
            series: 'Série',
            seriesChangeAlert:
                'Les modifications apportées à des instances spécifiques de cette série seront annulées et ces événements correspondront à nouveau à la série.',
            start: 'Début',
            startEndError:
                'La date de fin sélectionnée se situe avant la date de début.',
            startTimezone: 'Heure de début',
            subject: 'Objet',
            timelineDay: ' calendrier journalier',
            timelineMonth: 'calendrier Mois',
            timelineWeek: ' calendrier semaine',
            timelineWorkWeek: ' Calendrier de la Semaine de Travail ',
            timezone: 'Heure',
            title: 'Titre',
            today: "Aujourd'hui",
            week: 'semaine',
            weekAgenda: 'Agenda de la semaine',
            workWeek: 'semaine du travail',
            workWeekAgenda: 'Agenda de la semaine du travail',
            wrongPattern: "Le modèle de récurrence n'est pas valide.",
        },
        recurrenceeditor: {
            count: 'Compte',
            daily: 'Journalier',
            days: 'Jour(s)',
            end: 'FIN',
            every: 'chaque',
            first: 'Premier',
            fourth: 'Quatrième',
            last: 'Dernier',
            month: 'Mois',
            monthly: 'Mensuel',
            months: 'Mois',
            never: 'Jamais',
            none: 'Aucun',
            on: 'Répéter sur',
            onDay: 'Jour',
            repeat: 'Répéter',
            repeatEvery: 'Répétez chaque',
            second: 'Seconde',
            summaryDay: 'jour(s)',
            summaryMonth: 'mois',
            summaryOn: 'sur',
            summaryRepeat: 'Répétitions',
            summaryTimes: 'temps',
            summaryUntil: "jusqu'à",
            summaryWeek: 'semaine(s)',
            summaryYear: 'année(s)',
            third: 'Troisième',
            until: "Jusqu'à",
            weekly: 'Hebdomadaire',
            weeks: 'Semaine(s)',
            yearly: 'Annuellement',
            years: 'Année(s)',
        },
        dropdown: {
            noRecordsTemplate: 'Aucune entrée trouvée',
        },
    },
};
