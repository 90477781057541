import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { navigation } from './layout/navbar/navigation';
import {
    CurrentUser,
    userLogin,
    currentUser,
    LocalStorageService,
} from '@sansys/crosslib';
import {
    FuseNavigationService,
    FuseSplashScreenService,
    gridGlobalizationDe,
    gridGlobalizationFr,
    NavigationItem,
} from '@sansys/webapputils';
import { L10n, loadCldr, setCulture } from '@syncfusion/ej2-base';
import { TranslocoService } from '@ngneat/transloco';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as gregorian from 'cldr-data/main/de/ca-gregorian.json';
import * as numbers from 'cldr-data/main/de/numbers.json';
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json';

declare var require: any;

loadCldr(
    require('cldr-data/main/de-CH/numbers.json'),
    require('cldr-data/main/fr-PF/numbers.json'),
    require('cldr-data/main/de-CH/ca-gregorian.json'),
    require('cldr-data/main/fr-PF/ca-gregorian.json'),
    require('cldr-data/supplemental/numberingSystems.json'),
    require('cldr-data/main/de-CH/timeZoneNames.json'),
    require('cldr-data/main/fr-PF/timeZoneNames.json'),
    require('cldr-data/supplemental/weekData.json') // To load the culture based first day of week
);

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

L10n.load(gridGlobalizationDe);
L10n.load(gridGlobalizationFr);

@Component({
    selector: 'sys-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    navigation: NavigationItem[] = [];
    message: any;

    constructor(
        private localStorageService: LocalStorageService,
        private translocoServce: TranslocoService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private store: Store<any>
    ) {
        this.store.select(currentUser).subscribe(() => {
            this.navigation = navigation;
        });

        this._fuseNavigationService.register('main', this.navigation);

        this._fuseNavigationService.setCurrentNavigation('main');
    }

    ngOnInit(): void {
        const fetchedUser = this.localStorageService.getItem<CurrentUser>(
            'sansys_user'
        );
        if (fetchedUser) {
            this.store.dispatch(userLogin({ currentUser: fetchedUser }));
        }
        const language = this.localStorageService.getItem('admin-language');
        if (language === 'fr') {
            this.translocoServce.setActiveLang('medical_fr-PF');
            setCulture('fr-PF');
        } else {
            this.translocoServce.setActiveLang('medical_de-CH');
            setCulture('de-CH');
        }
    }
}
