import { Injectable } from '@angular/core';
import {
    EntityActionOptions,
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { tap, map, startWith } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { refresh, searchQueryChanged } from '../../state';
import { Material } from './material-entity.model';
import { combineLatest, Observable } from 'rxjs';
import { currentUser } from '../../auth';
import { select } from '@ngrx/store';
import { filterMaterials } from './material-helper.functions';
import { currentListOptions } from '../warehouse';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class MaterialEntityService extends EntityCollectionServiceBase<
    Material
> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions
    ) {
        super('Material', serviceElementsFactory);
    }

    refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    public filteredMaterials$ = combineLatest([
        this.entities$,
        this.action$.pipe(ofType(searchQueryChanged), startWith({ query: '' })),
        this.store.pipe(select(currentListOptions)),
        this.store.select(currentUser),
    ]).pipe(
        map(([entities, filter, listOptions, currentU]) => {
            if (!currentU) {
                return [];
            }
            return filterMaterials(
                entities,
                filter.query,
                listOptions.listOptions,
                currentU.user.firstName + ' ' + currentU.user.lastName
            );
        })
    );

    entitiesForList$ = this.entities$.pipe(
        map((materials) =>
            materials.map((material) => {
                const mat = { ...material };
                let stockTotal = 0;
                let minTotal = 0;
                let minReached = false;
                material.materialLocations?.forEach((materialLocation) => {
                    if (materialLocation.currentStock) {
                        stockTotal += materialLocation.currentStock;
                    }
                    if (materialLocation.minStock) {
                        if (
                            materialLocation.currentStock &&
                            materialLocation.minStock >
                                materialLocation.currentStock
                        ) {
                            minReached = true;
                        }
                        minTotal += materialLocation.minStock;
                    }
                });
                mat.totalStock = stockTotal;
                mat.totalMin = minTotal;
                mat.minReached = minReached;
                if (mat.usableTill) {
                    mat.usableTill = moment(mat.usableTill).toDate();
                    mat.usableTillReached =
                        moment(mat.usableTill).format('YYYYMMDD') <
                        moment().format('YYYYMMDD');
                }
                return mat;
            })
        )
    );

    update(
        entity: Partial<Material>,
        options?: EntityActionOptions
    ): Observable<Material> {
        const mat: Material = JSON.parse(JSON.stringify(entity));
        if (mat.usableTill && typeof mat.usableTill === 'string') {
            mat.usableTill = moment(mat.usableTill.substr(0, 10)).format(
                'YYYY-MM-DD'
            );
        }
        return super.update(mat, options);
    }
}
