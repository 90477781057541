import { Invoice } from './invoice.model';

export function createInvoice(invoice: Partial<Invoice>): Invoice | null {
    if (!invoice.status || !invoice.transport) {
        return null;
    }
    return {
        id: invoice.id || undefined,
        discountInPercent: invoice.discountInPercent || 0,
        invoiceNumber: invoice.invoiceNumber || undefined,
        status: invoice.status || undefined,
        tariffVersion: invoice.tariffVersion || undefined,
        transport: invoice.transport,
        dueAt: invoice.dueAt || null,
        version: invoice.version || undefined,
        payedSum: invoice.payedSum || undefined,
        createdAt: invoice.createdAt || undefined,
        lastStatusChangeDate: invoice.lastStatusChangeDate || undefined,
        activeVersion: invoice.activeVersion || undefined,
        debtcollectionAgency: invoice.debtcollectionAgency || undefined,
        manualItems: invoice.manualItems || undefined,
        note: invoice.note || undefined,
    };
}
