<sys-feature-container>
    <sys-feature-header
        [id]="id"
        title="{{ 'Start-/Zielorte' | transloco }} / {{
            transportLocation.companyName
        }}"
    >
        <div feature-header-right>
            <button
                mat-raised-button
                [disabled]="
                    transportLocationForm.invalid ||
                    transportLocationLoginForm.invalid ||
                    (transportLocationForm.pristine &&
                        transportLocationLoginForm.pristine) ||
                    assignedTariffs.length === 0
                "
                (click)="saveTransportLocation()"
                *ngxPermissionsOnly="['transportLocations|EDIT', 'admin']"
                class="mr-12"
                data-testid="saveButton"
            >
                <span>{{ 'Speichern' | transloco }}</span>
            </button>
            <button mat-raised-button (click)="goBack()">
                {{ 'Schliessen' | transloco }}
            </button>
        </div>
    </sys-feature-header>

    <ng-container page-content>
        <div class="tab-content p-24 w-100-p">
            <form [formGroup]="transportLocationForm">
                <formly-form
                    [form]="transportLocationForm"
                    [fields]="transportLocationFormFields"
                    [model]="transportLocation"
                ></formly-form>
            </form>

            <div
                fxLayout="row"
                fxLayoutAlign="space-between none"
                *ngIf="currentUser?.type === 'employee'"
            >
                <div class="example-container mr-20">
                    <h2>{{ 'Verfügbare Tarife' | transloco }}</h2>

                    <div
                        cdkDropList
                        #todoList="cdkDropList"
                        [cdkDropListSortingDisabled]="true"
                        [cdkDropListData]="availableTariffs"
                        [cdkDropListConnectedTo]="[doneList]"
                        class="example-list"
                        (cdkDropListDropped)="drop($event)"
                    >
                        <div
                            class="example-box"
                            *ngFor="let tariff of availableTariffs"
                            cdkDrag
                            [cdkDragDisabled]="
                                !(
                                    'transportLocations|EDIT'
                                    | permission
                                    | async
                                )
                            "
                        >
                            {{ tariff.name }}
                        </div>
                    </div>
                </div>

                <div class="example-container">
                    <h2>{{ 'Verwendete Tarife' | transloco }}*</h2>

                    <div
                        cdkDropList
                        #doneList="cdkDropList"
                        [cdkDropListData]="assignedTariffs"
                        [cdkDropListConnectedTo]="[todoList]"
                        [cdkDropListSortingDisabled]="true"
                        class="example-list"
                        (cdkDropListDropped)="drop($event)"
                        [ngClass]="{
                            'required-field': assignedTariffs.length === 0
                        }"
                    >
                        <div
                            class="example-box"
                            *ngFor="let tariff of assignedTariffs"
                            cdkDrag
                            [cdkDragDisabled]="
                                !(
                                    'transportLocations|EDIT'
                                    | permission
                                    | async
                                )
                            "
                        >
                            {{ tariff.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</sys-feature-container>
