import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { MailConfirmComponent } from './mail-confirm.component';
import { RouterModule } from '@angular/router';
import { AuthPageModule, SharedModule } from '@sansys/webapputils';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    declarations: [MailConfirmComponent],
    imports: [
        MatIconModule,
        SharedModule,
        RouterModule,
        AuthPageModule,
        FlexLayoutModule,
    ],
})
export class MailConfirmModule {}
