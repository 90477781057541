import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import {
    CompanyService,
    getCalculatedTimings,
    TransportModel,
    getCalculatedDuration,
} from '@sansys/crosslib';

@Component({
    selector: 'sys-formly-wrapper-panel',
    template: `
        <ng-container #fieldComponent></ng-container>
        <mat-card class="mb-12" *ngIf="to.transportModel.orderedStart">
            <mat-card-header>
                <mat-card-title>
                    {{
                        'Berechnete Entfernungen(tatsächliche Werte können abweichen)'
                            | transloco
                    }}
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p
                    *ngIf="
                        !to.transportModel.distances?.addressesFound ||
                        !to.transportModel.distances?.startToTargetMeters
                    "
                >
                    {{
                        'Entfernungen konnten nicht berechnet werden.'
                            | transloco
                    }}
                </p>
                <mat-list
                    role="list"
                    *ngIf="
                        to.transportModel.distances?.addressesFound &&
                        to.transportModel.distances?.startToTargetSeconds
                    "
                >
                    <mat-list-item role="listitem">
                        {{ 'Ankunft Startort' | transloco }}:
                        {{ getCalculatedTiming('arrivalStart') }}
                    </mat-list-item>
                    <mat-list-item *ngIf="loadingTime">
                        {{ 'Standzeit' | transloco }}:
                        {{ getCalculatedDuration('arrivalStart') }}
                        {{ 'Minuten' | transloco }}
                    </mat-list-item>
                    <mat-list-item role="listitem">
                        {{ 'Abfahrt Startort' | transloco }}:
                        {{ getCalculatedTiming('departureStart') }}
                    </mat-list-item>
                    <mat-list-item role="listitem">
                        {{ 'Fahrt Startort zu Zielort' | transloco }}:
                        {{ getCalculatedDuration('startToTarget') }}
                        {{ 'Minuten' | transloco }},
                        {{
                            getKilometersOfMeters(
                                to.transportModel.distances.startToTargetMeters
                            ) | number: '1.1-1'
                        }}
                        {{ 'Kilometer' | transloco }}
                    </mat-list-item>
                    <mat-list-item role="listitem">
                        {{ 'Ankunft Zielort' | transloco }}:
                        {{ getCalculatedTiming('arrivalDestination') }}
                    </mat-list-item>
                    <mat-list-item
                        role="listitem"
                        *ngIf="
                            loadingTime &&
                            this.to.transportModel.isReturnTransport
                        "
                    >
                        {{ 'Standzeit' | transloco }}:
                        {{ getCalculatedDuration('arrivalTarget') }}
                        {{ 'Minuten' | transloco }}
                    </mat-list-item>
                    <mat-list-item
                        role="listitem"
                        *ngIf="this.to.transportModel.isReturnTransport"
                    >
                        {{ 'Abfahrt Zielort' | transloco }}:
                        {{ getCalculatedTiming('departureDestination') }}
                    </mat-list-item>
                    <mat-list-item
                        role="listitem"
                        *ngIf="this.to.transportModel.isReturnTransport"
                    >
                        {{ 'Fahrt Zielort zu Startort' | transloco }}:
                        {{ getCalculatedDuration('targetToStart') }}
                        {{ 'Minuten' | transloco }},
                        {{
                            getKilometersOfMeters(
                                to.transportModel.distances.startToTargetMeters
                            ) | number: '1.1-1'
                        }}
                        {{ 'Kilometer' | transloco }}
                    </mat-list-item>
                    <mat-list-item
                        role="listitem"
                        *ngIf="this.to.transportModel.isReturnTransport"
                    >
                        {{ 'Wiederankunft Startort' | transloco }}:
                        {{ getCalculatedTiming('arrivalStartReturn') }}
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card>
    `,
})
export class TransportTimingsCustomerWrapperComponent
    extends FieldWrapper
    implements OnInit {
    loadingTime?: number;

    constructor(private companyService: CompanyService) {
        super();
    }

    ngOnInit(): void {
        this.companyService
            .getLoadingTime()
            .subscribe((loadingTime) => (this.loadingTime = loadingTime));
    }

    getCalculatedTiming(
        type:
            | 'arrivalStart'
            | 'departureStart'
            | 'arrivalDestination'
            | 'departureDestination'
            | 'arrivalStartReturn'
            | 'teamReady'
    ): string {
        return getCalculatedTimings(
            this.to.transportModel as TransportModel,
            this.loadingTime || 0,
            type
        );
    }

    getKilometersOfMeters(meters: number): number {
        return meters / 1000;
    }

    getCalculatedDuration(
        type:
            | 'returnStart'
            | 'arrivalStart'
            | 'arrivalTarget'
            | 'facilityToStart'
            | 'startToTarget'
            | 'targetToStart'
            | 'startToFacility'
            | 'targetToFacility'
    ): number {
        return getCalculatedDuration(
            this.to.transportModel as TransportModel,
            this.loadingTime || 0,
            type
        );
    }
}
