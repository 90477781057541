<div class="navbar-top">
    <div class="logo">
        <img alt="" class="logo-icon" src="assets/images/logos/sansys.png" />
        <span
            data-testid="loggedInCompanyName"
            class="logo-text secondary-text"
            >{{ (currentUser$ | async)?.user?.company?.name }}</span
        >
    </div>

    <div class="buttons">
        <button
            mat-icon-button
            class="toggle-sidebar-folded"
            (click)="toggleSidebarFolded()"
            fxHide.lt-lg
        >
            <mat-icon
                class="secondary-text"
                fontSet="fa"
                fontIcon="fa-bars"
            ></mat-icon>
        </button>

        <button
            mat-icon-button
            class="toggle-sidebar-opened"
            (click)="toggleSidebarOpened()"
            fxHide.gt-md
        >
            <mat-icon
                class="secondary-text"
                fontSet="fa"
                fontIcon="fa-arrow-left"
            ></mat-icon>
        </button>
    </div>
</div>

<div class="navbar-scroll-container">
    <div class="user" fxLayout="column">
        <div class="h3 username">
            {{ (currentUser$ | async)?.user?.firstName }}
            {{ (currentUser$ | async)?.user?.lastName }}
        </div>
        <div class="h5 email hint-text mt-8">
            {{ (currentUser$ | async)?.user?.email }}
        </div>
        <div class="avatar-container">
            <img
                class="avatar"
                src="{{
                    (currentUser$ | async)?.user?.profileImage
                        ? fileServer +
                          (currentUser$ | async)?.user?.profileImage
                        : 'assets/images/avatars/profile.jpg'
                }}"
                alt=""
            />
        </div>
    </div>

    <div class="navbar-content">
        <sys-navigation class="material2"></sys-navigation>
    </div>
</div>
