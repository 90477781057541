export const allPermissions: string[] = [
    'scheduler',
    'offers',
    'orders',
    'transports',
    'vehicles',
    'passengers',
    'customerAdministrations',
    'transportLocations',
    'insurances',
    'protocolTemplates',
    'employees',
    'employeesCloths',
    'employeesWorktimes',
    'employeesPayrolls',
    'employeesDocuments',
    'employeeDeductions',
    'shiftSchedule',
    'shiftTemplates',
    'classifications',
    'worktimeTypes',
    'trainings',
    'checklists',
    'checklistTemplates',
    'complaints',
    'defects',
    'qmDocuments',
    'qmDocumentCategories',
    'qmStatistics',
    'invoices',
    'debtCollections',
    'losses',
    'invoicingStatistics',
    'tariffs',
    'materials',
    'devices',
    'keyys',
    'orderSuggestions',
    'warehouseStatistics',
    'facilities',
    'company',
    'notificationCategories',
    'changelog',
];
