import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { CompleteRegistrationComponent } from './complete-registration.component';
import { RouterModule } from '@angular/router';
import { AuthPageModule, SharedModule } from '@sansys/webapputils';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    declarations: [CompleteRegistrationComponent],
    imports: [
        MatIconModule,
        SharedModule,
        RouterModule,
        AuthPageModule,
        FlexLayoutModule,
        TranslocoModule,
    ],
})
export class CompleteRegistrationModule {}
