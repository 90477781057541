import { Injectable } from '@angular/core';
import {
    Distances,
    DistanceService,
    DistanceType,
    GoogleDistance,
    TransportModel,
} from '@sansys/crosslib';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { InputDialogComponent } from '../components/input-dialog/input-dialog.component';
import { translate } from '@ngneat/transloco';

@Injectable({
    providedIn: 'root',
})
export class TravelDetailsService {
    constructor(
        private distanceService: DistanceService,
        private dialog: MatDialog
    ) {}

    private getMinutesAndKmFromDialog(): Observable<DistanceType | undefined> {
        const dialogRef = this.dialog.open(InputDialogComponent, {
            width: '400px',
            data: {
                title:
                    'Entfernung konnte nicht ermittelt werden. Bitte manuell eingeben.',
                inputElements: [
                    {
                        label: translate('Dauer in Minuten'),
                        input: '',
                        type: 'number',
                        step: 1,
                        min: 0,
                    },
                    {
                        label: translate('Entfernung in km'),
                        input: '',
                        type: 'number',
                        step: 1,
                        min: 0,
                    },
                ],
            },
        });

        return dialogRef.afterClosed().pipe(
            map((result) => {
                if (!result) {
                    return;
                }
                return {
                    minutes: result.inputElements[0].input * 1,
                    km: result.inputElements[1].input * 1,
                };
            })
        );
    }

    getDistances(transport: TransportModel): Observable<Distances> {
        let start =
            transport.startLocation?.street +
            ', ' +
            transport.startLocation?.zip +
            ' ' +
            transport.startLocation?.city +
            ',' +
            transport.startLocation?.country;
        let end =
            transport.targetLocation?.street +
            ', ' +
            transport.targetLocation?.zip +
            ' ' +
            transport.targetLocation?.city +
            ',' +
            transport.targetLocation?.country;
        if (transport.startLocationType === 'passenger') {
            start =
                transport.passenger?.street +
                ', ' +
                transport.passenger?.zip +
                ' ' +
                transport.passenger?.city;
        }

        if (transport.targetLocationType === 'passenger') {
            end =
                transport.passenger?.street +
                ', ' +
                transport.passenger?.zip +
                ' ' +
                transport.passenger?.city;
        }

        const calculateFromFacility =
            transport.tariffVersion?.calculateFromFacility ||
            transport.currentTariffVersion?.calculateFromFacility ||
            transport.tariff?.currentTariffVersion?.calculateFromFacility;

        const facility =
            calculateFromFacility && transport.facility
                ? transport.facility
                : undefined;

        return this.distanceService.getDistances(
            start,
            end,
            facility,
            !!transport.isReturnTransport
        );
    }

    getDistance(
        transport: TransportModel
    ): Observable<DistanceType | undefined> {
        if (
            transport.calculatedOneWayDistanceInMeters &&
            transport.calculatedOneWayDurationInMinutes
        ) {
            return of({
                minutes: transport.calculatedOneWayDurationInMinutes,
                km: transport.calculatedOneWayDistanceInMeters / 1000,
            });
        }
        if (!transport.startLocation || !transport.targetLocation) {
            return this.getMinutesAndKmFromDialog();
        }
        const start =
            transport.startLocation?.street +
            ', ' +
            transport.startLocation?.zip +
            ' ' +
            transport.startLocation?.city;
        const end =
            transport.targetLocation?.street +
            ', ' +
            transport.targetLocation?.zip +
            ' ' +
            transport.targetLocation?.city;

        const calculateFromFacility =
            transport.tariffVersion?.calculateFromFacility ||
            transport.currentTariffVersion?.calculateFromFacility ||
            transport.tariff?.currentTariffVersion?.calculateFromFacility;

        const facility =
            calculateFromFacility && transport.facility
                ? transport.facility
                : undefined;

        return this.distanceService
            .getDistance(start, end, facility, !!transport.isReturnTransport)
            .pipe(
                switchMap((googleDistance: GoogleDistance) => {
                    if (googleDistance.seconds && googleDistance.meters) {
                        const dist: DistanceType = {
                            minutes: Math.round(googleDistance.seconds / 60),
                            km: Math.round(googleDistance.meters / 1000),
                        };
                        return of(dist);
                    }
                    return this.getMinutesAndKmFromDialog();
                })
            );
    }
}
