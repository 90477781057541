import { TransportStatus } from '../../../types/transport-status.type';
import { TransportModel } from '../transport-entity.model';

export function getCurrentStatusOfTransport(
    transport: TransportModel
): TransportStatus | '' {
    let currentStatus: TransportStatus | '' = '';
    const allStatus: TransportStatus[] = [
        'canceled',
        'redirectedStart',
        'deleted',
        'transportFinished',
        'teamReady',
        'arrivalStartReturn',
        'departureDestination',
        'arrivalDestination',
        'departureStart',
        'arrivalStart',
        'transportStart',
        'plannedStart',
        'orderedStart',
    ];
    allStatus.forEach((transportStatus) => {
        if (!currentStatus && transport[transportStatus]) {
            currentStatus = transportStatus;
        }
    });
    return currentStatus;
}

export function transportIsOpen(transport: TransportModel): boolean {
    return !transport.invoiceSent;
}
