import { Keyy } from './keyy.model';

export function createKeyy(keyy: Partial<Keyy>): Keyy | null {
    if (!keyy.name) {
        return null;
    }
    return {
        id: keyy.id || undefined,
        itemNumber: keyy.itemNumber || undefined,
        name: keyy.name,
        home: keyy.home || undefined,
        owner: keyy.owner || undefined,
        address: keyy.address || undefined,
        amount: keyy.amount || undefined,
        note: keyy.note || undefined,
        assignedId: keyy.assignedId || undefined,
    };
}
