import { Injectable } from '@angular/core';
import {
    DefaultHttpUrlGenerator,
    HttpResourceUrls,
    normalizeRoot,
    Pluralizer,
} from '@ngrx/data';

@Injectable()
export class PluralHttpUrlGenerator extends DefaultHttpUrlGenerator {
    constructor(private myPluralizer: Pluralizer) {
        super(myPluralizer);
    }

    protected getResourceUrls(
        entityName: string,
        root: string
    ): HttpResourceUrls {
        let resourceUrls = this.knownHttpResourceUrls[entityName];
        if (!resourceUrls) {
            const nRoot = normalizeRoot(root);
            let url = `${nRoot}/${this.myPluralizer.pluralize(
                entityName
            )}/`.toLowerCase();

            url = url.replace('keyies', 'keyys');

            resourceUrls = {
                entityResourceUrl: url,
                collectionResourceUrl: url,
            };
            this.registerHttpResourceUrls({ [entityName]: resourceUrls });
        }
        return resourceUrls;
    }
}
