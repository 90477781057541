import { ProtocolTemplate } from './protocol-template.model';

export function createProtocolTemplate(
    protocolTemplate: ProtocolTemplate
): ProtocolTemplate {
    return {
        id: protocolTemplate.id || undefined,
        protocolSections: protocolTemplate.protocolSections || undefined,
        name: protocolTemplate.name,
    };
}
