<sys-auth-page [title]="title" [mainText]="mainText" [logoUrl]="logoUrl">
    <ng-container right-content>
        <div id="authForm">
            <div class="logo" fxHide.gt-xs>
                <img [src]="logoUrl" alt="logo" />
            </div>

            <div class="title sys--align-center mb-20">
                Bestätigungs-E-Mail ist unterwegs
            </div>

            <div
                class="register"
                fxLayout="column"
                fxLayoutAlign="center center"
            >
                <a class="link ml-8" [routerLink]="'/anmelden'"
                    >Zurück zur Anmeldung</a
                >
            </div>
        </div>
    </ng-container>
</sys-auth-page>
