import { TransportModel } from '../transport-entity.model';
import { TransportLocation } from '../../transport-locations/transport-location-entity.model';

export function setLocationData(
    transportLocation: TransportLocation,
    transportModel: TransportModel,
    type: 'start' | 'target'
): TransportModel {
    if (type === 'start') {
        if (!transportModel.startLocation) {
            transportModel.startLocation = { tariffs: [] };
        }
        if (!transportLocation) {
            transportModel.startLocation = { tariffs: [] };
        } else {
            transportModel.startLocation = {
                ...transportModel.startLocation,
            };
            transportModel.startLocation.companyName = transportLocation.customerAdministration
                ? transportLocation.customerAdministration.companyName
                : transportLocation.companyName;
            transportModel.startLocation.street = transportLocation.street;
            transportModel.startLocation.zip = transportLocation.zip;
            transportModel.startLocation.city = transportLocation.city;
            transportModel.startPassengerLocationBuilding =
                transportLocation.building;
            transportModel.startPassengerLocationFloor =
                transportLocation.floor;
            transportModel.startPassengerLocationRoom = transportLocation.room;
            transportModel.startPassengerLocationContactPerson =
                transportLocation.contactPerson;
            transportModel.startPassengerLocationPhone =
                transportLocation.phone;
            transportModel.startPassengerLocationComment =
                transportLocation.comment;
            transportModel.startLocation.id = transportLocation.id;
            transportModel.startLocation.isManual = transportLocation.isManual;
        }
    } else {
        if (!transportModel.targetLocation) {
            transportModel.targetLocation = { tariffs: [] };
        }
        if (!transportLocation) {
            transportModel.targetLocation = { tariffs: [] };
        } else {
            transportModel.targetLocation = {
                ...transportModel.targetLocation,
            };
            transportModel.targetLocation.companyName = transportLocation.customerAdministration
                ? transportLocation.customerAdministration.companyName
                : transportLocation.companyName;
            transportModel.targetLocation.street = transportLocation.street;
            transportModel.targetLocation.zip = transportLocation.zip;
            transportModel.targetLocation.city = transportLocation.city;
            transportModel.targetPassengerLocationBuilding =
                transportLocation.building;
            transportModel.targetPassengerLocationFloor =
                transportLocation.floor;
            transportModel.targetPassengerLocationRoom = transportLocation.room;
            transportModel.targetPassengerLocationContactPerson =
                transportLocation.contactPerson;
            transportModel.targetPassengerLocationPhone =
                transportLocation.phone;
            transportModel.targetPassengerLocationComment =
                transportLocation.comment;
            transportModel.targetLocation.id = transportLocation.id;
            transportModel.targetLocation.isManual = transportLocation.isManual;
        }
    }
    return transportModel;
}
