export const allMobilePermissions: string[] = [
    'transports',
    'orders',
    'vehicles',
    'passengers',
    'transportLocations',
    'insurances',
    'checklists',
    'complaints',
    'defects',
    'qmDocuments',
    'materials',
    'devices',
    'keyys',
];
