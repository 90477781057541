import { createAction, props } from '@ngrx/store';
import { ColumnModel } from '@syncfusion/ej2-angular-grids';
import { HttpRequest } from '@angular/common/http';
import { Keyy, KeyyProperty } from '../features/keyys';
import {
    ItemType,
    ScannerType,
    SysBaseEntityProperty,
    SysBaseEntityType,
} from '../types';
import { Material, MaterialProperty } from '../features/materials';
import { FileUpload } from '../features/file-upload';
import { Device, DeviceProperty } from '../features/devices';
import { Employee } from '../features';
import { EventData } from './event-data.model';

export const refresh = createAction('[App] App Refresh');
export const notRefresh = createAction('[App] App Not Refresh');
export const refreshColumns = createAction(
    '[App] Refresh Columns',
    props<{ columns: ColumnModel[]; entityType: string }>()
);
export const showRequestConfirmation = createAction(
    '[App] Ask for permission',
    props<{
        foundResults: string;
        originalRequest: HttpRequest<any>;
        requestType: 'duplicate' | 'transportInvoice' | 'worktime';
    }>()
);

export const showCollectionLocations = createAction(
    '[Common] show collection locations'
);

export const showUsers = createAction('[Common] show users');
export const showCompany = createAction('[Common] show company');
export const showUserDetails = createAction(
    '[Common] show user details',
    props<{ own: boolean; userId?: string }>()
);

export const showEditEntityValueAlert = createAction(
    '[Common] show edit entity value alert',
    props<{
        property:
            | KeyyProperty
            | MaterialProperty
            | DeviceProperty
            | SysBaseEntityProperty;
        entity: Keyy | Material | Device | SysBaseEntityType;
        ligType: ItemType;
    }>()
);

export const showImageDetails = createAction(
    '[Common] show update image alert',
    props<{
        item: SysBaseEntityType | Partial<Employee>;
        ligType: ItemType;
        file?: FileUpload;
        fileType: string;
    }>()
);

export const currentEntityUpdated = createAction(
    '[Entity] is updated',
    props<{ entityType: ItemType; id: string }>()
);

export const searchQueryChanged = createAction(
    '[List Tab] search query changed',
    props<{ query: string }>()
);

export const showScanner = createAction(
    '[Common] Scanner',
    props<{
        itemType: ItemType;
        action: ScannerType;
    }>()
);

export const processScannerAction = createAction(
    '[Common] Process scanner action',
    props<{
        itemType: ItemType;
        action: 'book' | 'refill' | 'toOrder' | 'details';
        resultString: string;
    }>()
);

export const eventReceived = createAction(
    '[Common] Event received',
    props<{ eventData: EventData }>()
);

export const showSpinner = createAction('[Common] Show spinner');
export const hideSpinner = createAction('[Common] Hide spinner');
