import { ColumnModel } from '@syncfusion/ej2-angular-grids';

export const checklistEntityColumns: ColumnModel[] = [
    {
        field: 'name',
        headerText: 'Name',
        validationRules: { required: true },
    },
    {
        field: 'createdAt',
        type: 'date',
        format: { type: 'dateTime', format: 'dd/MM/yyyy' },
        headerText: 'Start',
        editType: 'datetimepickeredit',
        allowEditing: false,
    },
    {
        field: 'dueAt',
        type: 'date',
        format: { type: 'dateTime', format: 'dd/MM/yyyy' },
        headerText: 'Fällig',
        editType: 'datetimepickeredit',
        allowEditing: false,
    },
    {
        field: 'completed',
        headerText: 'Status in %',
        allowEditing: false,
    },
    {
        field: 'itemsCount',
        headerText: 'Einträge',
        allowEditing: false,
    },
    {
        field: 'searchTerms',
        headerText: 'Suche',
        visible: false,
    },
];
