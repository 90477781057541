import { Injectable } from '@angular/core';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Cloth } from './cloth-entity.model';

@Injectable({ providedIn: 'root' })
export class ClothEntityService extends EntityCollectionServiceBase<Cloth> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory
    ) {
        super('Cloth', serviceElementsFactory);
    }

    findClothsOfEmployee(employeeId: string): Observable<Cloth[]> {
        return this.entities$.pipe(
            map((cloths: Cloth[]) => {
                return cloths.filter(
                    (cloth) => cloth.employeeId === employeeId
                );
            })
        );
    }
}
