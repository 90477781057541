import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

import { FuseNavigationComponent } from './navigation.component';
import { FuseNavVerticalItemComponent } from './item/item.component';
import { FuseNavVerticalCollapsableComponent } from './collapsable/collapsable.component';
import { FuseNavVerticalGroupComponent } from './group/group.component';
import { TranslocoModule } from '@ngneat/transloco';
import { AccessRightsModule } from '@sansys/crosslib';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        MatIconModule,
        MatRippleModule,

        TranslocoModule,

        AccessRightsModule,
        NgxPermissionsModule,
    ],
    exports: [FuseNavigationComponent],
    declarations: [
        FuseNavigationComponent,
        FuseNavVerticalGroupComponent,
        FuseNavVerticalItemComponent,
        FuseNavVerticalCollapsableComponent,
    ],
})
export class FuseNavigationModule {}
