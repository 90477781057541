import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldWrapper } from '@ngx-formly/core';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns/src/drop-down-base/drop-down-base-model';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { getSearchQuery } from '../../syncfusion/query.functions';

@Component({
    selector: 'sys-employee-autocomplete-type',
    template: `
        <ng-container #fieldComponent></ng-container>
        <div class="mb-20">
            <ejs-dropdownlist
                [showClearButton]="true"
                cssClass="e-outline"
                floatLabelType="Always"
                [dataSource]="data"
                [fields]="fields"
                [formControl]="formControl"
                [placeholder]="to.placeholder ? to.placeholder : ''"
                [allowFiltering]="true"
                (filtering)="onFiltering($event)"
                id="{{ field.key }}"
                #keyyDropdown
                [ngClass]="{
                    'required-field':
                        to.required && formControl.status !== 'VALID'
                }"
            >
                <ng-template #noRecordsTemplate>
                    <div>
                        <div>Neuen Eintrag erstellen?</div>
                        <button class="e-btn" (click)="onClick()" id="add">
                            Eintrag hinzufügen
                        </button>
                    </div>
                </ng-template>
            </ejs-dropdownlist>
            <div
                class="mat-error"
                *ngIf="
                    formControl.status !== 'VALID' &&
                    formControl.touched &&
                    to.required
                "
            >
                Pflichtfeld, bitte ausfüllen.
            </div>
        </div>
    `,
})
export class DefaultLocationsAutocompleteWrapperComponent
    extends FieldWrapper
    implements OnInit {
    @ViewChild('keyyDropdown') keyyDropdown!: DropDownListComponent;
    public data: {
        val: string;
    }[] = [];
    public defaultLocations: string[] = [];
    public formControl!: FormControl;
    // maps the appropriate column to fields property
    public fields: FieldSettingsModel = {
        text: 'val',
        value: 'val',
    };

    // Bind the filter event
    public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
        e.updateData(this.data, getSearchQuery('val', e.text));
    };

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (this.to.locationOptions) {
            this.defaultLocations = this.to.locationOptions;
            this.data.length = 0;
            this.defaultLocations.forEach((defaultLocation) => {
                this.data.push({ val: defaultLocation });
            });
        }
    }

    public onClick(): void {
        const newVal = (this.keyyDropdown as any).filterInput.value;
        this.keyyDropdown.addItem({ val: newVal });
        this.keyyDropdown.value = newVal;
    }
}
