import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { eventReceived } from './app.actions';
import { EventData } from './event-data.model';
import { MERCURE_URL } from '../injection-tokens';

@Injectable({ providedIn: 'root' })
export class EventsService {
    private eventSource?: EventSource;

    constructor(
        private store: Store<any>,
        @Inject(MERCURE_URL) public mercureUrl: string
    ) {}

    subscribeToEventStream(companyId?: string): void {
        if (!companyId) {
            if (this.eventSource) {
                this.eventSource.close();
            }
            return;
        }
        if (this.eventSource) {
            this.eventSource.close();
        }

        const url = new URL(
            'https://' + this.mercureUrl + '/.well-known/mercure'
        );

        // Add topic to listen to
        url.searchParams.append('topic', companyId);

        this.eventSource = new EventSource(url as any);

        // The callback will be called every time an update is published
        this.eventSource.onmessage = (e) => {
            const eventData = JSON.parse(e.data) as EventData;
            if (!eventData.itemType || !eventData.action) {
                return;
            }
            this.store.dispatch(eventReceived({ eventData }));
            console.log(eventData);
        };

        this.eventSource.onerror = (e) => console.log(JSON.stringify(e));
    }

    unsubscribeFromEventStream(): void {
        if (this.eventSource) {
            this.eventSource.close();
            this.eventSource = undefined;
        }
    }
}
