import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';
import { Company, WorktimeType } from './company.model';
import { Actions, ofType } from '@ngrx/effects';
import { tap, map } from 'rxjs/operators';
import { refresh } from '../../state/app.actions';
import { LocalStorageService } from '../../common-services/local-storage.service';
import { getContrastColorOfHexColor } from '../../scss/color.functions';
import { EmployeeClassificationOptions } from '../../types';
import { Badges } from '../../state';
import { FileUpload } from '../file-upload';

@Injectable({ providedIn: 'root' })
export class CompanyService {
    constructor(
        private localStorageService: LocalStorageService,
        private action$: Actions,
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {}

    private storedLoadingTime?: number;

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.getCompany().subscribe();
        })
    );

    getCompany(): Observable<Company> {
        return this.http.get<Company>(this.server + 'api/companies/own').pipe(
            map((company) => {
                company.employeeClassifications.departments.sort();
                company.employeeClassifications.functions.sort();
                company.employeeClassifications.groups.sort();
                company.employeeClassifications.categories.sort();
                this.storedLoadingTime = company.loadingTime || 0;
                return company;
            })
        );
    }

    getStoredLoadingTime(): number {
        return this.storedLoadingTime ?? 0;
    }

    getLoadingTime(): Observable<number> {
        if (this.storedLoadingTime || this.storedLoadingTime === 0) {
            return of(this.storedLoadingTime);
        }
        return this.http
            .get<number>(this.server + 'api/companies/loadingtime')
            .pipe(
                tap((loadingTime) => {
                    this.storedLoadingTime = loadingTime;
                })
            );
    }

    getWorktimeTypesForShiftSchedule(): Observable<WorktimeType[]> {
        return this.http.get<WorktimeType[]>(
            this.server + 'api/companies/worktimetypesforshiftschedule'
        );
    }

    setShortcuts(shortcuts: string): Observable<any> {
        return this.http.post(this.server + 'api/shortcuts', { shortcuts });
    }

    getShortcuts(): Observable<any> {
        return this.http.get(this.server + 'api/shortcuts');
    }

    getCustomerRouteInfos(subDomain: string): Observable<any> {
        return this.http.get(this.server + 'ping/customerinfos/' + subDomain);
    }

    getPrimaryColor(): Observable<any> {
        return this.http.get(this.server + 'api/companies/color');
    }

    getEmployeeClassifications(): Observable<EmployeeClassificationOptions> {
        return this.http.get<EmployeeClassificationOptions>(
            this.server + 'api/companies/employeeclassifications'
        );
    }

    getGroups(): Observable<string[]> {
        return this.http.get<string[]>(this.server + 'api/companies/groups');
    }

    update(company: Company): Observable<Company> {
        if (company.holidays) {
            company.holidays = company.holidays.split(' ').join('');
        }
        this.storedLoadingTime = company.loadingTime || 0;
        return this.http.put<Company>(
            this.server + 'api/companies/own',
            company
        );
    }

    setColors(primaryColor: string): void {
        if (primaryColor) {
            this.localStorageService.setItem('sansys_color', primaryColor);
            const textColor = getContrastColorOfHexColor(primaryColor);

            document.body.style.setProperty('--primary-color', primaryColor);
            document.body.style.setProperty(
                '--primary-color-text',
                `${textColor}`
            );
        }
    }

    getBadges(): Observable<Badges> {
        return this.http.get<Badges>(
            this.server + 'api/companies/badges?ignoreRequest=true'
        );
    }

    getAppVersion(): Observable<number> {
        return this.http.get<number>(this.server + 'api/companies/appversion');
    }

    getTargetCompanies(): Observable<Company[]> {
        return this.http.get<Company[]>(
            this.server + 'api/companies/targetcompanies'
        );
    }

    getFiles(): Observable<FileUpload[]> {
        return this.http.get<FileUpload[]>(this.server + 'api/companies/files');
    }
}
