import { FormlyFieldConfig } from '@ngx-formly/core';
import { Company } from '@sansys/crosslib';
import { translate } from '@ngneat/transloco';

export function getTariffVersionFormConfig(
    disabled: boolean,
    company: Company
): FormlyFieldConfig[] {
    let nightAddition = '';
    if (company.nightStart && company.nightEnd) {
        nightAddition =
            ' (' + company.nightStart + ' - ' + company.nightEnd + ' Uhr)';
    }
    return [
        {
            key: 'validFrom',
            type: 'datePickerType',
            templateOptions: {
                label: translate('Gültig ab'),
                placeholder: translate('Gültig ab'),
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'validFromInput',
                },
            },
            hideExpression: 'model.manual',
        },
        {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
                {
                    className: 'flex-50 pr-12',
                    key: 'calculateFromFacility',
                    type: 'toggle',
                    templateOptions: {
                        label: translate('Entfernungen ab Standort berechnen'),
                        type: 'boolean',
                        attributes: {
                            'data-testid': 'calculateFromFacilityInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': () => {
                            return disabled;
                        },
                    },
                },
                {
                    className: 'flex-50',
                    key: 'roundUpTo5Rappen',
                    type: 'toggle',
                    templateOptions: {
                        label: translate('Summe aufrunden auf 0.05CHF'),
                        type: 'boolean',
                        attributes: {
                            'data-testid': 'roundUpTo5RappenInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': () => {
                            return disabled;
                        },
                    },
                },
            ],
        },
        {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
                {
                    className: 'flex-50 pr-12',
                    key: 'nightFeePercent',
                    type: 'input',
                    templateOptions: {
                        label: translate('Nachtzuschlag in %') + nightAddition,
                        type: 'number',
                        placeholder: translate('Nachtzuschlag in %'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'nightFeePercentInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': () => {
                            return disabled;
                        },
                    },
                },
                {
                    className: 'flex-50',
                    key: 'nightFeeLump',
                    type: 'input',
                    templateOptions: {
                        label:
                            translate('Nachtzuschlag pauschal') + nightAddition,
                        type: 'number',
                        placeholder: translate('Nachtzuschlag pauschal'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'nightFeeLumpInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': () => {
                            return disabled;
                        },
                    },
                },
            ],
        },
        {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
                {
                    className: 'flex-50 pr-12',
                    key: 'sundayHolidayFeePercent',
                    type: 'input',
                    templateOptions: {
                        label: translate('Sonn-/Feiertagszuschlag in %'),
                        type: 'number',
                        placeholder: translate('Sonn-/Feiertagszuschlag in %'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'sundayHolidayFeePercentInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': () => {
                            return disabled;
                        },
                    },
                },
                {
                    className: 'flex-50',
                    key: 'sundayHolidayFeeLump',
                    type: 'input',
                    templateOptions: {
                        label: translate('Sonn-/Feiertagszuschlag pauschal'),
                        type: 'number',
                        placeholder: translate(
                            'Sonn-/Feiertagszuschlag pauschal'
                        ),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'sundayHolidayFeeLumpInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': () => {
                            return disabled;
                        },
                    },
                },
            ],
        },
        {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
                {
                    className: 'flex-50 pr-12',
                    key: 'saturdayFeePercent',
                    type: 'input',
                    templateOptions: {
                        label: translate('Samstagszuschlag in %'),
                        type: 'number',
                        placeholder: translate('Samstagszuschlag in %'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'saturdayFeePercentInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': () => {
                            return disabled;
                        },
                    },
                },
                {
                    className: 'flex-50',
                    key: 'saturdayFeeLump',
                    type: 'input',
                    templateOptions: {
                        label: translate('Samstagszuschlag pauschal'),
                        type: 'number',
                        placeholder: translate('Samstagszuschlag pauschal'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'saturdayFeeLumpInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': () => {
                            return disabled;
                        },
                    },
                },
            ],
        },
        {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
                {
                    className: 'flex-50 pr-12',
                    key: 'urgentFeePercent',
                    type: 'input',
                    templateOptions: {
                        label: translate('Dringlichkeitszuschlag in %'),
                        type: 'number',
                        placeholder: translate('Dringlichkeitszuschlag in %'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'urgentFeePercentInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': () => {
                            return disabled;
                        },
                    },
                },
                {
                    className: 'flex-50',
                    key: 'urgentFeeLump',
                    type: 'input',
                    templateOptions: {
                        label: translate('Dringlichkeitszuschlag pauschal'),
                        type: 'number',
                        placeholder: translate(
                            'Dringlichkeitszuschlag pauschal'
                        ),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'urgentFeeLumpInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': () => {
                            return disabled;
                        },
                    },
                },
            ],
        },
        {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
                {
                    className: 'flex-50 pr-12',
                    key: 'onlineDiscount',
                    type: 'input',
                    templateOptions: {
                        label: translate('Rabatt für Online Bestellung in CHF'),
                        type: 'number',
                        placeholder: translate(
                            'Rabatt für Online Bestellung in CHF'
                        ),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'onlineDiscountInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': () => {
                            return disabled;
                        },
                    },
                },
            ],
        },
        {
            key: 'lumpsums',
            type: 'sectionRepeater',
            wrappers: ['panelWrapper'],
            templateOptions: {
                addText: translate('Pauschale hinzufügen'),
                label: translate('Pauschalen pro Transport'),
                disabled: disabled,
            },
            fieldArray: {
                fieldGroupClassName: 'display-flex',
                fieldGroup: [
                    {
                        className: 'flex-75 pr-12',
                        type: 'input',
                        key: 'name',
                        templateOptions: {
                            label: translate('Name'),
                            required: true,
                            appearance: 'outline',
                            floatLabel: 'always',
                            attributes: {
                                'data-testid': 'lumpsumName',
                            },
                        },
                        expressionProperties: {
                            'templateOptions.disabled': () => {
                                return disabled;
                            },
                        },
                    },
                    {
                        className: 'w-160 pr-12',
                        type: 'input',
                        key: 'price',
                        templateOptions: {
                            type: 'number',
                            label: translate('Betrag in CHF'),
                            required: true,
                            appearance: 'outline',
                            floatLabel: 'always',
                            attributes: {
                                'data-testid': 'lumpsumPrice',
                            },
                        },
                        expressionProperties: {
                            'templateOptions.disabled': () => {
                                return disabled;
                            },
                        },
                    },
                    {
                        className: 'w-160',
                        type: 'toggle',
                        key: 'perEmployee',
                        templateOptions: {
                            type: 'boolean',
                            label: translate('Pro Mitarbeiter'),
                            attributes: {
                                'data-testid': 'lumpsumPerEmployee',
                            },
                        },
                        expressionProperties: {
                            'templateOptions.disabled': () => {
                                return disabled;
                            },
                        },
                    },
                    {
                        className: 'w-180 flex-10',
                        type: 'toggle',
                        key: 'nightSundayHolidayFeeRelevant',
                        templateOptions: {
                            type: 'boolean',
                            label: translate('Zuschlagsrelevant'),
                            attributes: {
                                'data-testid': 'nightSundayHolidayFeeRelevant',
                            },
                        },
                        expressionProperties: {
                            'templateOptions.disabled': () => {
                                return disabled;
                            },
                        },
                    },
                ],
            },
        },
        {
            key: 'variableCosts',
            type: 'sectionRepeater',
            wrappers: ['panelWrapper'],
            templateOptions: {
                addText: translate('Variable Kosten hinzufügen'),
                label: translate('Variable Kosten'),
                disabled: disabled,
            },
            fieldArray: {
                fieldGroup: [
                    {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                            {
                                className: 'flex-50 pr-12',
                                type: 'input',
                                key: 'name',
                                templateOptions: {
                                    label: translate('Name'),
                                    required: true,
                                    appearance: 'outline',
                                    floatLabel: 'always',
                                    attributes: {
                                        'data-testid': 'variableCostName',
                                    },
                                },
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return disabled;
                                    },
                                },
                            },
                            {
                                className: 'w-160 flex-10',
                                type: 'toggle',
                                key: 'perEmployee',
                                templateOptions: {
                                    type: 'boolean',
                                    label: translate('Pro Mitarbeiter'),
                                    attributes: {
                                        'data-testid':
                                            'variableCostPerEmployee',
                                    },
                                },
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return disabled;
                                    },
                                },
                            },
                            {
                                className: 'w-180 flex-10',
                                type: 'toggle',
                                key: 'nightSundayHolidayFeeRelevant',
                                templateOptions: {
                                    type: 'boolean',
                                    label: translate('Zuschlagsrelevant'),
                                    attributes: {
                                        'data-testid':
                                            'nightSundayHolidayFeeRelevant',
                                    },
                                },
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return disabled;
                                    },
                                },
                            },
                        ],
                    },
                    {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                            {
                                className: 'w-100 pr-12',
                                type: 'input',
                                key: 'unitAmount',
                                templateOptions: {
                                    type: 'number',
                                    label: translate('pro'),
                                    min: 1,
                                    required: true,
                                    appearance: 'outline',
                                    floatLabel: 'always',
                                    attributes: {
                                        'data-testid': 'variableCostUnitAmount',
                                    },
                                },
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return disabled;
                                    },
                                },
                            },
                            {
                                className: 'w-160 pr-12',
                                type: 'select',
                                key: 'unit',
                                templateOptions: {
                                    label: translate('Einheit'),
                                    appearance: 'outline',
                                    floatLabel: 'always',
                                    required: true,
                                    options: [
                                        {
                                            value: 'minutes',
                                            label: translate('Minuten'),
                                        },
                                        {
                                            value: 'km',
                                            label: translate('Kilometer'),
                                        },
                                    ],
                                    attributes: {
                                        'data-testid': 'variableCostUnit',
                                    },
                                },
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return disabled;
                                    },
                                },
                            },
                            {
                                className: 'w-160 pr-12',
                                type: 'input',
                                key: 'amountInclusive',
                                templateOptions: {
                                    type: 'number',
                                    label: translate('Km/Min inklusive'),
                                    min: 0,
                                    appearance: 'outline',
                                    floatLabel: 'always',
                                    attributes: {
                                        'data-testid':
                                            'variableCostAmountInclusive',
                                    },
                                },
                                defaultValue: 0,
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return disabled;
                                    },
                                },
                            },
                            {
                                className: 'w-160 pr-12',
                                type: 'input',
                                key: 'price',
                                templateOptions: {
                                    type: 'number',
                                    label: translate('Betrag in CHF'),
                                    required: true,
                                    appearance: 'outline',
                                    floatLabel: 'always',
                                    attributes: {
                                        'data-testid': 'variableCostPrice',
                                    },
                                },
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return disabled;
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
        {
            key: 'variableCostsDegressive',
            type: 'sectionRepeater',
            wrappers: ['panelWrapper'],
            templateOptions: {
                addText: translate('Degressive variable Kosten hinzufügen'),
                label: translate('Degressive Variable Kosten'),
                disabled: disabled,
            },
            fieldArray: {
                fieldGroup: [
                    {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                            {
                                className: 'flex-50 pr-12',
                                type: 'input',
                                key: 'name',
                                templateOptions: {
                                    label: translate('Name'),
                                    required: true,
                                    appearance: 'outline',
                                    floatLabel: 'always',
                                    attributes: {
                                        'data-testid': 'variableCostName',
                                    },
                                },
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return disabled;
                                    },
                                },
                            },
                            {
                                className: 'w-100 pr-12',
                                type: 'input',
                                key: 'unitAmount',
                                templateOptions: {
                                    type: 'number',
                                    label: translate('pro'),
                                    min: 1,
                                    required: true,
                                    appearance: 'outline',
                                    floatLabel: 'always',
                                    attributes: {
                                        'data-testid': 'variableCostUnitAmount',
                                    },
                                },
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return disabled;
                                    },
                                },
                            },
                            {
                                className: 'w-160 pr-12',
                                type: 'select',
                                key: 'unit',
                                templateOptions: {
                                    label: translate('Einheit'),
                                    appearance: 'outline',
                                    floatLabel: 'always',
                                    required: true,
                                    options: [
                                        {
                                            value: 'minutes',
                                            label: translate('Minuten'),
                                        },
                                        {
                                            value: 'km',
                                            label: translate('Kilometer'),
                                        },
                                    ],
                                    attributes: {
                                        'data-testid': 'variableCostUnit',
                                    },
                                },
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return disabled;
                                    },
                                },
                            },
                            {
                                className: 'w-160 flex-10',
                                type: 'toggle',
                                key: 'perEmployee',
                                templateOptions: {
                                    type: 'boolean',
                                    label: translate('Pro Mitarbeiter'),
                                    attributes: {
                                        'data-testid':
                                            'variableCostPerEmployee',
                                    },
                                },
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return disabled;
                                    },
                                },
                            },
                            {
                                className: 'w-180 flex-10',
                                type: 'toggle',
                                key: 'nightSundayHolidayFeeRelevant',
                                templateOptions: {
                                    type: 'boolean',
                                    label: translate('Zuschlagsrelevant'),
                                    attributes: {
                                        'data-testid':
                                            'nightSundayHolidayFeeRelevant',
                                    },
                                },
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return disabled;
                                    },
                                },
                            },
                        ],
                    },
                    {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                            {
                                className: 'w-160 pr-12',
                                type: 'input',
                                key: 'price',
                                templateOptions: {
                                    type: 'number',
                                    label: translate('Betrag in CHF') + ' 1',
                                    required: true,
                                    appearance: 'outline',
                                    floatLabel: 'always',
                                },
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return disabled;
                                    },
                                },
                            },
                            {
                                className: 'w-160 pr-12',
                                type: 'input',
                                key: 'from1',
                                defaultValue: 0,
                                templateOptions: {
                                    type: 'number',
                                    label: translate('Ab') + '... 1',
                                    required: true,
                                    appearance: 'outline',
                                    floatLabel: 'always',
                                },
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return true;
                                    },
                                },
                            },
                            {
                                className: 'w-160 pr-12',
                                type: 'input',
                                key: 'price2',
                                templateOptions: {
                                    type: 'number',
                                    label: translate('Betrag in CHF') + ' 2',
                                    required: true,
                                    appearance: 'outline',
                                    floatLabel: 'always',
                                },
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return disabled;
                                    },
                                },
                            },
                            {
                                className: 'w-160 pr-12',
                                type: 'input',
                                key: 'from2',
                                templateOptions: {
                                    type: 'number',
                                    label: translate('Ab') + '... 2',
                                    required: true,
                                    appearance: 'outline',
                                    floatLabel: 'always',
                                },
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return disabled;
                                    },
                                },
                            },
                            {
                                className: 'w-160 pr-12',
                                type: 'input',
                                key: 'price3',
                                templateOptions: {
                                    type: 'number',
                                    label: translate('Betrag in CHF') + ' 3',
                                    appearance: 'outline',
                                    floatLabel: 'always',
                                },
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return disabled;
                                    },
                                },
                            },
                            {
                                className: 'w-160 pr-12',
                                type: 'input',
                                key: 'from3',
                                templateOptions: {
                                    type: 'number',
                                    label: translate('Ab') + '... 3',
                                    appearance: 'outline',
                                    floatLabel: 'always',
                                },
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return disabled;
                                    },
                                },
                            },
                            {
                                className: 'w-160 pr-12',
                                type: 'input',
                                key: 'price4',
                                templateOptions: {
                                    type: 'number',
                                    label: translate('Betrag in CHF') + ' 4',
                                    appearance: 'outline',
                                    floatLabel: 'always',
                                },
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return disabled;
                                    },
                                },
                            },
                            {
                                className: 'w-160 pr-12',
                                type: 'input',
                                key: 'from4',
                                templateOptions: {
                                    type: 'number',
                                    label: translate('Ab') + '... 4',
                                    appearance: 'outline',
                                    floatLabel: 'always',
                                },
                                expressionProperties: {
                                    'templateOptions.disabled': () => {
                                        return disabled;
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
        {
            className: 'w-160 flex-10',
            type: 'toggle',
            defaultValue: false,
            key: 'mustSelectOneNotRequiredOption',
            templateOptions: {
                label: translate(
                    'Ausser den Pflichtfeldern muss mindestens eine Option gewählt werden'
                ),
                attributes: {
                    'data-testid': 'optionMustSelectOneNotRequiredOption',
                },
            },
            expressionProperties: {
                'templateOptions.disabled': () => {
                    return disabled;
                },
            },
        },
        {
            key: 'options',
            type: 'optionRepeater',
            wrappers: ['panelWrapper'],
            templateOptions: {
                label: translate('Option'),
                disabled: disabled,
            },
            fieldArray: {
                fieldGroupClassName: 'display-flex',
                fieldGroup: [
                    {
                        className: 'flex-50 pr-12',
                        type: 'input',
                        key: 'name',
                        templateOptions: {
                            label: translate('Name'),
                            required: true,
                            appearance: 'outline',
                            floatLabel: 'always',
                            attributes: {
                                'data-testid': 'optionName',
                            },
                        },
                        expressionProperties: {
                            'templateOptions.disabled': () => {
                                return disabled;
                            },
                        },
                        validators: {
                            mustNotContainPoint: {
                                expression: (field: any) => {
                                    return !field.value?.includes('.');
                                },
                                message: (
                                    error: any,
                                    field: FormlyFieldConfig
                                ): string => {
                                    if (field.formControl) {
                                        return translate(
                                            'Wert darf keinen Punkt enthalten.'
                                        );
                                    }
                                    return '';
                                },
                            },
                        },
                    },
                    {
                        className: 'w-160 pr-12',
                        type: 'select',
                        key: 'type',
                        templateOptions: {
                            type: 'number',
                            label: translate('Formulartyp'),
                            required: true,
                            appearance: 'outline',
                            floatLabel: 'always',
                            options: [
                                {
                                    value: 'checkbox',
                                    label: translate('Checkbox'),
                                },
                                { value: 'input', label: translate('Text') },
                                {
                                    value: 'select',
                                    label: translate('Auswahl'),
                                },
                                {
                                    value: 'vcReductionMinutes',
                                    label: translate('VK Nachlass Minuten'),
                                },
                            ],
                            attributes: {
                                'data-testid': 'optionType',
                            },
                        },
                        expressionProperties: {
                            'templateOptions.disabled': () => {
                                return disabled;
                            },
                        },
                    },
                    {
                        className: 'w-160 pr-12',
                        type: 'input',
                        key: 'price',
                        templateOptions: {
                            type: 'number',
                            label: translate('Betrag in CHF'),
                            appearance: 'outline',
                            floatLabel: 'always',
                            attributes: {
                                'data-testid': 'optionPrice',
                            },
                        },
                        hideExpression: (model: any) =>
                            model['type'] === 'select' ||
                            model['type'] === 'vcReductionMinutes',
                        expressionProperties: {
                            'templateOptions.disabled': () => {
                                return disabled;
                            },
                        },
                    },
                    {
                        className: 'w-160 pr-12',
                        type: 'input',
                        key: 'percentage',
                        templateOptions: {
                            type: 'number',
                            label: translate('Anteil in %'),
                            appearance: 'outline',
                            floatLabel: 'always',
                            attributes: {
                                'data-testid': 'optionPercentage',
                            },
                        },
                        hideExpression: (model: any) =>
                            model['type'] === 'select' ||
                            model['type'] === 'vcReductionMinutes',
                        expressionProperties: {
                            'templateOptions.disabled': () => {
                                return disabled;
                            },
                        },
                    },
                    {
                        className: 'w-160 flex-10',
                        type: 'toggle',
                        defaultValue: false,
                        key: 'customerForm',
                        templateOptions: {
                            label: translate('Kundenform.'),
                            attributes: {
                                'data-testid': 'optionCustomerForm',
                            },
                        },
                        expressionProperties: {
                            'templateOptions.disabled': () => {
                                return disabled;
                            },
                        },
                    },
                    {
                        className: 'w-160 flex-10',
                        type: 'toggle',
                        defaultValue: false,
                        key: 'mandatory',
                        templateOptions: {
                            label: translate('Pflichtfeld'),
                            attributes: {
                                'data-testid': 'mandatory',
                            },
                        },
                        expressionProperties: {
                            'templateOptions.disabled': () => {
                                return disabled;
                            },
                        },
                    },
                    {
                        className: 'w-180 flex-10',
                        type: 'toggle',
                        key: 'nightSundayHolidayFeeRelevant',
                        templateOptions: {
                            type: 'boolean',
                            label: translate('Zuschlagsrelevant'),
                            attributes: {
                                'data-testid': 'nightSundayHolidayFeeRelevant',
                            },
                        },
                        expressionProperties: {
                            'templateOptions.disabled': () => {
                                return disabled;
                            },
                        },
                        hideExpression: (model: any) =>
                            model['type'] === 'vcReductionMinutes',
                    },
                ],
            },
        },
    ];
}
