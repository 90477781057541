<sys-auth-page [title]="title" [mainText]="mainText" [logoUrl]="logoUrl">
    <ng-container right-content>
        <div id="authForm">
            <div class="logo" fxHide.gt-xs>
                <img [src]="logoUrl" alt="logo" />
            </div>

            <div class="title">Passwort zurücksetzen</div>

            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <formly-form
                    [form]="form"
                    [fields]="fields"
                    [model]="model"
                ></formly-form>
                <button
                    mat-raised-button
                    color="accent"
                    class="form__submit-button"
                    aria-label="Anmeldung"
                    [disabled]="form.invalid"
                    type="submit"
                    data-testid="loginButton"
                >
                    Passwort zurücksetzen
                </button>
            </form>

            <div
                class="register"
                fxLayout="column"
                fxLayoutAlign="center center"
            >
                <a class="link" [routerLink]="'/login'">
                    Zurück zur Anmeldung
                </a>
            </div>
        </div>
    </ng-container>
</sys-auth-page>
