import { FormlyFieldConfig } from '@ngx-formly/core';
import { translate } from '@ngneat/transloco';

export function getTransportLocationFormConfig(
    pageType: 'create' | 'edit'
): FormlyFieldConfig[] {
    return [
        {
            key: 'companyName',
            type: 'input',
            templateOptions: {
                label: translate('Firma/Name'),
                placeholder: translate('Firma/Name'),
                required: true,
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'companyName',
                },
            },
        },
        {
            key: 'street',
            type: 'input',
            templateOptions: {
                label: translate('Strasse'),
                placeholder: translate('Strasse'),
                appearance: 'outline',
                required: true,
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'street',
                },
            },
        },
        {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
                {
                    className: 'flex-25 pr-12',
                    key: 'zip',
                    type: 'input',
                    templateOptions: {
                        label: translate('PLZ'),
                        required: true,
                        placeholder: translate('PLZ'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'zip',
                        },
                    },
                },
                {
                    className: 'flex-75',
                    key: 'city',
                    type: 'input',
                    templateOptions: {
                        label: translate('Ort'),
                        required: true,
                        placeholder: translate('Ort'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'city',
                        },
                    },
                },
            ],
        },

        {
            key: 'building',
            type: 'input',
            templateOptions: {
                label: translate('Gebäude/Bereich'),
                placeholder: translate('Gebäude/Bereich'),
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'buildingInput',
                },
            },
        },
        {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
                {
                    className: 'flex-50 pr-12',
                    key: 'floor',
                    type: 'input',
                    templateOptions: {
                        label: translate('Stockwerk'),
                        placeholder: translate('Stockwerk'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'floorInput',
                        },
                    },
                },
                {
                    className: 'flex-50',
                    key: 'room',
                    type: 'input',
                    templateOptions: {
                        label: translate('Zimmer'),
                        placeholder: translate('Zimmer'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'roomInput',
                        },
                    },
                },
            ],
        },
        {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
                {
                    className: 'flex-50 pr-12',
                    key: 'contactPerson',
                    type: 'input',
                    templateOptions: {
                        label: translate('Kontaktperson'),
                        placeholder: translate('Kontaktperson'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'contactPersonInput',
                        },
                    },
                },
                {
                    className: 'flex-50',
                    key: 'phone',
                    type: 'input',
                    templateOptions: {
                        label: translate('Telefon'),
                        placeholder: translate('Telefon'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'phoneInput',
                        },
                    },
                },
            ],
        },
        {
            key: 'email',
            type: 'input',
            templateOptions: {
                label: translate('E-Mail'),
                placeholder: translate('E-Mail'),
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'emailInput',
                },
            },
        },
        {
            key: 'comment',
            type: 'textarea',
            templateOptions: {
                label: translate('Besonderes'),
                placeholder: translate('Besonderes'),
                appearance: 'outline',
                floatLabel: 'always',
                autosize: true,
                autosizeMinRows: 2,
                autosizeMaxRows: 5,
                attributes: {
                    'data-testid': 'commentInput',
                },
            },
        },
        {
            key: 'discountInPercent',
            type: 'input',
            defaultValue: 0,
            templateOptions: {
                label: translate('Rabatt in %'),
                type: 'number',
                min: 0,
                max: 100,
                placeholder: translate('Zwischen 0 und 100'),
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'discountInPercentInput',
                },
            },
        },
        {
            key: 'facility.id',
            wrappers: ['facilityAutocompleteWrapper'],
            templateOptions: {
                placeholder: translate('Standort'),
                appearance: 'outline',
                required: true,
                floatLabel: 'always',
            },
        },
        // {
        //     fieldGroupClassName: 'display-flex',
        //     fieldGroup: [
        //         {
        //             className: 'flex-50 pr-12',
        //             key: 'customerAdministration.id',
        //             wrappers: ['customerAdministrationAutocompleteWrapper'],
        //             templateOptions: {
        //                 placeholder: 'Kundenadministration',
        //                 appearance: 'outline',
        //                 floatLabel: 'always',
        //             },
        //         },
        //
        //     ],
        // },
        {
            key: 'separateInvoiceAddress',
            type: 'toggle',
            templateOptions: {
                label: translate('Abweichende Rechnungsadresse'),
                attributes: {
                    'data-testid': 'separateInvoiceAddress',
                },
            },
        },
        {
            key: 'invoiceAddressName',
            type: 'input',
            templateOptions: {
                label: translate('Firma/Name'),
                placeholder: translate('Firma/Name'),
                required: true,
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'invoiceAddressName',
                },
            },
            hideExpression: '!model.separateInvoiceAddress',
        },
        {
            key: 'invoiceAddressAddition',
            type: 'input',
            templateOptions: {
                label: translate('Zusatz'),
                placeholder: translate('Zusatz'),
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'invoiceAddressAddition',
                },
            },
            hideExpression: '!model.separateInvoiceAddress',
        },
        {
            key: 'invoiceAddressStreet',
            type: 'input',
            templateOptions: {
                label: translate('Strasse'),
                placeholder: translate('Strasse'),
                appearance: 'outline',
                floatLabel: 'always',
                required: true,
                attributes: {
                    'data-testid': 'invoiceAddressStreet',
                },
            },
            hideExpression: '!model.separateInvoiceAddress',
        },
        {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
                {
                    className: 'flex-25 pr-12',
                    key: 'invoiceAddressZip',
                    type: 'input',
                    templateOptions: {
                        label: translate('PLZ'),
                        placeholder: translate('PLZ'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        required: true,
                        attributes: {
                            'data-testid': 'invoiceAddressZip',
                        },
                    },
                    hideExpression: '!model.separateInvoiceAddress',
                },
                {
                    className: 'flex-75',
                    key: 'invoiceAddressCity',
                    type: 'input',
                    templateOptions: {
                        label: translate('Ort'),
                        placeholder: translate('Ort'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        required: true,
                        attributes: {
                            'data-testid': 'invoiceAddressCity',
                        },
                    },
                    hideExpression: '!model.separateInvoiceAddress',
                },
            ],
        },
        {
            fieldGroupClassName: 'display-flex',
            wrappers: ['panelWrapper'],
            templateOptions: {
                label: translate('Online-Zugang'),
            },
            fieldGroup: [
                {
                    className: 'flex-50 pr-12',
                    key: 'user.username',
                    type: 'input',
                    templateOptions: {
                        label: translate('Benutzername'),
                        placeholder: translate('Benutzername'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'usernameInput',
                        },
                    },
                },
                {
                    className: 'flex-50',
                    key: 'user.password',
                    type: 'input',
                    templateOptions: {
                        label: translate('Passwort'),
                        placeholder: translate('Passwort'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        type: 'password',
                        attributes: {
                            'data-testid': 'passwordInput',
                            autocomplete: 'new-password',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.required': (model) => {
                            return (
                                !!model.user.username && pageType === 'create'
                            );
                        },
                    },
                },
            ],
        },
    ];
}
