import { Injectable } from '@angular/core';
import {
    EntityActionOptions,
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { ChecklistTemplate } from './checklist-template.model';
import { Actions, ofType } from '@ngrx/effects';
import { refresh } from '../../state/app.actions';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Checklist } from '../checklists/checklist.model';

@Injectable({ providedIn: 'root' })
export class ChecklistTemplateEntityService extends EntityCollectionServiceBase<
    ChecklistTemplate
> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions
    ) {
        super('ChecklistTemplate', serviceElementsFactory);
    }

    entitiesForList$ = this.entities$.pipe(
        map((checklistTemplates) =>
            checklistTemplates.map((checklistTemplate) => {
                const checkl: Checklist = JSON.parse(
                    JSON.stringify(checklistTemplate)
                );
                checkl.itemsCount =
                    checklistTemplate.checklistTemplateItems?.length;
                return checkl;
            })
        )
    );

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    update(
        entity: Partial<ChecklistTemplate>,
        options?: EntityActionOptions
    ): Observable<ChecklistTemplate> {
        if (entity.repeatAfterDays) {
            entity.repeatAfterDays = parseInt(
                (entity.repeatAfterDays as unknown) as string,
                10
            );
        }
        return super.update(entity, options);
    }

    add(entity: ChecklistTemplate): Observable<ChecklistTemplate> {
        if (entity.repeatAfterDays) {
            entity.repeatAfterDays = parseInt(
                (entity.repeatAfterDays as unknown) as string,
                10
            );
        }
        return super.add(entity);
    }
}
