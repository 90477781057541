<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon
            *ngIf="!!id"
            class="nav-item__icon mr-12"
            fontSet="fa"
            fontIcon="{{ getItemInfo(id, 'fa_icon') }}"
        ></mat-icon>

        <span class="logo-text h1" data-testid="tableTitle">
            {{ (title ? title : getItemInfo(id, 'title')) | transloco }}
        </span>
    </div>

    <ng-content select="[feature-header-center]"></ng-content>

    <ng-content select="[feature-header-right]"></ng-content>
</div>
