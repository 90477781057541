import { FormlyFieldConfig } from '@ngx-formly/core';
import { translate, TranslocoService } from '@ngneat/transloco';

export const getResetPasswordForm = (
    translocoService: TranslocoService
): FormlyFieldConfig[] => {
    return [
        {
            key: 'newPassword',
            type: 'input',
            templateOptions: {
                label: translocoService.translate('Neues Passwort'),
                type: 'password',
                placeholder: translate('Neues Passwort'),
                required: true,
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'newPassword',
                },
            },
        },
    ];
};
