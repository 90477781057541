import {
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { FuseNavigationService } from '../navigation.service';
import { NavigationItem } from '../navigation.type';

@Component({
    selector: 'sys-nav-vertical-group[item]',
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.scss'],
})
export class FuseNavVerticalGroupComponent implements OnInit, OnDestroy {
    @Input() item!: NavigationItem;
    @HostBinding('class') classes = 'nav-group nav-item';

    private subscription = new Subscription();

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService
    ) {}

    ngOnInit(): void {
        const sub = merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).subscribe(() => {
            this._changeDetectorRef.markForCheck();
        });
        this.subscription.add(sub);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
