import { SectionItem } from './protocol-template-item.model';

export function compareProtocolItemByPosition(
    a: SectionItem,
    b: SectionItem
): number {
    const siA = a.position;
    const siB = b.position;

    let comparison = 0;
    if (siA > siB) {
        comparison = 1;
    } else if (siA < siB) {
        comparison = -1;
    }
    return comparison;
}
