import {
    Action,
    ActionReducer,
    ActionReducerMap,
    MetaReducer,
} from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';
import { logout } from '../auth/auth.actions';

export const reducers: ActionReducerMap<any> = {
    router: routerReducer,
};

export function clearState(
    reducer: ActionReducer<any>
): (state: any, action: Action) => {} {
    return (state: any, action: Action) => {
        if (action.type === logout.type) {
            state = undefined;
        }

        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer[] = [clearState];
