import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns/src/drop-down-base/drop-down-base-model';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { currentUser, VehicleEntityService } from '@sansys/crosslib';
import { filter, first, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';

@Component({
    selector: 'sys-vehicle-autocomplete-type',
    template: `
        <ng-container #fieldComponent></ng-container>
        <div class="mb-20">
            <ejs-dropdownlist
                [showClearButton]="true"
                cssClass="e-outline"
                floatLabelType="Always"
                [dataSource]="data"
                [fields]="fields"
                [formControl]="formControl"
                [placeholder]="getPlaceholder()"
                [allowFiltering]="true"
                (filtering)="onFiltering($event)"
                id="{{ field.key }}"
                [ngClass]="{
                    'required-field':
                        to.required && formControl.status !== 'VALID'
                }"
                #dropdown
            ></ejs-dropdownlist>
            <div
                class="mat-error"
                *ngIf="
                    formControl.status !== 'VALID' &&
                    formControl.touched &&
                    to.required
                "
            >
                Pflichtfeld, bitte ausfüllen.
            </div>
        </div>
    `,
})
export class VehicleAutocompleteWrapperComponent extends FieldWrapper
    implements OnInit, AfterViewInit {
    @ViewChild('dropdown') public dropdownObject!: DropDownListComponent;

    public data: {
        [key: string]: object;
    }[] = [];
    public formControl!: FormControl;
    // maps the appropriate column to fields property
    public fields: FieldSettingsModel = {
        text: 'nameWithEmployee',
        value: 'id',
    };
    // set the placeholder to the DropDownList input
    public text = 'Fahrzeug auswählen';
    // Bind the filter event
    public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
        let query = new Query();
        // frame the query based on search string with filter type.
        query =
            e.text !== ''
                ? query.where('nameWithEmployee', 'contains', e.text, true)
                : query;
        // pass the filter data source, filter query to updateData method.
        e.updateData(this.data, query);
    };

    constructor(
        private vehicleEntityService: VehicleEntityService,
        private store: Store<any>
    ) {
        super();
    }

    ngAfterViewInit(): void {
        const dropdownInput = this.dropdownObject.element.getElementsByTagName(
            'input'
        )[0];
        if (dropdownInput && this.to.placeholder) {
            if (this.to.required) {
                dropdownInput.placeholder =
                    this.to.placeholder + ' wählen (Pflichtfeld)';
            } else {
                dropdownInput.placeholder = this.to.placeholder + ' wählen';
            }
        }
    }

    ngOnInit(): void {
        this.vehicleEntityService.loaded$
            .pipe(
                filter((isLoaded) => !isLoaded),
                first(),
                tap(() => {
                    this.store.select(currentUser).subscribe((currentU) => {
                        if (currentU) {
                            this.vehicleEntityService.load();
                        }
                    });
                })
            )
            .subscribe();

        // this.vehicleEntityService.loaded$
        //     .pipe(
        //         filter((isLoaded) => isLoaded),
        //         switchMapTo(this.vehicleEntityService.entities$),
        //         first(),
        //         tap((vehicles) => {
        //             this.data.length = 0;
        //             vehicles.forEach((vehicle) => {
        //                 const veh = JSON.parse(JSON.stringify(vehicle));
        //
        //                 let m1 = '';
        //
        //                 console.log(this.to.attributes?.showEmployees);
        //
        //                 if (
        //                     vehicle.employee1 &&
        //                     this.to.attributes?.showEmployees
        //                 ) {
        //                     m1 =
        //                         'M1: ' +
        //                         vehicle.employee1.firstName +
        //                         ' ' +
        //                         vehicle.employee1.lastName;
        //                 }
        //
        //                 let m2 = '';
        //
        //                 if (
        //                     vehicle.employee2 &&
        //                     this.to.attributes?.showEmployees
        //                 ) {
        //                     m2 =
        //                         ', M2: ' +
        //                         vehicle.employee2.firstName +
        //                         ' ' +
        //                         vehicle.employee2.lastName;
        //                 }
        //
        //                 veh.nameWithEmployee =
        //                     m1 || m2
        //                         ? vehicle.name + ' (' + m1 + ' ' + m2 + ')'
        //                         : vehicle.name;
        //                 this.data.push(veh as any);
        //             });
        //         })
        //     )
        //     .subscribe();

        // TODO: Warum beides?

        this.vehicleEntityService.entities$
            .pipe(first())
            .subscribe((vehicles) => {
                this.data.length = 0;
                vehicles.forEach((vehicle) => {
                    const veh = JSON.parse(JSON.stringify(vehicle));

                    let m1 = '';

                    if (vehicle.employee1 && this.to.showEmployees) {
                        m1 =
                            'M1: ' +
                            vehicle.employee1.firstName +
                            ' ' +
                            vehicle.employee1.lastName;
                    }

                    let m2 = '';

                    if (vehicle.employee2 && this.to.showEmployees) {
                        m2 =
                            ', M2: ' +
                            vehicle.employee2.firstName +
                            ' ' +
                            vehicle.employee2.lastName;
                    }

                    veh.nameWithEmployee =
                        m1 || m2
                            ? vehicle.name + ' (' + m1 + ' ' + m2 + ')'
                            : vehicle.name;
                    this.data.push(veh as any);
                });
            });
    }

    getPlaceholder(): string {
        if (this.to.placeholder) {
            if (this.to.required) {
                return this.to.placeholder + ' *';
            } else {
                return this.to.placeholder;
            }
        }
        return '';
    }
}
