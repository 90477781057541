export type Claim =
    | 'invoicing'
    | 'material'
    | 'device'
    | 'warehouseStatistic'
    | 'warehouse'
    | 'features'
    | 'invoice'
    | 'debtcollection'
    | 'loss'
    | 'tariff'
    | 'invoicingStatistic'
    | 'transport'
    | 'vehicle'
    | 'training'
    | 'passenger'
    | 'customerAdministration'
    | 'transportLocation'
    | 'facility'
    | 'scheduling'
    | 'personell'
    | 'employee'
    | 'personellTime'
    | 'personellSalary'
    | 'qm'
    | 'qmChecklist'
    | 'qmComplaint'
    | 'qmStatistic'
    | 'insurance'
    | 'administration'
    | 'App\\Entity\\Transport'
    | 'App\\Entity\\Vehicle'
    | 'App\\Entity\\EmployeeDeductions'
    | 'App\\Entity\\Passenger'
    | 'App\\Entity\\CustomerAdministration'
    | 'App\\Entity\\TransportLocation'
    | 'App\\Entity\\Facility'
    | 'App\\Entity\\Insurance'
    | 'App\\Entity\\Employee'
    | 'App\\Entity\\Worktime'
    | 'App\\Entity\\Invoice'
    | 'App\\Entity\\Debtcollection'
    | 'App\\Entity\\Checklist'
    | 'App\\Entity\\Complaint'
    | 'App\\Entity\\QmStatistic'
    | 'App\\Entity\\Defect'
    | 'App\\Entity\\Training'
    | 'App\\Entity\\Loss'
    | 'App\\Entity\\InvoicingStatistic'
    | 'App\\Entity\\Material'
    | 'App\\Entity\\Device'
    | 'App\\Entity\\WarehouseStatistic'
    | 'App\\Entity\\Protocol'
    | 'App\\Entity\\Keyy'
    | 'App\\Entity\\QmDocuments'
    | 'App\\Entity\\QmDocumentCategories'
    | 'App\\Entity\\Tariff';

export type AccessLevel =
    | 'NONE'
    | 'READ'
    | 'EDIT'
    | 'CREATE'
    | 'DELETE'
    | 'ADMIN';

export const accessLevelToNumbers = {
    NONE: 0,
    READ: 2,
    BOOK: 4,
    EDIT: 8,
    CREATE: 16,
    DELETE: 32,
    ADMIN: 64,
};

export type Permission = {
    category: Claim;
    action: AccessLevel;
};

export type AccessRights = {
    permissions: Permission[];
    isAdmin: boolean;
    manageFullWarehouse: boolean;
};
