import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Store } from '@ngrx/store';
import { showEntityAddOrEditPage } from '@sansys/crosslib';

@Component({
    selector: 'sys-formly-wrapper-panel',
    template: `
        <ng-container #fieldComponent></ng-container>
        <mat-card class="mb-12">
            <mat-card-header class="w-100-p">
                <mat-card-title>
                    {{ 'Daten Auftraggeber' | transloco }}
                    <button
                        class="sys--float-right"
                        mat-raised-button
                        (click)="showEditTransportLocationDialog()"
                    >
                        {{ 'Anpassen' | transloco }}
                    </button>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content *ngIf="to.transportLocation">
                <mat-list role="list">
                    <mat-list-item role="listitem">
                        {{ 'Firma / Name / Adresse' | transloco }}:
                        {{ to.transportLocation.companyName }}
                    </mat-list-item>
                    <mat-list-item role="listitem">
                        {{ 'Strasse' | transloco }}:
                        {{ to.transportLocation.street }}
                    </mat-list-item>
                    <mat-list-item role="listitem">
                        {{ 'PLZ' | transloco }} / {{ 'Ort' | transloco }}:
                        {{ to.transportLocation.zip }}
                        {{ to.transportLocation.city }}
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card>
    `,
})
export class TransportLocationDetailsWrapperComponent extends FieldWrapper {
    constructor(private store: Store<any>) {
        super();
    }

    showEditTransportLocationDialog(): void {
        this.store.dispatch(
            showEntityAddOrEditPage({
                entityType: 'transportLocation',
                entityId: this.to.transportLocation.id,
                data: { transportLocationType: this.to.transportLocationType },
            })
        );
    }
}
