<sys-auth-page [title]="title" [mainText]="mainText">
    <ng-container right-content>
        <div id="authForm">
            <div class="logo" fxHide.gt-xs>
                <img src="assets/images/logos/sansys.png" alt="logo" />
            </div>

            <div class="title">{{ 'Anmeldung' | transloco }}</div>

            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <formly-form
                    [form]="form"
                    [fields]="fields"
                    [model]="model"
                ></formly-form>
                <div
                    class="register"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                >
                    <a class="link" [routerLink]="'/forgot-password'">{{
                        'Passwort vergessen' | transloco
                    }}</a>
                </div>

                <button
                    mat-raised-button
                    color="accent"
                    class="form__submit-button"
                    aria-label="Anmeldung"
                    [disabled]="form.invalid"
                    type="submit"
                    data-testid="loginButton"
                >
                    {{ 'Anmelden' | transloco }}
                </button>
            </form>

            <div
                class="register"
                fxLayout="column"
                fxLayoutAlign="center center"
            >
                <span class="text">{{
                    'Noch kein Zugang vorhanden?' | transloco
                }}</span>
                <a class="link" [routerLink]="'/registrieren'">{{
                    'Jetzt registrieren' | transloco
                }}</a>
            </div>
        </div>
    </ng-container>
</sys-auth-page>
