import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { select, Store } from '@ngrx/store';
import { AuthService, currentUser, errorOccured } from '@sansys/crosslib';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { noop } from 'rxjs';
import { translate } from '@ngneat/transloco';

@Component({
    selector: 'sys-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    forgotPasswordForm!: FormGroup;
    title = 'Willkommen bei Sansys';
    mainText = 'Passwort wiederherstellen';

    model: {
        username: string;
    } = {
        username: '',
    };

    form = new FormGroup({});
    fields: FormlyFieldConfig[] = [
        {
            key: 'username',
            type: 'input',
            templateOptions: {
                label: translate('Benutzername'),
                placeholder: translate('Benutzername'),
                required: true,
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'username',
                },
            },
        },
    ];

    constructor(
        private authService: AuthService,
        private store: Store<any>,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.store.pipe(select(currentUser)).subscribe((currentU) => {
            if (currentU) {
                this.router.navigate(['/home']);
            }
        });
    }

    onSubmit(): void {
        this.authService
            .forgotPassword(this.model.username)
            .pipe(
                tap(() => {
                    this.store.dispatch(
                        errorOccured({
                            message: translate(
                                'Der Link zum Zurücksetzen des Passworts wurde per E-Mail verschickt. Dieser sollte in den nächsten Minuten ankommen.'
                            ),
                            title: 'Info',
                        })
                    );
                    this.router.navigate(['/home']);
                })
            )
            .subscribe(noop, (err) => {
                console.error(err);
            });
    }
}
