import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
    CompanyService,
    currentEntityUpdated,
    Employee,
    EmployeeEntityService,
    errorOccured,
    parseHoursMinutesStringToMinutes,
    parseSecondsToHoursMinutesString,
    setAccessRights,
    Worktime,
    WorktimeEntityService,
    WorktimeType,
} from '@sansys/crosslib';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { getWorktimeDailyFormConfig } from './worktime-daily.form-config';
import { getWorktimeHourlyFormConfig } from './worktime-hourly.form-config';
import * as moment from 'moment';

@Component({
    selector: 'sys-worktime',
    templateUrl: './worktime.component.html',
    styleUrls: ['./worktime.component.scss'],
})
export class WorktimeComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();

    worktime: Partial<Worktime> = {};
    employee?: Employee;
    id = 'worktimes';
    worktimeForm: FormGroup = new FormGroup({});
    worktimeFormFields: FormlyFieldConfig[] = [];
    canEdit = false;
    worktimeTypes: WorktimeType[] = [];

    constructor(
        private worktimeEntityService: WorktimeEntityService,
        private employeeEntityService: EmployeeEntityService,
        private permissionService: NgxPermissionsService,
        private action$: Actions,
        private store: Store<any>,
        private companyService: CompanyService,
        private dialogRef: MatDialogRef<WorktimeComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { id: string; data: { employeeId: string } }
    ) {}

    private disableForm(disable: boolean): void {
        this.worktimeFormFields.forEach((outerGroup) => {
            outerGroup.templateOptions = {
                ...outerGroup.templateOptions,
                disabled: disable,
            };
            outerGroup.fieldGroup?.forEach((innerGroup) => {
                innerGroup.templateOptions = {
                    ...innerGroup.templateOptions,
                    disabled: disable,
                };
            });
        });
    }

    private isWorktimeCompleteDay(
        worktimeTypes: WorktimeType[],
        name?: string
    ): boolean {
        if (
            name &&
            ['Ausbezahlt Lohn Pikett', 'Ausbezahlt Lohn'].includes(name)
        ) {
            return true;
        }
        let isCompleteDay = false;
        worktimeTypes.forEach((worktimeType) => {
            if (worktimeType.name === name && worktimeType.isCompleteDay) {
                isCompleteDay = true;
            }
        });
        return isCompleteDay;
    }

    private setPermission(): void {
        this.permissionService
            .hasPermission(['employees|EDIT', 'admin'])
            .then((canEditEmployees) => {
                this.setFields();
                this.disableForm(!canEditEmployees);
                this.worktimeForm.markAsDirty();
            });
    }

    private setFields(): void {
        if (
            this.isWorktimeCompleteDay(
                this.worktimeTypes,
                this.worktime.worktimeType
            )
        ) {
            this.worktimeFormFields = getWorktimeDailyFormConfig(
                this.worktimeTypes,
                this.worktime,
                this.worktimeTypeChanged
            );
        } else {
            this.worktimeFormFields = getWorktimeHourlyFormConfig(
                this.worktimeTypes,
                this.worktime,
                this.worktimeTypeChanged
            );
        }
    }

    private worktimeTypeChanged = (): void => {
        if (!this.worktime.worktimeType) {
            return;
        }
        let minutesPerDay;
        this.worktimeTypes.forEach((worktimeType) => {
            if (
                worktimeType.name === this.worktime.worktimeType &&
                worktimeType.isCompleteDay
            ) {
                if (typeof worktimeType.minutesPerDay === 'number') {
                    minutesPerDay = parseSecondsToHoursMinutesString(
                        worktimeType.minutesPerDay * 60
                    );
                }
            }
        });
        this.worktime = {
            ...this.worktime,
            minutesPerDay: minutesPerDay || undefined,
        };
        this.setFields();
    };

    ngOnInit(): void {
        this.setPermission();
        this.subscription.add(
            this.action$.pipe(ofType(setAccessRights)).subscribe(() => {
                this.setPermission();
            })
        );

        this.companyService.getCompany().subscribe((company) => {
            this.worktimeTypes = company.worktimeTypes;
            this.setFields();
        });

        if (!this.data.data.employeeId) {
            this.store.dispatch(errorOccured({ message: 'Employee missing' }));
        } else {
            this.employeeEntityService
                .getByKey(this.data.data.employeeId)
                .subscribe(
                    (employee) =>
                        (this.employee = JSON.parse(JSON.stringify(employee)))
                );
        }
        if (this.data.id !== 'new') {
            this.worktimeEntityService
                .getByKey(this.data.id)
                .subscribe((worktime) => {
                    this.worktime = JSON.parse(JSON.stringify(worktime));
                    if (worktime.secondsPerDay) {
                        const minutesTotal = Math.floor(
                            worktime.secondsPerDay / 60
                        );
                        let hours = Math.floor(minutesTotal / 60);
                        if (minutesTotal < 0) {
                            hours = Math.ceil(minutesTotal / 60);
                        }
                        const minutes = Math.abs(minutesTotal % 60);
                        this.worktime.minutesPerDay =
                            hours +
                            ':' +
                            (minutes < 10 ? '0' + minutes : minutes);
                    }

                    this.setFields();
                });
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }

    onSaveWorktime(): void {
        if (this.worktime.minutesPerDay) {
            const minutesPerDay = parseHoursMinutesStringToMinutes(
                this.worktime.minutesPerDay
            );
            if (!minutesPerDay && minutesPerDay !== 0) {
                this.store.dispatch(
                    errorOccured({
                        message: 'Bitte im Format HH:MM angeben, z.B. 06:30',
                        title: 'Info',
                    })
                );
                return;
            }
            this.worktime.secondsPerDay = minutesPerDay * 60;
            if (this.isReductionWorktime() && this.worktime.secondsPerDay > 0) {
                this.worktime.secondsPerDay = this.worktime.secondsPerDay * -1;
            }
            if (!this.worktime.end) {
                this.worktime.end = moment(this.worktime.start).format(
                    'YYYY-MM-DD 23:59:59'
                );
            } else {
                this.worktime.end = moment(this.worktime.end).format(
                    'YYYY-MM-DD 23:59:59'
                );
            }
        }
        if (this.data.id !== 'new') {
            this.worktimeEntityService
                .update(JSON.parse(JSON.stringify(this.worktime)))
                .subscribe((worktime) => {
                    this.onCloseModal();
                    if (worktime.id) {
                        this.store.dispatch(
                            currentEntityUpdated({
                                id: worktime.id,
                                entityType: 'worktime',
                            })
                        );
                    }
                });
        } else {
            const workT: Worktime = JSON.parse(JSON.stringify(this.worktime));
            workT.employee = { id: this.data.data.employeeId };
            this.worktimeEntityService.add(workT).subscribe((worktime) => {
                this.onCloseModal();
                if (worktime.id) {
                    this.store.dispatch(
                        currentEntityUpdated({
                            id: worktime.id,
                            entityType: 'worktime',
                        })
                    );
                }
            });
        }
    }

    isReductionWorktime(): boolean {
        return (
            this.worktime.worktimeType === 'Ausbezahlt Lohn' ||
            this.worktime.worktimeType === 'Ausbezahlt Lohn Pikett'
        );
    }
}
