import { Inject, Injectable, Injector } from '@angular/core';
import {
    EntityActionOptions,
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { combineLatest, Observable, of } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { EmployeeEntityService } from '../employees';
import { TransportLocationEntityService } from '../transport-locations';
import { VehicleEntityService } from '../vehicles';
import { refresh } from '../../state';
import { FacilityEntityService } from '../facilities';
import { OwnAndOpenTransports } from '../../types';
import { stringifyTransportDates, transportIsOpen } from './functions';
import * as moment from 'moment';
import { TariffEntityService } from '../tariffs';
import { InsuranceEntityService } from '../insurances';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import { currentUser, JwtInterceptor } from '../../auth';
import { TransportLocation } from '../transport-locations';
import {
    OrderConfirmationResponse,
    TransportModel,
} from './transport-entity.model';
import { ProtocolTemplateEntityService } from '../protocols';
import { errorOccured } from '../../error-handling';
import { translate } from '@ngneat/transloco';
import { StationEntityService } from '../stations';
import { TransportLocationStatistics } from '../transport-locations/transport-location-statistics.model';
import { Invoice } from '../invoices';

@Injectable({ providedIn: 'root' })
export class TransportEntityService extends EntityCollectionServiceBase<TransportModel> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private transportLocationEntityService: TransportLocationEntityService,
        private facilityEntityService: FacilityEntityService,
        private tariffEntityService: TariffEntityService,
        private insuranceEntityService: InsuranceEntityService,
        private employeeEntityService: EmployeeEntityService,
        private vehicleEntityService: VehicleEntityService,
        private protocolTemplateEntityService: ProtocolTemplateEntityService,
        private stationEntityService: StationEntityService,
        private action$: Actions,
        private http: HttpClient,
        private injector: Injector,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('Transport', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    public offers$ = this.entities$.pipe(
        map((offers) => offers.filter((offer) => offer.isOffer))
    );

    public readonly allLoaded$ = combineLatest([
        // this.passengerEntityService.loaded$,
        // this.transportLocationEntityService.loaded$,
        this.employeeEntityService.loaded$,
        this.facilityEntityService.loaded$,
        this.vehicleEntityService.loaded$,
        this.tariffEntityService.loaded$,
        this.insuranceEntityService.loaded$,
        this.protocolTemplateEntityService.loaded$,
        this.stationEntityService.loaded$,
    ]).pipe(filter((loadedStates) => loadedStates.every((s) => s)));

    public loadRelatedEntities(transportId?: string): void {
        // this.passengerEntityService.load();
        // this.transportLocationEntityService.load();
        this.employeeEntityService.load();
        this.facilityEntityService.load();
        this.vehicleEntityService.load();
        if (transportId && transportId !== 'new') {
            this.tariffEntityService.loadWithTransportId(transportId);
        } else {
            this.tariffEntityService.load();
        }
        this.insuranceEntityService.load();
        this.protocolTemplateEntityService.load();
        this.stationEntityService.load();
    }

    public getGridDataSource(): Observable<DataManager> {
        const jwtInterceptor = this.injector.get(JwtInterceptor);
        return jwtInterceptor.jwtObservable.pipe(
            map((token) => {
                return new DataManager({
                    url: `${this.server}api/transports/query`,
                    headers: [
                        {
                            Authorization: 'Bearer ' + token,
                        },
                    ],
                    adaptor: new UrlAdaptor(),
                });
            })
        );
    }

    public getOffers(): Observable<TransportModel[]> {
        return this.http
            .get<TransportModel[]>(`${this.server}api/transports/offers`)
            .pipe(
                map((offers) =>
                    offers.map((offer) => {
                        const off: TransportModel = JSON.parse(
                            JSON.stringify(offer)
                        );
                        off.createdAt = moment(offer.createdAt).toDate();
                        off.plannedStart = moment(offer.plannedStart).toDate();
                        return off;
                    })
                )
            );
    }

    getTransportProtocolUrl(
        transport: TransportModel
    ): Observable<{ url: string }> {
        return this.http.get<{ url: string }>(
            `${this.server}api/transports/${transport.id}/printprotocol`
        );
    }

    getBulkTransportProtocolUrl(ids: string[]): Observable<{ url: string }> {
        return this.http.post<{ url: string }>(
            `${this.server}api/transports/printbatchprotocols`,
            { ids }
        );
    }

    public loadWithQuery(query: { [key: string]: string }): void {
        this.getWithQuery(query);
        this.setLoaded(true);
    }

    public getOrders(dontShowLoader = false): Observable<TransportModel[]> {
        const loaderParam = dontShowLoader ? '?ignoreRequest=true' : '';
        return this.http.get<TransportModel[]>(
            `${this.server}api/transports/orders${loaderParam}`
        );
    }

    public getOwnAndOpenTransportsOfCurrentEmployee(): Observable<OwnAndOpenTransports> {
        return combineLatest([
            this.entities$,
            this.store.select(currentUser),
        ]).pipe(
            map(([transports, currentU]) => {
                const sortedTransports: OwnAndOpenTransports = {
                    transportsWithOwnVehicle: [],
                    transportsWithoutVehicle: [],
                };
                if (!currentU) {
                    return sortedTransports;
                }
                transports.forEach((transportModel) => {
                    const transportVehicle = transportModel.vehicle;
                    if (!transportVehicle) {
                        sortedTransports.transportsWithoutVehicle.push(
                            transportModel
                        );
                    } else if (
                        transportVehicle.employee1?.id === currentU.user.id ||
                        transportVehicle.employee2?.id === currentU.user.id ||
                        transportVehicle.intern?.id === currentU.user.id
                    ) {
                        sortedTransports.transportsWithOwnVehicle.push(
                            transportModel
                        );
                    }
                });
                return sortedTransports;
            })
        );
    }

    public saveOrUpdateManualTransportLocation(
        transportLocation: TransportLocation
    ): void {
        transportLocation.isManual = true;
        if (transportLocation.id) {
            this.transportLocationEntityService.update({
                ...transportLocation,
            });
        } else {
            this.transportLocationEntityService.add(transportLocation);
        }
    }

    add(entity: TransportModel): Observable<TransportModel> {
        const transport = {
            ...stringifyTransportDates(entity as TransportModel),
        };
        if (transport.passenger?.birthday) {
            const birth = moment(transport.passenger.birthday).format(
                'YYYY-MM-DD'
            );
            transport.passenger = JSON.parse(
                JSON.stringify(transport.passenger)
            );
            if (transport.passenger) {
                transport.passenger.birthday = birth;
            }
        }
        if (!transport.vehicle?.id) {
            transport.vehicle = undefined;
        }
        if (transport.passengerIsStart || transport.passengerIsTarget) {
            if (!transport.passenger?.street) {
                this.store.dispatch(
                    errorOccured({
                        title: 'Info',
                        message:
                            'Transport kann nicht gespeichert werden. ' +
                            translate('passenger') +
                            ' Strasse fehlt.',
                    })
                );
                return of(entity);
            }
            if (!transport.passenger?.zip) {
                this.store.dispatch(
                    errorOccured({
                        title: 'Info',
                        message:
                            'Transport kann nicht gespeichert werden. ' +
                            translate('passenger') +
                            ' Postleitzahl fehlt.',
                    })
                );
                return of(entity);
            }
            if (!transport.passenger?.city) {
                this.store.dispatch(
                    errorOccured({
                        title: 'Info',
                        message:
                            'Transport kann nicht gespeichert werden. ' +
                            translate('passenger') +
                            ' Ort fehlt.',
                    })
                );
                return of(entity);
            }
        }
        if (transport.transportSource === 'onlineOrder') {
            return this.http.post<TransportModel>(
                `${this.server}api/transports/order`,
                transport
            );
        } else {
            return super.add(transport);
        }
    }

    public reloadTodaysTransports(): void {
        this.loadWithQuery({
            transportDate: moment().format('YYYY-MM-DD'),
        });
    }

    update(
        entity: Partial<TransportModel>,
        options?: EntityActionOptions
    ): Observable<TransportModel> {
        if (entity.passenger?.birthday) {
            entity.passenger = {
                ...entity.passenger,
                birthday: moment(entity.passenger.birthday).format(
                    'YYYY-MM-DD'
                ),
            };
        }
        const transport = {
            ...stringifyTransportDates(entity as TransportModel),
        };
        return super.update(transport, options);
    }

    getoffer(entity: Partial<TransportModel>): Observable<Invoice> {
        return this.http.post<Invoice>(
            `${this.server}api/transports/offer`,
            entity
        );
    }

    confirmOrder(
        transport: TransportModel,
        ignoreDuplicatePassengers: boolean
    ): Observable<OrderConfirmationResponse> {
        return this.http.post<OrderConfirmationResponse>(
            `${this.server}api/transports/confirmorder`,
            { transport, ignoreDuplicatePassengers }
        );
    }

    createInvoice(id: string): Observable<null> {
        return this.http.get<null>(
            `${this.server}api/transports/${id}/createinvoice`
        );
    }

    createInvoices(transportIds: string[]): Observable<null> {
        return this.http.post<null>(
            `${this.server}api/transports/createinvoices`,
            { transportIds }
        );
    }

    hasOwnUnfinishedTransport(): Observable<boolean> {
        return this.getOwnAndOpenTransportsOfCurrentEmployee().pipe(
            map((data) => {
                let hasUnfinishedTransport = false;
                const transports = data.transportsWithOwnVehicle;
                transports.forEach((transport) => {
                    if (transportIsOpen(transport)) {
                        hasUnfinishedTransport = true;
                    }
                });
                return hasUnfinishedTransport;
            })
        );
    }

    // cancelTransportInvoiceAndCreateNewVersion(
    //     transportId: string
    // ): Observable<null> {
    //     return this.http.post<null>(
    //         `${this.server}api/transports/${transportId}/cancelinvoiceandrecreate`,
    //         { id: transportId }
    //     );
    // }

    exportTransports(ids: string[]): Observable<{ url: string }> {
        return this.http.post<{ url: string }>(
            `${this.server}api/transports/export`,
            { ids }
        );
    }

    redirectTransport(
        transportId: string,
        targetCompanyId: string
    ): Observable<TransportModel> {
        return this.http.post<TransportModel>(
            `${this.server}api/transports/${transportId}/redirect`,
            { id: targetCompanyId }
        );
    }

    public printPdf(entity: TransportModel): Observable<string> {
        const transport = {
            ...stringifyTransportDates(entity as TransportModel),
        };
        if (transport.passenger?.birthday) {
            const birth = moment(transport.passenger.birthday).format(
                'YYYY-MM-DD'
            );
            transport.passenger = JSON.parse(
                JSON.stringify(transport.passenger)
            );
            if (transport.passenger) {
                transport.passenger.birthday = birth;
            }
        }
        if (!transport.vehicle?.id) {
            transport.vehicle = undefined;
        }
        if (transport.passengerIsStart || transport.passengerIsTarget) {
            if (!transport.passenger?.street) {
                this.store.dispatch(
                    errorOccured({
                        title: 'Info',
                        message:
                            'Transport kann nicht gespeichert werden. ' +
                            translate('passenger') +
                            ' Strasse fehlt.',
                    })
                );
                return of('');
            }
            if (!transport.passenger?.zip) {
                this.store.dispatch(
                    errorOccured({
                        title: 'Info',
                        message:
                            'Transport kann nicht gespeichert werden. ' +
                            translate('passenger') +
                            ' Postleitzahl fehlt.',
                    })
                );
                return of('');
            }
            if (!transport.passenger?.city) {
                this.store.dispatch(
                    errorOccured({
                        title: 'Info',
                        message:
                            'Transport kann nicht gespeichert werden. ' +
                            translate('passenger') +
                            ' Ort fehlt.',
                    })
                );
                return of('');
            }
        }
        return this.http.post<string>(
            this.server + `api/transports/print-order`,
            transport
        );
    }

    getMonthStatistic(day: string): Observable<number> {
        return this.http.get<number>(
            this.server + `api/transports/month_count?startDate=${day}`
        );
    }

    getOverview(
        overviewStart: string,
        overviewEnd: string
    ): Observable<string> {
        return this.http.post<string>(this.server + `api/transports/overview`, {
            overviewStart,
            overviewEnd,
        });
    }

    getStatistics(year?: string): Observable<TransportLocationStatistics> {
        return this.http.get<TransportLocationStatistics>(
            `${this.server}api/transports/statistics?year=${year}`
        );
    }
}
