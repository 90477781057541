import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { Store } from '@ngrx/store';
import {
    errorOccured,
    resetEntityValue,
    showEntityAddOrEditPage,
    showTransportProtocolDetails,
} from '@sansys/crosslib';

@Component({
    selector: 'sys-button-type',
    template: `
        <button type="button" (click)="onClick(to.action)" mat-raised-button>
            {{ to.text }}
        </button>
    `,
})
export class EditButtonTypeComponent extends FieldType {
    // TODO: this im TransportController lassen
    constructor(private store: Store<any>) {
        super();
    }
    onClick(
        action:
            | 'resetTariff'
            | 'editTariff'
            | 'resetProtocol'
            | 'showProtocolDetails'
            | 'editPassenger'
    ): void {
        switch (action) {
            case 'editTariff':
                this.store.dispatch(
                    showEntityAddOrEditPage({
                        entityType: 'tariffVersion',
                        entityId: this.formControl.value,
                    })
                );
                break;
            case 'resetTariff':
                this.store.dispatch(
                    resetEntityValue({ entityType: 'tariffVersion' })
                );
                break;
            case 'resetProtocol':
                this.store.dispatch(
                    resetEntityValue({ entityType: 'transportProtocol' })
                );
                break;
            case 'editPassenger':
                this.store.dispatch(
                    showEntityAddOrEditPage({
                        entityType: 'passenger',
                        entityId: this.formControl.value,
                    })
                );
                break;
            case 'showProtocolDetails':
                if (!this.to['transport']) {
                    this.store.dispatch(
                        errorOccured({ message: 'Kein Transport gefunden' })
                    );
                    return;
                }
                this.store.dispatch(
                    showTransportProtocolDetails({
                        transport: this.to['transport'],
                    })
                );
                break;
        }
    }
}
