import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns/src/drop-down-base/drop-down-base-model';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { currentUser, FacilityEntityService } from '@sansys/crosslib';
import { filter, first, switchMapTo, tap } from 'rxjs/operators';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { Store } from '@ngrx/store';
import { getSearchQuery } from '../../syncfusion/query.functions';

@Component({
    selector: 'sys-facility-autocomplete-type',
    template: `
        <ng-container #fieldComponent></ng-container>
        <div class="mb-20">
            <ejs-dropdownlist
                #dropdown
                [showClearButton]="true"
                cssClass="e-outline"
                floatLabelType="Always"
                [dataSource]="data"
                [fields]="fields"
                [formControl]="formControl"
                [placeholder]="getPlaceholder()"
                [allowFiltering]="true"
                (filtering)="onFiltering($event)"
                data-testid="facilityAutocomplete"
                [value]="value"
                (change)="setValue()"
                id="{{ field.key }}"
                [ngClass]="{
                    'required-field':
                        to.required &&
                        formControl.status !== 'VALID' &&
                        !to.disabled
                }"
            ></ejs-dropdownlist>
            <div
                class="mat-error"
                *ngIf="
                    formControl.status !== 'VALID' &&
                    formControl.touched &&
                    to.required
                "
            >
                Pflichtfeld, bitte ausfüllen.
            </div>
        </div>
    `,
})
export class FacilityAutocompleteWrapperComponent
    extends FieldWrapper
    implements OnInit, AfterViewInit {
    @ViewChild('dropdown') public dropdownObject!: DropDownListComponent;
    public data: {
        [key: string]: object;
    }[] = [];
    value: any;
    public formControl!: FormControl;
    // maps the appropriate column to fields property
    public fields: FieldSettingsModel = { text: 'name', value: 'id' };
    // set the placeholder to the DropDownList input
    public text = 'Standort auswählen';
    // Bind the filter event
    public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
        e.updateData(this.data, getSearchQuery('name', e.text));
    };

    constructor(
        private facilityEntityService: FacilityEntityService,
        private store: Store<any>
    ) {
        super();
    }

    ngAfterViewInit(): void {
        const dropdownInput = this.dropdownObject.element.getElementsByTagName(
            'input'
        )[0];
        if (dropdownInput && this.to.placeholder) {
            if (this.to.required) {
                dropdownInput.placeholder =
                    this.to.placeholder + ' wählen (Pflichtfeld)';
            } else {
                dropdownInput.placeholder = this.to.placeholder + ' wählen';
            }
        }
    }

    ngOnInit(): void {
        this.facilityEntityService.loaded$
            .pipe(
                filter((isLoaded) => isLoaded),
                switchMapTo(this.facilityEntityService.entities$),
                first()
            )
            .subscribe((facilities) => {
                this.data.length = 0;
                facilities.forEach((facility) => {
                    this.data.push(facility as any);
                });
            });

        this.facilityEntityService.loaded$
            .pipe(
                filter((isLoaded) => !isLoaded),
                first(),
                tap(() => {
                    this.store.select(currentUser).subscribe((currentU) => {
                        if (currentU) {
                            this.facilityEntityService.load();
                        }
                    });
                })
            )
            .subscribe();
    }

    setValue(): void {
        const value = this.dropdownObject.value;
        this.value = this.dropdownObject.getDataByValue(value);
    }

    getPlaceholder(): string {
        if (this.to.placeholder) {
            if (this.to.required) {
                return this.to.placeholder + ' *';
            } else {
                return this.to.placeholder;
            }
        }
        return '';
    }
}
