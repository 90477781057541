export const gridGlobalizationDe = {
    'de-CH': {
        grid: {
            DateTimeFilter: 'Datum Filter',
            SelectAll: 'Alle auswählen',
            NumberFilter: 'Nummer Filter',
            ClearFilter: 'Filter löschen',
            Matchs: 'Keine Einträge gefunden',
            NoResult: 'Keine Einträge gefunden',
            Between: 'Zwischen',
            CustomFilter: 'Eigener Filter',
            Placeholder: 'Bitte Datum auswählen',
            ChooseDate: 'Bitte Datum auswählen',
            CustomFilterDatePlaceHolder: 'Bitte Datum auswählen',
            TextFilter: 'Text Filter',
            MatchCase: 'Gross-/Kleinschreibung',
            ShowRowsWhere: 'Einträge anzeigen für',
            EnterValue: 'Wert eintragen',
            CustomFilterPlaceHolder: 'Wert eintragen',
            AND: 'UND',
            OR: 'ODER',
            EmptyRecord: 'Keine Einträge vorhanden',
            Item: 'Artikel',
            Items: 'Artikel',
            True: 'Ja',
            False: 'Nein',
            Blanks: '[Leer]',
            StartsWith: 'Beginnt mit',
            EndsWith: 'Endet mit',
            Contains: 'Enthält',
            Equal: 'Gleich',
            NotEqual: 'Ungleich',
            LessThan: 'Weniger als',
            LessThanOrEqual: 'Weniger oder gleich',
            GreaterThan: 'Grösser als',
            GreaterThanOrEqual: 'Grösser oder gleich',
            FilterButton: 'Filtern',
            ClearButton: 'Löschen',
            All: 'Alle',
            Search: 'Suchen',
            Add: 'Neu',
            Edit: 'Bearbeiten',
            Delete: 'Löschen',
            Update: 'Speichern',
            Cancel: 'Abbrechen',
            Excelexport: 'Excel Export',
            ConfirmDelete: 'Wirklich löschen?',
            CancelButton: 'Abbrechen',
            SaveButton: 'Speichern',
            DateFilter: 'Filter',
            EditOperationAlert: 'Bitte zunächst Eintrag auswählen',
            DeleteOperationAlert: 'Bitte zunächst Eintrag auswählen',
        },
        pager: {
            currentPageInfo: '{0} von {1} Seite(n)',
            totalItemsInfo: '({0} Einträge)',
            firstPageTooltip: 'Zur ersten Seite',
            lastPageTooltip: 'Zur letzten Seite',
            nextPageTooltip: 'Zur nächsten Seite',
            previousPageTooltip: 'Zurück zur letzten Seit',
            nextPagerTooltip: 'Zum nächsten Pager',
            previousPagerTooltip: 'Zum vorherigen Pager',
            pagerDropDown: 'Einträge pro Seite',
            All: 'Alle',
        },
        uploader: {
            invalidMinFileSize: 'Minimale Datei-Grösse unterschritten.',
            invalidMaxFileSize: 'Maximale Datei-Grösse überschritten',
            invalidFileType: 'Datei-Format nicht unterstützt.',
            Browse: 'Auswählen...',
            Clear: 'Löschen',
            Upload: 'Upload',
            dropFilesHint: 'Hier Datei ablegen',
            uploadFailedMessage: 'Upload nicht erfolgreich',
            uploadSuccessMessage: 'Upload erfolgreich',
            removedSuccessMessage: 'Datei wurde entfernt',
            removedFailedMessage: 'Datei konnte nicht entfernt werden',
            inProgress: 'In Arbeit...',
            readyToUploadMessage: 'Bereit zum Upload',
            remove: 'Entfernen',
            cancel: 'Abbrechen',
            delete: 'Löschen',
        },
        datetimepicker: {
            today: 'Heute',
            placeholder: 'Datum/Uhrzeit im Format TT.MM.JJJJ HH:mm',
        },
        datepicker: {
            today: 'Heute',
            placeholder: 'Bitte Datum auswählen',
        },
        excelFilter: {
            True: 'Active',
            False: 'Inactive',
        },
        calendar: {
            today: 'Heute',
        },
        spreadsheet: {
            EditAlert: 'Die Zelle kann nicht bearbeitet werden.',
            ValidationError: 'Der Wert ist nicht erlaubt.',
            Retry: 'Bearbeiten',
            Cancel: 'Ok',
        },
        schedule: {
            addTitle: 'Titel hinzufügen',
            agenda: 'Agenda',
            alert: 'Hinweis',
            allDay: 'Ganztägig',
            cancel: 'Abbrechen',
            cancelButton: 'Abbrechen',
            close: 'Schliessen',
            createError:
                'The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.',
            createEvent: 'Erstellen',
            day: 'Tag',
            delete: 'Löschen',
            deleteButton: 'Löschen',
            deleteContent:
                'Sind Sie sicher, dass Sie den Eintrag löschen wollen?',
            deleteEvent: 'Eintrag löschen',
            deleteMultipleContent:
                'Are you sure you want to delete the selected events?',
            deleteMultipleEvent: 'Delete Multiple Events',
            deleteRecurrenceContent:
                'Do you want to delete only this event or entire series?',
            deleteSeries: 'Delete Series',
            description: 'Description',
            edit: 'Edit',
            editContent:
                'Do you want to edit only this event or entire series?',
            editEvent: 'Eintrag bearbeiten',
            editRecurrence: 'Edit Recurrence',
            editSeries: 'Edit Series',
            emptyContainer: 'There are no events scheduled on this day.',
            end: 'End',
            endTimezone: 'End Timezone',
            invalidDateError: 'The entered date value is invalid.',
            location: 'Location',
            month: 'Month',
            monthAgenda: 'Month Agenda',
            more: 'more',
            moreDetails: 'Details',
            newEvent: 'Neuer Transport',
            next: 'Nächster',
            noEvents: 'Keine Einträge',
            noTitle: '(Kein Titel)',
            occurrence: 'Occurrence',
            ok: 'Ok',
            previous: 'Vorheriger',
            recurrence: 'Recurrence',
            recurrenceDateValidation:
                'Some months have fewer than the selected date. For these months, the occurrence will fall on the last date of the month.',
            repeat: 'Repeat',
            repeats: 'Repeats',
            sameDayAlert:
                'Two occurrences of the same event cannot occur on the same day.',
            save: 'Speichern',
            saveButton: 'Speichern',
            selectedItems: 'Items selected',
            series: 'Serie',
            seriesChangeAlert:
                'The changes made to specific instances of this series will be canceled and those events will match the series again.',
            start: 'Start',
            startEndError:
                'The selected end date occurs before the start date.',
            startTimezone: 'Start Timezone',
            subject: 'Subject',
            timelineDay: 'Tagesübersicht',
            timelineMonth: 'Monatsübersicht',
            timelineWeek: 'Wochenübersicht',
            timelineWorkWeek: 'Timeline Work Week',
            timezone: 'Timezone',
            title: 'Title',
            today: 'Heute',
            week: 'Week',
            weekAgenda: 'Week Agenda',
            workWeek: 'Work Week',
            workWeekAgenda: 'Work Week Agenda',
            wrongPattern: 'The recurrence pattern is not valid.',
        },
        recurrenceeditor: {
            count: 'Count',
            daily: 'Daily',
            days: 'Day(s)',
            end: 'End',
            every: 'every',
            first: 'First',
            fourth: 'Fourth',
            last: 'Last',
            month: 'Month',
            monthly: 'Monthly',
            months: 'Month(s)',
            never: 'Never',
            none: 'None',
            on: 'Repeat On',
            onDay: 'Day',
            repeat: 'Repeat',
            repeatEvery: 'Repeat Every',
            second: 'Second',
            summaryDay: 'day(s)',
            summaryMonth: 'month(s)',
            summaryOn: 'on',
            summaryRepeat: 'Repeats',
            summaryTimes: 'time(s)',
            summaryUntil: 'until',
            summaryWeek: 'week(s)',
            summaryYear: 'year(s)',
            third: 'Third',
            until: 'Until',
            weekly: 'Weekly',
            weeks: 'Week(s)',
            yearly: 'Yearly',
            years: 'Year(s)',
        },
    },
    calendar: {
        today: 'heute',
    },
    dropdown: {
        noRecordsTemplate: 'Keine Einträge gefunden',
    },
};
