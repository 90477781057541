import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    logError(message: string, stack: string | undefined): void {
        // TODO: Send errors to server
    }
}
