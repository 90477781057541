import { Inject, Injectable, Injector } from '@angular/core';
import {
    EntityActionOptions,
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { Actions, ofType } from '@ngrx/effects';
import { refresh } from '../../state/app.actions';
import { map, tap } from 'rxjs/operators';
import { Checklist } from './checklist.model';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { ChecklistItem } from './checklist-item.model';
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import { JwtInterceptor } from '../../auth';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';

@Injectable({ providedIn: 'root' })
export class ChecklistEntityService extends EntityCollectionServiceBase<Checklist> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions,
        private injector: Injector,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('Checklist', serviceElementsFactory);
    }

    refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    entitiesForList$ = this.entities$.pipe(
        map((checklists) =>
            checklists.map((checklist) => {
                const checkl: Checklist = JSON.parse(JSON.stringify(checklist));
                checkl.itemsCount = checklist.checklistItems?.length;
                checkl.searchTerms = moment(checklist.createdAt).format(
                    'DD.MM.YYYY'
                );
                if (!checklist.dueAt || !checklist.createdAt) {
                    return checkl;
                }
                checkl.createdAt = new Date(checklist.createdAt);
                checkl.dueAt = new Date(checklist.dueAt);
                return checkl;
            })
        )
    );

    update(
        entity: Partial<Checklist>,
        options?: EntityActionOptions
    ): Observable<Checklist> {
        entity.createdAt = moment(entity.createdAt).format('YYYY-MM-DD');
        entity.dueAt = moment(entity.dueAt).format('YYYY-MM-DD');
        entity.itemsCount = undefined;
        const checklistIs: ChecklistItem[] = [];
        entity.checklistItems?.forEach((checklistItem) => {
            let val = checklistItem.value;
            if (checklistItem.inputType === 'check') {
                val = checklistItem.value ? 'true' : undefined;
            }
            checklistIs.push({ ...checklistItem, value: val });
        });
        entity.checklistItems = checklistIs;
        return super.update(entity, options);
    }

    public getGridDataSource(): Observable<DataManager> {
        const jwtInterceptor = this.injector.get(JwtInterceptor);
        return jwtInterceptor.jwtObservable.pipe(
            map((token) => {
                return new DataManager({
                    url: `${this.server}api/checklists/query?XDEBUG_SESSION=PHPSTORM`,
                    headers: [
                        {
                            Authorization: 'Bearer ' + token,
                        },
                    ],
                    adaptor: new UrlAdaptor(),
                });
            })
        );
    }
}
