import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';

import { select, Store } from '@ngrx/store';
import { isLoggedIn } from './auth.selectors';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthState } from '.';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private store: Store<AuthState>) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.store.pipe(
            select(isLoggedIn),
            tap((loggedIn) => {
                if (!loggedIn) {
                    this.router.navigate(['/anmelden'], {
                        queryParams: { returnUrl: state.url },
                    });
                }
            })
        );
    }
}
