import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
    CurrentUser,
    AuthState,
    currentUser,
    API_BASE_FILE_SERVER,
    eventReceived,
    BadgesService,
    EventData,
} from '@sansys/crosslib';
import { Observable, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SidebarService } from '@sansys/webapputils';
import { Actions, ofType } from '@ngrx/effects';

@Component({
    selector: 'sys-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
    public currentUser$: Observable<
        CurrentUser | undefined
    > = new Observable<CurrentUser>();
    private subscription = new Subscription();

    constructor(
        private store: Store<AuthState>,
        private _fuseSidebarService: SidebarService,
        private _router: Router,
        private action$: Actions,
        private badgesService: BadgesService,
        @Inject(API_BASE_FILE_SERVER) public fileServer: string
    ) {}

    private processEventUpdate(eventData: EventData): void {
        if (
            eventData.itemType === 'order' ||
            eventData.itemType === 'transport'
        ) {
            this.badgesService.initializeBadges();
        }
    }

    ngOnInit(): void {
        this.subscription.add(
            this._router.events
                .pipe(filter((event) => event instanceof NavigationEnd))
                .subscribe(() => {
                    const sidebar = this._fuseSidebarService.getSidebar(
                        'navbar'
                    );
                    if (sidebar) {
                        sidebar.close();
                    }
                })
        );

        this.currentUser$ = this.store.pipe(select(currentUser));

        this.subscription.add(
            this.action$.pipe(ofType(eventReceived)).subscribe((action) => {
                this.processEventUpdate(action.eventData);
            })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    toggleSidebarOpened(): void {
        const sidebar = this._fuseSidebarService.getSidebar('navbar');
        if (sidebar) {
            sidebar.toggleOpen();
        }
    }

    toggleSidebarFolded(): void {
        const sidebar = this._fuseSidebarService.getSidebar('navbar');
        if (sidebar) {
            sidebar.toggleFold();
        }
    }
}
