<h1 mat-dialog-title>{{ data.title }}</h1>
<h2 *ngIf="data.subtitle" [innerHTML]="data.subtitle"></h2>
<div mat-dialog-content *ngIf="data.selectElements">
    <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="example-radio-group"
        [(ngModel)]="result"
    >
        <mat-radio-button
            class="example-radio-button"
            *ngFor="let selectElement of data.selectElements"
            [value]="selectElement.input"
        >
            {{ selectElement.label }}
        </mat-radio-button>
    </mat-radio-group>
</div>
<div mat-dialog-actions class="w-100-p" *ngIf="!data.buttons">
    <button mat-button (click)="onNoClick()">
        {{ 'Abbrechen' | transloco }}
    </button>
    <button
        *ngIf="data.additionalButtonTitle"
        mat-button
        (click)="onOtherClick(data.additionalButtonTitle)"
    >
        {{ data.additionalButtonTitle }}
    </button>
    <button
        mat-button
        [mat-dialog-close]="result"
        cdkFocusInitial
        [disabled]="disabled()"
    >
        {{ 'Ok' | transloco }}
    </button>
</div>
<div class="w-100-p" *ngIf="data.buttons">
    <button
        *ngFor="let button of data.buttons"
        mat-raised-button
        [disabled]="disabled()"
        (click)="onButtonClick(button)"
        class="sys--block mb-12 w-100-p"
    >
        {{ button.title }}
    </button>
    <button
        class="sys--block w-100-p"
        mat-raised-button
        color="warn"
        (click)="onNoClick()"
    >
        {{ 'Abbrechen' | transloco }}
    </button>
</div>
