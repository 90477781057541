import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    showEntityAddOrEditPage,
    showTransportProtocolDetails,
} from '@sansys/crosslib';
import { tap } from 'rxjs/operators';
import { FeatureService } from './feature.service';

@Injectable()
export class FeatureEffects {
    constructor(
        private featureService: FeatureService,
        private action$: Actions
    ) {}

    showAddOrEditPage$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(showEntityAddOrEditPage),
                tap((data) =>
                    this.featureService.showAddOrEditEntityPage(
                        data.entityType,
                        data.entityId,
                        data.manual,
                        undefined,
                        data.data
                    )
                )
            ),
        { dispatch: false }
    );

    showTransportProtocol$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(showTransportProtocolDetails),
                tap((data) =>
                    this.featureService.showAddOrEditEntityPage(
                        'transportProtocol',
                        undefined,
                        undefined,
                        data.transport
                    )
                )
            ),
        { dispatch: false }
    );

    // showInvoiceSumChangedConfirmation$ = createEffect(
    //     () =>
    //         this.action$.pipe(
    //             ofType(showInvoiceSumChangedConfirmation),
    //             tap((data) =>
    //                 this.featureService.showTransportAskForInvoiceSumChangeConfirmation(
    //                     data.message,
    //                     data.transportId
    //                 )
    //             )
    //         ),
    //     { dispatch: false }
    // );
}
