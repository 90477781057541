import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from '.';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const isLoggedIn = createSelector(
    selectAuthState,
    (auth) => !!auth.currentUser
);

export const currentUser = createSelector(
    selectAuthState,
    (auth) => auth.currentUser
);

export const defaultTariff = createSelector(
    selectAuthState,
    (auth) => auth.defaultTariff
);

export const userAccessRights = createSelector(
    selectAuthState,
    (auth) => auth.accessRights
);

export const currentWorktime = createSelector(selectAuthState, (auth) => {
    return auth.currentWorktime;
});
