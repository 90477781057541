import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureHeaderComponent } from './feature-header.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '../../shared.module';

@NgModule({
    declarations: [FeatureHeaderComponent],
    imports: [
        CommonModule,
        MatIconModule,
        FlexModule,
        SharedModule,
        TranslocoModule,
    ],
    exports: [FeatureHeaderComponent],
})
export class FeatureHeaderModule {}
