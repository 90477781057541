import { Component } from '@angular/core';

@Component({
    selector: 'sys-mail-confirm',
    templateUrl: './mail-confirm.component.html',
    styleUrls: ['./mail-confirm.component.scss'],
})
export class MailConfirmComponent {
    title = 'Willkommen bei Sansys';
    mainText =
        'Vielen Dank für Ihre Registrierung. Eine E-Mail mit einem Bestätigungs-Link ist unterwegs zu Ihrem Postfach.';
    logoUrl = 'assets/images/logos/sansys.png';
}
