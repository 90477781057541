import { MatDialogConfig } from '@angular/material/dialog/dialog-config';
import { Invoice } from '@sansys/crosslib';

export interface FormDialogueData {
    transportId: string;
    plannedStart?: string;
    vehicleId?: string;
    transportDate?: string;
}

export function getFormDialogConfig(
    formDialogueData: FormDialogueData | Invoice | string | object
): MatDialogConfig {
    return {
        width: '90vw',
        height: '90vh',
        minWidth: '90vw',
        minHeight: '90vh',
        data: formDialogueData,
        panelClass: 'sys--no-padding',
    };
}
