import { createAction, props } from '@ngrx/store';
import { TariffVersion } from '../features/tariffs';
import { Passenger, Station, TransportLocation } from '../features';

export const showEntityAddOrEditPage = createAction(
    '[Entity] Show add page',
    props<{
        entityType: string;
        entityId?: string;
        manual?: boolean;
        data?: object;
    }>()
);

export const resetEntityValue = createAction(
    '[Entity] Reset value',
    props<{ entityType: string }>()
);

export const tariffVersionCreated = createAction(
    '[Tariff Version] created',
    props<{ tariffVersion: TariffVersion; manual?: boolean }>()
);

export const passengerCreated = createAction(
    '[Passenger] created',
    props<{ passenger: Passenger }>()
);

export const passengerUpdated = createAction(
    '[Passenger] updated',
    props<{ passenger: Passenger }>()
);

export const transportLocationCreated = createAction(
    '[TransportLocation] created',
    props<{
        transportLocation: TransportLocation;
        transportLocationType?: 'start' | 'target' | 'customer';
    }>()
);

export const transportLocationUpdated = createAction(
    '[TransportLocation] updated',
    props<{
        transportLocation: TransportLocation;
        transportLocationType: 'start' | 'target' | 'customer';
    }>()
);

export const transportLocationSelected = createAction(
    '[TransportLocation] selected',
    props<{
        transportLocation?: TransportLocation;
        transportLocationType: 'start' | 'target' | 'customer';
    }>()
);

export const stationSelected = createAction(
    '[Station] selected',
    props<{ station?: Station; stationType: 'start' | 'target' | 'customer' }>()
);

export const stationsLoaded = createAction('[Station] loaded');
