import { Inject, Injectable } from '@angular/core';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { ShiftTemplateEntity } from './shift-template-entity.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';

@Injectable({ providedIn: 'root' })
export class ShiftTemplateEntityService extends EntityCollectionServiceBase<ShiftTemplateEntity> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('ShiftTemplate', serviceElementsFactory);
    }

    getByYearAndMonth(yearAndMonth: string): Observable<ShiftTemplateEntity[]> {
        return this.http.get<ShiftTemplateEntity[]>(
            `${this.server}api/shifttemplates/${yearAndMonth}`,
            {}
        );
    }
}
