import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { companyReducer } from './company.reducer';
import { CompanyEffects } from './company.effects';

@NgModule({
    imports: [
        StoreModule.forFeature('company', companyReducer),
        EffectsModule.forFeature([CompanyEffects]),
    ],
})
export class CompanyModule {}
