import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_BASE_URL_TOKEN } from '../injection-tokens';
import { BugReportData } from '../types/bug-report.type';

@Injectable({ providedIn: 'root' })
export class TrelloService {
    constructor(
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {}

    sendErrorToTrello(bug: BugReportData): Observable<any> {
        const bugData = new FormData();
        bugData.append('topic', bug.topic);
        if (bug.details) {
            bugData.append('details', bug.details);
        }
        if (bug.screenshot) {
            bugData.append(
                'single_file_upload[file]',
                bug.screenshot,
                bug.screenshot.name
            );
        }

        return this.http.post(this.server + 'api/trello', bugData);
    }
}
