import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UploadFileEffects } from './file-upload.effects';
import { featureReducer } from './file-upload.reducer';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('uploadFile', featureReducer),
        EffectsModule.forFeature([UploadFileEffects]),
    ],
})
export class UploadFileStoreModule {}
