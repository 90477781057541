import { Complaint } from './complaint.model';

export function createComplaint(complaint: Complaint): Complaint {
    return {
        id: complaint.id || undefined,
        complaintNumber: complaint.complaintNumber || undefined,
        createdAt: complaint.createdAt || undefined,
        createdBy: complaint.createdBy || undefined,
        transportNumber: complaint.transportNumber || undefined,
        firstContact: complaint.firstContact || undefined,
        dateCompleted: complaint.dateCompleted || undefined,
        channel: complaint.channel || undefined,
        category: complaint.category || undefined,
        processedById: complaint.processedById || undefined,
        isSettled: complaint.isSettled || undefined,
        redirectedTo: complaint.redirectedTo || undefined,
        responseTimeMet: complaint.responseTimeMet || undefined,
        actionRequired: complaint.actionRequired || undefined,
        actionCompleted: complaint.actionCompleted || undefined,
        details: complaint.details || undefined,
        actions: complaint.actions || undefined,
        complainer: complaint.complainer || undefined,
    };
}
