import { createReducer, on } from '@ngrx/store';
import {
    userLogin,
    logout,
    setDefaultTariff,
    setAccessRights,
    setCurrentWorktime,
} from './auth.actions';
import { CurrentUser } from '../features/user';
import { AccessRights } from './access-right.type';
import { Worktime } from '../features/worktime/worktime.model';
import { Tariff } from '../features/tariffs/tariff.model';

export interface AuthState {
    currentUser?: CurrentUser;
    defaultTariff?: Tariff;
    accessRights: AccessRights;
    currentWorktime: Worktime | undefined;
}

export const initialAuthState: AuthState = {
    currentUser: undefined,
    defaultTariff: undefined,
    accessRights: {
        permissions: [],
        isAdmin: false,
        manageFullWarehouse: false,
    },
    currentWorktime: undefined,
};

export const authReducer = createReducer(
    initialAuthState,

    on(userLogin, (state, action) => {
        return {
            ...state,
            currentUser: action.currentUser,
        };
    }),

    on(logout, () => {
        return {
            ...initialAuthState,
        };
    }),

    on(setDefaultTariff, (state, action) => {
        return {
            ...state,
            defaultTariff: action.tariff,
        };
    }),

    on(setAccessRights, (state, action) => {
        return {
            ...state,
            accessRights: action.accessRights,
        };
    }),

    on(setCurrentWorktime, (state, action) => {
        return {
            ...state,
            currentWorktime: action.currentWorktime,
        };
    })
);
