import { Passenger } from './passenger-entity.model';

export function createPassenger(passenger: Passenger): Passenger {
    return {
        id: passenger.id || undefined,
        nursingHome: passenger.nursingHome || undefined,
        firstName: passenger.firstName,
        lastName: passenger.lastName,
        birthday: passenger.birthday,
        street: passenger.street || undefined,
        zip: passenger.zip || undefined,
        city: passenger.city || undefined,
        employer: passenger.employer || undefined,
        phone: passenger.phone || undefined,
        phoneWork: passenger.phoneWork || undefined,
        representative: passenger.representative || undefined,
        repInstitution: passenger.repInstitution || undefined,
        repFirstName: passenger.repFirstName || undefined,
        repLastName: passenger.repLastName || undefined,
        repStreet: passenger.repStreet || undefined,
        repZip: passenger.repZip || undefined,
        repCity: passenger.repCity || undefined,
        healthInsurance: passenger.healthInsurance || undefined,
        accidentInsurance: passenger.accidentInsurance || undefined,
        fullName: passenger.firstName + ' ' + passenger.lastName,
        healthInsuranceType: passenger.healthInsuranceType || undefined,
        warningMessage: passenger.warningMessage || undefined,
    };
}
