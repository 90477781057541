import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TariffVersionComponent } from './tariffs/tariff-version/tariff-version.component';
import { getFormDialogConfig } from '@sansys/webapputils';
import { TransportProtocolComponent } from './transports/transport/transport-protocol/transport-protocol.component';
import { TransportModel } from '@sansys/crosslib';
import { PassengerModalComponent } from './passengers/passenger-modal/passenger-modal.component';
import { WorktimeComponent } from './employees/employee/worktimes/worktime/worktime.component';
import { TransportLocationModalComponent } from './transport-locations/transport-location-modal/transport-location-modal.component';

@Injectable({ providedIn: 'root' })
export class FeatureService {
    constructor(private dialog: MatDialog) {}

    public showAddOrEditEntityPage(
        entityType: string,
        id: string = 'new',
        manual: boolean = false,
        transport?: TransportModel,
        data?: object
    ): void {
        switch (entityType) {
            case 'passenger':
                this.dialog.open(
                    PassengerModalComponent,
                    getFormDialogConfig({ id, manual, data })
                );
                break;
            case 'tariffVersion':
                this.dialog.open(
                    TariffVersionComponent,
                    getFormDialogConfig({ id, manual })
                );
                break;
            case 'transportProtocol':
                this.dialog.open(
                    TransportProtocolComponent,
                    getFormDialogConfig({ transport })
                );
                break;
            case 'worktime':
                this.dialog.open(
                    WorktimeComponent,
                    getFormDialogConfig({ id, data })
                );
                break;
            case 'transportLocation':
                this.dialog.open(
                    TransportLocationModalComponent,
                    getFormDialogConfig({ id, data })
                );
                break;
        }
    }
}
