import { EmployeeShiftModel } from '@sansys/crosslib';
import { TranslocoService } from '@ngneat/transloco';

export const getEmployeeShiftRuleChecksResultColor = (
    employeeShift: EmployeeShiftModel
): string => {
    let result = 0;
    employeeShift.ruleChecks?.forEach(
        (ruleCheck) =>
            (result = ruleCheck.result > result ? ruleCheck.result : result)
    );
    switch (result) {
        case 1:
            return '#ffffe0';
        case 2:
            return '#ffddb9';
        case 3:
            return '#ffb6c1';
        default:
            return '#FFFFFF';
    }
};

export const getEmployeeShiftRuleChecksResultPopupMessage = (
    employeeShift: EmployeeShiftModel
): string | undefined => {
    let result = 0;
    let name = '';
    employeeShift.ruleChecks?.forEach((ruleCheck) => {
        if (ruleCheck.result > result) {
            result = ruleCheck.result;
            name = ruleCheck.rule;
        }
    });
    if (result === 2) {
        return name;
    }
};
