import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns/src/drop-down-base/drop-down-base-model';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { getSearchQuery } from '../../syncfusion/query.functions';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'sys-autocomplete-type',
    template: `
        <ng-container #fieldComponent></ng-container>
        <div class="mb-20">
            <ejs-autocomplete
                [showClearButton]="true"
                cssClass="e-outline"
                [floatLabelType]="to.floatLabel ? to.floatLabel : 'always'"
                [dataSource]="data"
                [fields]="fields"
                [formControl]="formControl"
                [placeholder]="
                    to.placeholder ? to.placeholder : 'Option wählen'
                "
                [allowFiltering]="true"
                (filtering)="onFiltering($event)"
                data-testid="autocomplete"
                id="{{ field.key }}"
                [ngClass]="{
                    'required-field':
                        to.required && formControl.status !== 'VALID'
                }"
            ></ejs-autocomplete>
            <div
                class="mat-error"
                *ngIf="
                    formControl.status !== 'VALID' &&
                    formControl.touched &&
                    to.required
                "
            >
                Pflichtfeld, bitte ausfüllen.
            </div>
        </div>
    `,
})
export class AutocompleteWrapperComponent
    extends FieldWrapper
    implements OnInit {
    public data: { name: string; id: string }[] = [];
    public formControl!: FormControl;
    // maps the appropriate column to fields property
    public fields: FieldSettingsModel = { text: 'name', value: 'id' };
    // set the placeholder to the DropDownList input
    public text = 'Standort auswählen';
    // Bind the filter event
    public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
        e.updateData(this.data, getSearchQuery('name', e.text));
    };

    constructor(public dialog: MatDialog) {
        super();
    }

    ngOnInit(): void {
        if (this.to.autocompleteOptions) {
            this.data = this.to.autocompleteOptions;
        }
    }
}
