import {
    EditSettingsModel,
    FilterSettingsModel,
    PageSettingsModel,
    SelectionSettingsModel,
} from '@syncfusion/ej2-angular-grids';

export interface TableDefaults {
    selectionOptions: SelectionSettingsModel;
    editSettings: EditSettingsModel;
    filterOptions: FilterSettingsModel;
    pageSettings: PageSettingsModel;
}

export const tableDefaults: TableDefaults = {
    selectionOptions: {
        type: 'Multiple',
        mode: 'Row',
    },
    editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showDeleteConfirmDialog: true,
        mode: 'Normal',
    },
    filterOptions: {
        type: 'Excel',
    },
    pageSettings: {
        pageSizes: ['10', '50', '100', '500', '1000'],
        pageSize: 50,
    },
};
