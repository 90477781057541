import {
    Component,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FuseNavigationService } from '../navigation/navigation.service';
import { CompanyService, FEATURE_INFOS } from '@sansys/crosslib';
import { translate } from '@ngneat/transloco';
import { NavigationItem } from '..';

@Component({
    selector: 'fuse-shortcuts',
    templateUrl: './shortcuts.component.html',
    styleUrls: ['./shortcuts.component.scss'],
})
export class FuseShortcutsComponent implements OnInit, OnDestroy {
    @Input() navigation: any;
    @ViewChild('searchInput') searchInputField!: any;
    @ViewChild('shortcuts') shortcutsEl!: ElementRef;

    private subscription = new Subscription();

    shortcutItems: NavigationItem[];
    navigationItems: NavigationItem[] = [];
    filteredNavigationItems: NavigationItem[] = [];
    searching: boolean;

    constructor(
        private _fuseNavigationService: FuseNavigationService,
        private shortcutsService: CompanyService,
        @Inject(FEATURE_INFOS) private featureInfos: object
    ) {
        this.shortcutItems = [];
        this.searching = false;
    }

    private removeShortcutIfInCurrentShortcuts(
        itemToToggle: NavigationItem
    ): boolean {
        for (let i = 0; i < this.shortcutItems.length; i++) {
            if (this.shortcutItems[i].url === itemToToggle.url) {
                this.shortcutItems.splice(i, 1);

                this.shortcutsService
                    .setShortcuts(JSON.stringify(this.shortcutItems))
                    .subscribe();

                return true;
            }
        }
        return false;
    }

    ngOnInit(): void {
        this.filteredNavigationItems = this.navigationItems = this._fuseNavigationService.getFlatNavigation(
            this.navigation
        );

        this.shortcutsService.getShortcuts().subscribe((shortcuts) => {
            if (shortcuts) {
                this.shortcutItems = JSON.parse(shortcuts);
            }
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    search(event: any): void {
        const value = event.target.value.toLowerCase();

        if (value === '') {
            this.searching = false;
            this.filteredNavigationItems = this.navigationItems;

            return;
        }

        this.searching = true;

        this.filteredNavigationItems = this.navigationItems.filter(
            (navigationItem) => {
                const title = translate(
                    (this.featureInfos as any)[navigationItem.id]['title']
                );
                return title.toLowerCase().includes(value);
            }
        );
    }

    toggleShortcut(event: MouseEvent, itemToToggle: NavigationItem): void {
        event.stopPropagation();

        if (this.removeShortcutIfInCurrentShortcuts(itemToToggle)) {
            return;
        }

        this.shortcutItems.push(itemToToggle);

        this.shortcutsService
            .setShortcuts(JSON.stringify(this.shortcutItems))
            .subscribe();
    }

    isInShortcuts(navigationItem: NavigationItem): any {
        return this.shortcutItems.find((item) => {
            return item.url === navigationItem.url;
        });
    }

    onMenuOpen(): void {
        setTimeout(() => {
            this.searchInputField.nativeElement.focus();
        });
    }

    public getItemInfo(id: string, type: string): string {
        if (
            (this.featureInfos as any) &&
            (this.featureInfos as any)[id] &&
            (this.featureInfos as any)[id][type]
        ) {
            return (this.featureInfos as any)[id][type];
        }
        return '';
    }
}
