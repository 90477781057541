import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    API_BASE_URL_TOKEN,
    notificationReceived,
    ONESIGNAL_ID,
} from '@sansys/crosslib';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OneSignal } from 'onesignal-ngx';

@Injectable({
    providedIn: 'root',
})
export class PushNotificationService {
    constructor(
        private store: Store<any>,
        private http: HttpClient,
        private oneSignalService: OneSignal,
        @Inject(ONESIGNAL_ID) private oneSignalId: string,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {}

    private sendUserIdToServer(
        userId: string,
        action: 'add' | 'remove'
    ): Observable<any> {
        return this.http.post<null>(`${this.server}api/notificationdevices/`, {
            userId,
            action,
        });
    }

    initializeOneSignal(): void {
        const self = this;
        this.oneSignalService.getUserId().then((userId) => {
            if (userId) {
                this.sendUserIdToServer(userId, 'add').subscribe();
            }
        });

        this.oneSignalService.on(
            'subscriptionChange',
            (isSubscribed: boolean) => {
                this.oneSignalService.getUserId().then((userId) => {
                    if (userId) {
                        this.sendUserIdToServer(
                            userId,
                            isSubscribed ? 'add' : 'remove'
                        ).subscribe();
                    }
                });
            }
        );
        this.oneSignalService.on('notificationDisplay', (data: any) => {
            console.log('NOTIFICATION RECEIVED');
            console.log(data);
            if (document.hidden) {
                self.store.dispatch(
                    notificationReceived({
                        notificationContent: data,
                        isActive: false,
                    })
                );
            } else {
                self.store.dispatch(
                    notificationReceived({
                        notificationContent: data,
                        isActive: true,
                    })
                );
            }
        });
        this.oneSignalService.on(
            'addListenerForNotificationOpened',
            (data: any) => {
                console.log('NOTIFICATION OPENED');
                console.log(data);
                // if (document.hidden) {
                //     self.store.dispatch(
                //         notificationReceived({
                //             notificationContent: data,
                //             isActive: false,
                //         })
                //     );
                // } else {
                //     self.store.dispatch(
                //         notificationReceived({
                //             notificationContent: data,
                //             isActive: true,
                //         })
                //     );
                // }
            }
        );
    }
}
