import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InputDialogDataType } from './input-dialog-data.type';

@Component({
    selector: 'sys-input-dialog',
    templateUrl: 'input-dialog.component.html',
    styleUrls: ['./input-dialog.component.scss'],
})
export class InputDialogComponent {
    locale = 'de-CH';

    constructor(
        public dialogRef: MatDialogRef<InputDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: InputDialogDataType
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

    disabled(): boolean {
        let disabled = false;
        this.data.inputElements.forEach((inputElement) => {
            if (!inputElement.input && inputElement.required) {
                disabled = true;
            }
        });
        return disabled;
    }
}
