import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { refresh } from '../../state';
import { StockChange } from './stock-change.model';

@Injectable({ providedIn: 'root' })
export class StockChangeEntityService extends EntityCollectionServiceBase<
    StockChange
> {
    constructor(
        private serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions
    ) {
        super('StockChange', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );
}
