import { Injectable } from '@angular/core';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { TemplatePeriod } from './template-period.model';

@Injectable({ providedIn: 'root' })
export class TemplatePeriodEntityService extends EntityCollectionServiceBase<TemplatePeriod> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory
    ) {
        super('TemplatePeriod', serviceElementsFactory);
    }
}
