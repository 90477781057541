<div class="nav vertical">
    <ng-container *ngFor="let item of navigation">
        <sys-nav-vertical-group
            *ngIf="item.type == 'group'"
            [item]="item"
        ></sys-nav-vertical-group>
        <sys-nav-vertical-collapsable
            *ngIf="item.type == 'collapsable'"
            [item]="item"
        ></sys-nav-vertical-collapsable>
        <sys-nav-vertical-item
            *ngIf="item.type == 'item'"
            [item]="item"
        ></sys-nav-vertical-item>
    </ng-container>
</div>
