import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { ForgotPasswordComponent } from './forgot-password.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthPageModule, SharedModule } from '@sansys/webapputils';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { FormlyModule } from '@ngx-formly/core';

@NgModule({
    declarations: [ForgotPasswordComponent],
    imports: [
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        AuthPageModule,
        SharedModule,
        RouterModule,
        ReactiveFormsModule,
        FlexModule,
        ExtendedModule,
        FormlyModule,
    ],
})
export class ForgotPasswordModule {}
