import { Inject, Injectable } from '@angular/core';
import {
    EntityActionOptions,
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { Actions, ofType } from '@ngrx/effects';
import { refresh } from '../../state/app.actions';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { setDefaultTariff } from '../../auth/auth.actions';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';
import { Tariff } from './tariff.model';
import { Observable } from 'rxjs';
import { TariffVersion } from './tariff-version.model';

@Injectable({ providedIn: 'root' })
export class TariffEntityService extends EntityCollectionServiceBase<Tariff> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions,
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('Tariff', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    public fetchAndStoreOwnTariff(): void {
        this.http
            .get<Tariff[]>(this.server + 'api/tariffs/own')
            .pipe(
                tap((tariff) => {
                    if (tariff.length === 1) {
                        this.store.dispatch(
                            setDefaultTariff({ tariff: tariff[0] })
                        );
                    }
                })
            )
            .subscribe();
    }

    public getOwnTariff(tariffId?: string): Observable<Tariff[]> {
        if (tariffId && tariffId !== 'new') {
            return this.http.get<Tariff[]>(
                this.server + 'api/tariffs/own?transportId=' + tariffId
            );
        }
        return this.http.get<Tariff[]>(this.server + 'api/tariffs/own');
    }

    public getByKey(
        key: any,
        options?: EntityActionOptions
    ): Observable<Tariff> {
        return super.getByKey(key, options).pipe(
            map((tariff) => {
                const t = JSON.parse(JSON.stringify(tariff));
                if (!t.protocolTemplate) {
                    t.protocolTemplate = { id: '' };
                }
                return t;
            })
        );
    }

    loadWithTransportId(transportId: string): void {
        this.clearCache();
        this.getWithQuery({ transportId }).subscribe((tariffs) => {
            this.addManyToCache(tariffs);
            this.setLoaded(true);
        });
    }

    copy(tariff: Tariff): Observable<Tariff> {
        return this.http.post<Tariff>(
            this.server + `api/tariffs/${tariff.id}/copy`,
            {}
        );
    }

    getTariffVersions(id: string): Observable<TariffVersion[]> {
        return this.http.get<TariffVersion[]>(
            `${this.server}api/tariffs/${id}/tariffversions`
        );
    }
}
