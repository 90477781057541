import { ListOptionsType } from './list-option.types';

export const listOptionsDefaultValue: ListOptionsType = {
    typeFilter: {
        material: true,
        device: true,
        keyy: true,
    },
    materialFilters: {
        withStock: false,
        onlyAvailable: false,
        onlyOnItsWay: false,
        onlyToOrder: false,
        onlyWithMyLocation: false,
    },
    materialSortType: 'name',
    materialSearchType: 'all',
    keyySortType: 'name',
    keyySearchType: 'all',
    keyyFilters: {
        onlyMine: false,
        onlyWithMe: false,
    },
    deviceSortType: 'name',
    deviceSearchType: 'all',
    deviceFilters: {
        onlyMine: false,
        onlyWithMe: false,
        onlyWorking: false,
        onlyNotWorking: false,
    },
    taskSortType: 'dueDate',
    taskSearchType: 'all',
    taskFilters: {
        onlyDue: false,
        onlyStarted: true,
        onlyOpen: true,
    },
    consignmentFilters: {
        onlyWithOpenItems: true,
    },
    consignmentItemFilters: {
        onlyOpen: true,
    },
    consignmentItemSearchType: 'all',
    consignmentItemSortType: 'consignmentName',
    clickType: 'quickFunctions',
    commonFilters: {
        onlyWithTask: false,
    },
};
