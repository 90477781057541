export const featureInfos = {
    scheduling: {
        title: 'Transportverwaltung',
        permission: [
            'orders|READ',
            'scheduler|READ',
            'offers|READ',
            'transports|READ',
            'vehicles|READ',
            'passengers|READ',
            'customerAdministrations|READ',
            'transportLocations|READ',
            'insurances|READ',
            'protocolTemplates|READ',
            'admin',
        ],
    },

    personell: {
        title: 'Personal',
        permission: [
            'employees|READ',
            'employeesCloths|READ',
            'employeesWorktimes|READ',
            'employeesPayrolls|READ',
            'employeesDocuments|READ',
            'employeeDeductions|READ',
            'shiftSchedule|READ',
            'shiftTemplates|READ',
            'classifications|READ',
            'worktimeTypes|READ',
            'trainings|READ',
            'admin',
        ],
    },

    qm: {
        title: 'Qualitätsmanagement',
        permission: [
            'checklists|READ',
            'checklistTemplates|READ',
            'complaints|READ',
            'defects|READ',
            'qmDocuments|READ',
            'qmDocumentCategories|READ',
            'qmStatistics|READ',
        ],
    },

    invoicing: {
        title: 'Rechnungswesen',
        permission: [
            'invoices|READ',
            'debtcollections|READ',
            'losses|READ',
            'invoicingStatistics|READ',
            'tariffs|READ',
            'tariffVersions|READ',
        ],
    },

    warehouse: {
        title: 'Lagermanagement',
        permission: [
            'materials|READ',
            'devices|READ',
            'keyys|READ',
            'orderSuggestions|READ',
            'warehouseStatistics|READ',
        ],
    },

    administration: {
        title: 'Administration',
        permission: [
            'facilities|READ',
            'company|READ',
            'changelog|READ',
            'notificationCategories|READ',
        ],
    },

    schedulingOverview: {
        title: 'Übersicht',
        fa_icon: 'fa-calendar',
        type: 'item',
        url: '/features/scheduler',
        permission: 'scheduler|READ',
    },
    schedulingGeoLocations: {
        title: 'Fahrzeugkarte',
        fa_icon: 'fa-map-marked',
        type: 'item',
        url: '/features/geo-locations',
        permission: 'vehicles|READ',
    },
    schedulingOrders: {
        title: 'Bestellungen',
        fa_icon: 'fa-clipboard-list-check',
        type: 'item',
        url: '/features/orders',
        permission: 'orders|READ',
    },
    changeLogs: {
        title: 'Änderungen Historie',
        fa_icon: 'fa-history',
        type: 'item',
        url: '/features/change-logs',
        permission: 'changeLogs|READ',
    },
    schedulingOffer: {
        title: 'Offerten',
        fa_icon: 'fa-envelope-open-text',
        type: 'item',
        url: '/features/offers',
        permission: 'offers|READ',
    },
    schedulingTransports: {
        title: 'Transporte',
        fa_icon: 'fa-car-building',
        type: 'item',
        url: '/features/transports',
        permission: 'transports|READ',
    },
    schedulingVehicles: {
        title: 'Fahrzeuge',
        fa_icon: 'fa-cars',
        type: 'item',
        url: '/features/vehicles',
        permission: 'vehicles|READ',
    },
    schedulingPassengers: {
        title: 'passengers',
        fa_icon: 'fa-users-medical',
        type: 'item',
        url: '/features/passengers',
        permission: 'passengers|READ',
    },
    schedulingCustomerAdministrations: {
        title: 'Kundenadministrationen',
        fa_icon: 'fa-address-card',
        type: 'item',
        url: '/features/customer-administrations',
        permission: 'customerAdministrations|READ',
    },
    schedulingTransportLocations: {
        title: 'Start-/Zielorte',
        fa_icon: 'fa-map-marker-alt',
        type: 'item',
        url: '/features/transport-locations',
        permission: 'transportLocations|READ',
    },
    schedulingInsurances: {
        title: 'Versicherungen',
        fa_icon: 'fa-gavel',
        type: 'item',
        url: '/features/insurances',
        permission: 'insurances|READ',
    },
    schedulingProtocolTemplates: {
        title: 'Protokoll-Vorlagen',
        fa_icon: 'fa-stream',
        type: 'item',
        url: '/features/protocol-templates',
        permission: 'protocolTemplates|READ',
    },
    personellEmployees: {
        title: 'Mitarbeiter',
        fa_icon: 'fa-user-circle',
        type: 'item',
        url: '/features/employees',
        permission: [
            'employees|READ',
            'employeesCloths|READ',
            'employeesWorktimes|READ',
            'employeesPayrolls|READ',
            'employeesDocuments|READ',
        ],
    },
    // worktimes: {
    //     title: 'Zeiterfassung',
    //     fa_icon: 'fa-user-clock',
    //     type: 'item',
    //     url: 'features/worktimes',
    // },
    personellEmployeeDeductions: {
        title: 'Lohnabzüge',
        fa_icon: 'fa-abacus',
        type: 'item',
        url: 'features/employee-deductions',
        permission: 'employeeDeductions|READ',
    },
    personellClassifications: {
        title: 'Klassifizierungen',
        fa_icon: 'fa-users-class',
        type: 'item',
        url: 'features/employee-classifications',
        permission: 'classifications|READ',
    },
    personellWorktimeTypes: {
        title: 'Arbeitszeit-Typen',
        fa_icon: 'fa-chart-network',
        type: 'item',
        url: 'features/employee-worktime-types',
        permission: 'worktimeTypes|READ',
    },
    personellWorktimeRules: {
        title: 'Arbeitszeit-Regeln',
        fa_icon: 'fa-ruler',
        type: 'item',
        url: 'features/employee-worktime-rules',
        permission: 'worktimeTypes|READ',
    },
    personellTrainings: {
        title: 'Schulungen',
        fa_icon: 'fa-file-certificate',
        type: 'item',
        url: 'features/trainings',
        permission: 'trainings|READ',
    },
    personellShiftSchedule: {
        title: 'Schichtplan',
        fa_icon: 'fa-calendar-day',
        type: 'item',
        url: 'features/shift-schedule',
        permission: 'shiftSchedule|READ',
    },
    // personellShiftScheduleBeta: {
    //     title: 'Schichtplan (beta)',
    //     fa_icon: 'fa-calendar-day',
    //     type: 'item',
    //     url: 'features/shift-schedule-beta',
    //     permission: 'shiftSchedule|READ',
    // },
    personellShiftTemplates: {
        title: 'Schicht Vorlagen',
        fa_icon: 'fa-list',
        type: 'item',
        url: 'features/shift-templates',
        permission: 'shiftTemplates|READ',
    },
    qmChecklists: {
        title: 'Checklisten',
        fa_icon: 'fa-clipboard-list-check',
        type: 'item',
        url: '/features/checklists',
        permission: 'checklists|READ',
    },
    qmChecklistTemplates: {
        title: 'Checklisten Vorlagen',
        fa_icon: 'fa-paste',
        type: 'item',
        url: '/features/checklist-templates',
        permission: 'checklistTemplates|READ',
    },
    qmComplaints: {
        title: 'Beschwerden',
        fa_icon: 'fa-exclamation-circle',
        type: 'item',
        url: '/features/complaints',
        permission: 'complaints|READ',
    },
    qmDefects: {
        title: 'Defekte',
        fa_icon: 'fa-tools',
        type: 'item',
        url: '/features/defects',
        permission: 'defects|READ',
    },
    qmDocuments: {
        title: 'QM-Dokumente',
        fa_icon: 'fa-book',
        type: 'item',
        url: '/features/qm-documents',
        permission: 'qmDocuments|READ',
    },
    qmDocumentCategories: {
        title: 'QM-Kategorien',
        fa_icon: 'fa-list-alt',
        type: 'item',
        url: '/features/qm-document-categories',
        permission: 'qmDocumentCategories|READ',
    },
    // qmStatistics: {
    //     title: 'QM-Statistiken',
    //     fa_icon: 'fa-chart-pie',
    //     type: 'item',
    //     url: '/features/statistics',
    //     permission: 'qmStatistics|READ',
    // },
    invoicingInvoices: {
        title: 'Rechnungen',
        fa_icon: 'fa-wallet',
        type: 'item',
        url: '/features/invoices',
        permission: 'invoices|READ',
    },
    invoicingDebtcollections: {
        title: 'Betreibungsbegehren',
        fa_icon: 'fa-alarm-clock',
        type: 'item',
        url: '/features/debtcollections',
        permission: 'debtCollections|READ',
    },
    invoicingLosses: {
        title: 'Debitoren Verluste',
        fa_icon: 'fa-flag',
        type: 'item',
        url: '/features/invoices/losses',
        permission: 'losses|READ',
    },
    // invoicingStatistics: {
    //     title: 'Finanzstatistik',
    //     fa_icon: 'fa-chart-line',
    //     type: 'item',
    //     url: '/features/finance-statistics',
    //     permission: 'invoicingStatistics|READ',
    // },
    invoicingTariffs: {
        title: 'Tarife',
        fa_icon: 'fa-list',
        type: 'item',
        url: '/features/tariffs',
        permission: 'tariffs|READ',
    },
    invoicingTariffVersions: {
        title: 'Tarifversion',
        fa_icon: 'fa-list',
        type: 'item',
        url: '/features/tariffs',
        permission: 'tariffVersions|READ',
    },
    warehouseMaterials: {
        title: 'Materialien',
        fa_icon: 'fa-dolly',
        type: 'item',
        url: '/features/materials',
        permission: 'materials|READ',
    },
    warehouseDevices: {
        title: 'Geräte',
        fa_icon: 'fa-phone-laptop',
        type: 'item',
        url: '/features/devices',
        permission: 'devices|READ',
    },
    warehouseKeyys: {
        title: 'Schlüssel',
        fa_icon: 'fa-key',
        type: 'item',
        url: '/features/keys',
        permission: 'keyys|READ',
    },
    warehouseOrderSuggestions: {
        title: 'Bestellvorschläge',
        fa_icon: 'fa-shopping-basket',
        type: 'item',
        url: '/features/warehouse-order-suggestions',
        permission: 'orderSuggestions|READ',
    },
    // warehouseStatistics: {
    //     title: 'Lagerstatistik',
    //     fa_icon: 'fa-analytics',
    //     type: 'item',
    //     url: '/features/warehouse-statistics',
    //     permission: 'warehouseStatistics|READ',
    // },
    company: {
        title: 'Unternehmenseinstellungen',
        fa_icon: 'fa-city',
        permission: 'company|READ',
    },
    rapports: {
        title: 'Informationsplattform',
        fa_icon: 'fa-info-circle',
        permission: 'rapports|READ',
    },
    administrationFacilities: {
        title: 'Standorte',
        fa_icon: 'fa-building',
        type: 'item',
        url: '/features/facilities',
        permission: 'facilities|READ',
    },
    administrationCompany: {
        title: 'Unternehmen',
        fa_icon: 'fa-city',
        type: 'item',
        url: '/features/company',
        permission: 'company|READ',
    },
    notificationCategories: {
        title: 'Benachrichtigungsvorlagen',
        fa_icon: 'fa-bell-exclamation',
        type: 'item',
        url: '/features/notification-categories',
        permission: 'notificationCategories|READ',
    },
    administrationHistory: {
        title: 'Historie',
        fa_icon: 'fa-history',
        type: 'item',
        url: '/features/change-logs',
        permission: 'changelog|READ',
    },
};
