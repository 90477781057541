import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
    createPassenger,
    errorOccured,
    Passenger,
    passengerCreated,
    PassengerEntityService,
    passengerUpdated,
} from '@sansys/crosslib';
import * as moment from 'moment';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { getPassengerFormConfig } from '@sansys/webapputils';
import { Store } from '@ngrx/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'sys-passenger-modal',
    templateUrl: './passenger-modal.component.html',
    styleUrls: ['./passenger-modal.component.scss'],
})
export class PassengerModalComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();
    private entityId = 'new';

    public id = 'schedulingPassengers';

    public pageType = 'create';
    public passenger: Passenger = { firstName: '', lastName: '', birthday: '' };
    public passengerForm: FormGroup = new FormGroup({});
    public passengerFormFields: FormlyFieldConfig[] = [];

    constructor(
        private _formBuilder: FormBuilder,
        private store: Store<any>,
        private passengerEntityService: PassengerEntityService,
        public dialogRef: MatDialogRef<PassengerModalComponent>,
        public translocoService: TranslocoService,
        @Inject(MAT_DIALOG_DATA)
        public options: { id?: string; manual?: boolean; data: any }
    ) {}

    ngOnInit(): void {
        this.passengerFormFields = getPassengerFormConfig(
            this.translocoService
        );
        if (!this.options.id || this.options?.id === 'new') {
            this.entityId = 'new';
        } else {
            this.pageType = 'edit';
            this.entityId = this.options?.id;
        }

        if (this.entityId !== 'new') {
            this.passengerEntityService.getByKey(this.entityId);
            this.pageType = 'edit';
            const sub = this.passengerEntityService
                .getByKey(this.entityId)
                .subscribe({
                    next: (passenger) => {
                        if (!passenger) {
                            this.store.dispatch(
                                errorOccured({ message: 'Datenfehler' })
                            );
                            return;
                        }
                        const pass: Passenger = JSON.parse(
                            JSON.stringify(passenger)
                        );
                        pass.birthday = moment(pass.birthday).toDate();
                        this.passenger = pass;
                        if (this.options.data) {
                            this.passenger.nursingHome = this.options.data[
                                'nursingHome'
                            ];
                            this.passenger.street = this.options.data['street'];
                            this.passenger.zip = this.options.data['zip'];
                            this.passenger.city = this.options.data['city'];
                            this.passengerForm.markAsDirty();
                        }
                    },
                });
            this.subscription.add(sub);
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public goBack(): void {
        this.dialogRef.close();
    }

    public onSavePassenger(): void {
        if (!this.passenger) {
            return;
        }
        const passenger: Passenger = createPassenger(this.passenger);

        if (this.pageType === 'edit') {
            this.passengerEntityService.update(passenger).subscribe(() => {
                this.store.dispatch(passengerUpdated({ passenger }));
                this.goBack();
            });
        } else {
            this.passengerEntityService.add(passenger).subscribe((pass) => {
                this.goBack();
                this.store.dispatch(passengerCreated({ passenger: pass }));
            });
        }
    }
}
