import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns/src/drop-down-base/drop-down-base-model';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { ProtocolTemplateEntityService } from '@sansys/crosslib';
import { filter, first, switchMapTo, tap } from 'rxjs/operators';
import {
    ChangeEventArgs,
    DropDownListComponent,
} from '@syncfusion/ej2-angular-dropdowns';

@Component({
    selector: 'sys-protocol-wrapper-type',
    template: `
        <ng-container #fieldComponent></ng-container>
        <div class="mb-20">
            <ejs-dropdownlist
                #dropdown
                [showClearButton]="true"
                cssClass="e-outline"
                floatLabelType="Always"
                [dataSource]="data"
                [fields]="fields"
                [formControl]="formControl"
                [placeholder]="getPlaceholder()"
                [allowFiltering]="true"
                (change)="onChange($event)"
                (filtering)="onFiltering($event)"
                id="{{ field.key }}"
            ></ejs-dropdownlist>
        </div>
    `,
})
export class ProtocolWrapperComponent extends FieldWrapper
    implements OnInit, AfterViewInit {
    @ViewChild('dropdown') public dropdownObject!: DropDownListComponent;
    public data: {
        [key: string]: object;
    }[] = [];
    public formControl!: FormControl;
    // maps the appropriate column to fields property
    public fields: FieldSettingsModel = { text: 'name', value: 'name' };
    // Bind the filter event
    public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
        let query = new Query();
        // frame the query based on search string with filter type.
        query =
            e.text !== ''
                ? query.where('name', 'contains', e.text, true)
                : query;
        // pass the filter data source, filter query to updateData method.
        e.updateData(this.data, query);
    };

    constructor(
        private protocolTemplateEntityService: ProtocolTemplateEntityService
    ) {
        super();
    }

    ngAfterViewInit(): void {
        const dropdownInput = this.dropdownObject.element.getElementsByTagName(
            'input'
        )[0];
        if (dropdownInput && this.to.placeholder) {
            if (this.to.required) {
                dropdownInput.placeholder =
                    this.to.placeholder + ' wählen (Pflichtfeld)';
            } else {
                dropdownInput.placeholder = this.to.placeholder + ' wählen';
            }
        }
    }

    onChange(args: ChangeEventArgs): void {
        if (
            this.field &&
            this.field.templateOptions &&
            this.field.templateOptions.changeCallback
        ) {
            this.field.templateOptions.changeCallback(args.itemData);
        }
    }

    ngOnInit(): void {
        this.protocolTemplateEntityService.loaded$
            .pipe(
                filter((isLoaded) => isLoaded),
                switchMapTo(this.protocolTemplateEntityService.entities$),
                first(),
                tap((protocolTemplates) => {
                    this.data.length = 0;
                    protocolTemplates.forEach((protocolTemplate) => {
                        const protTep = JSON.parse(
                            JSON.stringify(protocolTemplate)
                        );
                        protTep.id = protocolTemplate.name;
                        this.data.push(protTep as any);
                    });
                })
            )
            .subscribe();

        this.protocolTemplateEntityService.loaded$
            .pipe(
                filter((isLoaded) => !isLoaded),
                first(),
                tap(() => {
                    this.protocolTemplateEntityService.load();
                })
            )
            .subscribe();
    }

    getPlaceholder(): string {
        if (this.to.placeholder) {
            if (this.to.required) {
                return this.to.placeholder + ' *';
            } else {
                return this.to.placeholder;
            }
        }
        return '';
    }
}
