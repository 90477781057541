import { Inject, Injectable } from '@angular/core';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { GeoLocationModel } from './geo-location.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';

@Injectable({ providedIn: 'root' })
export class GeoLocationEntityService {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {}

    addGeoLocation(
        geoLocation: GeoLocationModel
    ): Observable<GeoLocationModel | null> {
        return this.http.post<GeoLocationModel | null>(
            `${this.server}api/geolocations/`,
            geoLocation
        );
    }

    getCurrentLocationOfVehicles(): Observable<GeoLocationModel[]> {
        return this.http.get<GeoLocationModel[]>(
            `${this.server}api/geolocations/current`
        );
    }
}
