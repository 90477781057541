import { Defect } from './defect.model';

export function createDefect(defect: Defect): Defect {
    return {
        id: defect.id || undefined,
        topic: defect.topic,
        description: defect.description,
        vehicleId: defect.vehicleId || undefined,
        facilityId: defect.facilityId || undefined,
        repairedAt: defect.repairedAt || undefined,
        createdAt: defect.createdAt || undefined,
        checkedAt: defect.checkedAt || undefined,
        repairedById: defect.repairedById || undefined,
        repairedBy: defect.repairedBy || undefined,
        checkedById: defect.checkedById || undefined,
        checkedBy: defect.checkedBy || undefined,
        createdBy: defect.createdBy || undefined,
        repairDetails: defect.repairDetails || undefined,
        outOfOrder: defect.outOfOrder || undefined,
        costs: defect.costs || undefined,
    };
}
