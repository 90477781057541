import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { FieldWrapper } from '@ngx-formly/core';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns/src/drop-down-base/drop-down-base-model';
import { FormControl } from '@angular/forms';
import {
    errorOccured,
    TransportLocation,
    TransportLocationEntityService,
    transportLocationSelected,
} from '@sansys/crosslib';
import { Subscription } from 'rxjs';
import {
    ChangeEventArgs,
    DropDownListComponent,
} from '@syncfusion/ej2-angular-dropdowns';
import { Store } from '@ngrx/store';
import { getSearchQuery } from '../../syncfusion/query.functions';
import { translate } from '@ngneat/transloco';
import { DataManager, Query } from '@syncfusion/ej2-data';

@Component({
    selector: 'sys-transport-location-autocomplete-type',
    template: `
        <ng-container #fieldComponent></ng-container>
        <ejs-dropdownlist
            [showClearButton]="true"
            cssClass="e-outline"
            floatLabelType="Always"
            [dataSource]="data"
            [fields]="fields"
            [formControl]="formControl"
            [placeholder]="getPlaceholder()"
            [allowFiltering]="true"
            (change)="onChange($event)"
            (filtering)="onFiltering($event)"
            id="{{ field.key }}"
            #dropdown
            [ngClass]="{
                'required-field':
                    to.required &&
                    formControl.status !== 'VALID' &&
                    !to.disabled
            }"
        ></ejs-dropdownlist>
        <div
            class="mat-error"
            *ngIf="
                formControl.status !== 'VALID' &&
                formControl.touched &&
                to.required
            "
        >
            Pflichtfeld, bitte ausfüllen.
        </div>
    `,
})
export class TransportLocationAutocompleteWrapperComponent
    extends FieldWrapper
    implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('dropdown') public dropdownObject!: DropDownListComponent;
    private subscription = new Subscription();

    public query: Query = new Query().from('transportlocation').take(100);
    data: DataManager = new DataManager();
    public formControl!: FormControl;
    // maps the appropriate column to fields property
    public fields: FieldSettingsModel = {
        text: 'nameWithAddition',
        value: 'id',
    };
    // set the placeholder to the DropDownList input
    public text = 'Ort auswählen';
    // Bind the filter event
    public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
        e.updateData(this.data, getSearchQuery('nameWithAddition', e.text));
    };

    constructor(
        private transportLocationEntityService: TransportLocationEntityService,
        private store: Store<any>
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.to.originalTransportLocation) {
            const transportL: TransportLocation = JSON.parse(
                JSON.stringify(this.to.originalTransportLocation)
            );
            const nameWithAddition =
                transportL.companyName +
                ' (' +
                transportL.street +
                ', ' +
                transportL.zip +
                ' ' +
                transportL.city +
                ')';
            if (transportL.isPartner) {
                transportL.companyName += ' [Partner]';
            }
            const tl = {
                ...this.to.originalTransportLocation,
                ...{ nameWithAddition },
            };
            this.data.insert(tl);
        }
    }

    ngAfterViewInit(): void {
        this.transportLocationEntityService
            .getDropdownListDataSource()
            .subscribe((manager) => {
                this.data = manager;
            });
        const dropdownInput = this.dropdownObject.element.getElementsByTagName(
            'input'
        )[0];
        if (dropdownInput && this.to.placeholder) {
            if (this.to.required) {
                dropdownInput.placeholder =
                    this.to.placeholder + translate(' wählen (Pflichtfeld)');
            } else {
                dropdownInput.placeholder =
                    this.to.placeholder + translate(' wählen');
            }
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onChange(args: ChangeEventArgs): void {
        const selectedTransportLocation = (this.dropdownObject.getDataByValue(
            args.value
        ) as unknown) as TransportLocation;
        const transportLocationType = this.to.transportLocationType;
        if (!transportLocationType) {
            this.store.dispatch(
                errorOccured({ message: 'transportLocationType not provided' })
            );
        }
        this.store.dispatch(
            transportLocationSelected({
                transportLocation: selectedTransportLocation,
                transportLocationType,
            })
        );
        if (
            this.field &&
            this.field.templateOptions &&
            this.field.templateOptions.changeCallback
        ) {
            this.field.templateOptions.changeCallback(
                this.dropdownObject.getDataByValue(args.value)
            );
        }
    }

    getPlaceholder(): string {
        if (this.to.placeholder) {
            if (this.to.required) {
                return this.to.placeholder + ' *';
            } else {
                return this.to.placeholder;
            }
        }
        return '';
    }
}
