import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'sys-bvg-wrapper-type',
    template: `
        <ng-container #fieldComponent></ng-container>
        <div class="mb-20 display-flex">
            <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="flex-50 pr-12"
            >
                <mat-label>BVG Jahr</mat-label>
                <mat-select [(ngModel)]="data" placeholder="Jahr wählen">
                    <mat-option
                        *ngFor="let dat of field.templateOptions?.model?.bvg"
                        [value]="dat"
                    >
                        {{ dat.year }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>BVG Wert</mat-label>
                <input
                    placeholder="Jahr wählen"
                    matInput
                    type="number"
                    [(ngModel)]="data.value"
                    [disabled]="!!to.disabled"
                />
            </mat-form-field>
        </div>
    `,
})
export class BvgWrapperComponent extends FieldWrapper {
    public formControl!: FormControl;

    data?: any = { year: 2020, value: undefined };
}
