import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NavbarModule } from './navbar/navbar.module';
import { ToolbarModule } from './toolbar/toolbar.module';
import { LayoutComponent } from './layout.component';
import {
    BugReportModule,
    FooterModule,
    FuseSidebarModule,
    LoadingModule,
    SharedModule,
} from '@sansys/webapputils';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [LayoutComponent],
    imports: [
        RouterModule,

        SharedModule,
        FuseSidebarModule,
        BugReportModule,

        FooterModule,
        NavbarModule,
        ToolbarModule,
        CommonModule,

        LoadingModule,
    ],
    exports: [LayoutComponent],
})
export class LayoutModule {}
