import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';

import { SignedInComponent } from './signed-in/signed-in.component';
import { ToolbarComponent } from './toolbar.component';
import { TranslocoModule } from '@ngneat/transloco';
import { FuseShortcutsModule, SharedModule } from '@sansys/webapputils';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
    declarations: [ToolbarComponent, SignedInComponent],
    imports: [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        SharedModule,
        FuseShortcutsModule,
        TranslocoModule,
        CommonModule,
        FlexLayoutModule,
        MatBadgeModule,
        NgxPermissionsModule,
    ],
    exports: [ToolbarComponent],
})
export class ToolbarModule {}
