import { createAction, props } from '@ngrx/store';
import { CurrentUser } from '../features/user';
import { AccessRights } from './access-right.type';
import { Worktime } from '../features/worktime/worktime.model';
import { Tariff } from '../features/tariffs/tariff.model';

export const userLogin = createAction(
    '[CurrentUser Page] CurrentUser Login',
    props<{ currentUser: CurrentUser; redirectToHome?: boolean }>()
);

export const setAccessRights = createAction(
    '[Auth] Set Access Rights',
    props<{ accessRights: AccessRights }>()
);

export const setCurrentWorktime = createAction(
    '[Auth] Set Current Worktime',
    props<{ currentWorktime: Worktime | undefined }>()
);

export const logout = createAction('[Auth] CurrentUser Logout');

export const initialCheckAuth = createAction('[Auth] initial auth check');

export const softLogin = createAction('[Auth] soft login');

export const setDefaultTariff = createAction(
    '[Auth] set default tariff',
    props<{ tariff: Tariff }>()
);
