import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
    selector: 'sys-formly-invoice-item-repeater-section',
    template: `
        <div *ngFor="let field of field.fieldGroup; let i = index">
            <div class="display-flex">
                <formly-field
                    class="flex-75 pr-12"
                    [field]="field"
                ></formly-field>
                <div>
                    <button
                        mat-raised-button
                        color="warn"
                        type="button"
                        (click)="remove(i)"
                        [disabled]="getDeleteDisabled() || to.disabled"
                    >
                        {{ 'Löschen' | transloco }}
                    </button>
                </div>
            </div>
        </div>
    `,
})
export class InvoiceItemRepeaterFormlyTypeComponent extends FieldArrayType {
    addSelectOption(i: number): void {
        if (!this.model[i]['selectOptions']) {
            this.model[i]['selectOptions'] = [];
        }
        this.model[i]['selectOptions'].push({ name: null, price: null });
    }

    removeOption(i: number, j: number): void {
        this.model[i]['selectOptions'].splice(j, 1);
    }

    getDeleteDisabled(): boolean {
        return !!this.field.templateOptions?.disabled;
    }
}
