import { Inject, Injectable } from '@angular/core';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { first, map, tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { EntityBaseService } from '../../state/entity-base.service';
import { refresh } from '../../state/app.actions';
import { combineLatest, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';
import { getChecklistsAreCompleted } from '../checklists/checklist-helper.functions';
import { currentWorktime } from '../../auth/auth.selectors';
import { Checklist } from '../checklists/checklist.model';
import { Facility } from './facility.model';

@Injectable({ providedIn: 'root' })
export class FacilityEntityService extends EntityBaseService<Facility> {
    constructor(
        private serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions,
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('Facility', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    currentFacility$ = combineLatest([
        this.store.select(currentWorktime),
        this.entities$,
    ]).pipe(
        map(([currentW, facilities]) => {
            return facilities.find(
                (facility) => currentW?.facility?.id === facility.id
            );
        })
    );

    entitiesForLists$ = this.entities$.pipe(
        map((facilities) =>
            facilities.map((facility) => {
                const checklistStatus = getChecklistsAreCompleted(facility);
                return { ...facility, checklistStatus };
            })
        )
    );

    getFacilityChecklists(facilityId: string): Observable<Checklist[]> {
        return this.http.get<Checklist[]>(
            this.server + `api/facilities/${facilityId}/checklists`
        );
    }

    findFacility(facilityId: string): Observable<Facility | undefined> {
        return this.entities$.pipe(
            first(),
            map((facilities: Facility[]) => {
                return facilities.find(
                    (facility) => facility.id === facilityId
                );
            })
        );
    }

    getFacilityNames(): Observable<string[]> {
        return this.http.get<string[]>(this.server + `api/facilities/names`);
    }

    getForSlect(): Observable<Facility[]> {
        return this.http.get<Facility[]>(
            `${this.server}api/facilities/forselect`
        );
    }
}
