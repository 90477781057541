import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'sys-feature-container',
    templateUrl: './feature-container.component.html',
    styleUrls: ['./feature-container.component.scss'],
})
export class FeatureContainerComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
