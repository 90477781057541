import { Device } from './device-entity.model';

export function createDevice(device: Partial<Device>): Device | null {
    if (!device.name) {
        return null;
    }
    return {
        id: device.id || undefined,
        itemNumber: device.itemNumber || undefined,
        name: device.name,
        home: device.home || undefined,
        owner: device.owner || undefined,
        note: device.note || undefined,
        purchasingPrice: device.purchasingPrice || undefined,
        manufacturerNumber: device.manufacturerNumber || undefined,
        manufacturerName: device.manufacturerName || undefined,
        barcode: device.barcode || undefined,
        blecode: device.blecode || undefined,
        purchasingDate: device.purchasingDate || undefined,
        serviceDate: device.serviceDate || undefined,
        notWorking: device.notWorking || false,
        assignedId: device.assignedId || undefined,
    };
}
