import * as moment from 'moment';
import { Worktime, WorktimeBreak } from './worktime.model';

function convertSecondsToHoursAndMinutes(seconds: number): string {
    const dur = moment.duration(seconds, 'seconds');
    let hours = Math.floor(dur.asHours());
    if (dur.asHours() < 0) {
        hours = Math.ceil(dur.asHours());
    }
    const mins = Math.abs(Math.floor(dur.asMinutes()) - hours * 60);
    return hours + ':' + (mins > 9 ? mins : '0' + mins);
}

export function getWorktimeTime(worktime: Worktime): string {
    if (worktime.secondsPerDay) {
        return convertSecondsToHoursAndMinutes(worktime.secondsPerDay);
    }
    if (worktime.end) {
        return moment
            .utc(moment(worktime.end).diff(moment(worktime.start)))
            .subtract(getWorktimeBreakSumInMinutes(worktime), 'minutes')
            .format('HH:mm');
    }
    return moment
        .utc(moment().diff(moment(worktime.start)))
        .subtract(getWorktimeBreakSumInMinutes(worktime), 'minutes')
        .format('HH:mm');
}

export function getActiveWorktimeBreak(
    worktime: Worktime
): WorktimeBreak | null {
    let activeWorktimeBreak = null;
    worktime.worktimeBreaks?.forEach((worktimeBreak) => {
        if (!worktimeBreak.end) {
            activeWorktimeBreak = worktimeBreak;
        }
    });
    return activeWorktimeBreak;
}

export function getWorktimeBreakTime(worktime: Worktime): string {
    if (worktime.worktimeTypeBreakMinutes) {
        return parseMinutesToHoursMinutesString(
            worktime.worktimeTypeBreakMinutes
        );
    }
    return moment
        .utc(
            moment
                .duration(getWorktimeBreakSumInMinutes(worktime), 'minutes')
                .asMilliseconds()
        )
        .format('HH:mm');
}

export function getWorktimeBreakSumInMinutes(worktime: Worktime): number {
    if (!worktime.worktimeBreaks || worktime.worktimeBreaks.length === 0) {
        return 0;
    }
    let minutes = 0;
    worktime.worktimeBreaks.forEach((worktimeBreak) => {
        if (worktimeBreak.end) {
            const minutesToAdd = moment(worktimeBreak.end).diff(
                moment(worktimeBreak.start),
                'minutes'
            );
            minutes += minutesToAdd;
        } else {
            const minutesToAdd = moment().diff(
                moment(worktimeBreak.start),
                'minutes'
            );
            minutes += minutesToAdd;
        }
    });
    return minutes;
}

export function prepareWorktimeForList(worktime: Worktime): Worktime {
    const workt: Worktime = JSON.parse(JSON.stringify(worktime));
    workt.start = new Date(workt.start);
    if (workt.end) {
        workt.end = new Date(workt.end);
    }
    workt.workTime = getWorktimeTime(workt);
    workt.breakTime = getWorktimeBreakTime(workt);
    workt.searchTerms =
        (workt.employee.firstName && workt.employee.lastName
            ? workt.employee.firstName && workt.employee.lastName
            : '') +
        (workt.facility?.name ? workt.facility.name : '') +
        moment(workt.start).format('DD.MM.YYYY HH:mm:ss') +
        (workt.end ? moment(workt.end).format('DD.MM.YYYY HH:mm:ss') : '');
    return workt;
}

export function parseHoursMinutesStringToMinutes(
    timeString: string
): number | undefined {
    const minutesPerDayStrings = timeString.split(':');
    if (
        minutesPerDayStrings.length !== 2 ||
        minutesPerDayStrings[1].length > 2 ||
        parseInt(minutesPerDayStrings[1], 10) > 59
    ) {
        return undefined;
    }
    const hours = parseInt(minutesPerDayStrings[0], 10);
    const minutes = parseInt(minutesPerDayStrings[1], 10);
    if (isNaN(hours) || isNaN(minutes)) {
        return undefined;
    }
    if (hours < 0) {
        return hours * 60 - minutes;
    }
    return hours * 60 + minutes;
}

export function parseMinutesToHoursMinutesString(minutes: number): string {
    return parseSecondsToHoursMinutesString(minutes * 60);
}

export function parseSecondsToHoursMinutesString(seconds: number): string {
    const negativeSign = seconds < 0 ? '-' : '';
    seconds = Math.abs(seconds);
    const minutesTotal = Math.floor(seconds / 60);
    let hours = Math.floor(minutesTotal / 60);
    if (minutesTotal < 0) {
        hours = Math.ceil(minutesTotal / 60);
    }
    const minutes = Math.abs(minutesTotal % 60);

    return (
        negativeSign +
        (hours < 10 ? '0' + hours : hours) +
        ':' +
        (minutes < 10 ? '0' + minutes : minutes)
    );
}
