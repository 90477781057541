import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns/src/drop-down-base/drop-down-base-model';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import {
    QmDocumentCategory,
    QmDocumentCategoryEntityService,
} from '@sansys/crosslib';
import { ChangeEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { Subscription } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { getSearchQuery } from '../../syncfusion/query.functions';

@Component({
    selector: 'sys-parent-document-category-autocomplete-type',
    template: `
        <div class="mb-20">
            <ejs-dropdownlist
                [showClearButton]="true"
                cssClass="e-outline"
                floatLabelType="Always"
                [dataSource]="data"
                [fields]="fields"
                [formControl]="formControl"
                [placeholder]="to.placeholder ? to.placeholder : ''"
                [allowFiltering]="true"
                (change)="onChange($event)"
                (filtering)="onFiltering($event)"
                data-testid="tariffAutocomplete"
                id="{{ field.key }}"
                [ngClass]="{
                    'required-field':
                        to.required && formControl.status !== 'VALID'
                }"
            ></ejs-dropdownlist>
        </div>
    `,
})
export class ParentDocumentCategoryAutocompleteWrapperComponent
    extends FieldWrapper
    implements OnInit, OnDestroy {
    private subscription = new Subscription();

    public data: {
        [key: string]: object;
    }[] = [];
    public formControl!: FormControl;
    // maps the appropriate column to fields property
    public fields: FieldSettingsModel = { text: 'name', value: 'id' };
    // set the placeholder to the DropDownList input
    public text = 'QM-Kategorie auswählen';
    // Bind the filter event
    public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
        e.updateData(this.data, getSearchQuery('name', e.text));
    };

    constructor(
        private qmDocumentCategoryEntityService: QmDocumentCategoryEntityService
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscription.add(
            this.qmDocumentCategoryEntityService.entities$.subscribe(
                (qmDocumentCategories) => {
                    const qmDocCats: QmDocumentCategory[] = JSON.parse(
                        JSON.stringify(qmDocumentCategories)
                    );
                    this.data.length = 0;
                    qmDocCats.forEach((qmDocCat) => {
                        if (qmDocCat.id !== this.to.childId) {
                            this.data.push(qmDocCat as any);
                        }
                    });
                }
            )
        );

        this.subscription.add(
            this.qmDocumentCategoryEntityService.loaded$
                .pipe(
                    filter((isLoaded) => !isLoaded),
                    first(),
                    tap(() => {
                        this.qmDocumentCategoryEntityService.load();
                    })
                )
                .subscribe()
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onChange(args: ChangeEventArgs): void {
        if (
            this.field &&
            this.field.templateOptions &&
            this.field.templateOptions.changeCallback
        ) {
            this.field.templateOptions.changeCallback(args.value);
        }
    }
}
