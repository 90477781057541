import { Checklist } from './checklist.model';

export function getChecklistsAreCompleted(entity: {
    currentChecklists?: Checklist[];
}): boolean {
    return getChecklistStatus(entity) === 100;
}

export function getChecklistStatus(entity: {
    currentChecklists?: Checklist[];
}): number {
    if (!entity.currentChecklists || entity.currentChecklists.length === 0) {
        return 100;
    }
    let completed = 0;
    entity.currentChecklists.forEach(
        (checklist) => (completed += checklist.completed)
    );

    return Math.round(completed / entity.currentChecklists.length);
}
