import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@sansys/crosslib';
import { Subscription } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { getRegistrationFormConfig } from './registration.form-config';

@Component({
    selector: 'sys-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnDestroy {
    private subscription = new Subscription();

    public title = 'Willkommen bei Sansys';

    model: {
        firstName: string;
        lastName: string;
        username: string;
        email: string;
        password: string;
        companyName: string;
        medicalCompany: boolean;
        agbAccepted: boolean;
    } = {
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        password: '',
        companyName: '',
        medicalCompany: true,
        agbAccepted: false,
    };

    form = new FormGroup({});
    fields: FormlyFieldConfig[] = getRegistrationFormConfig();

    constructor(
        private router: Router,
        private _formBuilder: FormBuilder,
        private authService: AuthService
    ) {}

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onSubmit(): void {
        this.authService.register(this.model).subscribe(() => {
            this.router.navigate(['/mail-confirm']);
        });
    }
}
