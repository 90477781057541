import { Material, MaterialListFilter, MaterialLocation } from '.';
import {
    MaterialSortType,
    ListOptionsType,
} from '../warehouse/list-option.types';
import { Vehicle } from '../vehicles/vehicle-entity.model';
import { Facility } from '../facilities/facility.model';

export function sortMaterials(
    materials: Material[],
    sortType: MaterialSortType
): Material[] {
    const collator = new Intl.Collator('de', {
        numeric: true,
        sensitivity: 'base',
    });

    switch (sortType) {
        case 'name':
            return materials.sort((a, b) => collator.compare(a.name, b.name));
        case 'location':
            return materials.sort((a, b) => {
                return collator.compare(
                    getMainLocationOfMaterial(a)?.name || '',
                    getMainLocationOfMaterial(b)?.name || ''
                );
            });
        case 'itemNumber':
            return materials.sort((a, b) =>
                collator.compare(a.itemNumber || '', b.itemNumber || '')
            );
    }

    return materials ?? [];
}

export function getMainLocationOfMaterial(
    material: Material
): MaterialLocation | undefined {
    if (!material.materialLocations) {
        return undefined;
    }
    return material.materialLocations[0];
}

export function filterMaterials(
    materials: Material[],
    filter: string,
    listOptions: ListOptionsType,
    currentUserName: string
): Material[] {
    let filteredMaterials = [...materials];

    if (listOptions.materialFilters.onlyWithMyLocation) {
        filteredMaterials = filteredMaterials.filter((material) => {
            let found = false;
            if (material.materialLocations) {
                material.materialLocations.forEach((materialLocation) => {
                    found = materialLocation.name === currentUserName || found;
                });
            }
            return found;
        });
    }

    if (listOptions.materialFilters.withStock) {
        filteredMaterials = filteredMaterials.filter(
            (material) => getTotalStockOfMaterial(material) > 0
        );
    }

    if (!filter) {
        return filteredMaterials;
    }

    return filteredMaterials.filter((entity) => {
        let haystack;
        switch (listOptions.materialSearchType) {
            case 'name':
                haystack = entity.name;
                break;
            case 'location':
                haystack = getAllLocationCodesOfMaterial(entity);
                break;
            default:
                haystack =
                    entity.name +
                    entity.note +
                    entity.itemNumber +
                    entity.manufacturerNumber +
                    entity.barcode +
                    entity.manufacturerName +
                    entity.supplier +
                    entity.orderNumber +
                    getAllLocationCodesOfMaterial(entity);
        }

        const position = haystack.toLowerCase().indexOf(filter);
        return position > -1;
    });
}

export function getTotalStockOfMaterial(material: Material): number {
    let totalStock = 0;
    if (material.materialLocations) {
        material.materialLocations.forEach((location) => {
            totalStock += location.currentStock || 0;
        });
    }
    return totalStock;
}

export function getLocationClass(
    material: Material,
    location: MaterialLocation
): string {
    if (
        (!location.currentStock && location.currentStock !== 0) ||
        !location.minStock
    ) {
        return '';
    }
    if (location.currentStock < location.minStock) {
        return 'danger';
    }
    if (location.currentStock === location.minStock) {
        return 'warning';
    }
    return '';
}

export function getLocationState(
    material: Material,
    location: MaterialLocation
): string {
    if (location.currentStock || location.currentStock === 0) {
        const minIndicator = location.minStock
            ? 'Min: ' + location.minStock + ' '
            : '';
        return minIndicator + '[' + location.currentStock + ']';
    }
    return '';
}

export function getAllLocationCodesOfMaterial(material: Material): string {
    let result = '';
    material.materialLocations?.forEach(
        (materialLocation) => (result += materialLocation.name)
    );
    return result;
}

export function materialHasLocationWithSpecificName(
    material: Material,
    name: string
): boolean {
    let result = false;
    material.materialLocations?.forEach((materialLocation) => {
        if (materialLocation.name === name) {
            result = true;
        }
    });
    return result;
}

export function appylMaterialsFilter(
    materials: Material[],
    filter: MaterialListFilter,
    currentUserFullName: string
): Material[] {
    switch (filter) {
        case 'onlyMine':
            return materials.filter((material) =>
                materialHasLocationWithSpecificName(
                    material,
                    currentUserFullName
                )
            );
        case 'withStock':
            return materials.filter(
                (material) => getTotalStockOfMaterial(material) > 0
            );
    }
    return materials;
}

export function getAssignedOptions(
    facilities: Facility[],
    vehicles: Vehicle[]
): {
    id: string;
    value: string;
}[] {
    const asssignedOptions: {
        id: string;
        value: string;
    }[] = [];
    facilities.forEach((facility) => {
        if (!facility.id) {
            return;
        }
        asssignedOptions.push({
            value: facility.name,
            id: facility.id,
        });
    });
    vehicles.forEach((vehicle) => {
        if (!vehicle.id) {
            return;
        }
        asssignedOptions.push({
            value: vehicle.name,
            id: vehicle.id,
        });
    });

    return asssignedOptions;
}
