import { createAction, props } from '@ngrx/store';
import { NotificationsModel } from './notifications.model';

export const notificationReceived = createAction(
    '[Notification] Notification Received',
    props<{ notificationContent: NotificationsModel; isActive: boolean }>()
);

export const resetUnreadRapportsCount = createAction(
    '[Notification] Reset Unread Rapports Count'
);
