import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'sys--formly-field-button',
    template: `
        <div>
            <button mat-button [type]="to.type" (click)="onClick($event)">
                {{ to.text }}
            </button>
        </div>
    `,
})
export class FormlyFieldButtonComponent extends FieldType {
    onClick($event: any): void {
        if (this.to.onClick) {
            this.to.onClick($event);
        }
    }
}
