import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { Inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { refresh } from '../../state';
import { QmDocument } from './qm-document.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';

@Injectable({ providedIn: 'root' })
export class QmDocumentEntityService extends EntityCollectionServiceBase<
    QmDocument
> {
    constructor(
        private serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions,
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('QmDocument', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    uploadDocument(document: QmDocument): Observable<any> {
        const documentData = new FormData();
        if (document.documentCategory?.id) {
            documentData.append(
                'documentCategoryId',
                document.documentCategory.id
            );
        }
        if (document.file.displayedName) {
            documentData.append('displayedName', document.file.displayedName);
        }
        documentData.append(
            'document_file_upload[file]',
            document.file.file,
            document.file.displayedName
        );

        const req = new HttpRequest(
            'POST',
            this.server + 'api/qmdocuments/',
            documentData
        );
        return this.http.request(req);
    }
}
