import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {
    DatePickerModule,
    DateTimePickerModule,
    TimePickerModule,
} from '@syncfusion/ej2-angular-calendars';
import {
    AutoCompleteModule,
    DropDownListModule,
} from '@syncfusion/ej2-angular-dropdowns';
import { FormlyModule } from '@ngx-formly/core';
import { MatButtonModule } from '@angular/material/button';
import { AccidentInsuranceAutocompleteWrapperComponent } from './formly-wrapper/accident-insurance-autocomplete-wrapper.component';
import { EmployeeAutocompleteWrapperComponent } from './formly-wrapper/employee-autocomplete-wrapper.component';
import { FacilityAutocompleteWrapperComponent } from './formly-wrapper/facility-autocomplete-wrapper.component';
import { PassengerAutocompleteWrapperComponent } from './formly-wrapper/passenger-autocomplete-wrapper.component';
import { HealthInsuranceAutocompleteWrapperComponent } from './formly-wrapper/health-insurance-autocomplete-wrapper.component';
import { TransportLocationAutocompleteWrapperComponent } from './formly-wrapper/transport-location-autocomplete-wrapper.component';
import { VehicleAutocompleteWrapperComponent } from './formly-wrapper/vehicle-autocomplete-wrapper.component';
import { CustomerAdminitrationAutocompleteWrapperComponent } from './formly-wrapper/customer-adminitration-autocomplete-wrapper.component';
import { TariffRepeaterFormlyTypeComponent } from './formly-types/tariff-repeater-formly-type.component';
import { DateTimeTypeComponent } from './formly-types/date-time-type.component';
import { DateTypeComponent } from './formly-types/date-type.component';
import { PanelWrapperComponent } from './formly-wrapper/panel-wrapper.component';
import { MatCardModule } from '@angular/material/card';
import { TariffAutocompleteWrapperComponent } from './formly-wrapper/tariff-autocomplete-wrapper.component';
import { PassengerTransferReasonAutocompleteWrapperComponent } from './formly-wrapper/passenger-transfer-reason-autocomplete-wrapper.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OptionRepeaterFormlyTypeComponent } from './formly-types/option-repeater-formly-type.component';
import { EditButtonTypeComponent } from './formly-types/edit-button-type.component';
import { RepeatSectionTypeComponent } from './formly-types/repeat-section-type.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DragDropRepeatSectionTypeComponent } from './formly-types/drag-drop-repeat-section-type.component';
import { TransportTimingsWrapperComponent } from './formly-wrapper/transport-timings-wrapper.component';
import { MatListModule } from '@angular/material/list';
import { ProtocolWrapperComponent } from './formly-wrapper/protocol-wrapper.component';
import { ProtocolRepeaterFormlyTypeComponent } from './formly-types/protocol-repeater-formly-type.component';
import { DefaultLocationsAutocompleteWrapperComponent } from './formly-wrapper/default-locations-autocomplete-wrapper.component';
import { AssignedAutocompleteWrapperComponent } from './formly-wrapper/assigned-autocomplete-wrapper.component';
import { TimeTypeComponent } from './formly-types/time-type.component';
import { BvgWrapperComponent } from './formly-wrapper/bvg-wrapper.component';
import { MatSelectModule } from '@angular/material/select';
import { ParentDocumentCategoryAutocompleteWrapperComponent } from './formly-wrapper/parent-document-category-autocomplete-wrapper.component';
import { InsuranceAutocompleteWrapperComponent } from './formly-wrapper/insurance-autocomplete-wrapper.component';
import { TranslocoModule } from '@ngneat/transloco';
import { StationAutocompleteWrapperComponent } from './formly-wrapper/station-autocomplete-wrapper.component';
import { TransportTimingsCustomerWrapperComponent } from './formly-wrapper/transport-timings-customer-wrapper.component';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TooltipWrapperComponent } from './formly-wrapper/tooltip-wrapper.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PassengerDetailsWrapperComponent } from './formly-wrapper/passenger-details-wrapper.component';
import { TransportLocationDetailsWrapperComponent } from './formly-wrapper/transport-location-details-wrapper.component';
import { FormlyFieldButtonComponent } from './formly-types/formly-field-button.component';
import { InvoiceItemRepeaterFormlyTypeComponent } from './formly-types/invoice-item-repeater-formly-type.component';
import { RepeatSectionWithButtonsTypeComponent } from './formly-types/repeat-section-with-buttons-type.component';
import { AutocompleteWrapperComponent } from './formly-wrapper/autocomplete-wrapper.component';

@NgModule({
    imports: [
        CommonModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatInputModule,
        DateTimePickerModule,
        DropDownListModule,
        FormlyModule,
        MatButtonModule,
        FlexLayoutModule,
        DatePickerModule,
        MatCardModule,
        AutoCompleteModule,
        MatIconModule,
        FormsModule,
        DragDropModule,
        MatListModule,
        TimePickerModule,
        MatSelectModule,
        TranslocoModule,
        NgxMatDatetimePickerModule,
        MatDatepickerModule,
        MatTooltipModule,
    ],
    declarations: [
        FacilityAutocompleteWrapperComponent,
        TransportLocationAutocompleteWrapperComponent,
        PassengerAutocompleteWrapperComponent,
        VehicleAutocompleteWrapperComponent,
        EmployeeAutocompleteWrapperComponent,
        HealthInsuranceAutocompleteWrapperComponent,
        AccidentInsuranceAutocompleteWrapperComponent,
        CustomerAdminitrationAutocompleteWrapperComponent,
        TariffAutocompleteWrapperComponent,
        TariffRepeaterFormlyTypeComponent,
        OptionRepeaterFormlyTypeComponent,
        PassengerTransferReasonAutocompleteWrapperComponent,
        ParentDocumentCategoryAutocompleteWrapperComponent,
        TooltipWrapperComponent,
        DateTimeTypeComponent,
        TimeTypeComponent,
        DateTypeComponent,
        PanelWrapperComponent,
        EditButtonTypeComponent,
        RepeatSectionTypeComponent,
        DragDropRepeatSectionTypeComponent,
        TransportTimingsWrapperComponent,
        TransportTimingsCustomerWrapperComponent,
        ProtocolWrapperComponent,
        ProtocolRepeaterFormlyTypeComponent,
        DefaultLocationsAutocompleteWrapperComponent,
        AssignedAutocompleteWrapperComponent,
        BvgWrapperComponent,
        InsuranceAutocompleteWrapperComponent,
        StationAutocompleteWrapperComponent,
        PassengerDetailsWrapperComponent,
        TransportLocationDetailsWrapperComponent,
        FormlyFieldButtonComponent,
        InvoiceItemRepeaterFormlyTypeComponent,
        RepeatSectionWithButtonsTypeComponent,
        AutocompleteWrapperComponent,
    ],
    exports: [],
})
export class FormlyCustomModule {}
