import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'sys-formly-drag-drop-repeat-section',
    styleUrls: ['./drag-drop-repeat-section-type.component.scss'],
    template: `
        <div
            cdkDropList
            class="drag-drop-list"
            (cdkDropListDropped)="drop($event)"
        >
            <div *ngIf="field?.fieldGroup?.length === 0" class="ml-8 mt-8">
                Keine Einträge vorhanden
            </div>
            <div
                *ngFor="let field of field.fieldGroup; let i = index"
                cdkDrag
                class="row drag-drop-box"
                [cdkDragDisabled]="to.disabled"
            >
                <formly-field class="w-100-p" [field]="field"></formly-field>
                <div class="ml-20">
                    <button
                        mat-raised-button
                        color="warn"
                        (click)="remove(i)"
                        [disabled]="
                            field.templateOptions?.disabled || to.disabled
                        "
                    >
                        {{ 'Löschen' | transloco }}
                    </button>
                </div>
            </div>
        </div>
        <div>
            <button
                mat-raised-button
                class="mt-20"
                color="primary"
                (click)="add()"
                [disabled]="field.templateOptions?.disabled"
            >
                {{ to.addText }}
            </button>
        </div>
    `,
})
export class DragDropRepeatSectionTypeComponent extends FieldArrayType {
    drop(event: CdkDragDrop<string[]>): void {
        if (this.to.disabled) {
            return;
        }
        if (!this.field?.fieldGroup) {
            return;
        }
        moveItemInArray(
            this.field.fieldGroup,
            event.previousIndex,
            event.currentIndex
        );
        this.checkField();
        this.form.markAsDirty();
    }

    // Trigger change for position update after drop
    private checkField(): void {
        (this.options as any)._checkField({
            fieldGroup: this.field?.fieldGroup,
            model: this.model,
            formControl: this.form,
            options: this.options,
        });
    }
}
