import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_BASE_URL_TOKEN } from '../injection-tokens';
import { Facility } from '../features/facilities/facility.model';
import { Distances } from '../features';

@Injectable({ providedIn: 'root' })
export class DistanceService {
    constructor(
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {}

    getDistance(
        start: string,
        end: string,
        facility?: Facility,
        isReturnTransport?: boolean
    ): Observable<any> {
        return this.http.post(this.server + 'api/google/distance', {
            start,
            end,
            facilityId: facility?.id,
            isReturnTransport,
        });
    }

    getDistances(
        start: string,
        end: string,
        facility?: Facility,
        isReturnTransport?: boolean
    ): Observable<Distances> {
        return this.http.post<Distances>(this.server + 'api/google/distances', {
            start,
            end,
            facilityId: facility?.id,
            isReturnTransport,
        });
    }
}
