import { TariffVersion } from './tariff-version.model';

export function createTariffVersion(
    tariffVersion: Partial<TariffVersion>
): TariffVersion | undefined {
    if (!tariffVersion.tariffId && !tariffVersion.manual) {
        return;
    }
    return {
        id: tariffVersion.id || undefined,
        tariffId: tariffVersion.tariffId || undefined,
        manual: tariffVersion.manual || false,
        version: tariffVersion.version || 1,
        lumpsums: tariffVersion.lumpsums || [],
        variableCosts: tariffVersion.variableCosts || [],
        variableCostsDegressive: tariffVersion.variableCostsDegressive || [],
        options: tariffVersion.options || [],
        nightFeePercent: tariffVersion.nightFeePercent || undefined,
        sundayHolidayFeePercent:
            tariffVersion.sundayHolidayFeePercent || undefined,
        nightFeeLump: tariffVersion.nightFeeLump || undefined,
        sundayHolidayFeeLump: tariffVersion.sundayHolidayFeeLump,
        saturdayFeeLump: tariffVersion.saturdayFeeLump,
        saturdayFeePercent: tariffVersion.saturdayFeePercent,
        urgentFeePercent: tariffVersion.urgentFeePercent,
        urgentFeeLump: tariffVersion.urgentFeeLump,
        onlineDiscount: tariffVersion.onlineDiscount,
        calculateFromFacility: tariffVersion.calculateFromFacility || false,
        roundUpTo5Rappen: tariffVersion.roundUpTo5Rappen || false,
        validFrom: tariffVersion.validFrom,
        mustSelectOneNotRequiredOption:
            tariffVersion.mustSelectOneNotRequiredOption || false,
    };
}
