import { FormlyFieldConfig } from '@ngx-formly/core';
import { Worktime, WorktimeType } from '@sansys/crosslib';
import { translate } from '@ngneat/transloco';

export function getWorktimeDailyFormConfig(
    worktimeTypes: WorktimeType[],
    m: Partial<Worktime>,
    onChangeWorktimeType: (field: FormlyFieldConfig) => void
): FormlyFieldConfig[] {
    const worktimeTypeOptions: { value: string; label: string }[] = [];
    worktimeTypes.forEach((worktimeType) =>
        worktimeTypeOptions.push({
            value: worktimeType.name,
            label: worktimeType.name,
        })
    );

    worktimeTypeOptions.push({
        value: 'Ausbezahlt Lohn Pikett',
        label: translate('Ausbezahlt Lohn Pikett'),
    });
    worktimeTypeOptions.push({
        value: 'Ausbezahlt Lohn',
        label: translate('Ausbezahlt Lohn'),
    });

    if (m?.worktimeType) {
        let currentWorktimeFound = false;

        worktimeTypeOptions.forEach((wT) => {
            if (wT.value === m.worktimeType) {
                currentWorktimeFound = true;
            }
        });

        if (!currentWorktimeFound) {
            worktimeTypeOptions.push({
                value: m.worktimeType,
                label: m.worktimeType,
            });
        }
    }

    return [
        {
            key: 'worktimeType',
            type: 'select',
            templateOptions: {
                label: translate('Arbeitstyp'),
                placeholder: translate('Arbeitstyp'),
                appearance: 'outline',
                floatLabel: 'always',
                required: true,
                options: worktimeTypeOptions,
                change: onChangeWorktimeType,
            },
        },
        {
            type: 'input',
            key: 'minutesPerDay',
            templateOptions: {
                label: translate('Zeit pro Tag'),
                appearance: 'outline',
                placeholder: translate('Format HH:MM'),
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'minutesPerDayInput',
                },
            },
        },
        {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
                {
                    className: 'flex-50 pr-12',
                    key: 'start',
                    type: 'datePickerType',
                    templateOptions: {
                        label: translate('Start'),
                        placeholder: translate('Start'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        required: true,
                    },
                },
                {
                    className: 'flex-50',
                    key: 'end',
                    type: 'datePickerType',
                    templateOptions: {
                        label: translate('Ende'),
                        placeholder: translate('Ende'),
                        appearance: 'outline',
                        floatLabel: 'always',
                    },
                },
            ],
        },
    ];
}
