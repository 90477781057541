import { Inject, Injectable } from '@angular/core';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
    EntityActionOptions,
} from '@ngrx/data';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TariffVersion } from './tariff-version.model';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';

@Injectable({ providedIn: 'root' })
export class TariffVersionEntityService extends EntityCollectionServiceBase<TariffVersion> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('TariffVersion', serviceElementsFactory);
    }

    getTariffVersionOfDate(
        tariffId: string,
        plannedStart?: string
    ): Observable<TariffVersion | null> {
        return this.http.get<TariffVersion | null>(
            this.server +
                'api/tariffversions/current/' +
                tariffId +
                '?plannedStart=' +
                plannedStart
        );
    }

    update(
        entity: Partial<TariffVersion>,
        options?: EntityActionOptions
    ): Observable<TariffVersion> {
        if (entity.validFrom) {
            entity.validFrom = moment(entity.validFrom).format('YYYY-MM-DD');
        }
        return super.update(entity, options);
    }
}
