import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Store } from '@ngrx/store';
import { errorOccured, showEntityAddOrEditPage } from '@sansys/crosslib';

@Component({
    selector: 'sys-formly-wrapper-panel',
    template: `
        <ng-container #fieldComponent></ng-container>
        <mat-card class="mb-12">
            <mat-card-header class="w-100-p">
                <mat-card-title>
                    {{ 'Daten' | transloco }} {{ 'passenger' | transloco }}
                    <div class="sys--float-right">
                        <button
                            mat-raised-button
                            (click)="useStartLocationAddress()"
                        >
                            {{ 'Startort Adresse übernehmen' | transloco }}
                        </button>
                        <button
                            class="ml-12"
                            mat-raised-button
                            (click)="useTargetLocationAddress()"
                        >
                            {{ 'Zielort Adresse übernehmen' | transloco }}
                        </button>
                        <button
                            class="ml-12"
                            mat-raised-button
                            (click)="showEditPassengerDialog()"
                        >
                            {{ 'Anpassen' | transloco }}
                        </button>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content *ngIf="to.transport?.passenger">
                <mat-list role="list">
                    <mat-list-item role="listitem">
                        {{ 'Name' | transloco }}:
                        {{ to.transport.passenger.firstName }}
                        {{ to.transport.passenger.lastName }}
                    </mat-list-item>
                    <mat-list-item role="listitem">
                        {{ 'Geburtstag' | transloco }}:
                        {{ to.transport.passenger.birthday | date }}
                    </mat-list-item>
                    <mat-list-item role="listitem">
                        {{ 'Strasse' | transloco }}:
                        {{ to.transport.passenger.street }}
                    </mat-list-item>
                    <mat-list-item role="listitem">
                        {{ 'PLZ / Ort' | transloco }}:
                        {{ to.transport.passenger.zip }}
                        {{ to.transport.passenger.city }}
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card>
    `,
})
export class PassengerDetailsWrapperComponent extends FieldWrapper {
    constructor(private store: Store<any>) {
        super();
    }

    showEditPassengerDialog(): void {
        this.store.dispatch(
            showEntityAddOrEditPage({
                entityType: 'passenger',
                entityId: this.to.transport.passenger.id,
            })
        );
    }

    useStartLocationAddress(): void {
        if (!this.to.transport.startLocation) {
            this.store.dispatch(
                errorOccured({ title: 'Info', message: 'Kein Startort' })
            );
            return;
        }

        this.store.dispatch(
            showEntityAddOrEditPage({
                entityType: 'passenger',
                entityId: this.to.transport.passenger.id,
                data: {
                    street: this.to.transport.startLocation.street,
                    zip: this.to.transport.startLocation.zip,
                    city: this.to.transport.startLocation.city,
                    nursingHome: this.to.transport.startLocation.companyName,
                },
            })
        );
    }

    useTargetLocationAddress(): void {
        if (!this.to.transport.targetLocation) {
            this.store.dispatch(
                errorOccured({ title: 'Info', message: 'Kein Zielort' })
            );
            return;
        }

        this.store.dispatch(
            showEntityAddOrEditPage({
                entityType: 'passenger',
                entityId: this.to.transport.passenger.id,
                data: {
                    street: this.to.transport.targetLocation.street,
                    zip: this.to.transport.targetLocation.zip,
                    city: this.to.transport.targetLocation.city,
                    nursingHome: this.to.transport.targetLocation.companyName,
                },
            })
        );
    }
}
