import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
    selector: 'sys-formly-wrapper-panel',
    template: `
        <span matTooltip="{{ to.tooltip || '' }}">
            <ng-container #fieldComponent></ng-container>
        </span>
    `,
})
export class TooltipWrapperComponent extends FieldWrapper {}
