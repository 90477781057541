import { ValueType } from '@syncfusion/ej2-grids/src/grid/base/type';

export function numericSortComparer(
    valueA: ValueType,
    valueB: ValueType
): number {
    if (typeof valueA === 'number') {
        valueA = valueA.toString();
    }
    if (typeof valueA !== 'string') {
        return -1;
    }
    if (typeof valueB === 'number') {
        valueB = valueB.toString();
    }
    if (typeof valueB !== 'string') {
        return -1;
    }
    const collator = new Intl.Collator('de', {
        numeric: true,
        sensitivity: 'base',
    });
    return collator.compare(valueA, valueB);
}
