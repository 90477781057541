import { Inject, Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
} from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoadingService } from '../common-services/loading.service';
import { errorOccured } from '../error-handling';
import { refresh, showRequestConfirmation } from './app.actions';
import { logout } from '../auth';
import { Store } from '@ngrx/store';
import { translate } from '@ngneat/transloco';
import { PLATFORM } from '../injection-tokens';

/**
 * This class is for intercepting http requests. When a request starts, we set the loadingSub property
 * in the LoadingService to true. Once the request completes and we have a response, set the loadingSub
 * property to false. If an error occurs while servicing the request, set the loadingSub property to false.
 * @class {HttpRequestInterceptor}
 */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    constructor(
        private _loading: LoadingService,
        private store: Store<any>,
        @Inject(PLATFORM) private platf: string
    ) {}

    private handleError(
        request: HttpRequest<any>,
        err: any
    ): Observable<HttpEvent<any>> {
        console.error('Error: ', err);
        console.error(typeof err);

        if (JSON.stringify(err).includes('ChunkLoadError')) {
            if (
                confirm(
                    'Es ist eine neue Version der Anwendung verfügbar. Seite neu laden?'
                )
            ) {
                window.location.reload();
            }
        }

        if (err.error?.message === 'Invalid credentials.') {
            this.store.dispatch(
                errorOccured({
                    message:
                        'Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre Zugangsdaten.',
                    title: 'Info',
                })
            );
            return EMPTY;
        }
        if (err.error?.message === 'Confirmation required') {
            this.store.dispatch(
                showRequestConfirmation({
                    foundResults: err.error.results,
                    originalRequest: request,
                    requestType:
                        err.error.requestType === 'transportInvoice'
                            ? 'transportInvoice'
                            : 'duplicate',
                })
            );
            return EMPTY;
        }
        if (
            this.platf === 'employee' &&
            typeof err.error?.error === 'string' &&
            err.error.error.includes(
                'Arbeitszeit konnte nicht erstellt werden. Es ist bereits ein Eintrag vorhanden'
            )
        ) {
            this.store.dispatch(
                showRequestConfirmation({
                    foundResults: err.error.error,
                    originalRequest: request,
                    requestType: 'worktime',
                })
            );
            return EMPTY;
        }
        // if (err.error?.message === 'Invoice sum changed confirmation required') {
        //     this.store.dispatch(
        //         showInvoiceSumChangedConfirmation({
        //             message: err.error.result,
        //             transportId: err.error.transportId
        //         })
        //     );
        //     return EMPTY;
        // }

        if (err.error?.error?.includes('wurde kein Nutzer gefunden')) {
            this.store.dispatch(
                errorOccured({
                    message: err.error.error,
                    title: 'Info',
                })
            );
            return EMPTY;
        }

        if (
            err.error?.error?.includes(
                'Arbeitszeit Änderungen sind nur für den aktuellen Monat erlaubt.'
            )
        ) {
            this.store.dispatch(
                errorOccured({
                    message:
                        'Arbeitszeit Änderungen sind nur für den aktuellen Monat erlaubt.',
                    title: 'Info',
                })
            );
            return EMPTY;
        }

        if (err.error?.error?.includes('Kundentyp ')) {
            this.store.dispatch(
                errorOccured({
                    message: err.error.error,
                    title: 'Info',
                })
            );
            return EMPTY;
        }

        if (
            err.error?.error?.includes('Deadlock found when trying to get lock')
        ) {
            this.store.dispatch(
                errorOccured({
                    message:
                        'Daten konnten nicht geschrieben werden. Bitte versuchen Sie es nochmals.',
                    title: 'Info',
                })
            );
            return EMPTY;
        }

        if (
            err.error?.error?.includes(
                'Bei gesetztem Team muss das Fahrzeug funktionstüchtig sein.'
            )
        ) {
            this.store.dispatch(
                errorOccured({
                    message: err.error.error,
                    title: 'Info',
                })
            );
            return EMPTY;
        }

        if (
            err.error &&
            JSON.stringify(err.error).includes('It should have 8 characters')
        ) {
            this.store.dispatch(
                errorOccured({
                    message:
                        'Passwort zu kurz. Bitte mindestens 8 Zeichen eingeben.',
                    title: 'Info',
                })
            );
            return EMPTY;
        }

        if (err.error?.error?.includes('Fehlende Berechtigung')) {
            this.store.dispatch(
                errorOccured({
                    message:
                        'Fehlende Berechtigung. Bitte wenden Sie sich an Ihren Administrator',
                    title: 'Info',
                })
            );
            return EMPTY;
        } else if (err.status === 401) {
            this.store.dispatch(logout());
            return EMPTY;
        }

        let message =
            err.error.message ||
            err.error.error ||
            err.statusText ||
            JSON.stringify(err);

        if (err.status === 400 && err.error.errors) {
            message = '';
            for (const k in err.error.errors) {
                if (err.error.errors.hasOwnProperty(k)) {
                    message +=
                        translate(k) + ': ' + err.error.errors[k] + '<br>';
                }
            }
            this.store.dispatch(
                errorOccured({
                    message,
                    title: 'Info',
                })
            );
            this.store.dispatch(refresh());
            return EMPTY;
        }

        if (err.status === 400) {
            this.store.dispatch(
                errorOccured({
                    message: err.error?.error,
                    title: 'Info',
                })
            );
            this.store.dispatch(refresh());
            return EMPTY;
        }

        if (err.error.errors) {
            message = '';
            for (const k in err.error.errors) {
                if (err.error.errors.hasOwnProperty(k)) {
                    message +=
                        translate(k) + ': ' + err.error.errors[k] + '<br>';
                }
            }
        }

        if (message.includes('username: Wert muss einmalig sein')) {
            this.store.dispatch(
                errorOccured({
                    message:
                        'Der Nutzername wird bereits verwenden. Bitte wählen Sie einen anderen.',
                    title: 'Info',
                })
            );
            this.store.dispatch(refresh());
            return EMPTY;
        }

        if (message.includes('Wert muss einmalig sein')) {
            this.store.dispatch(
                errorOccured({
                    message:
                        message +
                        ' <br><h2>Daten wurden nicht gespeichert. Bitte laden Sie die Seite neu.</h2>',
                    title: 'Info',
                })
            );
            this.store.dispatch(refresh());
            return EMPTY;
        }

        const errorMessage =
            '<h3>' +
            message +
            '</h3>' +
            '<br><br>' +
            'Vollständiger Fehler: <br>' +
            JSON.stringify(err) +
            '<br><br>' +
            'Ursprüngliche Anfrage:<br>' +
            JSON.stringify(request);

        const errorObject = {
            message: errorMessage,
            code: err.status,
            refresh: true,
        };

        this.store.dispatch(errorOccured(errorObject));

        return EMPTY;
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const setLoading = !request.urlWithParams.includes(
            'ignoreRequest=true'
        );
        if (setLoading) {
            this._loading.setLoading(true, request.url);
        }
        return next
            .handle(request)
            .pipe(
                catchError((err) => {
                    if (setLoading) {
                        this._loading.setLoading(false, request.url);
                    }
                    return this.handleError(request, err);
                })
            )
            .pipe(
                map<any, any>((evt: HttpEvent<any>) => {
                    if (evt instanceof HttpResponse && setLoading) {
                        this._loading.setLoading(false, request.url);
                    }
                    return evt;
                })
            );
    }
}
