import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { errorOccured, FileUpload } from '@sansys/crosslib';
import { Store } from '@ngrx/store';

@Component({
    selector: 'sys-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
    fileurl: string | ArrayBuffer | undefined | null;
    constructor(
        public dialogRef: MatDialogRef<FileUploadComponent>,
        private store: Store<any>,
        @Inject(MAT_DIALOG_DATA) public data: FileUpload
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

    getFileFromInput(event: any): void {
        const files = event.target['files'];
        if (!files) {
            return;
        }
        const file = files.item(0);
        if (!file) {
            return;
        }

        if (
            (this.data.docType === 'profileImage' ||
                this.data.docType === 'image') &&
            !file.type.includes('image')
        ) {
            this.store.dispatch(
                errorOccured({
                    message: 'Nur Bilder sind für diese Dateiart erlaubt.',
                })
            );
            return;
        }

        const reader = new FileReader();

        reader.onload = (e) => {
            this.fileurl = e.target?.result;
        };

        reader.readAsDataURL(file);

        this.data.file = file;
    }
}
