import * as moment from 'moment';
import { Distances, TransportModel } from '../transport-entity.model';
import { Moment } from 'moment';

export function getCalculatedTimingsAsMoment(
    transport: TransportModel,
    loadingTime: number,
    type:
        | 'departureFacility'
        | 'arrivalStart'
        | 'departureStart'
        | 'arrivalDestination'
        | 'departureDestination'
        | 'arrivalStartReturn'
        | 'teamReady'
        | 'arrivalFacility'
): Moment | undefined {
    const waitingTime = transport.waitingTimeUntilReturn
        ? transport.waitingTimeUntilReturn
        : loadingTime;
    switch (type) {
        case 'departureFacility':
            if (transport.transportStart) {
                return moment(transport.transportStart);
            }
            if (
                transport.orderedStart &&
                transport.distances?.facilityToStartSeconds
            ) {
                return moment(transport.orderedStart).subtract(
                    transport.distances?.facilityToStartSeconds
                        ? transport.distances?.facilityToStartSeconds / 60
                        : 0,
                    'minutes'
                );
            }
            if (
                transport.plannedStart &&
                transport.distances?.facilityToStartSeconds
            ) {
                return moment(transport.plannedStart).subtract(
                    transport.distances?.facilityToStartSeconds
                        ? transport.distances?.facilityToStartSeconds / 60
                        : 0,
                    'minutes'
                );
            }
            return undefined;
        case 'arrivalStart':
            if (transport.arrivalStart) {
                return moment(transport.arrivalStart);
            }
            if (transport.plannedStart) {
                return moment(transport.plannedStart);
            }
            return moment(transport.orderedStart);
        case 'departureStart':
            if (transport.departureStart) {
                return moment(transport.departureStart);
            }
            if (transport.arrivalStart) {
                return moment(transport.arrivalStart).add(
                    loadingTime,
                    'minutes'
                );
            }
            if (transport.orderedStart) {
                return moment(transport.orderedStart).add(
                    loadingTime,
                    'minutes'
                );
            }
            return moment(transport.plannedStart).add(loadingTime, 'minutes');
        case 'arrivalDestination':
            if (transport.arrivalDestination) {
                return moment(transport.arrivalDestination);
            }
            if (transport.departureStart) {
                return moment(transport.departureStart).add(
                    transport.distances?.startToTargetSeconds
                        ? transport.distances?.startToTargetSeconds / 60
                        : 0,
                    'minutes'
                );
            }
            if (transport.arrivalStart) {
                return moment(transport.arrivalStart)
                    .add(loadingTime, 'minutes')
                    .add(
                        transport.distances?.startToTargetSeconds
                            ? transport.distances?.startToTargetSeconds / 60
                            : 0,
                        'minutes'
                    );
            }
            if (transport.plannedStart) {
                return moment(transport.plannedStart)
                    .add(loadingTime, 'minutes')
                    .add(
                        transport.distances?.startToTargetSeconds
                            ? transport.distances?.startToTargetSeconds / 60
                            : 0,
                        'minutes'
                    );
            }
            return moment(transport.orderedStart)
                .add(loadingTime, 'minutes')
                .add(
                    transport.distances?.startToTargetSeconds
                        ? transport.distances?.startToTargetSeconds / 60
                        : 0,
                    'minutes'
                );
        case 'departureDestination':
            if (transport.departureDestination) {
                return moment(transport.departureDestination);
            }
            if (transport.arrivalDestination) {
                return moment(transport.arrivalDestination).add(
                    waitingTime,
                    'minutes'
                );
            }
            if (transport.departureStart) {
                return moment(transport.departureStart)
                    .add(
                        transport.distances?.startToTargetSeconds
                            ? transport.distances?.startToTargetSeconds / 60
                            : 0,
                        'minutes'
                    )
                    .add(waitingTime, 'minutes');
            }
            if (transport.arrivalStart) {
                return moment(transport.arrivalStart)
                    .add(loadingTime, 'minutes')
                    .add(
                        transport.distances?.startToTargetSeconds
                            ? transport.distances?.startToTargetSeconds / 60
                            : 0,
                        'minutes'
                    )
                    .add(waitingTime, 'minutes');
            }
            if (transport.plannedStart) {
                return moment(transport.plannedStart)
                    .add(loadingTime, 'minutes')
                    .add(
                        transport.distances?.startToTargetSeconds
                            ? transport.distances?.startToTargetSeconds / 60
                            : 0,
                        'minutes'
                    )
                    .add(waitingTime, 'minutes');
            }
            return moment(transport.orderedStart)
                .add(loadingTime, 'minutes')
                .add(
                    transport.distances?.startToTargetSeconds
                        ? transport.distances?.startToTargetSeconds / 60
                        : 0,
                    'minutes'
                )
                .add(waitingTime, 'minutes');
        case 'arrivalStartReturn':
            if (transport.arrivalStartReturn) {
                return moment(transport.arrivalStartReturn);
            }
            if (transport.departureDestination) {
                return moment(transport.departureDestination).add(
                    transport.distances?.startToTargetSeconds
                        ? transport.distances?.startToTargetSeconds / 60
                        : 0,
                    'minutes'
                );
            }
            if (transport.arrivalDestination) {
                return moment(transport.arrivalDestination)
                    .add(waitingTime, 'minutes')
                    .add(
                        transport.distances?.startToTargetSeconds
                            ? transport.distances?.startToTargetSeconds / 60
                            : 0,
                        'minutes'
                    );
            }
            if (transport.departureStart) {
                return moment(transport.departureStart)
                    .add(
                        transport.distances?.startToTargetSeconds
                            ? transport.distances?.startToTargetSeconds / 60
                            : 0,
                        'minutes'
                    )
                    .add(waitingTime, 'minutes')
                    .add(
                        transport.distances?.startToTargetSeconds
                            ? transport.distances?.startToTargetSeconds / 60
                            : 0,
                        'minutes'
                    );
            }
            if (transport.arrivalStart) {
                return moment(transport.arrivalStart)
                    .add(loadingTime, 'minutes')
                    .add(
                        transport.distances?.startToTargetSeconds
                            ? transport.distances?.startToTargetSeconds / 60
                            : 0,
                        'minutes'
                    )
                    .add(waitingTime, 'minutes')
                    .add(
                        transport.distances?.startToTargetSeconds
                            ? transport.distances?.startToTargetSeconds / 60
                            : 0,
                        'minutes'
                    );
            }
            if (transport.plannedStart) {
                return moment(transport.plannedStart)
                    .add(loadingTime, 'minutes')
                    .add(
                        transport.distances?.startToTargetSeconds
                            ? transport.distances?.startToTargetSeconds / 60
                            : 0,
                        'minutes'
                    )
                    .add(waitingTime, 'minutes')
                    .add(
                        transport.distances?.startToTargetSeconds
                            ? transport.distances?.startToTargetSeconds / 60
                            : 0,
                        'minutes'
                    );
            }
            return moment(transport.orderedStart)
                .add(loadingTime, 'minutes')
                .add(
                    transport.distances?.startToTargetSeconds
                        ? transport.distances?.startToTargetSeconds / 60
                        : 0,
                    'minutes'
                )
                .add(waitingTime, 'minutes')
                .add(
                    transport.distances?.startToTargetSeconds
                        ? transport.distances?.startToTargetSeconds / 60
                        : 0,
                    'minutes'
                );
        case 'teamReady':
            if (transport.teamReady) {
                return moment(transport.teamReady);
            }
            if (transport.arrivalStartReturn && transport.isReturnTransport) {
                return moment(transport.arrivalStartReturn).add(
                    loadingTime,
                    'minutes'
                );
            }

            if (transport.departureDestination && transport.isReturnTransport) {
                return moment(transport.departureDestination)
                    .add(
                        transport.distances?.startToTargetSeconds
                            ? transport.distances?.startToTargetSeconds / 60
                            : 0,
                        'minutes'
                    )
                    .add(loadingTime, 'minutes');
            }

            if (transport.arrivalDestination) {
                if (transport.isReturnTransport) {
                    return moment(transport.arrivalDestination)
                        .add(waitingTime, 'minutes')
                        .add(
                            transport.distances?.startToTargetSeconds
                                ? transport.distances?.startToTargetSeconds / 60
                                : 0,
                            'minutes'
                        )
                        .add(loadingTime, 'minutes');
                }
                return moment(transport.arrivalDestination).add(
                    loadingTime,
                    'minutes'
                );
            }

            if (transport.departureStart) {
                if (transport.isReturnTransport) {
                    return moment(transport.departureStart)
                        .add(
                            transport.distances?.startToTargetSeconds
                                ? transport.distances?.startToTargetSeconds / 60
                                : 0,
                            'minutes'
                        )
                        .add(waitingTime, 'minutes')
                        .add(
                            transport.distances?.startToTargetSeconds
                                ? transport.distances?.startToTargetSeconds / 60
                                : 0,
                            'minutes'
                        )
                        .add(loadingTime, 'minutes');
                }
                return moment(transport.departureStart)
                    .add(
                        transport.distances?.startToTargetSeconds
                            ? transport.distances?.startToTargetSeconds / 60
                            : 0,
                        'minutes'
                    )
                    .add(loadingTime, 'minutes');
            }

            if (transport.arrivalStart) {
                if (transport.isReturnTransport) {
                    return moment(transport.arrivalStart)
                        .add(loadingTime, 'minutes')
                        .add(
                            transport.distances?.startToTargetSeconds
                                ? transport.distances?.startToTargetSeconds / 60
                                : 0,
                            'minutes'
                        )
                        .add(waitingTime, 'minutes')
                        .add(
                            transport.distances?.startToTargetSeconds
                                ? transport.distances?.startToTargetSeconds / 60
                                : 0,
                            'minutes'
                        )
                        .add(loadingTime, 'minutes');
                }
                return moment(transport.arrivalStart)
                    .add(loadingTime, 'minutes')
                    .add(
                        transport.distances?.startToTargetSeconds
                            ? transport.distances?.startToTargetSeconds / 60
                            : 0,
                        'minutes'
                    )
                    .add(waitingTime, 'minutes');
            }

            if (transport.plannedStart) {
                if (transport.isReturnTransport) {
                    return moment(transport.plannedStart)
                        .add(loadingTime, 'minutes')
                        .add(
                            transport.distances?.startToTargetSeconds
                                ? transport.distances?.startToTargetSeconds / 60
                                : 0,
                            'minutes'
                        )
                        .add(waitingTime, 'minutes')
                        .add(
                            transport.distances?.startToTargetSeconds
                                ? transport.distances?.startToTargetSeconds / 60
                                : 0,
                            'minutes'
                        )
                        .add(loadingTime, 'minutes');
                }
                return moment(transport.plannedStart)
                    .add(loadingTime, 'minutes')
                    .add(
                        transport.distances?.startToTargetSeconds
                            ? transport.distances?.startToTargetSeconds / 60
                            : 0,
                        'minutes'
                    )
                    .add(loadingTime, 'minutes');
            }

            const teamReadyTime = moment(transport.orderedStart)
                .add(loadingTime, 'minutes')
                .add(
                    transport.distances?.startToTargetSeconds
                        ? transport.distances?.startToTargetSeconds / 60
                        : 0,
                    'minutes'
                );

            if (transport.isReturnTransport) {
                teamReadyTime
                    .add(loadingTime, 'minutes')
                    .add(
                        transport.distances?.startToTargetSeconds
                            ? transport.distances?.startToTargetSeconds / 60
                            : 0,
                        'minutes'
                    );
            }
            return teamReadyTime.add(waitingTime, 'minutes');
        case 'arrivalFacility':
            if (transport.transportFinished) {
                return moment(transport.transportFinished);
            }
            const teamReady = getCalculatedTimingsAsMoment(
                transport,
                loadingTime,
                'teamReady'
            );
            if (teamReady) {
                if (transport.isReturnTransport) {
                    return teamReady.add(
                        transport.distances?.facilityToStartSeconds
                            ? transport.distances?.facilityToStartSeconds / 60
                            : 0,
                        'minutes'
                    );
                }
                return teamReady.add(
                    transport.distances?.targetToFacilitySeconds
                        ? transport.distances?.targetToFacilitySeconds / 60
                        : 0,
                    'minutes'
                );
            }
            return undefined;
    }

    return undefined;
}

export function getCalculatedTimings(
    transport: TransportModel,
    loadingTime: number,
    type:
        | 'departureFacility'
        | 'arrivalStart'
        | 'departureStart'
        | 'arrivalDestination'
        | 'departureDestination'
        | 'arrivalStartReturn'
        | 'teamReady'
        | 'arrivalFacility'
): string {
    const mom = getCalculatedTimingsAsMoment(transport, loadingTime, type);
    if (!mom) {
        return 'Entfernungen konnten nicht berechnet werden';
    }
    return mom.format('HH:mm') + ' Uhr';
}

export function getCalculatedDuration(
    transport: TransportModel,
    loadingTime: number,
    type:
        | 'returnStart'
        | 'arrivalStart'
        | 'arrivalTarget'
        | 'facilityToStart'
        | 'startToTarget'
        | 'targetToStart'
        | 'startToFacility'
        | 'targetToFacility'
): number {
    switch (type) {
        case 'arrivalStart':
            if (transport.arrivalStart && transport.departureStart) {
                return moment(transport.departureStart).diff(
                    transport.arrivalStart,
                    'minutes'
                );
            }
            return loadingTime;
        case 'arrivalTarget':
            if (
                transport.arrivalDestination &&
                transport.departureDestination
            ) {
                return moment(transport.departureDestination).diff(
                    transport.arrivalDestination,
                    'minutes'
                );
            }
            if (transport.arrivalDestination && transport.teamReady) {
                return moment(transport.teamReady).diff(
                    transport.arrivalDestination,
                    'minutes'
                );
            }
            return transport.waitingTimeUntilReturn
                ? transport.waitingTimeUntilReturn
                : loadingTime;
        case 'returnStart':
            if (transport.arrivalStartReturn && transport.teamReady) {
                return moment(transport.teamReady).diff(
                    transport.arrivalStartReturn,
                    'minutes'
                );
            }
            return loadingTime;
        case 'facilityToStart':
            if (transport.transportStart && transport.arrivalStart) {
                return moment(transport.arrivalStart).diff(
                    transport.transportStart,
                    'minutes'
                );
            }
            return transport.distances?.facilityToStartSeconds
                ? Math.ceil(transport.distances.facilityToStartSeconds / 60)
                : 0;
        case 'startToTarget':
            if (transport.departureStart && transport.arrivalDestination) {
                return moment(transport.arrivalDestination).diff(
                    transport.departureStart,
                    'minutes'
                );
            }
            return transport.distances?.startToTargetSeconds
                ? Math.ceil(transport.distances.startToTargetSeconds / 60)
                : 0;
        case 'targetToStart':
            if (
                transport.departureDestination &&
                transport.arrivalStartReturn
            ) {
                return moment(transport.arrivalStartReturn).diff(
                    transport.departureDestination,
                    'minutes'
                );
            }
            return transport.distances?.startToTargetSeconds
                ? Math.ceil(transport.distances.startToTargetSeconds / 60)
                : 0;
        case 'startToFacility':
            if (transport.teamReady && transport.transportFinished) {
                return moment(transport.transportFinished).diff(
                    transport.teamReady,
                    'minutes'
                );
            }
            return transport.distances?.facilityToStartSeconds
                ? Math.ceil(transport.distances.facilityToStartSeconds / 60)
                : 0;
        case 'targetToFacility':
            if (transport.teamReady && transport.transportFinished) {
                return moment(transport.transportFinished).diff(
                    transport.teamReady,
                    'minutes'
                );
            }
            return transport.distances?.targetToFacilitySeconds
                ? Math.ceil(transport.distances.targetToFacilitySeconds / 60)
                : 0;
    }

    return loadingTime;
}

export function getCalculatedTransportStartDateObject(
    transport: TransportModel
): Date {
    if (transport.transportStart) {
        return moment(transport.transportStart).toDate();
    }
    if (transport.arrivalStart) {
        return moment(transport.arrivalStart).toDate();
    }
    if (transport.plannedStart) {
        return moment(transport.plannedStart).toDate();
    }
    return moment(transport.orderedStart).toDate();
}

export function getCalculatedTransportEndDateObject(
    transport: TransportModel,
    loadingTime: number
): Date {
    const waitingTimeAtStart = transport.waitingTimeUntilReturn
        ? transport.waitingTimeUntilReturn
        : loadingTime;
    let calculatedOneWayDurationInMinutes = 0;
    if (transport.distances?.startToTargetSeconds) {
        calculatedOneWayDurationInMinutes =
            transport.distances.startToTargetSeconds / 60;
    }

    if (transport.transportFinished) {
        return moment(transport.transportFinished).toDate();
    }

    if (transport.teamReady) {
        return moment(transport.teamReady).toDate();
    }

    if (transport.arrivalStartReturn && transport.isReturnTransport) {
        return moment(transport.arrivalStartReturn)
            .add(waitingTimeAtStart, 'minutes')
            .toDate();
    }

    if (transport.departureDestination && transport.isReturnTransport) {
        return moment(transport.departureDestination)
            .add(calculatedOneWayDurationInMinutes, 'minutes')
            .add(waitingTimeAtStart, 'minutes')
            .toDate();
    }

    if (transport.arrivalDestination) {
        if (transport.isReturnTransport) {
            return moment(transport.arrivalDestination)
                .add(loadingTime, 'minutes')
                .add(calculatedOneWayDurationInMinutes, 'minutes')
                .add(waitingTimeAtStart, 'minutes')
                .toDate();
        }
        return moment(transport.arrivalDestination)
            .add(loadingTime, 'minutes')
            .toDate();
    }

    if (transport.departureStart) {
        if (transport.isReturnTransport) {
            return moment(transport.departureStart)
                .add(calculatedOneWayDurationInMinutes, 'minutes')
                .add(waitingTimeAtStart, 'minutes')
                .add(calculatedOneWayDurationInMinutes, 'minutes')
                .add(loadingTime, 'minutes')
                .toDate();
        }
        return moment(transport.departureStart)
            .add(calculatedOneWayDurationInMinutes, 'minutes')
            .add(loadingTime, 'minutes')
            .toDate();
    }

    if (transport.arrivalStart) {
        if (transport.isReturnTransport) {
            return moment(transport.arrivalStart)
                .add(loadingTime, 'minutes')
                .add(calculatedOneWayDurationInMinutes, 'minutes')
                .add(waitingTimeAtStart, 'minutes')
                .add(calculatedOneWayDurationInMinutes, 'minutes')
                .add(loadingTime, 'minutes')
                .toDate();
        }
        return moment(transport.arrivalStart)
            .add(loadingTime, 'minutes')
            .add(calculatedOneWayDurationInMinutes, 'minutes')
            .add(loadingTime, 'minutes')
            .toDate();
    }

    if (transport.transportStart) {
        const teamReadyTimePl = moment(transport.transportStart)
            .add(loadingTime, 'minutes')
            .add(calculatedOneWayDurationInMinutes, 'minutes');

        if (transport.isReturnTransport) {
            teamReadyTimePl
                .add(waitingTimeAtStart, 'minutes')
                .add(calculatedOneWayDurationInMinutes, 'minutes');
        }
        return teamReadyTimePl.add(loadingTime, 'minutes').toDate();
    }

    if (transport.plannedStart) {
        const teamReadyTimePl = moment(transport.plannedStart)
            .add(loadingTime, 'minutes')
            .add(calculatedOneWayDurationInMinutes, 'minutes');

        if (transport.isReturnTransport) {
            teamReadyTimePl
                .add(waitingTimeAtStart, 'minutes')
                .add(calculatedOneWayDurationInMinutes, 'minutes');
        }
        return teamReadyTimePl.add(loadingTime, 'minutes').toDate();
    }

    const teamReadyTime = moment(transport.orderedStart)
        .add(loadingTime, 'minutes')
        .add(calculatedOneWayDurationInMinutes, 'minutes');

    if (transport.isReturnTransport) {
        teamReadyTime
            .add(waitingTimeAtStart, 'minutes')
            .add(calculatedOneWayDurationInMinutes, 'minutes');
    }
    return teamReadyTime.add(loadingTime, 'minutes').toDate();
}

export function getCalculatedTransportEndDateObjectFromTransportAndDistances(
    transport: TransportModel,
    distances: Distances,
    loadingTime: number
): Date {
    let calculatedOneWayDurationInMinutes = 0;
    if (transport.distances?.startToTargetSeconds) {
        calculatedOneWayDurationInMinutes =
            transport.distances.startToTargetSeconds / 60;
    }

    if (transport.transportFinished) {
        return moment(transport.transportFinished).toDate();
    }

    if (transport.teamReady) {
        return moment(transport.teamReady).toDate();
    }

    if (transport.arrivalStartReturn && transport.isReturnTransport) {
        return moment(transport.arrivalStartReturn)
            .add(loadingTime, 'minutes')
            .toDate();
    }

    if (transport.departureDestination && transport.isReturnTransport) {
        return moment(transport.departureDestination)
            .add(calculatedOneWayDurationInMinutes, 'minutes')
            .add(loadingTime, 'minutes')
            .toDate();
    }

    if (transport.arrivalDestination) {
        if (transport.isReturnTransport) {
            return moment(transport.arrivalDestination)
                .add(loadingTime, 'minutes')
                .add(calculatedOneWayDurationInMinutes, 'minutes')
                .add(loadingTime, 'minutes')
                .toDate();
        }
        return moment(transport.arrivalDestination)
            .add(loadingTime, 'minutes')
            .toDate();
    }

    if (transport.departureStart) {
        if (transport.isReturnTransport) {
            return moment(transport.departureStart)
                .add(calculatedOneWayDurationInMinutes, 'minutes')
                .add(loadingTime, 'minutes')
                .add(calculatedOneWayDurationInMinutes, 'minutes')
                .add(loadingTime, 'minutes')
                .toDate();
        }
        return moment(transport.departureStart)
            .add(calculatedOneWayDurationInMinutes, 'minutes')
            .add(loadingTime, 'minutes')
            .toDate();
    }

    if (transport.arrivalStart) {
        if (transport.isReturnTransport) {
            return moment(transport.arrivalStart)
                .add(loadingTime, 'minutes')
                .add(calculatedOneWayDurationInMinutes, 'minutes')
                .add(loadingTime, 'minutes')
                .add(calculatedOneWayDurationInMinutes, 'minutes')
                .add(loadingTime, 'minutes')
                .toDate();
        }
        return moment(transport.arrivalStart)
            .add(loadingTime, 'minutes')
            .add(calculatedOneWayDurationInMinutes, 'minutes')
            .add(loadingTime, 'minutes')
            .toDate();
    }

    const teamReadyTime = moment(transport.plannedStart)
        .add(loadingTime, 'minutes')
        .add(calculatedOneWayDurationInMinutes, 'minutes');

    if (transport.isReturnTransport) {
        teamReadyTime
            .add(loadingTime, 'minutes')
            .add(calculatedOneWayDurationInMinutes, 'minutes');
    }

    return teamReadyTime.add(loadingTime, 'minutes').toDate();
}

export function getDistanceFromTransportInMeters(
    transport: TransportModel
): number | undefined {
    const calculateFromFacility = !!transport.currentTariffVersion
        ?.calculateFromFacility;
    const isReturn = !!transport.isReturnTransport;
    const distances = transport.distances;
    const localService = !!transport.localService;
    if (distances === undefined) {
        return;
    }

    if (localService && calculateFromFacility) {
        return (
            distances.facilityToStartMeters +
            distances.startToTargetMeters +
            distances.facilityToStartMeters
        );
    }
    if (localService) {
        return distances.startToTargetMeters;
    }

    if (calculateFromFacility && isReturn) {
        return (
            distances.facilityToStartMeters +
            distances.startToTargetMeters +
            distances.startToTargetMeters +
            distances.facilityToStartMeters
        );
    }
    if (calculateFromFacility) {
        return (
            distances.facilityToStartMeters +
            distances.startToTargetMeters +
            distances.targetToFacilityMeters
        );
    }

    if (isReturn) {
        return distances.startToTargetMeters + distances.startToTargetMeters;
    }

    return distances.startToTargetMeters;
}
