import { Inject, Injectable } from '@angular/core';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { refresh } from '../../state/app.actions';
import { EmployeeDeduction } from './employee-deduction-model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';

@Injectable({ providedIn: 'root' })
export class EmployeeDeductionEntityService extends EntityCollectionServiceBase<EmployeeDeduction> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions,
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('EmployeeDeduction', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    printSummary(year: number): Observable<string> {
        return this.http.get<string>(
            this.server + 'api/employeedeductions/printsummary/' + year
        );
    }

    printSalaryCertificates(year: number): Observable<string> {
        return this.http.get<string>(
            this.server +
                'api/employeedeductions/printsalarycertificates/' +
                year
        );
    }
}
