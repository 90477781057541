import { Device } from './device-entity.model';
import {
    DeviceSortType,
    ListOptionsType,
} from '../warehouse/list-option.types';

export function sortDevices(
    devices: Device[],
    sortType: DeviceSortType
): Device[] {
    const collator = new Intl.Collator('de', {
        numeric: true,
        sensitivity: 'base',
    });

    switch (sortType) {
        case 'name':
            return devices.sort((a, b) => collator.compare(a.name, b.name));
        case 'home':
            return devices.sort((a, b) =>
                collator.compare(a.home || '', b.home || '')
            );
        case 'itemNumber':
            return devices.sort((a, b) =>
                collator.compare(a.itemNumber || '', b.itemNumber || '')
            );
    }

    return devices;
}

export function filterDevices(
    devices: Device[],
    filter: string,
    listOptions: ListOptionsType,
    currentUserName: string
): Device[] {
    let filteredDevices = [...devices];

    if (
        listOptions.deviceFilters.onlyMine &&
        listOptions.deviceFilters.onlyWithMe
    ) {
        filteredDevices = filteredDevices.filter(
            (device) =>
                device.home === currentUserName ||
                device.owner === currentUserName
        );
    } else if (listOptions.deviceFilters.onlyWithMe) {
        filteredDevices = filteredDevices.filter(
            (device) => device.owner === currentUserName
        );
    } else if (listOptions.deviceFilters.onlyMine) {
        filteredDevices = filteredDevices.filter(
            (device) => device.home === currentUserName
        );
    }

    if (listOptions.deviceFilters.onlyWorking) {
        filteredDevices = filteredDevices.filter(
            (device) => !device.notWorking
        );
    }

    if (listOptions.deviceFilters.onlyNotWorking) {
        filteredDevices = filteredDevices.filter((device) => device.notWorking);
    }

    if (!filter) {
        return filteredDevices;
    }

    return filteredDevices.filter((entity) => {
        let haystack = '';
        switch (listOptions.deviceSearchType) {
            case 'name':
                haystack = entity.name;
                break;
            case 'location':
                if (entity.home) {
                    haystack += entity.home;
                }
                if (entity.owner) {
                    haystack += entity.owner;
                }
                break;
            case 'itemNumber':
                haystack = entity.itemNumber || '';
                break;
            default:
                haystack =
                    entity.name +
                    entity.note +
                    entity.itemNumber +
                    entity.manufacturerNumber +
                    entity.barcode +
                    entity.manufacturerName +
                    entity.owner +
                    entity.home;
        }

        const position = haystack.toLowerCase().indexOf(filter);
        return position > -1;
    });
}
