<div id="fuse-shortcuts" #shortcuts>
    <div class="shortcuts" fxLayout="row">
        <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxFlex="0 1 auto"
        >
            <div fxLayout="row" fxLayoutAlign="start center">
                <div
                    class="w-40 h-40 p-4"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    *ngFor="let shortcutItem of shortcutItems"
                >
                    <a
                        mat-icon-button
                        matTooltip="{{
                            getItemInfo(shortcutItem.id, 'title') | transloco
                        }}"
                        [routerLink]="shortcutItem.url"
                    >
                        <mat-icon
                            class="secondary-text"
                            *ngIf="getItemInfo(shortcutItem.id, 'fa_icon')"
                            style="font-size: 1.2em; display: inline"
                            fontSet="fa"
                            fontIcon="{{
                                getItemInfo(shortcutItem.id, 'fa_icon')
                            }}"
                        ></mat-icon>
                        <span
                            *ngIf="!getItemInfo(shortcutItem.id, 'fa_icon')"
                            class="h2 secondary-text text-bold"
                        >
                            {{
                                getItemInfo(shortcutItem.id, 'title')
                                    .substr(0, 1)
                                    .toUpperCase()
                            }}
                        </span>
                    </a>
                </div>

                <button
                    mat-icon-button
                    [matMenuTriggerFor]="addMenu"
                    matTooltip="{{ 'Favoriten verwalten' | transloco }}"
                    (menuOpened)="onMenuOpen()"
                >
                    <span class="fas fa-star star-icon"></span>
                </button>
            </div>
        </div>

        <mat-menu #addMenu="matMenu" class="w-240">
            <mat-form-field
                class="px-16 w-100-p"
                (click)="$event.stopPropagation()"
                floatLabel="never"
            >
                <input
                    #searchInput
                    matInput
                    [placeholder]="'Seite suchen' | transloco"
                    autocomplete="off"
                    (input)="search($event)"
                />
            </mat-form-field>

            <mat-divider></mat-divider>

            <mat-nav-list
                *ngIf="!searching"
                style="max-height: 312px; overflow: auto"
            >
                <mat-list-item
                    *ngFor="let shortcutItem of shortcutItems"
                    (click)="toggleShortcut($event, shortcutItem)"
                >
                    <div
                        class="w-100-p"
                        fxLayout="row"
                        fxLayoutAlign="start center"
                    >
                        <mat-icon
                            *ngIf="getItemInfo(shortcutItem.id, 'fa_icon')"
                            mat-list-icon
                            class="mr-8 secondary-text"
                            style="font-size: 1.2em; display: inline"
                            fontSet="fa"
                            fontIcon="{{
                                getItemInfo(shortcutItem.id, 'fa_icon')
                            }}"
                        >
                        </mat-icon>

                        <span
                            class="h2 w-32 h-32 p-4 mr-8 secondary-text text-bold"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            *ngIf="!getItemInfo(shortcutItem.id, 'fa_icon')"
                        >
                            {{
                                getItemInfo(shortcutItem.id, 'title')
                                    .substr(0, 1)
                                    .toUpperCase() | transloco
                            }}
                        </span>

                        <p matLine fxFlex>
                            {{
                                getItemInfo(shortcutItem.id, 'title')
                                    | transloco
                            }}
                        </p>

                        <span class="fas fa-star star-icon"></span>
                    </div>
                </mat-list-item>

                <mat-list-item *ngIf="shortcutItems.length === 0">
                    <p>
                        <small>{{
                            'Bitte gewünschten Menü-Eintrag eingeben'
                                | transloco
                        }}</small>
                    </p>
                </mat-list-item>
            </mat-nav-list>

            <mat-nav-list
                *ngIf="searching"
                style="max-height: 312px; overflow: auto"
            >
                <mat-list-item
                    *ngFor="let navigationItem of filteredNavigationItems"
                    (click)="toggleShortcut($event, navigationItem)"
                >
                    <div
                        class="w-100-p"
                        fxLayout="row"
                        fxLayoutAlign="start center"
                    >
                        <mat-icon
                            mat-list-icon
                            class="mr-8 secondary-text"
                            *ngIf="getItemInfo(navigationItem.id, 'fa_icon')"
                            style="font-size: 1.2em; display: inline"
                            fontSet="fa"
                            fontIcon="{{
                                getItemInfo(navigationItem.id, 'fa_icon')
                            }}"
                        >
                        </mat-icon>

                        <span
                            class="h2 w-32 h-32 p-4 mr-8 secondary-text text-bold"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            *ngIf="!getItemInfo(navigationItem.id, 'fa_icon')"
                        >
                            {{
                                getItemInfo(navigationItem.id, 'title')
                                    .substr(0, 1)
                                    .toUpperCase() | transloco
                            }}
                        </span>

                        <p matLine fxFlex>
                            {{
                                getItemInfo(navigationItem.id, 'title')
                                    | transloco
                            }}
                        </p>

                        <span
                            *ngIf="isInShortcuts(navigationItem)"
                            class="fas fa-star star-icon ml-8"
                        ></span>
                    </div>
                </mat-list-item>
            </mat-nav-list>
        </mat-menu>
    </div>
</div>
