import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';
import { Invoice } from './invoice.model';
import { TransportModel } from '../transports/transport-entity.model';

@Injectable({ providedIn: 'root' })
export class InvoiceService {
    constructor(
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {}

    getInvoiceUrl(invoice: Invoice): Observable<{ url: string }> {
        return this.http.get<{ url: string }>(
            `${this.server}api/invoices/${invoice.id}/print`
        );
    }

    getMultipleInvoicesUrl(ids: string[]): Observable<{ url: string }> {
        return this.http.post<{ url: string }>(
            `${this.server}api/invoices/print-multiple`,
            { ids }
        );
    }

    increseInvoicesStatus(ids: string[]): Observable<Invoice[]> {
        return this.http.post<Invoice[]>(
            `${this.server}api/invoices/increase-multiple`,
            { ids }
        );
    }

    sendAutomatically(
        ids: string[],
        increaseStatus = false
    ): Observable<{ total: number; sent: number; notSentLink: string }> {
        return this.http.post<{
            total: number;
            sent: number;
            notSentLink: string;
        }>(`${this.server}api/invoices/send-automatically`, {
            ids,
            increaseStatus,
        });
    }

    getMultipleDebtcollectionsUrl(ids: string[]): Observable<{ url: string }> {
        return this.http.post<{ url: string }>(
            `${this.server}api/invoices/print-debtcollections`,
            { ids }
        );
    }

    getInvoiceUrlFromTransport(
        transport: TransportModel
    ): Observable<{ url: string }> {
        return this.http.get<{ url: string }>(
            `${this.server}api/transports/${transport.id}/print-invoice`
        );
    }
}
