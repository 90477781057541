import { Inject, Injectable, Injector } from '@angular/core';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
    EntitySelectorsFactory,
} from '@ngrx/data';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { refresh } from '../../state';
import { TransportLocation } from './transport-location-entity.model';
import { HttpClient } from '@angular/common/http';
import { TransportLocationStatistics } from './transport-location-statistics.model';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import { JwtInterceptor } from '../../auth';

export const transportLocationSelector = new EntitySelectorsFactory().create<TransportLocation>(
    'TransportLocation'
);

@Injectable({ providedIn: 'root' })
export class TransportLocationEntityService extends EntityCollectionServiceBase<TransportLocation> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions,
        private http: HttpClient,
        private injector: Injector,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('TransportLocation', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    public autoLocations$ = this.entities$.pipe(
        map((transportLocations) =>
            transportLocations.filter(
                (transportLocation) => !transportLocation.isManual
            )
        )
    );

    getTransportLocationsOfCustomerAdministration(
        customerAdministrationId: string
    ): Observable<TransportLocation[]> {
        return this.entities$.pipe(
            map((transportLocations) =>
                transportLocations.filter((transportLocation) => {
                    return (
                        transportLocation.customerAdministration?.id ===
                        customerAdministrationId
                    );
                })
            )
        );
    }

    findTransportLocation(
        transportLocationId: string
    ): Observable<TransportLocation> {
        return this.getByKey(transportLocationId);
    }

    getOwnStatistics(): Observable<TransportLocationStatistics> {
        return this.http.get<TransportLocationStatistics>(
            `${this.server}api/transportlocations/ownstatistics`
        );
    }

    getStatistics(
        transportLocationId: string
    ): Observable<TransportLocationStatistics> {
        return this.http.get<TransportLocationStatistics>(
            `${this.server}api/transportlocations/${transportLocationId}/statistics`
        );
    }

    combineTransportLocations(
        originalTransportLocation: TransportLocation,
        targetLocationId: string
    ): Observable<null> {
        return this.http
            .post<null>(`${this.server}api/transportlocations/combine`, {
                originalTransportLocationId: originalTransportLocation.id,
                targetLocationId,
            })
            .pipe(
                tap(() => {
                    this.removeOneFromCache(originalTransportLocation);
                })
            );
    }

    getDropdownListDataSource(): Observable<DataManager> {
        const jwtInterceptor = this.injector.get(JwtInterceptor);
        return jwtInterceptor.jwtObservable.pipe(
            map((token) => {
                return new DataManager({
                    url: `${this.server}api/transportlocations/query`,
                    headers: [
                        {
                            Authorization: 'Bearer ' + token,
                        },
                    ],
                    adaptor: new UrlAdaptor(),
                });
            })
        );
    }

    activateApiToken(
        transportLocation: TransportLocation
    ): Observable<TransportLocation> {
        if (!transportLocation.id) {
            return of(transportLocation);
        }
        return this.http.post<TransportLocation>(
            `${this.server}api/transportlocations/${transportLocation.id}/activateapi`,
            {}
        );
    }

    deactivateApiToken(
        transportLocation: TransportLocation
    ): Observable<TransportLocation> {
        if (!transportLocation.id) {
            return of(transportLocation);
        }
        return this.http.post<TransportLocation>(
            `${this.server}api/transportlocations/${transportLocation.id}/deactivateapi`,
            {}
        );
    }

    exportOverview(): Observable<{ url: string }> {
        return this.http.get<{ url: string }>(
            `${this.server}api/transportlocations/turnoveroverview`
        );
    }
}
