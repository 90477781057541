import { Injectable } from '@angular/core';
import {
    EntityActionOptions,
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { map, tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { refresh } from '../../state/app.actions';
import { Complaint } from './complaint.model';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class ComplaintEntityService extends EntityCollectionServiceBase<
    Complaint
> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions
    ) {
        super('Complaint', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    public entitiesForList$ = this.entities$.pipe(
        map((complaints) =>
            complaints.map((complaint) => {
                const compl = JSON.parse(JSON.stringify(complaint));
                if (compl.createdAt) {
                    compl.createdAt = new Date(compl.createdAt);
                }
                if (compl.firstContact) {
                    compl.firstContact = new Date(compl.firstContact);
                }
                if (compl.dateCompleted) {
                    compl.dateCompleted = new Date(compl.dateCompleted);
                }
                return compl;
            })
        )
    );

    public update(
        entity: Partial<Complaint>,
        options?: EntityActionOptions
    ): Observable<Complaint> {
        if (entity.firstContact) {
            entity.firstContact = moment(entity.firstContact).format(
                'YYYY-MM-DD'
            );
        }
        if (entity.dateCompleted) {
            entity.dateCompleted = moment(entity.dateCompleted).format(
                'YYYY-MM-DD'
            );
        }
        if (entity.createdAt) {
            entity.createdAt = moment(entity.createdAt).format('YYYY-MM-DD');
        }
        return super.update(entity, options);
    }
}
