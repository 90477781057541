<mat-toolbar>
    <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutAlign.gt-xs="space-between center"
        fxFlex
    >
        <a
            routerLink="/"
            mat-button
            fxFlex="0 0 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
        >
            <mat-icon
                class="s-16 mr-sm-4"
                fontSet="fa"
                fontIcon="fa-copyright"
            ></mat-icon>
            <span>2023 SANSYS SOLUTIONS AG</span>
        </a>

        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <!--            <a-->
            <!--                mat-button-->
            <!--                routerLink="/documentation/getting-started/introduction"-->
            <!--                >Handbuch</a-->
            <!--            >-->
            <!--            <span>&bull;</span>-->
            <a mat-button routerLink="/terms">{{
                'Datenschutz' | transloco
            }}</a>
        </div>
    </div>
</mat-toolbar>
