import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NavigationItem } from './navigation.type';

@Injectable({
    providedIn: 'root',
})
export class FuseNavigationService {
    onItemCollapsed: Subject<any>;
    onItemCollapseToggled: Subject<any>;

    // Private
    private _onNavigationChanged: BehaviorSubject<any>;
    private _onNavigationRegistered: BehaviorSubject<any>;
    private _onNavigationUnregistered: BehaviorSubject<any>;
    private _onNavigationItemAdded: BehaviorSubject<any>;
    private _onNavigationItemUpdated: BehaviorSubject<any>;
    private _onNavigationItemRemoved: BehaviorSubject<any>;

    private _currentNavigationKey: string | null;
    private _registry: { [key: string]: any } = {};

    constructor() {
        // Set the defaults
        this.onItemCollapsed = new Subject();
        this.onItemCollapseToggled = new Subject();

        // Set the private defaults
        this._currentNavigationKey = null;
        this._onNavigationChanged = new BehaviorSubject(null);
        this._onNavigationRegistered = new BehaviorSubject(null);
        this._onNavigationUnregistered = new BehaviorSubject(null);
        this._onNavigationItemAdded = new BehaviorSubject(null);
        this._onNavigationItemUpdated = new BehaviorSubject(null);
        this._onNavigationItemRemoved = new BehaviorSubject(null);
    }

    get onNavigationChanged(): Observable<any> {
        return this._onNavigationChanged.asObservable();
    }

    get onNavigationItemAdded(): Observable<any> {
        return this._onNavigationItemAdded.asObservable();
    }

    get onNavigationItemUpdated(): Observable<any> {
        return this._onNavigationItemUpdated.asObservable();
    }

    get onNavigationItemRemoved(): Observable<any> {
        return this._onNavigationItemRemoved.asObservable();
    }

    register(key: string, navigation: NavigationItem[]): void {
        // Check if the key already being used
        if (this._registry[key]) {
            console.error(
                `The navigation with the key '${key}' already exists. Either unregister it first or use a unique key.`
            );

            return;
        }

        // Add to the registry
        this._registry[key] = navigation;

        // Notify the subject
        this._onNavigationRegistered.next([key, navigation]);
    }

    getNavigation(key: string): any {
        // Check if the navigation exists
        if (!this._registry[key]) {
            console.warn(
                `The navigation with the key '${key}' doesn't exist in the registry.`
            );
            return;
        }

        // Return the sidebar
        return this._registry[key];
    }

    getFlatNavigation(
        navigation: NavigationItem[],
        flatNavigation: NavigationItem[] = []
    ): any {
        for (const item of navigation) {
            if (item.type === 'item') {
                flatNavigation.push(item);
                continue;
            }

            if (item.type === 'collapsable' || item.type === 'group') {
                if (item.children) {
                    this.getFlatNavigation(item.children, flatNavigation);
                }
            }
        }

        return flatNavigation;
    }

    getCurrentNavigation(): any {
        if (!this._currentNavigationKey) {
            console.warn(`The current navigation is not set.`);
            return;
        }

        return this.getNavigation(this._currentNavigationKey);
    }

    setCurrentNavigation(key: string): void {
        // Check if the sidebar exists
        if (!this._registry[key]) {
            console.warn(
                `The navigation with the key '${key}' doesn't exist in the registry.`
            );

            return;
        }

        // Set the current navigation key
        this._currentNavigationKey = key;

        // Notify the subject
        this._onNavigationChanged.next(key);
    }
}
