import { Inject, Injectable } from '@angular/core';
import {
    EntityActionOptions,
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { Actions, ofType } from '@ngrx/effects';
import { refresh } from '../../state/app.actions';
import { map, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import * as moment from 'moment';
import { prepareWorktimeForList } from './worktime-helper.functions';
import { Worktime, WorktimeBreak } from './worktime.model';
import { errorOccured } from '../../error-handling/error.actions';
import { setCurrentWorktime } from '../../auth/auth.actions';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';
import { Employee } from '../employees';
import { Facility } from '../facilities/facility.model';
import { createWorktime } from './create-worktime-entity.function';

@Injectable({ providedIn: 'root' })
export class WorktimeEntityService extends EntityCollectionServiceBase<Worktime> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions,
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('Worktime', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    public entitiesForWorktimes$ = this.entities$.pipe(
        map((entities) => {
            const newEntities: Worktime[] = [];
            entities.forEach((worktime) => {
                newEntities.push(prepareWorktimeForList(worktime));
            });
            return newEntities;
        })
    );

    startWorktime(
        employee: Employee,
        worktimeType: string,
        facility: Facility,
        isPikett: boolean,
        shiftStartMinute?: number,
        shiftEndMinute?: number
    ): Observable<Worktime> {
        const worktime = createWorktime({
            start: moment().format('YYYY-MM-DD HH:mm:ss'),
            employee: employee,
            worktimeType,
            isPikett,
            facility,
            worktimeBreaks: [],
            shiftStartMinute,
            shiftEndMinute,
        });
        return this.add(worktime).pipe(
            tap((createdWorktime) =>
                this.store.dispatch(
                    setCurrentWorktime({ currentWorktime: createdWorktime })
                )
            )
        );
    }

    add(entity: Worktime): Observable<Worktime> {
        entity.start = moment(entity.start).toISOString(true);
        if (entity.end) {
            entity.end = moment(entity.end).toISOString(true);
        }
        return super.add(entity);
    }

    update(
        entity: Worktime,
        options?: EntityActionOptions
    ): Observable<Worktime> {
        entity.start = moment(entity.start).toISOString(true);
        if (entity.end) {
            entity.end = moment(entity.end).toISOString(true);
        }
        if (moment(entity.start).diff(moment(entity.end)) > 0) {
            this.store.dispatch(
                errorOccured({
                    message: 'Das Ende muss nach dem Start sein.',
                    title: 'Info',
                })
            );
            return EMPTY;
        }
        const breaks: WorktimeBreak[] = [];
        entity.worktimeBreaks.forEach((b) => {
            if (b.start) {
                b.start = moment(b.start).toISOString(true);
            }
            if (b.end) {
                b.end = moment(b.end).toISOString(true);
            }
            breaks.push(b);
        });
        entity.worktimeBreaks = breaks;
        this.store.dispatch(setCurrentWorktime({ currentWorktime: entity }));
        return super.update(entity, options);
    }

    getWorktimesPdfUrl(
        employeeId: string,
        month?: number,
        year?: number,
        includingOverTime?: boolean
    ): Observable<{ url: string }> {
        const data = {
            month,
            year,
            includingOverTime,
        };
        return this.http.post<{ url: string }>(
            `${this.server}api/worktimes/${employeeId}/print`,
            data
        );
    }

    getWorktimesCsvUrl(
        employeeId: string,
        month?: number,
        year?: number,
        includingOverTime?: boolean
    ): Observable<{ url: string }> {
        const data = {
            month,
            year,
            includingOverTime,
        };
        return this.http.post<{ url: string }>(
            `${this.server}api/worktimes/${employeeId}/csv`,
            data
        );
    }

    getAllWorktimesOfMonthByEmployee(
        year: number,
        month: number
    ): Observable<{ [key: string]: Worktime[] }> {
        return this.http.get<{ [key: string]: Worktime[] }>(
            `${this.server}api/worktimes/employee/${year}/${month}`
        );
    }
}
