import { Insurance } from './insurance-entity.model';

export function createInsurance(insurance: Insurance): Insurance {
    return {
        id: insurance.id || undefined,
        number: insurance.number || undefined,
        name: insurance.name || undefined,
        street: insurance.street || undefined,
        zip: insurance.zip || undefined,
        city: insurance.city || undefined,
        phone: insurance.phone || undefined,
        invoiceEmail: insurance.invoiceEmail || undefined,
        insuranceType: insurance.insuranceType,
        discountInPercent: insurance.discountInPercent || undefined,
    };
}
