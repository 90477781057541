import { NgModule } from '@angular/core';
import { FeatureHeaderModule } from './feature-header/feature-header.module';
import { FeatureContainerComponent } from './feature-container/feature-container.component';
import { FeatureTableComponent } from './feature-table/feature-table.component';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { InputDialogComponent } from './input-dialog/input-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '../shared.module';
import { CommonModule } from '@angular/common';
import { SelectDialogComponent } from './select-dialog/select-dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { FormlyModule } from '@ngx-formly/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexModule } from '@angular/flex-layout';
import { PipesModule } from '@sansys/crosslib';
import {
    DatePickerModule,
    DateTimePickerModule,
} from '@syncfusion/ej2-angular-calendars';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslocoModule } from '@ngneat/transloco';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    imports: [
        SharedModule,
        FeatureHeaderModule,
        GridModule,
        DialogModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatButtonModule,
        CommonModule,
        MatRadioModule,
        MatTabsModule,
        ReactiveFormsModule,
        FormlyModule,
        NgxPermissionsModule,
        DragDropModule,
        FlexModule,
        PipesModule,
        DateTimePickerModule,
        MatCheckboxModule,
        TranslocoModule,
        DatePickerModule,
        MatSelectModule,
    ],
    exports: [
        FeatureContainerComponent,
        FeatureTableComponent,
        FeatureHeaderModule,
    ],
    declarations: [
        FeatureContainerComponent,
        FeatureTableComponent,
        InputDialogComponent,
        SelectDialogComponent,
    ],
})
export class ComponentsModule {}
