import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { getTransportLocationFormConfig } from './transport-location-modal.form-config';
import { Subscription } from 'rxjs';
import {
    TransportLocation,
    TransportLocationEntityService,
    createTransportLocation,
    errorOccured,
    currentUser,
    CustomerAdministration,
    CurrentUser,
    setAccessRights,
    ChecklistTemplate,
    Tariff,
    compareTariffsByName,
    TariffEntityService,
    transportLocationUpdated,
} from '@sansys/crosslib';
import { Store } from '@ngrx/store';
import { filter, first, switchMapTo, tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { NgxPermissionsService } from 'ngx-permissions';
import {
    CdkDragDrop,
    moveItemInArray,
    transferArrayItem,
} from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'sys-transport-location-modal',
    templateUrl: './transport-location-modal.component.html',
    styleUrls: ['./transport-location-modal.component.scss'],
})
export class TransportLocationModalComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();
    private entityId = 'new';

    id = 'schedulingTransportLocations';

    pageType: 'edit' | 'create' = 'create';
    transportLocation: TransportLocation = {
        separateInvoiceAddress: false,
        user: {},
        tariffs: [],
    };

    currentUser?: CurrentUser;
    permission = 'transportLocations';
    private currentCustomerAdministration?: CustomerAdministration;
    transportLocationForm: FormGroup = new FormGroup({});
    transportLocationFormFields: FormlyFieldConfig[] = [];
    transportLocationLoginForm: FormGroup = new FormGroup({});
    transportLocationLoginFormFields: FormlyFieldConfig[] = [];
    availableTariffs: Tariff[] = [];
    assignedTariffs: Tariff[] = [];

    constructor(
        private _formBuilder: FormBuilder,
        private store: Store<any>,
        private action$: Actions,
        private permissionService: NgxPermissionsService,
        private transportLocationEntityService: TransportLocationEntityService,
        private tariffEntityService: TariffEntityService,
        public dialogRef: MatDialogRef<TransportLocationModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public options: {
            id?: string;
            manual?: boolean;
            data: { transportLocationType: 'start' | 'target' | 'customer' };
        }
    ) {}

    private setPermission(): void {
        this.permissionService
            .hasPermission([this.permission + '|EDIT', 'admin'])
            .then((hasPermission) => {
                if (
                    !hasPermission &&
                    this.transportLocationForm &&
                    this.transportLocationLoginForm
                ) {
                    this.transportLocationForm.disable();
                    this.transportLocationLoginForm.disable();
                    this.transportLocationFormFields.forEach((formField) => {
                        formField.templateOptions = {
                            ...formField.templateOptions,
                            disabled: true,
                        };
                        formField.fieldGroup?.forEach((innerField) => {
                            innerField.templateOptions = {
                                ...innerField.templateOptions,
                                disabled: true,
                            };
                        });
                    });
                } else {
                    this.transportLocationForm.enable();
                    this.transportLocationLoginForm.enable();
                    this.transportLocationFormFields.forEach((formField) => {
                        formField.templateOptions = {
                            ...formField.templateOptions,
                            disabled: false,
                        };
                        formField.fieldGroup?.forEach((innerField) => {
                            innerField.templateOptions = {
                                ...innerField.templateOptions,
                                disabled: false,
                            };
                        });
                    });
                }
            });
    }

    private sortTariffs(): void {
        this.assignedTariffs.sort(compareTariffsByName);
        this.availableTariffs.sort(compareTariffsByName);
    }

    private initializeTariffs(): void {
        this.subscription.add(
            this.tariffEntityService.loaded$
                .pipe(
                    filter((isLoaded) => isLoaded),
                    switchMapTo(this.tariffEntityService.entities$),
                    first()
                )
                .subscribe((tariffs) => {
                    const availableT: Tariff[] = [];
                    tariffs.forEach((t) => {
                        let tAlreadyAssigned = false;
                        if (!this.transportLocation.tariffs) {
                            this.transportLocation.tariffs = [];
                        }
                        this.transportLocation.tariffs.forEach(
                            (transportLocationT) => {
                                if (transportLocationT.id === t.id) {
                                    tAlreadyAssigned = true;
                                }
                            }
                        );
                        if (!tAlreadyAssigned) {
                            availableT.push(t);
                        }
                    });

                    this.availableTariffs = availableT;
                    this.assignedTariffs = JSON.parse(
                        JSON.stringify(this.transportLocation.tariffs)
                    );
                    this.sortTariffs();
                })
        );
        this.tariffEntityService.load();
    }

    ngOnInit(): void {
        if (!this.options.id || this.options?.id === 'new') {
            this.entityId = 'new';
        } else {
            this.pageType = 'edit';
            this.entityId = this.options?.id;
        }

        this.store
            .select(currentUser)
            .pipe(
                first(),
                tap((currentU) => {
                    if (currentU) {
                        this.currentUser = currentU;
                    }
                })
            )
            .subscribe();

        if (this.entityId !== 'new') {
            this.pageType = 'edit';
            this.subscription.add(
                this.transportLocationEntityService
                    .findTransportLocation(this.entityId)
                    .subscribe({
                        next: (transportLocation) => {
                            this.transportLocation = JSON.parse(
                                JSON.stringify(transportLocation)
                            );
                            if (!this.transportLocation) {
                                return;
                            }
                            if (!this.transportLocation.user) {
                                this.transportLocation.user = {};
                            }
                            if (transportLocation.discountInPercent) {
                                transportLocation.discountInHundrethPercent = Math.floor(
                                    transportLocation.discountInPercent * 100
                                );
                            }
                            this.initializeTariffs();
                            this.transportLocationFormFields = getTransportLocationFormConfig(
                                this.pageType
                            );
                        },
                    })
            );
        } else {
            this.pageType = 'create';
            this.initializeTariffs();
            this.transportLocationFormFields = getTransportLocationFormConfig(
                this.pageType
            );
        }

        this.setPermission();
        this.subscription.add(
            this.action$.pipe(ofType(setAccessRights)).subscribe(() => {
                this.setPermission();
            })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    drop(event: CdkDragDrop<ChecklistTemplate[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
            this.transportLocationForm.markAsDirty();
        }
        this.sortTariffs();
    }

    saveTransportLocation(): void {
        const transportLocation: TransportLocation | null = createTransportLocation(
            {
                ...this.transportLocation,
            }
        );
        if (!transportLocation) {
            this.store.dispatch(
                errorOccured({
                    message: 'Ort konnte nicht angelegt/aktualisiert werden.',
                })
            );
            return;
        }
        // if (transportLocation.user?.username && !transportLocation.email) {
        //     this.store.dispatch(
        //         errorOccured({
        //             message: 'Bitte geben Sie die E-Mail-Adresse ein.',
        //             title: 'Info',
        //         })
        //     );
        //     return;
        // }
        if (this.currentCustomerAdministration) {
            transportLocation.customerAdministration = this.currentCustomerAdministration;
        }
        if (!transportLocation.separateInvoiceAddress) {
            transportLocation.invoiceAddressName = undefined;
            transportLocation.invoiceAddressAddition = undefined;
            transportLocation.invoiceAddressStreet = undefined;
            transportLocation.invoiceAddressCity = undefined;
            transportLocation.invoiceAddressZip = undefined;
        }

        transportLocation.tariffs = this.assignedTariffs;

        if (transportLocation.tariffs.length === 0) {
            this.store.dispatch(
                errorOccured({
                    message: 'Bitte mindestens einen Tarif zuweisen',
                })
            );
            return;
        }

        if (transportLocation.discountInPercent) {
            transportLocation.discountInHundrethPercent = Math.floor(
                transportLocation.discountInPercent * 100
            );
        }

        if (transportLocation.id) {
            this.transportLocationEntityService
                .update(transportLocation)
                .subscribe(() => {
                    this.store.dispatch(
                        transportLocationUpdated({
                            transportLocation,
                            transportLocationType: this.options.data
                                .transportLocationType,
                        })
                    );
                    this.goBack();
                });
        } else {
            this.transportLocationEntityService
                .add(transportLocation)
                .subscribe(() => this.goBack());
        }
    }

    goBack(): void {
        this.dialogRef.close();
    }
}
