import * as moment from 'moment';
import { TransportModel } from './transport-entity.model';

export function createTransport(
    transport: Partial<TransportModel>
): TransportModel | null {
    return {
        id: transport?.id || undefined,
        transportSource: transport.transportSource || 'scheduler',
        transportNumber: transport.transportNumber || undefined,

        employee1: transport?.employee1 || undefined,
        employee2: transport?.employee2 || undefined,
        intern: transport?.intern || undefined,
        scheduler: transport?.scheduler || undefined,
        facility: transport?.facility || undefined,
        vehicle: transport?.vehicle || undefined,
        passenger: transport?.passenger || {
            firstName: '',
            lastName: '',
            birthday: '',
        },
        tariff: transport?.tariff || undefined,
        tariffVersion: transport?.tariffVersion || undefined,
        tariffOptions: transport?.tariffOptions || undefined,
        startLocation: transport?.startLocation || undefined,
        targetLocation: transport?.targetLocation || undefined,
        customerLocation: transport?.customerLocation || undefined,
        customerInsurance: transport?.customerInsurance || undefined,

        customerType: transport?.customerType || 'startLocation',

        confirmationEmail: transport?.confirmationEmail || undefined,

        note: transport?.note || undefined,

        startDetailsAskReceptionFlag:
            transport?.startDetailsAskReceptionFlag || false,
        startPassengerLocationBuilding:
            transport?.startPassengerLocationBuilding || undefined,
        startPassengerLocationFloor:
            transport?.startPassengerLocationFloor || undefined,
        startPassengerLocationRoom:
            transport?.startPassengerLocationRoom || undefined,
        startPassengerLocationContactPerson:
            transport?.startPassengerLocationContactPerson || undefined,
        startPassengerLocationPhone:
            transport?.startPassengerLocationPhone || undefined,
        startPassengerLocationComment:
            transport?.startPassengerLocationComment || undefined,

        targetDetailsAskReceptionFlag:
            transport?.targetDetailsAskReceptionFlag || true,
        targetPassengerLocationBuilding:
            transport?.targetPassengerLocationBuilding || undefined,
        targetPassengerLocationFloor:
            transport?.targetPassengerLocationFloor || undefined,
        targetPassengerLocationRoom:
            transport?.targetPassengerLocationRoom || undefined,
        targetPassengerLocationContactPerson:
            transport?.targetPassengerLocationContactPerson || undefined,
        targetPassengerLocationPhone:
            transport?.targetPassengerLocationPhone || undefined,
        targetPassengerLocationComment:
            transport?.targetPassengerLocationComment || undefined,

        // Einsatzverlauf
        orderedStart: transport?.orderedStart || undefined,
        redirectedStart: transport?.redirectedStart || undefined,
        plannedStart: transport.plannedStart || undefined,
        plannedEnd: transport?.plannedEnd || undefined,
        isReturnTransport: transport?.isReturnTransport || undefined,
        transportStart: transport?.transportStart || undefined,
        arrivalStart: transport?.arrivalStart || undefined,
        departureStart: transport?.departureStart || undefined,
        arrivalDestination: transport?.arrivalDestination || undefined,
        departureDestination: transport?.departureDestination || undefined,
        arrivalStartReturn: transport?.arrivalStartReturn || undefined,
        transportFinished: transport?.transportFinished || undefined,
        teamReady: transport?.teamReady || undefined,

        isUrgent: transport?.isUrgent || undefined,
        isSnz144: transport?.isSnz144 || undefined,

        transportDate:
            transport.transportDate ||
            moment(transport.plannedStart).format('YYYY-MM-DD'),
        distance: transport?.distance || undefined,
        protocolSections: transport?.protocolSections || undefined,
        protocolName: transport?.protocolName || undefined,
        isOffer: transport?.isOffer || false,
        calculatedOneWayDistanceInMeters:
            transport?.calculatedOneWayDistanceInMeters || undefined,
        calculatedOneWayDurationInMinutes:
            transport?.calculatedOneWayDurationInMinutes || undefined,
        passengerIsStart: transport?.passengerIsStart || undefined,
        passengerIsTarget: transport?.passengerIsTarget || undefined,

        localService: transport?.localService || undefined,

        passengerCaseNumber: transport?.passengerCaseNumber || undefined,
        passengerDiagnosis: transport?.passengerDiagnosis || undefined,
        passengerTransferReason:
            transport?.passengerTransferReason || undefined,
        passengerWeight: transport?.passengerWeight || undefined,
        passengerPosition: transport.passengerPosition || undefined,
        targetStation: transport?.targetStation || undefined,
        startStation: transport?.startStation || undefined,
        orderedStation: transport?.orderedStation || undefined,
        orderedTransportLocation:
            transport?.orderedTransportLocation || undefined,
    };
}
