<sys-feature-container>
    <sys-feature-header
        [id]="id"
        [title]="
            ('Arbeitszeit von ' | transloco) +
            employee?.firstName +
            ' ' +
            employee?.lastName
        "
    >
        <div feature-header-right>
            <button
                mat-raised-button
                (click)="onSaveWorktime()"
                data-testid="saveButton"
                class="mr-12"
            >
                <span>{{ 'Speichern' | transloco }}</span>
            </button>
            <button mat-raised-button (click)="onCloseModal()" class="mr-12">
                {{ 'Schliessen' | transloco }}
            </button>
        </div>
    </sys-feature-header>

    <ng-container page-content>
        <div class="tab-content p-24 w-100-p">
            <form [formGroup]="worktimeForm">
                <formly-form
                    [form]="worktimeForm"
                    [fields]="worktimeFormFields"
                    [model]="worktime"
                ></formly-form>
            </form>
        </div>
    </ng-container>
</sys-feature-container>
