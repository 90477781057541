import * as moment from 'moment';
import { TransportModel } from '../transport-entity.model';

export type Unit = 'seconds' | 'minutes' | 'quarterHour';

export function getTransportLength(
    transport: TransportModel,
    unit: Unit
): number {
    const transportStart = transport.transportStart
        ? transport.transportStart
        : transport.plannedStart;
    const transportFinished = transport.transportFinished
        ? transport.transportFinished
        : transport.plannedEnd;

    const lengthInMiliSeconds = moment(transportFinished).diff(
        moment(transportStart)
    );

    switch (unit) {
        case 'seconds':
            return Math.round(lengthInMiliSeconds / 1000);
        case 'minutes':
            return Math.round(lengthInMiliSeconds / 1000 / 60);
        case 'quarterHour':
            return Math.round(lengthInMiliSeconds / 1000 / 60 / 15);
    }
}
