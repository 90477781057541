import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectDialogDataType } from './select-dialog-data.type';

@Component({
    selector: 'sys-select-dialog',
    templateUrl: 'select-dialog.component.html',
    styleUrls: ['./select-dialog.component.scss'],
})
export class SelectDialogComponent {
    result: any;
    constructor(
        public dialogRef: MatDialogRef<SelectDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: SelectDialogDataType
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

    onOtherClick(result: string): void {
        this.dialogRef.close(result);
    }

    onButtonClick(button: { title: string; value?: object }): void {
        this.dialogRef.close(button);
    }

    disabled(): boolean {
        if (!this.data.selectElements) {
            return false;
        }
        let disabled = false;
        this.data.selectElements.forEach((inputElement) => {
            if (!inputElement.input) {
                disabled = true;
            }
        });
        return disabled;
    }
}
