import { Tariff } from './tariff.model';
import { OptionTariffPosition } from './tariff-version.model';
import { translate } from '@ngneat/transloco';

export function compareTariffsByName(a: Tariff, b: Tariff): number {
    const clA = a.name.toUpperCase();
    const clB = b.name.toUpperCase();

    let comparison = 0;
    if (clA > clB) {
        comparison = 1;
    } else if (clA < clB) {
        comparison = -1;
    }
    return comparison;
}

export function getPlaceholder(
    option: OptionTariffPosition
): string | undefined {
    if (option.type === 'input') {
        return option.name;
    }
    if (option.type === 'select') {
        return option.mandatory
            ? option.name + ' ' + translate('auswählen (Pflichtfeld)')
            : option.name + ' ' + translate('auswählen');
    }
}
