import { Pipe, PipeTransform } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { userAccessRights } from '../auth/auth.selectors';

@Pipe({
    name: 'permission',
})
export class PermissionPipe implements PipeTransform {
    constructor(
        private permissionService: NgxPermissionsService,
        private store: Store<any>
    ) {}

    transform(value: string): Observable<boolean> {
        return this.store.select(userAccessRights).pipe(
            map(() => {
                return (
                    !!this.permissionService.getPermission(value) ||
                    !!this.permissionService.getPermission('admin')
                );
            })
        );
    }
}
