import { createAction, props } from '@ngrx/store';
import { TransportModel } from './transport-entity.model';

export const showTransportProtocolDetails = createAction(
    '[Transport] Show protocol details',
    props<{ transport: TransportModel }>()
);

export const transportProtocolUpdated = createAction(
    '[Transport] Transport protocol data updated',
    props<{ transport: TransportModel }>()
);

export const showInvoiceSumChangedConfirmation = createAction(
    '[Transport] Ask for invoic sum change permission',
    props<{ message: string; transportId: string }>()
);
