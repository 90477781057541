<sys-feature-container>
    <sys-feature-header [id]="id">
        <div feature-header-right>
            <button
                mat-raised-button
                [disabled]="passengerForm.invalid || passengerForm.pristine"
                (click)="onSavePassenger()"
                class="mr-12"
                data-testid="saveButton"
            >
                <span>{{ 'Speichern' | transloco }}</span>
            </button>
            <button mat-raised-button (click)="goBack()">
                {{ 'Abbrechen' | transloco }}
            </button>
        </div>
    </sys-feature-header>

    <ng-container page-content>
        <div class="tab-content p-24 w-100-p">
            <form [formGroup]="passengerForm">
                <formly-form
                    [form]="passengerForm"
                    [fields]="passengerFormFields"
                    [model]="passenger"
                ></formly-form>
            </form>
        </div>
    </ng-container>
</sys-feature-container>
