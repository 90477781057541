import { Component, OnDestroy, OnInit } from '@angular/core';
import { hideSpinner, showSpinner } from '@sansys/crosslib';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sys-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();

    isLoading = false;

    constructor(private action$: Actions) {}

    ngOnInit(): void {
        this.subscription.add(
            this.action$
                .pipe(ofType(showSpinner))
                .subscribe(() => (this.isLoading = true))
        );
        this.subscription.add(
            this.action$
                .pipe(ofType(hideSpinner))
                .subscribe(() => (this.isLoading = false))
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
