<h1 mat-dialog-title [innerHTML]="data.title | transloco"></h1>
<div mat-dialog-content>
    <ng-container *ngFor="let inputElement of data.inputElements">
        <mat-form-field
            class="w-100-p"
            *ngIf="
                inputElement.type !== 'datetimepicker' &&
                inputElement.type !== 'datepicker' &&
                inputElement.type !== 'checkbox'
            "
        >
            <mat-label>{{ inputElement.label }}</mat-label>
            <input
                *ngIf="
                    inputElement.type !== 'textarea' &&
                    inputElement.type !== 'multipleselect' &&
                    inputElement.type !== 'simpleselect'
                "
                [required]="!!inputElement.required"
                matInput
                [(ngModel)]="inputElement.input"
                name="inputElement.label"
                [type]="inputElement.type || 'text'"
                [min]="inputElement.min || undefined"
                [step]="inputElement.step || undefined"
            />
            <textarea
                *ngIf="inputElement.type === 'textarea'"
                [required]="!!inputElement.required"
                matInput
                name="inputElement.label"
                [rows]="inputElement.rows"
                [(ngModel)]="inputElement.input"
            ></textarea>
            <mat-select
                *ngIf="inputElement.type === 'simpleselect'"
                [(ngModel)]="inputElement.input"
                name="inputElement.label"
                [placeholder]="
                    inputElement.label + (inputElement.required ? ' *' : '')
                "
                [required]="!!inputElement.required"
            >
                <mat-option
                    *ngFor="let type of inputElement.options"
                    [value]="type"
                >
                    {{ type }}
                </mat-option>
            </mat-select>
            <mat-select
                *ngIf="inputElement.type === 'multipleselect'"
                [(ngModel)]="inputElement.input"
                name="inputElement.label"
                [placeholder]="
                    inputElement.label + (inputElement.required ? ' *' : '')
                "
                [required]="!!inputElement.required"
                multiple
            >
                <mat-option
                    *ngFor="let type of inputElement.options"
                    [value]="type"
                >
                    {{ type }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <ejs-datetimepicker
            *ngIf="inputElement.type === 'datetimepicker'"
            [step]="15"
            [(ngModel)]="inputElement.input"
            [required]="true"
            [locale]="locale"
            [placeholder]="inputElement.label || ''"
            name="inputElement.label"
        ></ejs-datetimepicker>
        <ejs-datepicker
            *ngIf="inputElement.type === 'datepicker'"
            [(ngModel)]="inputElement.input"
            [required]="!!inputElement.required"
            [locale]="locale"
            [placeholder]="
                inputElement.label + (inputElement.required ? ' *' : '')
            "
            name="inputElement.label"
        ></ejs-datepicker>
        <mat-checkbox
            *ngIf="inputElement.type === 'checkbox'"
            [(ngModel)]="inputElement.input"
            name="inputElement.label"
            >{{ inputElement.label }}</mat-checkbox
        >
    </ng-container>
</div>
<div mat-dialog-actions class="w-100-p">
    <button mat-button (click)="onNoClick()">
        {{ 'Abbrechen' | transloco }}
    </button>
    <button
        mat-button
        [mat-dialog-close]="data"
        cdkFocusInitial
        [disabled]="disabled()"
    >
        {{ 'Ok' | transloco }}
    </button>
</div>
