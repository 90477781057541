import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { SysBaseEntityType } from '../../types/sys-base-entity.type';
import { ItemType } from '../../types/item.type';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';
import { FileUpload } from './file-upload.model';

@Injectable({
    providedIn: 'root',
})
export class FileUploadService {
    constructor(
        private httpClient: HttpClient,
        private store: Store<any>,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {}

    public uploadFile(
        file: Blob,
        filyType: string,
        item: Partial<SysBaseEntityType>,
        type: ItemType,
        fileName: string
    ): Observable<HttpEvent<{}>> {
        const formData = new FormData();
        formData.append('single_file_upload[file]', file, fileName);
        formData.append('single_file_upload[docType]', filyType);
        const options = {
            reportProgress: true,
        };

        if (filyType === 'paymentXml') {
            return this.httpClient.request(
                new HttpRequest(
                    'POST',
                    `${this.server}api/files/importpayments`,
                    formData,
                    options
                )
            );
        }

        const req = new HttpRequest(
            'POST',
            `${this.server}api/files/${type}/${item.id}`,
            formData,
            options
        );
        return this.httpClient.request(req);
    }

    public uploadBlob(
        blobData: Blob,
        fileType: string,
        item: Partial<SysBaseEntityType>,
        type: ItemType
    ): Observable<HttpEvent<{}>> {
        const formData = new FormData();
        formData.append('file', blobData, `file`);
        formData.append('name', fileType);
        const options = {
            reportProgress: true,
        };

        const req = new HttpRequest(
            'POST',
            `${this.server}api/files/${type}/${item.id}`,
            formData,
            options
        );

        return this.httpClient.request(req);
    }

    public updateFile(
        file: FileUpload,
        type: ItemType,
        item: Partial<SysBaseEntityType>
    ): Observable<null> {
        return this.httpClient.put<null>(
            `${this.server}api/files/${type}/${item.id}`,
            file
        );
    }

    removeFile(
        file: FileUpload,
        type: ItemType,
        item: Partial<SysBaseEntityType>
    ): Observable<null> {
        return this.httpClient.post<null>(
            `${this.server}api/files/remove/${type}/${item.id}`,
            file
        );
    }
}
