import { Vehicle } from './vehicle-entity.model';

export function getEmployeeTypeOfVehicleAndEmployeeId(
    currentVehicle: Vehicle,
    employeeId: string
): 'employee1' | 'employee2' | 'intern' | undefined {
    if (
        currentVehicle?.employee1 &&
        currentVehicle.employee1.id === employeeId
    ) {
        return 'employee1';
    }
    if (
        currentVehicle?.employee2 &&
        currentVehicle.employee2.id === employeeId
    ) {
        return 'employee2';
    }
    if (currentVehicle?.intern && currentVehicle.intern.id === employeeId) {
        return 'intern';
    }

    return undefined;
}
