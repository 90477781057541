import { EntityMetadataMap } from '@ngrx/data';

export const entityMetadata: EntityMetadataMap = {
    TransportLocation: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Passenger: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Insurance: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Transport: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Vehicle: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    CustomerAdministration: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Facility: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Employee: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Cloth: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Keyy: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Device: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Material: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Tariff: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    TariffVersion: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Invoice: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Worktime: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    ChecklistTemplate: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Checklist: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Complaint: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Defect: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Training: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    ProtocolTemplate: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    ChangeLog: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    MaterialLocation: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    EmployeeDeduction: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    StockChange: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Payroll: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Rapport: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    QmDocumentCategory: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    QmDocument: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    NotificationCategory: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    Station: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    ShiftTemplate: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    TemplatePeriod: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
    WorktimeNet: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
};
