import * as moment from 'moment';
import { Rapport } from './rapport.model';

export function filterRapportsByQuery(
    initialRapports: Rapport[],
    query: string
): Rapport[] {
    return initialRapports.filter((rapport) => {
        let rapportData =
            rapport.content +
            rapport.employee +
            moment(rapport.createdAt).format('DD.MM.YYYY HH:mm');
        rapport.comments?.forEach(
            (comment) =>
                (rapportData +=
                    comment.content +
                    comment.employee +
                    moment(comment.createdAt).format('DD.MM.YYYY HH:mm'))
        );
        return rapportData.toLowerCase().includes(query);
    });
}
