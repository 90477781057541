import { Component, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FormControl } from '@angular/forms';
import {
    NGX_MAT_DATE_FORMATS,
    NgxMatDateAdapter,
    NgxMatDateFormats,
    NgxMatDatetimePicker,
} from '@angular-material-components/datetime-picker';
import { NgxMatMomentAdapter } from '@angular-material-components/moment-adapter';

const CUSTOM_MOMENT_FORMATS: NgxMatDateFormats = {
    parse: {
        dateInput: 'DD.MM.YYYY HH:mm',
    },
    display: {
        dateInput: 'DD.MM.YYYY HH:mm',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'MM',
        monthYearA11yLabel: 'MM.YYYY',
    },
};

@Component({
    selector: 'sys-date-time-type',
    providers: [
        { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
        { provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter },
    ],
    styleUrls: ['./date-time-type.component.scss'],
    template: `
        <div
            fxLayout="row"
            fxLayout.xs="column"
            fxFlexFill
            fxLayoutAlign="center center"
        >
            <input
                matInput
                [ngxMatDatetimePicker]="$any(picker)"
                [formControl]="formControl"
                [max]="'2121-01-01'"
                (focus)="openPicker()"
                autocomplete="off"
                (dateChange)="onChange()"
                placeholder="{{
                    'Datum/Uhrzeit eingeben oder auswählen' | transloco
                }}"
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="$any(picker)"
                class="float-right"
                (click)="openPicker()"
            ></mat-datepicker-toggle>
        </div>
        <ngx-mat-datetime-picker
            #picker
            [showSpinners]="true"
            [showSeconds]="false"
            [stepHour]="1"
            [stepMinute]="1"
        >
            <ng-template>
                <span>{{ 'Ok' | transloco }}</span>
            </ng-template>
        </ngx-mat-datetime-picker>
    `,
})
export class DateTimeTypeComponent extends FieldType {
    @ViewChild('picker') public picker!: NgxMatDatetimePicker<any>;
    public formControl!: FormControl;

    openPicker(): void {
        this.picker.open();
    }

    onChange(): void {
        if (
            this.field &&
            this.field.templateOptions &&
            this.field.templateOptions.changeCallback
        ) {
            this.field.templateOptions.changeCallback(this.value);
        }
    }
}
