import { HttpClient } from '@angular/common/http';
import {
    TRANSLOCO_LOADER,
    Translation,
    TranslocoLoader,
    TRANSLOCO_CONFIG,
    translocoConfig,
    TranslocoModule,
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) {}

    getTranslation(lang: string): Observable<Translation> {
        if (!lang) {
            return EMPTY;
        }
        return this.http.get<Translation>(
            `/assets/i18n/${lang}.json?v=${Date.now()}`
        );
    }
}

@NgModule({
    exports: [TranslocoModule],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: [
                    'medical_de-CH',
                    'medical_fr-PF',
                    'standard_de-CH',
                    'standard_fr-PF',
                ],
                defaultLang: 'medical_de-CH',
                missingHandler: {
                    useFallbackTranslation: true,
                    logMissingKey: true,
                },
                fallbackLang: 'medical_de-CH',
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: true,
            }),
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    ],
})
export class TranslocoRootModule {}
