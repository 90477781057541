import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';
import { EmployeeShiftModel } from './employee-shift.model';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable({ providedIn: 'root' })
export class EmployeeShiftEntityService {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {}

    upsertEmployeeShift(
        employeeShift: EmployeeShiftModel
    ): Observable<EmployeeShiftModel | null> {
        return this.http.post<EmployeeShiftModel | null>(
            `${this.server}api/employeeshifts/upsert`,
            employeeShift
        );
    }

    getAllEmployeeShifts(
        year: number,
        month: number
    ): Observable<EmployeeShiftModel[]> {
        return this.http.get<EmployeeShiftModel[]>(
            `${this.server}api/employeeshifts/${year}/${month}`
        );
    }

    getAllEmployeeShiftsByEmployee(
        year: number,
        month: number
    ): Observable<{ [key: string]: EmployeeShiftModel[] }> {
        return this.http.get<{ [key: string]: EmployeeShiftModel[] }>(
            `${this.server}api/employeeshifts/employee/${year}/${month}`
        );
    }

    getOwnEmployeeShifts(): Observable<EmployeeShiftModel[]> {
        return this.http.get<EmployeeShiftModel[]>(
            `${this.server}api/employeeshifts/own`
        );
    }

    getOwnEmployeeShiftsNextWeek(): Observable<EmployeeShiftModel[]> {
        return this.http.get<EmployeeShiftModel[]>(
            `${this.server}api/employeeshifts/ownnextweek`
        );
    }

    print(
        year: number,
        month: number,
        sortBy: 'name' | 'qualification'
    ): Observable<string> {
        return this.http.post<string>(
            `${this.server}api/employeeshifts/print`,
            { year, month, sortBy }
        );
    }

    getEmployeeShiftsForDay(date: string): Observable<EmployeeShiftModel[]> {
        return this.http.get<EmployeeShiftModel[]>(
            `${this.server}api/employeeshifts/date/${date}`
        );
    }
}
