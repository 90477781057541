import { createAction, props } from '@ngrx/store';
import { Keyy } from './keyy.model';

export const showAddKeyyPage = createAction('[Keyy] show add page');
export const updateKeyyLocation = createAction(
    '[Keyy] update keyy location',
    props<{
        keyy: Keyy;
        locationType: 'home' | 'owner';
        selectedLocation?: string;
    }>()
);

export const showKeyyQuickFunctions = createAction(
    '[Keyy] show quick functions',
    props<{ keyy: Keyy; fullScreen?: boolean; showTasks?: boolean }>()
);

export const showKeyyDetailsPage = createAction(
    '[Keyy] show keyy details',
    props<{ keyyId: string }>()
);

export const showBookKeyyScanner = createAction('[Keyy] show book scanner');
export const showKeyysList = createAction('[Keyy] show list page');
export const showKeyyDetailsScanner = createAction(
    '[Keyy] show keyy details scanner'
);
export const showOwnKeyysList = createAction('[Keyy] show my keyys');

export const keyyOwnerChangeHistory = createAction(
    '[Keyy] show owner change history'
);
