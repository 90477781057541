import {
    Directive,
    ElementRef,
    OnDestroy,
    OnInit,
    Renderer2,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FuseMatchMediaService } from '../../services/match-media.service';

@Directive({
    selector: '.inner-scroll', // TODO: Get rid of directive
})
export class FuseInnerScrollDirective implements OnInit, OnDestroy {
    private _parent: any;
    private _grandParent: any;
    private subscription = new Subscription();

    constructor(
        private _elementRef: ElementRef,
        private _fuseMediaMatchService: FuseMatchMediaService,
        private _renderer: Renderer2
    ) {}

    private _addClass(): void {
        this._renderer.addClass(this._grandParent, 'inner-scroll');
    }

    private _removeClass(): void {
        this._renderer.removeClass(this._grandParent, 'inner-scroll');
    }

    ngOnInit(): void {
        this._parent = this._renderer.parentNode(
            this._elementRef.nativeElement
        );

        if (!this._parent) {
            return;
        }

        this._grandParent = this._renderer.parentNode(this._parent);

        const sub = this._fuseMediaMatchService.onMediaChange.subscribe(
            (alias) => {
                if (alias === 'xs') {
                    this._removeClass();
                } else {
                    this._addClass();
                }
            }
        );
        this.subscription.add(sub);
    }

    ngOnDestroy(): void {
        if (!this._parent) {
            return;
        }

        this._removeClass();

        this.subscription.unsubscribe();
    }
}
