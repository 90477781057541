import { createReducer, on } from '@ngrx/store';
import {
    notificationReceived,
    resetUnreadRapportsCount,
} from './notifications.actions';

export interface NotificationsState {
    unreadRapports?: number;
}

export const initialNotificationsState: NotificationsState = {
    unreadRapports: 0,
};

export const notificationsReducer = createReducer(
    initialNotificationsState,
    on(notificationReceived, (state, action) => {
        const newValue = state.unreadRapports ? state.unreadRapports + 1 : 1;
        if (action.notificationContent.data.type === 'rapport') {
            return {
                ...state,
                unreadRapports: newValue,
            };
        }
        return { ...state };
    }),
    on(resetUnreadRapportsCount, (state) => {
        return {
            ...state,
            unreadRapports: 0,
        };
    })
);
