import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { setListOptions } from './list-options.actions';
import { LocalStorageService } from '../../common-services/local-storage.service';

@Injectable()
export class ListEffects {
    constructor(
        private localStorageService: LocalStorageService,
        private action$: Actions
    ) {}

    setListOptions$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(setListOptions),
                tap((data) =>
                    this.localStorageService.setItem(
                        'listOptions',
                        data.listOptions
                    )
                )
            ),
        { dispatch: false }
    );
}
