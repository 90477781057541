import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EntityDefinitionService, EntityMetadataMap } from '@ngrx/data';
import { authReducer } from './auth.reducer';

const entityMetadata: EntityMetadataMap = {
    Employee: {
        entityDispatcherOptions: {
            optimisticUpdate: true,
        },
    },
};

@NgModule({
    imports: [StoreModule.forFeature('auth', authReducer)],
})
export class AuthModule {
    constructor(private eds: EntityDefinitionService) {
        eds.registerMetadataMap(entityMetadata);
    }
}
