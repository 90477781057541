import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasRightsDirective } from './has-rights.directive';

@NgModule({
    declarations: [HasRightsDirective],
    imports: [CommonModule],
    exports: [HasRightsDirective],
})
export class AccessRightsModule {}
