import { Inject, Injectable } from '@angular/core';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { refresh } from '../../state';
import { Payroll } from './payroll.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';

@Injectable({ providedIn: 'root' })
export class PayrollEntityService extends EntityCollectionServiceBase<Payroll> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions,
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('Payroll', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    getPayrollUrl(payroll: Payroll): Observable<{ url: string }> {
        return this.http.get<{ url: string }>(
            `${this.server}api/payrolls/${payroll.id}/print`
        );
    }

    getPayrollsUrl(ids: string[]): Observable<{ url: string }> {
        return this.http.post<{ url: string }>(
            `${this.server}api/payrolls/print`,
            { ids }
        );
    }

    getPayrollsByEmployeeId(employeeId: string): Observable<Payroll[]> {
        return this.http.get<Payroll[]>(
            `${this.server}api/payrolls/employee/${employeeId}`
        );
    }
}
