import { TransportLocation } from './transport-location-entity.model';

export function createTransportLocation(
    transportLocation: Partial<TransportLocation>
): TransportLocation | null {
    if (!transportLocation.companyName) {
        return null;
    }
    return {
        id: transportLocation.id || undefined,
        companyName: transportLocation.companyName,
        firstName: transportLocation.firstName,
        lastName: transportLocation.lastName,
        street: transportLocation.street || undefined,
        zip: transportLocation.zip || undefined,
        city: transportLocation.city || undefined,
        isManual: transportLocation.isManual || false,
        separateInvoiceAddress:
            transportLocation.separateInvoiceAddress || false,
        invoiceAddressName: transportLocation.invoiceAddressName || undefined,
        invoiceAddressAddition:
            transportLocation.invoiceAddressAddition || undefined,
        invoiceAddressStreet:
            transportLocation.invoiceAddressStreet || undefined,
        invoiceAddressZip: transportLocation.invoiceAddressZip || undefined,
        invoiceAddressCity: transportLocation.invoiceAddressCity || undefined,
        user: transportLocation.user || undefined,
        customerAdministration:
            transportLocation.customerAdministration || undefined,
        tariffs: transportLocation.tariffs || [],
        tariffVersion: transportLocation.tariffVersion || undefined,
        building: transportLocation.building || undefined,
        floor: transportLocation.floor || undefined,
        room: transportLocation.room || undefined,
        contactPerson: transportLocation.contactPerson || undefined,
        phone: transportLocation.phone || undefined,
        comment: transportLocation.comment || undefined,
        email: transportLocation.email || undefined,
        facility: transportLocation.facility || undefined,
        discountInPercent: transportLocation.discountInPercent || undefined,
        invoiceSendType: transportLocation.invoiceSendType || undefined,
        invoiceEmail: transportLocation.invoiceEmail || undefined,
        qrInvoice: transportLocation.qrInvoice || undefined,
        canSeeInvoices: transportLocation.canSeeInvoices || undefined,
        canSeeOffers: transportLocation.canSeeOffers || undefined,
        isPartner: transportLocation.isPartner || undefined,
        turnoverStatistic: transportLocation.turnoverStatistic || undefined,
        isB2C: transportLocation.isB2C || undefined,
        language: transportLocation.language || undefined,
    };
}
