import { createAction, props } from '@ngrx/store';
import { MaterialListFilter, Material } from './material-entity.model';
import { MaterialLocation } from './material-location/material-location.model';

export const showAddMaterialPageManually = createAction(
    '[Material] show add page manual'
);
export const showAddMaterialPageAutomatically = createAction(
    '[Material] show add page auto'
);
export const showMaterialDetailsPage = createAction(
    '[Material] show material details page',
    props<{ materialId: string }>()
);

export const showMaterialQuickFunctions = createAction(
    '[Material] show quick functions',
    props<{
        material: Material;
        materialLocation?: MaterialLocation;
        fullScreen?: boolean;
        onlyBook?: boolean;
        showTasks?: boolean;
    }>()
);

export const showBookMaterialPage = createAction(
    '[Material] show book scanner',
    props<{ materialId: string }>()
);
export const showMaterialDetailsScanner = createAction(
    '[Material] show material info page',
    props<{ materialId: string }>()
);

export const showStocktakingPage = createAction(
    '[Material] Show stocktaking page'
);

export const showMaterialList = createAction(
    '[Material] show materials list',
    props<{
        filter: MaterialListFilter;
    }>()
);

export const orderHistory = createAction('[Material] show order history');
export const stockChangeHistory = createAction(
    '[Material] show stock change history'
);
export const orderDetails = createAction(
    '[Material] show order history',
    props<{ materialOrderId: string }>()
);
