import { Injectable } from '@angular/core';
import {
    EntityActionOptions,
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { map, tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Defect } from './defect.model';
import { refresh } from '../../state/app.actions';

@Injectable({ providedIn: 'root' })
export class DefectEntityService extends EntityCollectionServiceBase<Defect> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions
    ) {
        super('Defect', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    public entitiesForList$ = this.entities$.pipe(
        map((defects) =>
            defects.map((defect) => {
                const def: Defect = JSON.parse(JSON.stringify(defect));
                if (def.createdAt) {
                    def.createdAt = new Date(def.createdAt);
                }
                if (def.repairedAt) {
                    def.repairedAt = new Date(def.repairedAt);
                }
                if (def.checkedAt) {
                    def.checkedAt = new Date(def.checkedAt);
                }
                return def;
            })
        )
    );

    public update(
        entity: Partial<Defect>,
        options?: EntityActionOptions
    ): Observable<Defect> {
        if (entity.repairedAt) {
            entity.repairedAt = moment(entity.repairedAt).format(
                'YYYY-MM-DD HH:mm:ss'
            );
        }
        if (entity.checkedAt) {
            entity.checkedAt = moment(entity.checkedAt).format(
                'YYYY-MM-DD HH:mm:ss'
            );
        }
        if (entity.createdAt) {
            entity.createdAt = moment(entity.createdAt).format(
                'YYYY-MM-DD HH:mm:ss'
            );
        }
        return super.update(entity, options);
    }
}
