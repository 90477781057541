import { Material } from './material-entity.model';

export function createMaterial(material: Partial<Material>): Material | null {
    if (!material.name) {
        return null;
    }
    return {
        name: material.name,
        id: material.id || undefined,
        note: material.note || undefined,
        itemNumber: material.itemNumber || undefined,
        manufacturerNumber: material.manufacturerNumber || undefined,
        manufacturerName: material.manufacturerName || undefined,
        barcode: material.barcode || undefined,
        unit: material.unit || undefined,
        orderAmount: material.orderAmount || undefined,
        usableTill: material.usableTill || undefined,
        supplier: material.supplier || undefined,
        purchasingPrice: material.purchasingPrice || undefined,
        materialLocations: material.materialLocations || [],
        locationName: material.locationName || undefined,
        currentStock: material.currentStock || undefined,
        assignedId: material.assignedId || undefined,
    };
}
