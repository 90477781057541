import {
    Directive,
    Input,
    TemplateRef,
    ViewContainerRef,
    OnInit,
} from '@angular/core';
import {
    Permission,
    accessLevelToNumbers,
    AccessRights,
} from './access-right.type';
import { AuthService } from './auth.service';
import { Store } from '@ngrx/store';
import { userAccessRights } from './auth.selectors';

@Directive({
    selector: '[sysHasRights]',
})
export class HasRightsDirective implements OnInit {
    @Input() sysHasRights!: Permission;

    constructor(
        private authService: AuthService,
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        private store: Store<any>
    ) {}

    ngOnInit(): void {
        this.store.select(userAccessRights).subscribe((accesRights) => {
            if (this.hasAccessRight(accesRights)) {
                this.viewContainerRef.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainerRef.clear();
            }
        });
        this.viewContainerRef.clear();
    }

    private hasAccessRight(accessRights?: AccessRights): boolean {
        if (!accessRights) {
            return false;
        }

        if (accessRights.isAdmin) {
            return true;
        }

        let userHasAccess = false;

        accessRights.permissions.forEach((permission) => {
            if (userHasAccess) {
                return;
            }
            if (permission.category === this.sysHasRights.category) {
                const userAccessLevel = accessLevelToNumbers[permission.action];
                const requiredAccessLevel =
                    accessLevelToNumbers[this.sysHasRights.action];
                userHasAccess = userAccessLevel >= requiredAccessLevel;
            }
        });

        return userHasAccess;
    }
}
