import * as moment from 'moment';
import { Worktime } from './worktime.model';

export function createWorktime(worktime: Worktime): Worktime {
    return {
        id: worktime.id || undefined,
        start: moment(worktime.start).toDate(),
        end: worktime.end ? moment(worktime.end).toDate() : undefined,
        worktimeType: worktime.worktimeType,
        employee: worktime.employee,
        facility: worktime.facility,
        worktimeBreaks: worktime.worktimeBreaks || [],
        isPikett: worktime.isPikett,
        shiftStartMinute: worktime.shiftStartMinute || undefined,
        shiftEndMinute: worktime.shiftEndMinute || undefined,
    };
}
