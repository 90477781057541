import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyState } from './company.reducer';

export const selectCompanyState = createFeatureSelector<CompanyState>(
    'company'
);

export const companyData = createSelector(
    selectCompanyState,
    (company) => company.companyData
);
