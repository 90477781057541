import { TransportModel } from '../transports';
import { DistanceType } from '../../types';
import { OfferPosition } from './offer-position.model';
import { Company } from '../company';
import * as moment from 'moment';
import { translate } from '@ngneat/transloco';

export function getOfferPositions(
    transport: TransportModel,
    company: Company,
    distance?: DistanceType
): OfferPosition[] | undefined {
    if (!distance || !transport.tariffVersion) {
        return;
    }
    const offerPositions = [];
    let position = 1;

    let total = 0;

    transport.tariffVersion.lumpsums.forEach((lumpsum) => {
        offerPositions.push({
            position,
            description: lumpsum.name,
            amount: 1,
            unit: 'pauschal',
            pricePerUnit: lumpsum.price,
            sum: lumpsum.price,
        });
        position++;
        total += lumpsum.price;
        if (transport.secondEmployee && lumpsum.perEmployee) {
            offerPositions.push({
                position,
                description: lumpsum.name,
                amount: 1,
                unit: 'pauschal',
                pricePerUnit: lumpsum.price,
                sum: lumpsum.price,
            });
            position++;
            total += lumpsum.price;
        }
    });

    transport.tariffVersion.variableCosts.forEach((variableCost) => {
        let baseAmount =
            variableCost.unit === 'km' ? distance.km : distance.minutes;
        if (variableCost.amountInclusive) {
            baseAmount -= variableCost.amountInclusive;
        }
        const amount = Math.ceil(baseAmount / variableCost.unitAmount);

        const sum = amount * variableCost.price;
        if (sum > 0) {
            offerPositions.push({
                position,
                description: variableCost.name,
                amount: baseAmount,
                unit: translate(variableCost.unit),
                pricePerUnit: variableCost.price,
                sum,
            });
            position++;
            total += sum;

            if (
                variableCost.nightSundayHolidayFeeRelevant &&
                company.nightStart &&
                company.nightEnd &&
                transport.tariffVersion?.nightFeePercent
            ) {
                const transportHourAndMinutes = moment(
                    transport.plannedStart
                ).format('HHmm');
                const nightStartHourAndMinutes = company.nightStart + '00';
                const nightEndHourAndMinutes = company.nightEnd + '00';
                const isNight =
                    nightStartHourAndMinutes <= transportHourAndMinutes &&
                    transportHourAndMinutes < nightEndHourAndMinutes;
                if (isNight) {
                    const nightSum =
                        (sum * transport.tariffVersion.nightFeePercent) / 100;
                    offerPositions.push({
                        position,
                        description: variableCost.name + ' Nachtzuschlag',
                        amount: amount,
                        unit: variableCost.unit,
                        pricePerUnit:
                            (variableCost.price *
                                transport.tariffVersion.nightFeePercent) /
                            100,
                        sum: nightSum,
                    });
                    position++;
                    total += nightSum;
                }
            }

            if (
                variableCost.nightSundayHolidayFeeRelevant &&
                transport.tariffVersion?.sundayHolidayFeePercent
            ) {
                let isSundayOrHoliday =
                    moment(transport.plannedStart).weekday() === 6;
                if (company.holidays) {
                    company.holidays.split(',').forEach((holiday) => {
                        if (
                            holiday ===
                            moment(transport.plannedStart).format('DD.MM.YYYY')
                        ) {
                            isSundayOrHoliday = true;
                        }
                    });
                }
                if (isSundayOrHoliday) {
                    const sundayOrHolidaySum =
                        (sum *
                            transport.tariffVersion.sundayHolidayFeePercent) /
                        100;
                    offerPositions.push({
                        position,
                        description:
                            variableCost.name + ' Sonn-/Feiertagszuschlag',
                        amount: amount,
                        unit: variableCost.unit,
                        pricePerUnit:
                            (variableCost.price *
                                transport.tariffVersion
                                    .sundayHolidayFeePercent) /
                            100,
                        sum: sundayOrHolidaySum,
                    });
                    position++;
                    total += sundayOrHolidaySum;
                }
            }

            if (
                variableCost.nightSundayHolidayFeeRelevant &&
                transport.tariffVersion?.saturdayFeePercent
            ) {
                const isSaturday =
                    moment(transport.plannedStart).weekday() === 5;
                if (isSaturday) {
                    const saturdaySum =
                        (sum * transport.tariffVersion.saturdayFeePercent) /
                        100;
                    offerPositions.push({
                        position,
                        description: variableCost.name + ' Samstagszuschlag',
                        amount: amount,
                        unit: variableCost.unit,
                        pricePerUnit:
                            (variableCost.price *
                                transport.tariffVersion.saturdayFeePercent) /
                            100,
                        sum: saturdaySum,
                    });
                    position++;
                    total += saturdaySum;
                }
            }
        }

        if (transport.secondEmployee && variableCost.perEmployee) {
            let baseAmount2 =
                variableCost.unit === 'km' ? distance.km : distance.minutes;
            if (variableCost.amountInclusive) {
                baseAmount2 -= variableCost.amountInclusive;
            }
            const amount2 = Math.ceil(baseAmount2 / variableCost.unitAmount);

            const sum2 = amount2 * variableCost.price;
            if (sum2 > 0) {
                offerPositions.push({
                    position,
                    description: variableCost.name,
                    amount: baseAmount2,
                    unit: translate(variableCost.unit),
                    pricePerUnit: variableCost.price,
                    sum: sum2,
                });
                position++;
                total += sum2;

                if (
                    variableCost.nightSundayHolidayFeeRelevant &&
                    company.nightStart &&
                    company.nightEnd &&
                    transport.tariffVersion?.nightFeePercent
                ) {
                    const transportHourAndMinutes = moment(
                        transport.plannedStart
                    ).format('HHmm');
                    const nightStartHourAndMinutes = company.nightStart + '00';
                    const nightEndHourAndMinutes = company.nightEnd + '00';
                    const isNight =
                        nightStartHourAndMinutes <= transportHourAndMinutes &&
                        transportHourAndMinutes < nightEndHourAndMinutes;
                    if (isNight) {
                        const nightSum =
                            (sum2 * transport.tariffVersion.nightFeePercent) /
                            100;
                        offerPositions.push({
                            position,
                            description: variableCost.name + ' Nachtzuschlag',
                            amount: amount2,
                            unit: variableCost.unit,
                            pricePerUnit:
                                (variableCost.price *
                                    transport.tariffVersion.nightFeePercent) /
                                100,
                            sum: nightSum,
                        });
                        position++;
                        total += nightSum;
                    }
                }

                if (
                    variableCost.nightSundayHolidayFeeRelevant &&
                    transport.tariffVersion?.sundayHolidayFeePercent
                ) {
                    let isSundayOrHoliday =
                        moment(transport.plannedStart).weekday() === 6;
                    if (company.holidays) {
                        company.holidays.split(',').forEach((holiday) => {
                            if (
                                holiday ===
                                moment(transport.plannedStart).format(
                                    'DD.MM.YYYY'
                                )
                            ) {
                                isSundayOrHoliday = true;
                            }
                        });
                    }
                    if (isSundayOrHoliday) {
                        const sundayOrHolidaySum =
                            (sum2 *
                                transport.tariffVersion
                                    .sundayHolidayFeePercent) /
                            100;
                        offerPositions.push({
                            position,
                            description:
                                variableCost.name + ' Sonn-/Feiertagszuschlag',
                            amount: amount2,
                            unit: variableCost.unit,
                            pricePerUnit:
                                (variableCost.price *
                                    transport.tariffVersion
                                        .sundayHolidayFeePercent) /
                                100,
                            sum: sundayOrHolidaySum,
                        });
                        position++;
                        total += sundayOrHolidaySum;
                    }
                }

                if (
                    variableCost.nightSundayHolidayFeeRelevant &&
                    transport.tariffVersion?.saturdayFeePercent
                ) {
                    const isSaturday =
                        moment(transport.plannedStart).weekday() === 5;
                    if (isSaturday) {
                        const saturdaySum =
                            (sum2 *
                                transport.tariffVersion.saturdayFeePercent) /
                            100;
                        offerPositions.push({
                            position,
                            description:
                                variableCost.name + ' Sonn-/Feiertagszuschlag',
                            amount: amount2,
                            unit: variableCost.unit,
                            pricePerUnit:
                                (variableCost.price *
                                    transport.tariffVersion
                                        .saturdayFeePercent) /
                                100,
                            sum: saturdaySum,
                        });
                        position++;
                        total += saturdaySum;
                    }
                }
            }
        }
    });

    transport.tariffVersion.options.forEach((option) => {
        if (
            option.price &&
            transport.tariffOptions &&
            transport.tariffOptions[option.name] &&
            option.type !== 'select'
        ) {
            offerPositions.push({
                position,
                description: option.name,
                amount: 1,
                unit: 'pauschal',
                pricePerUnit: option.price,
                sum: option.price,
            });
            position++;
            total += option.price;
        }

        if (option.type === 'select') {
            const encoded = encodeURI(option.name);
            if (transport.tariffOptions && transport.tariffOptions[encoded]) {
                option.selectOptions?.forEach((selectOption: any) => {
                    if (selectOption.name === option.name) {
                        if (!selectOption.price) {
                            return;
                        }
                        if (selectOption.name === option.name) {
                            selectOption.name = `${selectOption.name}: ${option.name}`;
                            offerPositions.push({
                                position,
                                description: selectOption.name,
                                amount: 1,
                                unit: 'pauschal',
                                pricePerUnit: selectOption.price,
                                sum: selectOption.price,
                            });
                            position++;
                            total += selectOption.price;
                        }
                    }
                });
            }
        }
    });

    if (transport.tariffVersion.sundayHolidayFeeLump) {
        offerPositions.push({
            position,
            description: 'Sonn-/Feiertagszuschlag',
            amount: 1,
            unit: 'pauschal',
            pricePerUnit: transport.tariffVersion.sundayHolidayFeeLump,
            sum: transport.tariffVersion.sundayHolidayFeeLump,
        });
        position++;
        total += transport.tariffVersion.sundayHolidayFeeLump;
    }

    if (transport.tariffVersion.saturdayFeeLump) {
        offerPositions.push({
            position,
            description: 'Samstagszuschlag',
            amount: 1,
            unit: 'pauschal',
            pricePerUnit: transport.tariffVersion.saturdayFeeLump,
            sum: transport.tariffVersion.saturdayFeeLump,
        });
        position++;
        total += transport.tariffVersion.saturdayFeeLump;
    }

    if (transport.tariffVersion.nightFeeLump) {
        offerPositions.push({
            position,
            description: 'Nachtzuschlag',
            amount: 1,
            unit: 'pauschal',
            pricePerUnit: transport.tariffVersion.nightFeeLump,
            sum: transport.tariffVersion.nightFeeLump,
        });
        position++;
        total += transport.tariffVersion.nightFeeLump;
    }

    offerPositions.push({
        position: 'Total',
        sum: total,
    });

    return offerPositions;
}
