import { Vehicle } from '../../vehicles/vehicle-entity.model';
import { translate } from '@ngneat/transloco';

export function getNameOfEmployeeFromVehicle(
    vehicle: Vehicle,
    employeeType: string
): string {
    switch (employeeType) {
        case 'employee1':
            return vehicle?.employee1
                ? vehicle.employee1.firstName + ' ' + vehicle.employee1.lastName
                : translate('offen');
        case 'employee2':
            return vehicle?.employee2
                ? vehicle.employee2.firstName + ' ' + vehicle.employee2.lastName
                : translate('offen');
        case 'intern':
            return vehicle?.intern
                ? vehicle.intern.firstName + ' ' + vehicle.intern.lastName
                : translate('offen');
    }
    return '';
}
