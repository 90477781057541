import { createReducer, on } from '@ngrx/store';
import { setCompanyData } from './company.actions';
import { Company } from './company.model';

export interface CompanyState {
    companyData?: Company;
}

export const initialCompanyState: CompanyState = {
    companyData: undefined,
};

export const companyReducer = createReducer(
    initialCompanyState,
    on(setCompanyData, (state, action) => {
        return {
            ...state,
            companyData: action.companyData,
        };
    })
);
