import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { FuseNavigationService } from './navigation.service';
import { NavigationItem } from './navigation.type';

@Component({
    selector: 'sys-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuseNavigationComponent implements OnInit, OnDestroy {
    @Input() navigation: NavigationItem[] = [];

    private subscription = new Subscription();

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService
    ) {}

    ngOnInit(): void {
        this.navigation =
            this.navigation ||
            this._fuseNavigationService.getCurrentNavigation();

        const sub1 = this._fuseNavigationService.onNavigationChanged.subscribe(
            () => {
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                this._changeDetectorRef.markForCheck();
            }
        );
        this.subscription.add(sub1);

        const sub2 = merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).subscribe(() => {
            this._changeDetectorRef.markForCheck();
        });
        this.subscription.add(sub2);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
