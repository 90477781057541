import { Inject, Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { tap, switchMapTo, map } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { Action, Store } from '@ngrx/store';
import {
    AuthService,
    BadgesService,
    CompanyService,
    ENVIRONMENT,
    EventsService,
    initialCheckAuth,
    logout,
    setAccessRights,
    setCurrentWorktime,
    softLogin,
    TariffEntityService,
    userLogin,
} from '@sansys/crosslib';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable()
export class AuthEffects implements OnInitEffects {
    constructor(
        private injector: Injector,
        private translocoService: TranslocoService,
        private authService: AuthService,
        private action$: Actions,
        private companyService: CompanyService,
        private permissionsService: NgxPermissionsService,
        private badgesService: BadgesService,
        private eventsService: EventsService,
        private store: Store<any>,
        @Inject(ENVIRONMENT) public environment: { production: boolean }
    ) {}

    login$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(userLogin),
                tap((action) => {
                    if (!action.currentUser.user.company) {
                        return;
                    }

                    if (action.currentUser.transportLocation) {
                        this.manuallyInjectEntityService().fetchAndStoreOwnTariff();
                    }

                    this.companyService.getCompany().subscribe();

                    this.authService
                        .getCurrentWorkTime()
                        .subscribe((currentWorktime) =>
                            this.store.dispatch(
                                setCurrentWorktime({ currentWorktime })
                            )
                        );

                    this.authService
                        .getPermissions()
                        .subscribe((accessRights) => {
                            if (accessRights.isAdmin) {
                                this.permissionsService.addPermission('admin');
                            }
                            accessRights.permissions.forEach((permission) => {
                                const permissionString =
                                    permission.category +
                                    '|' +
                                    permission.action;
                                this.permissionsService.addPermission(
                                    permissionString
                                );
                            });
                            this.store.dispatch(
                                setAccessRights({ accessRights })
                            );
                        });
                    this.badgesService.initializeBadges();
                    if (
                        action.currentUser?.user?.company?.id &&
                        this.environment.production
                    ) {
                        this.eventsService.subscribeToEventStream(
                            action.currentUser.user.company.id
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    softLogin$ = createEffect(() =>
        this.action$.pipe(
            ofType(softLogin),
            switchMapTo(this.authService.softLogin()),
            map((currentUser) => {
                if (!currentUser) {
                    return logout();
                }
                return userLogin({ currentUser });
            })
        )
    );

    logout$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(logout),
                tap(() => {
                    this.authService.logout();
                    this.eventsService.unsubscribeFromEventStream();
                })
            ),
        { dispatch: false }
    );

    initialAuthCheck$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(initialCheckAuth),
                switchMapTo(this.authService.checkAuthentication())
            ),
        { dispatch: false }
    );

    private manuallyInjectEntityService(): TariffEntityService {
        return this.injector.get(TariffEntityService);
    }

    ngrxOnInitEffects(): Action {
        return initialCheckAuth();
    }
}
