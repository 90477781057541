import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, takeUntil } from 'rxjs/operators';
import * as fromFileUploadActions from './file-upload.actions';
import { FileUploadService } from './file-upload.service';
import { serializeError } from 'serialize-error';
import { UploadFailureAction } from './file-upload.actions';
import { ItemType, SysBaseEntityType } from '../../types';

@Injectable()
export class UploadFileEffects {
    @Effect()
    uploadRequestEffect$: Observable<Action> = this.actions$.pipe(
        ofType(fromFileUploadActions.UploadActionTypes.UPLOAD_REQUEST),
        concatMap((action) =>
            this.fileUploadService
                .uploadFile(
                    action.payload.file,
                    action.payload.fileType,
                    action.payload.item,
                    action.payload.type,
                    action.payload.fileName ?? 'Bild'
                )
                .pipe(
                    takeUntil(
                        this.actions$.pipe(
                            ofType(
                                fromFileUploadActions.UploadActionTypes
                                    .UPLOAD_CANCEL
                            )
                        )
                    ),
                    map((event) =>
                        UploadFileEffects.getActionFromHttpEvent(
                            event,
                            action.payload.item,
                            action.payload.type
                        )
                    ),
                    catchError((error) =>
                        of(UploadFileEffects.handleError(error))
                    )
                )
        )
    );

    constructor(
        private fileUploadService: FileUploadService,
        private actions$: Actions<fromFileUploadActions.UploadActions>
    ) {}

    private static getActionFromHttpEvent(
        event: HttpEvent<any>,
        sysEntity: Partial<SysBaseEntityType>,
        entityType: ItemType
    ): Action {
        switch (event.type) {
            case HttpEventType.Sent: {
                return new fromFileUploadActions.UploadStartedAction();
            }
            case HttpEventType.UploadProgress: {
                return new fromFileUploadActions.UploadProgressAction({
                    progress: event.total
                        ? Math.round((100 * event.loaded) / event.total)
                        : 0,
                });
            }
            case HttpEventType.ResponseHeader:
            case HttpEventType.Response: {
                if (event.status === 200 || event.status === 204) {
                    return new fromFileUploadActions.UploadCompletedAction({
                        item: sysEntity,
                        type: entityType,
                    });
                } else {
                    return new fromFileUploadActions.UploadFailureAction({
                        error: event.statusText,
                    });
                }
            }
            default: {
                return new fromFileUploadActions.UploadFailureAction({
                    error: `Unknown Event: ${JSON.stringify(event)}`,
                });
            }
        }
    }

    private static handleError(error: any): UploadFailureAction {
        const friendlyErrorMessage = serializeError(error).message;
        return new fromFileUploadActions.UploadFailureAction({
            error: friendlyErrorMessage,
        });
    }
}
