import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { MatInputModule } from '@angular/material/input';
import { DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { FormlyCustomModule } from './forms/formly-custom.module';
import { FuseDirectivesModule } from './directives/directives';

@NgModule({
    imports: [
        CommonModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        FormlyModule,
        MatInputModule,
        DateTimePickerModule,
        DropDownListModule,
        FormlyCustomModule,
        FuseDirectivesModule,
    ],
})
export class SharedModule {}
