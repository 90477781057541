/* tslint:disable:quotemark */
import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { errorOccured } from './error.actions';
import { Store } from '@ngrx/store';
import { ErrorService } from './error.service';
import { ENVIRONMENT } from '../injection-tokens';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private injector: Injector,
        @Inject(ENVIRONMENT) public environment: { production: boolean }
    ) {}

    handleError(err: Error | HttpErrorResponse): void {
        console.error('in global error handler');
        const errorService = this.injector.get(ErrorService);
        const store = this.injector.get(Store);

        let errorMessage;
        let stackTrace;
        let status;
        let refresh = false;

        if (err instanceof HttpErrorResponse) {
            // Server Error
            errorMessage = errorService.getServerMessage(err);
            stackTrace = errorService.getServerStack(err);
            status = err.status;
            refresh = true;
        } else {
            // Client Error
            errorMessage = errorService.getClientMessage(err);
            stackTrace = errorService.getClientStack(err);
            if (!this.environment.production) {
                console.log(err);
            }
        }

        if (errorMessage.includes('find variable: OneSignal')) {
            return; // TODO: Implement for Safari
        }

        if (errorMessage.includes('ChunkLoadError')) {
            if (
                confirm(
                    'Es ist eine neue Version der Anwendung verfügbar. Seite neu laden?'
                )
            ) {
                window.location.reload();
            }
            return;
        }

        if (
            errorMessage.includes('ExpressionChangedAfterItHasBeenCheckedError')
        ) {
            console.error('ExpressionChangedAfterItHasBeenCheckedError');
            return;
        }

        if (errorMessage.includes('Cannot read properties of undefined')) {
            console.error(errorMessage);
            return;
        }

        if (errorMessage.includes("evaluating 'this.inputWrapper.buttons'")) {
            console.error("evaluating 'this.inputWrapper.buttons'");
            return;
        }

        if (
            errorMessage.includes(
                "Cannot read properties of undefined (reading 'buttons')"
            )
        ) {
            console.error(
                "Cannot read properties of undefined (reading 'buttons')"
            );
            return;
        }

        if (
            errorMessage.includes("Cannot read property 'buttons' of undefined")
        ) {
            console.error("Cannot read property 'buttons' of undefined");
            return;
        }

        if (
            errorMessage.includes(
                "Cannot use 'in' operator to search for '__eventList' in undefined"
            )
        ) {
            console.error(
                "Cannot use 'in' operator to search for '__eventList' in undefined"
            );
            return;
        }

        console.error(err);

        const errorObject = {
            message: errorMessage,
            code: status,
            stackTrace,
            refresh,
        };

        store.dispatch(errorOccured(errorObject));
    }
}
