import { FormlyFieldConfig } from '@ngx-formly/core';
import {
    OptionTariffPosition,
    transportIsOpen,
    UserType,
} from '@sansys/crosslib';
import { translate } from '@ngneat/transloco';

function getPlaceholder(option: OptionTariffPosition): string | undefined {
    if (option.type !== 'select') {
        return undefined;
    }
    return option.mandatory
        ? option.name + ' ' + translate('auswählen (Pflichtfeld)')
        : option.name + ' ' + translate('auswählen');
}

export function getTariffOptionFormlyFieldConfig(
    option: OptionTariffPosition,
    index: number,
    userType: UserType
): FormlyFieldConfig {
    const selectOptions: {
        value: string;
        label: string;
    }[] = [];
    if (option.type === 'select') {
        option.selectOptions?.forEach((selectOption) => {
            selectOptions.push({
                label: selectOption.name,
                value: escape(selectOption.name),
            });
        });
    }
    return {
        key: 'tariffOptions.' + escape(option.name),
        type: option.type,
        className: 'mt-20',
        templateOptions: {
            label: option.name,
            placeholder: getPlaceholder(option),
            indeterminate: false,
            required: !!option.mandatory,
            options: selectOptions,
            appearance: option.type === 'checkbox' ? undefined : 'outline',
            floatLabel: option.type === 'checkbox' ? undefined : 'always',
            attributes: {
                'data-testid': 'option' + index,
            },
        },
        expressionProperties: {
            'templateOptions.disabled': (model: any) => {
                return (
                    (model.id && !transportIsOpen(model)) ||
                    (userType !== 'employee' && !!model.plannedStart)
                );
            },
        },
    };
}
