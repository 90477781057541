<main>
    <div id="container-1" class="container">
        <fuse-sidebar
            name="navbar"
            class="navbar-fuse-sidebar"
            [folded]="false"
            lockedOpen="gt-md"
            *ngIf="currentUser$ | async"
        >
            <sys-navbar class="left-navbar vertical-style"></sys-navbar>
        </fuse-sidebar>

        <div id="container-2" class="container">
            <div id="container-3" class="container">
                <sys-toolbar
                    class="mat-elevation-z4"
                    *ngIf="currentUser$ | async"
                ></sys-toolbar>

                <main class="mat-elevation-z1">
                    <sys-loading></sys-loading>
                    <router-outlet></router-outlet>
                </main>

                <!--                <sys-footer></sys-footer>-->
            </div>
        </div>
    </div>
</main>
