import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@sansys/crosslib';

@Component({
    selector: 'sys-complete-registration',
    templateUrl: './complete-registration.component.html',
    styleUrls: ['./complete-registration.component.scss'],
})
export class CompleteRegistrationComponent implements OnInit {
    title = 'Willkommen bei Sansys';
    mainText =
        'Ihre Registrierung wird abgeschlossen. Bitte haben Sie noch einen Moment Gedult.';
    logoUrl = 'assets/images/logos/sansys.png';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        const token = this.route.snapshot.queryParams['token'];
        if (!token) {
            this.router.navigate(['anmelden']);
            return;
        }
        this.authService
            .confirmRegistration(token)
            .subscribe(() => this.router.navigate(['home']));
    }
}
