export function hexToRgb(hex: string): [number, number, number] | null {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? [
              parseInt(result[1], 16),
              parseInt(result[2], 16),
              parseInt(result[3], 16),
          ]
        : null;
}

export function getContrastColor(
    rgbValue: [number, number, number]
): 'black' | 'white' {
    const color = Math.round(
        (rgbValue[0] * 299 + rgbValue[1] * 587 + rgbValue[2] * 114) / 1000
    );
    return color > 125 ? 'black' : 'white';
}

export function getContrastColorOfHexColor(
    hexColor?: string
): 'black' | 'white' {
    if (!hexColor) {
        return 'black';
    }
    const rgb = hexToRgb(hexColor);
    if (!rgb) {
        return 'black';
    }
    return getContrastColor(rgb);
}
