import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
    selector: 'sys-passenger-transfer-reason-autocomplete-type',
    template: `
        <ng-container #fieldComponent></ng-container>
        <div class="display-flex">
            <div class="flex-grow-1 m-form">
                <mat-form-field
                    class="w-100-p"
                    appearance="outline"
                    [floatLabel]="'always'"
                >
                    <mat-label>Verlegungsgrund</mat-label>
                    <input
                        type="text"
                        placeholder="Verlegungsgrund"
                        aria-label="Verlegungsgrund"
                        data-testid="transferReasonInput"
                        matInput
                        [formControl]="formControl"
                        [matAutocomplete]="auto"
                    />
                    <mat-autocomplete
                        autoActiveFirstOption
                        #auto="matAutocomplete"
                    >
                        <mat-option
                            *ngFor="let option of filteredOptions"
                            [value]="option"
                        >
                            {{ option }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <div
                    class="mat-error"
                    *ngIf="
                        formControl.status !== 'VALID' &&
                        formControl.touched &&
                        to.required
                    "
                >
                    Pflichtfeld, bitte ausfüllen.
                </div>
            </div>
        </div>
    `,
})
export class PassengerTransferReasonAutocompleteWrapperComponent extends FieldWrapper {
    public filteredOptions = [
        'Austritt',
        'Interne Stationsverlegung',
        'Rückverlegung in vorgängige Klinik',
        'Untersuch / Arzttermin',
        'Übertritt in die Rehabilitation',
    ];

    public formControl!: FormControl;

    constructor() {
        super();
    }
}
