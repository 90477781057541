<ng-container *ngxPermissionsOnly="getPermission(item.id)">
    <!-- normal collapsable -->
    <a class="nav-link" (click)="toggleOpen($event)">
        <span class="nav-link-title">
            {{ getItemInfo(item.id, 'title') | transloco }}
        </span>
        <span class="nav-link-badge" *ngIf="badges[item.id]">
            {{ badges[item.id] }}
        </span>
        <mat-icon
            class="collapsable-arrow"
            style="font-size: 1.2em; display: inline;"
            fontSet="fas"
            fontIcon="fa-chevron-right"
        ></mat-icon>
    </a>

    <div class="children" [@slideInOut]="isOpen">
        <ng-container *ngFor="let item of item.children">
            <ng-container>
                <sys-nav-vertical-item
                    *ngIf="item.type == 'item'"
                    [item]="item"
                ></sys-nav-vertical-item>
                <sys-nav-vertical-collapsable
                    *ngIf="item.type == 'collapsable'"
                    [item]="item"
                ></sys-nav-vertical-collapsable>
                <sys-nav-vertical-group
                    *ngIf="item.type == 'group'"
                    [item]="item"
                ></sys-nav-vertical-group>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
