import { Facility } from './facility.model';

export function createFacility(facility: Facility): Facility {
    return {
        id: facility.id || undefined,
        name: facility.name,
        street: facility.street || undefined,
        zip: facility.zip || undefined,
        city: facility.city || undefined,
        checklistTemplates: facility.checklistTemplates || undefined,
        checklist: facility.checklist || undefined,
    };
}
