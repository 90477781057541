import { Inject, Injectable } from '@angular/core';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { refresh } from '../../state/app.actions';
import { Station } from './station-entity.model';
import { Observable, of } from 'rxjs';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class StationEntityService extends EntityCollectionServiceBase<Station> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions,
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('Station', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    activateApiToken(station: Station): Observable<Station> {
        if (!station.id) {
            return of(station);
        }
        return this.http.post<Station>(
            `${this.server}api/stations/${station.id}/activateapi`,
            {}
        );
    }

    deactivateApiToken(station: Station): Observable<Station> {
        if (!station.id) {
            return of(station);
        }
        return this.http.post<Station>(
            `${this.server}api/stations/${station.id}/deactivateapi`,
            {}
        );
    }
}
