import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    HostBinding,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { FuseNavigationService } from '../navigation.service';
import { BadgesService, FEATURE_INFOS, Badges } from '@sansys/crosslib';
import { NavigationItem } from '../navigation.type';
import { Store } from '@ngrx/store';

@Component({
    selector: 'sys-nav-vertical-item[item]',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss'],
})
export class FuseNavVerticalItemComponent
    implements OnInit, AfterViewInit, OnDestroy {
    @HostBinding('class')
    classes = 'nav-item';

    badges: Badges = {
        schedulingOrders: 0,
        scheduling: 0,
    };

    @Input() item!: NavigationItem;

    private subscription = new Subscription();

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private store: Store<any>,
        private badgesService: BadgesService,
        @Inject(FEATURE_INFOS) private featureInfos: string
    ) {}

    ngOnInit(): void {
        this.subscription.add(
            this.badgesService.badges$.subscribe((badges) => {
                this.badges = badges;
                this._changeDetectorRef.markForCheck();
            })
        );
        this.subscription.add(
            merge(
                this._fuseNavigationService.onNavigationItemAdded,
                this._fuseNavigationService.onNavigationItemUpdated,
                this._fuseNavigationService.onNavigationItemRemoved
            ).subscribe(() => {
                this._changeDetectorRef.markForCheck();
            })
        );
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this._changeDetectorRef.detectChanges();
        }, 1000); // TODO: Why is this necessary?
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getItemInfo(id: string, type: string): string {
        if (
            (this.featureInfos as any) &&
            (this.featureInfos as any)[id] &&
            (this.featureInfos as any)[id][type]
        ) {
            return (this.featureInfos as any)[id][type];
        }
        return '';
    }

    getPermissions(id: string): string[] {
        let perm = [];
        if (
            (this.featureInfos as any) &&
            (this.featureInfos as any)[id] &&
            (this.featureInfos as any)[id]['permission']
        ) {
            let permissions = (this.featureInfos as any)[id]['permission'];
            if (typeof permissions === 'string') {
                permissions = [permissions];
            }
            perm = permissions;
        }
        return [...perm, 'admin'];
    }
}
