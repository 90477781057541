import { NgModule } from '@angular/core';

import { MailConfirmModule } from './mail-confirm/mail-confirm.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { LoginModule } from './login/login.module';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { RegisterModule } from './register/register.module';
import { CompleteRegistrationModule } from './complete-registration/complete-registration.module';

@NgModule({
    imports: [
        ForgotPasswordModule,
        LoginModule,
        MailConfirmModule,
        RegisterModule,
        ResetPasswordModule,
        CompleteRegistrationModule,
    ],
})
export class AuthPagesModule {}
