<sys-feature-container>
    <sys-feature-header [id]="id">
        <div feature-header-right>
            <button
                mat-raised-button
                (click)="addVersion()"
                class="mr-12"
                *ngIf="
                    !tariffVersion?.manual &&
                    ('tariffs|EDIT' | permission | async)
                "
            >
                <span>{{ 'Neue Version' | transloco }}</span>
            </button>
            <button
                mat-raised-button
                [disabled]="
                    tariffVersionForm.invalid || tariffVersionForm.pristine
                "
                (click)="saveTariffVersion()"
                class="mr-12"
                *ngIf="'tariffs|EDIT' | permission | async"
            >
                <span>{{ 'Speichern' | transloco }}</span>
            </button>
            <button mat-raised-button (click)="closeModal()">
                {{ 'Abbrechen' | transloco }}
            </button>
        </div>
    </sys-feature-header>

    <ng-container page-content>
        <div class="p-12">
            <ng-container *ngIf="!tariffVersion?.manual">
                <mat-form-field
                    appearance="outline"
                    floatLabel="always"
                    class="w-40-p pr-12"
                >
                    <mat-label>Tarif</mat-label>
                    <input
                        matInput
                        placeholder="Tarif"
                        [value]="tariff?.name"
                        [disabled]="true"
                    />
                </mat-form-field>

                <span class="w-10-p pr-12">
                    <label>Tariffarbe:</label>
                    <input
                        class="w-32 ml-12 color-picker"
                        data-testid="colorPicker"
                        [style.background]="color"
                        [(colorPicker)]="color"
                        (colorPickerClose)="colorPickerClose($event)"
                        cpAlphaChannel="disabled"
                        [cpOKButton]="true"
                    />
                </span>

                <mat-form-field
                    appearance="outline"
                    floatLabel="always"
                    class="w-25-p"
                >
                    <mat-label>Ausgewählte Version</mat-label>
                    <mat-select
                        #tariffVersionSelect
                        (selectionChange)="
                            changeTariffVersion(tariffVersionSelect.value)
                        "
                        [value]="tariffVersion?.id"
                    >
                        <mat-option
                            *ngFor="let tariffV of tariff?.tariffVersions"
                            [value]="tariffV.id"
                        >
                            {{ tariffV.version }}: Ab
                            {{ tariffV.validFrom | date }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <button
                    mat-raised-button
                    (click)="showDeleteVersionDialog()"
                    class="ml-12"
                    color="warn"
                    *ngIf="
                        tariffVersion &&
                        !tariffVersion?.hasInvoices &&
                        ('tariffs|EDIT' | permission | async)
                    "
                >
                    <span>Version löschen</span>
                </button>
            </ng-container>

            <form [formGroup]="tariffVersionForm">
                <formly-form
                    [form]="tariffVersionForm"
                    [fields]="tariffVersionFormFields"
                    [model]="tariffVersion"
                ></formly-form>
            </form>
        </div>
    </ng-container>
</sys-feature-container>
