import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
    selector: 'sys-formly-wrapper-panel',
    template: `
        <mat-card class="mb-12">
            <mat-card-header>
                <mat-card-title>
                    {{ to.label }}
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ng-container #fieldComponent></ng-container>
            </mat-card-content>
        </mat-card>
    `,
})
export class PanelWrapperComponent extends FieldWrapper {}
