import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
    createTariffVersion,
    TariffVersion,
    tariffVersionCreated,
    TariffVersionEntityService,
    TariffEntityService,
    Tariff,
    setAccessRights,
    CompanyService,
} from '@sansys/crosslib';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { getTariffVersionFormConfig } from './tariff-version.form-config';
import { Store } from '@ngrx/store';
import { Dialog, DialogUtility } from '@syncfusion/ej2-angular-popups';
import * as moment from 'moment';
import { combineLatest, Subscription } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { Actions, ofType } from '@ngrx/effects';
import { ColorPickerService } from 'ngx-color-picker';
import { translate } from '@ngneat/transloco';

@Component({
    selector: 'sys-tariff-version',
    templateUrl: './tariff-version.component.html',
    styleUrls: ['./tariff-version.component.scss'],
    providers: [ColorPickerService],
})
export class TariffVersionComponent implements OnInit, OnDestroy {
    private deleteConfirmationDialog: Dialog = new Dialog();
    private canEdit = false;
    private subscription = new Subscription();

    tariffVersionForm: FormGroup = new FormGroup({});
    tariffVersionFormFields: FormlyFieldConfig[] = [];
    id = 'invoicingTariffVersions';
    pageType = 'edit';
    tariffVersion?: TariffVersion;
    tariff?: Tariff;
    color = '#075380';

    constructor(
        private _formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private action$: Actions,
        private tariffVersionEntityService: TariffVersionEntityService,
        private tariffEntityService: TariffEntityService,
        public dialogRef: MatDialogRef<TariffVersionComponent>,
        private companyService: CompanyService,
        private store: Store<any>,
        private permissionService: NgxPermissionsService,
        @Inject(MAT_DIALOG_DATA)
        public options: { id: string; manual?: boolean }
    ) {}

    private initialize(id: string, canEdit: boolean): void {
        combineLatest([
            this.tariffVersionEntityService.getByKey(id),
            this.companyService.getCompany(),
        ]).subscribe({
            next: ([tariffVersion, company]) => {
                this.tariffVersion = JSON.parse(JSON.stringify(tariffVersion));
                const disabled = !!this.tariffVersion?.hasInvoices || !canEdit;
                this.tariffVersionFormFields = getTariffVersionFormConfig(
                    disabled,
                    company
                );
                if (!this.tariffVersion?.tariff?.id) {
                    return;
                }
                this.tariffEntityService
                    .getByKey(this.tariffVersion.tariff.id)
                    .subscribe({
                        next: (tariff) => {
                            this.tariff = JSON.parse(JSON.stringify(tariff));
                            if (tariff.color) {
                                this.color = tariff.color;
                            }
                        },
                    });
            },
        });
    }

    private setPermission(): void {
        this.permissionService
            .hasPermission(['tariffs|EDIT', 'admin'])
            .then((canEdit) => {
                this.canEdit = canEdit;
                if (this.options.id === 'new') {
                    this.pageType = 'new';
                    this.tariffVersion = {
                        manual: true,
                        version: 0,
                        lumpsums: [],
                        variableCosts: [],
                        variableCostsDegressive: [],
                        options: [],
                    };
                    this.tariff = {
                        name: translate('Manuell'),
                    };
                    this.companyService.getCompany().subscribe((company) => {
                        this.tariffVersionFormFields = getTariffVersionFormConfig(
                            !canEdit,
                            company
                        );
                    });
                } else {
                    this.initialize(this.options.id, canEdit);
                }
            });
    }

    ngOnInit(): void {
        this.setPermission();
        this.subscription.add(
            this.action$.pipe(ofType(setAccessRights)).subscribe(() => {
                this.setPermission();
            })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    closeModal(): void {
        this.dialogRef.close();
    }

    saveTariffVersion(): void {
        const tariffVersion = createTariffVersion({
            ...(this.tariffVersion
                ? { ...this.tariffVersion }
                : { ...this.tariffVersionForm.value }),
            tariffId: this.tariff?.id,
        });

        if (!tariffVersion) {
            return;
        }

        if (this.options.manual) {
            tariffVersion.manual = true;
        }

        if (this.pageType === 'edit') {
            this.tariffVersionEntityService
                .update(tariffVersion)
                .subscribe(() => {
                    this.tariffEntityService.load();
                    this.closeModal();
                });
        } else {
            this.tariffVersionEntityService
                .add(tariffVersion)
                .subscribe((newTariffVersion) => {
                    if (newTariffVersion.id) {
                        this.store.dispatch(
                            tariffVersionCreated({
                                tariffVersion: newTariffVersion,
                                manual: newTariffVersion.manual,
                            })
                        );
                    }
                    this.tariffEntityService.load();
                    this.closeModal();
                });
        }
    }

    addVersion(): void {
        if (!this.tariffVersion?.tariff?.id || !this.tariffVersion.version) {
            return;
        }
        const newTariffVersion = createTariffVersion({
            ...this.tariffVersion,
            tariffId: this.tariffVersion.tariff.id,
            version: this.tariffVersion.version + 1,
            validFrom: moment().format('YYYY-MM-DD'),
        });
        if (newTariffVersion) {
            this.tariffVersionEntityService
                .add(newTariffVersion)
                .subscribe((newTariffV) => {
                    if (newTariffV.id) {
                        this.initialize(newTariffV.id, this.canEdit);
                    }
                });
        }
    }

    showDeleteVersionDialog(): void {
        if (this.tariffVersion) {
            this.deleteConfirmationDialog = DialogUtility.confirm({
                title: '',
                content: translate('Eintrag löschen?'),
                okButton: {
                    text: translate('OK'),
                    click: () => {
                        if (this.tariffVersion) {
                            this.tariffVersionEntityService
                                .delete(this.tariffVersion)
                                .subscribe(() => {
                                    this.tariffEntityService.load();
                                    this.closeModal();
                                });
                        }
                        this.deleteConfirmationDialog.hide();
                    },
                },
                cancelButton: {
                    text: translate('Abbrechen'),
                },
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'SlideTop' },
            });
        }
    }

    changeTariffVersion(id: string): void {
        this.initialize(id, this.canEdit);
    }

    colorPickerClose(color: string): void {
        if (!this.tariff) {
            return;
        }
        const tar = JSON.parse(JSON.stringify(this.tariff));
        tar.color = color;
        this.tariffEntityService.update(tar);
    }
}
