<sys-auth-page [title]="title" [mainText]="mainText">
    <ng-container right-content>
        <div id="authForm">
            <div class="logo" fxHide.gt-xs>
                <img src="assets/images/logos/sansys.png" alt="logo" />
            </div>

            <div class="title">Passwort wiederherstellen</div>

            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <formly-form
                    [form]="form"
                    [fields]="fields"
                    [model]="model"
                ></formly-form>

                <button
                    mat-raised-button
                    color="accent"
                    class="form__submit-button"
                    aria-label="Passwort zurücksetzen Link"
                    [disabled]="form.invalid"
                    type="submit"
                    data-testid="resetButton"
                >
                    Passwort wiederherstellen
                </button>
            </form>

            <div
                class="register"
                fxLayout="column"
                fxLayoutAlign="center center"
            >
                <a class="link" [routerLink]="'/forgot-password'"
                    >Zurück zur Anmeldung</a
                >
            </div>
        </div>
    </ng-container>
</sys-auth-page>
