import { Inject, Injectable, Injector } from '@angular/core';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { Actions, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { refresh } from '../../state/app.actions';
import { ChangeLog } from './change-log.model';
import { Observable } from 'rxjs';
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import { JwtInterceptor } from '../../auth';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';

@Injectable({ providedIn: 'root' })
export class ChangeLogEntityService extends EntityCollectionServiceBase<ChangeLog> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions,
        private injector: Injector,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('ChangeLog', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    entitiesForList$ = this.entities$.pipe(
        map((changeLogs) =>
            changeLogs.map((changeLog) => {
                const changeL: ChangeLog = JSON.parse(
                    JSON.stringify(changeLog)
                );
                changeL.searchTerms = moment(changeLog.createdAt).format(
                    'DD.MM.YYYY HH:mm:ss'
                );
                changeL.createdAt = new Date(changeLog.createdAt);
                return changeL;
            })
        )
    );

    public getGridDataSource(): Observable<DataManager> {
        const jwtInterceptor = this.injector.get(JwtInterceptor);
        return jwtInterceptor.jwtObservable.pipe(
            map((token) => {
                return new DataManager({
                    url: `${this.server}api/changelogs/query`,
                    headers: [
                        {
                            Authorization: 'Bearer ' + token,
                        },
                    ],
                    adaptor: new UrlAdaptor(),
                });
            })
        );
    }
}
