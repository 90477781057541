import { Employee } from './employee-entity.model';

export function createEmployee(employee: Employee): Employee {
    return {
        id: employee.id || undefined,
        ahvNumber: employee.ahvNumber || undefined,
        birthday: employee.birthday,
        bvg: employee.bvg || undefined,
        childrenBonus: employee.childrenBonus || undefined,
        city: employee.city || undefined,
        civilStatus: employee.civilStatus || undefined,
        cloths: employee.cloths || undefined,
        comment: employee.comment || undefined,
        department: employee.department || undefined,
        driversLicenceType: employee.driversLicenceType || undefined,
        email: employee.email || undefined,
        employeeCategory: employee.employeeCategory || undefined,
        employeeGroup: employee.employeeGroup || undefined,
        entryDate: employee.entryDate || undefined,
        exitDate: employee.exitDate || undefined,
        worktimesResponsible: employee.worktimesResponsible || false,
        firstName: employee.firstName,
        gender: employee.gender || undefined,
        gratification: employee.gratification || undefined,
        holidayBonus: employee.holidayBonus || undefined,
        publicHolidayBonus: employee.publicHolidayBonus || undefined,
        holidaysPerYear: employee.holidaysPerYear || undefined,
        homeTown: employee.homeTown || undefined,
        hourlySalary: employee.hourlySalary || undefined,
        hoursPerYear: employee.hoursPerYear || undefined,
        ibanNumber: employee.ibanNumber || undefined,
        keyys: employee.keyys || undefined,
        lastName: employee.lastName,
        medCompetencyValidTill: employee.medCompetencyValidTill || undefined,
        mobile: employee.mobile || undefined,
        monthlySalary: employee.monthlySalary || undefined,
        pikettSalary: employee.pikettSalary || undefined,
        nationality: employee.nationality || undefined,
        pager: employee.pager || undefined,
        password: employee.password || undefined,
        personellId: employee.personellId || undefined,
        phone: employee.phone || undefined,
        salaryType: employee.salaryType || undefined,
        sendNewAssignmentEmail: employee.sendNewAssignmentEmail || undefined,
        sendNewAssignmentMessage:
            employee.sendNewAssignmentMessage || undefined,
        isAdmin: employee.isAdmin || undefined,
        sendNewAssignmentSms: employee.sendNewAssignmentSms || undefined,
        sourceTax: employee.sourceTax || undefined,
        street: employee.street || undefined,
        thirteenthSalary: employee.thirteenthSalary || undefined,
        pensionary: employee.pensionary || undefined,
        type: employee.type || undefined,
        username: employee.username || undefined,
        workFunction: employee.workFunction || undefined,
        workingPercentage: employee.workingPercentage,
        zip: employee.zip || undefined,
        profileImage: employee.profileImage || undefined,
        company: employee.company,
        user: employee.user,
        permissions: employee.permissions || undefined,
        mobilePermissions: employee.mobilePermissions || undefined,
        files: employee.files || [],
        reductions: employee.reductions || undefined,
        grossAdjustments: employee.grossAdjustments || undefined,
        expenses: employee.expenses || undefined,
        webAccess: employee.webAccess || undefined,
        mobileAccess: employee.mobileAccess || undefined,
        manageFullWarehouse: employee.manageFullWarehouse || undefined,
        bookMainLocation: employee.bookMainLocation || undefined,
        enabledForShiftScheduler:
            employee.enabledForShiftScheduler || undefined,
        facilities: employee.facilities || undefined,
        regions: employee.regions || undefined,
    };
}
