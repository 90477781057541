import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationsState } from './notifications.reducer';

export const selectNotificationsState = createFeatureSelector<
    NotificationsState
>('notifications');

export const currentUnreadNotifications = createSelector(
    selectNotificationsState,
    (notificationsState) => notificationsState.unreadRapports
);
