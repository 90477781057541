import { ChecklistTemplate } from './checklist-template.model';

export function compareChecklistTemplatesByName(
    a: ChecklistTemplate,
    b: ChecklistTemplate
): number {
    const clA = a.name.toUpperCase();
    const clB = b.name.toUpperCase();

    let comparison = 0;
    if (clA > clB) {
        comparison = 1;
    } else if (clA < clB) {
        comparison = -1;
    }
    return comparison;
}
