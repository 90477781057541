import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { RegisterComponent } from './register.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthPageModule, SharedModule } from '@sansys/webapputils';
import { FormlyModule } from '@ngx-formly/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { TranslocoRootModule } from '@sansys/crosslib';

@NgModule({
    declarations: [RegisterComponent],
    imports: [
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        ReactiveFormsModule,
        SharedModule,
        RouterModule,
        AuthPageModule,
        FormlyModule,
        FlexModule,
        FlexLayoutModule,
        TranslocoRootModule,
    ],
})
export class RegisterModule {}
