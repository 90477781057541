import { Inject, Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';
import { PermissionRoleModel } from './permission-role.model';
import { Employee } from '../employees';

@Injectable({ providedIn: 'root' })
export class PermissionRoleEntityService {
    constructor(
        private action$: Actions,
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {}

    getPermissionRoles(): Observable<PermissionRoleModel[]> {
        return this.http.get<PermissionRoleModel[]>(
            this.server + 'api/permissionroles/'
        );
    }

    createPermissionRole(
        employee: Employee,
        name: string
    ): Observable<PermissionRoleModel> {
        return this.http.post<PermissionRoleModel>(
            this.server + 'api/permissionroles/',
            { name, employeeId: employee.id }
        );
    }

    loadPermissionRole(
        employee: Employee,
        name: string
    ): Observable<PermissionRoleModel> {
        return this.http.post<PermissionRoleModel>(
            this.server + 'api/permissionroles/load',
            { name, employeeId: employee.id }
        );
    }

    deletePermissionRole(
        permissionRoleModel: PermissionRoleModel
    ): Observable<null> {
        return this.http.delete<null>(
            this.server + 'api/permissionroles/' + permissionRoleModel
        );
    }
}
