import { Inject, Injectable, Injector } from '@angular/core';
import {
    EntityActionOptions,
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
    EntitySelectorsFactory,
    QueryParams,
} from '@ngrx/data';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { InsuranceEntityService } from '../insurances/insurance-entity.service';
import { refresh } from '../../state/app.actions';
import * as moment from 'moment';
import { Passenger } from './passenger-entity.model';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import { JwtInterceptor } from '../../auth';

export const passengerSelector = new EntitySelectorsFactory().create<Passenger>(
    'Passenger'
);

@Injectable({ providedIn: 'root' })
export class PassengerEntityService extends EntityCollectionServiceBase<Passenger> {
    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    passengersWithFullNames$ = this.entities$.pipe(
        map((passengers) =>
            passengers.map((passenger) => {
                const pass = JSON.parse(JSON.stringify(passenger));
                pass.fullName = pass.firstName + ' ' + pass.lastName;
                return pass;
            })
        )
    );

    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private insuranceEntityService: InsuranceEntityService,
        private action$: Actions,
        private http: HttpClient,
        private injector: Injector,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('Passenger', serviceElementsFactory);
    }

    add(entity: Partial<Passenger>): Observable<Passenger> {
        const pass = JSON.parse(JSON.stringify(entity));
        pass.birthday = moment(entity.birthday).format('YYYY-MM-DD');
        return super.add(pass);
    }

    update(
        entity: Partial<Passenger>,
        options?: EntityActionOptions
    ): Observable<Passenger> {
        const pass = JSON.parse(JSON.stringify(entity));
        pass.birthday = moment(entity.birthday).format('YYYY-MM-DD');
        return super.update(pass, options);
    }

    getWithQueryAsync(queryParams: QueryParams): Observable<Passenger[]> {
        return this.http.get<Passenger[]>(this.server + 'api/passengers/', {
            params: queryParams,
        });
    }

    getDropdownListDataSource(): Observable<DataManager> {
        const jwtInterceptor = this.injector.get(JwtInterceptor);
        return jwtInterceptor.jwtObservable.pipe(
            map((token) => {
                return new DataManager({
                    url: `${this.server}api/passengers/query`,
                    headers: [
                        {
                            Authorization: 'Bearer ' + token,
                        },
                    ],
                    adaptor: new UrlAdaptor(),
                });
            })
        );
    }
}
