import { WorktimeType } from './company.model';

export function getWorktimeTypeLengthInMinutes(
    worktimeType: WorktimeType,
    workingPercentage: number
): number {
    if (!worktimeType.workingPercentageRelevantOnShiftSchedule) {
        workingPercentage = 100;
    }
    if (worktimeType.minutesPerDay) {
        return (worktimeType.minutesPerDay * workingPercentage) / 100;
    }
    if (worktimeType.endMinute && worktimeType.startMinute) {
        let length = Math.abs(
            worktimeType.endMinute - worktimeType.startMinute
        );
        if (
            worktimeType.fixedBreakMinutesStart &&
            length > worktimeType.fixedBreakMinutesStart &&
            worktimeType.fixedBreakMinutes
        ) {
            length -= worktimeType.fixedBreakMinutes;
        }
        return (length * workingPercentage) / 100;
    }
    return 0;
}
