import { Injectable } from '@angular/core';
import {
    EntityActionOptions,
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { refresh } from '../../state/app.actions';
import { Insurance } from './insurance-entity.model';

@Injectable({ providedIn: 'root' })
export class InsuranceEntityService extends EntityCollectionServiceBase<Insurance> {
    constructor(
        private serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions
    ) {
        super('Insurance', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    update(
        entity: Partial<Insurance>,
        options?: EntityActionOptions
    ): Observable<Insurance> {
        if (entity.discountInPercent) {
            entity.discountInHundrethPercent = entity.discountInPercent * 100;
        } else {
            entity.discountInHundrethPercent = 0;
        }
        return super.update(entity, options);
    }

    findInsurance(insuranceId: string): Observable<Insurance | undefined> {
        return this.entities$.pipe(
            map((insurances: Insurance[]) => {
                return insurances.find(
                    (insurance) => insurance.id === insuranceId
                );
            })
        );
    }
}
