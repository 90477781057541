import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import {
    API_BASE_FILE_SERVER,
    AuthService,
    Company,
    errorOccured,
} from '@sansys/crosslib';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getResetPasswordForm } from './reset-password.form';
import { translate, TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'sys-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
    title = 'Willkommen bei der SANSYS SOLUTIONS AG';
    mainText = 'Passwort zurücksetzen...';
    logoUrl = 'assets/images/logos/sansys.png';

    companyData$: Observable<Company | undefined> = new Observable<Company>();

    model: {
        newPassword: string;
    } = {
        newPassword: '',
    };

    form = new FormGroup({});
    fields: FormlyFieldConfig[] = [];

    constructor(
        private _formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private store: Store<any>,
        private activatedRoute: ActivatedRoute,
        private translocoService: TranslocoService,
        @Inject(API_BASE_FILE_SERVER) public fileServer: string
    ) {}

    ngOnInit(): void {
        const token = this.activatedRoute.snapshot.queryParams['token'];
        if (!token) {
            this.router.navigate(['home']);
        }
        this.fields = getResetPasswordForm(this.translocoService);
    }

    onSubmit(): void {
        const token = this.activatedRoute.snapshot.queryParams['token'];
        if (!token) {
            return;
        }
        this.authService
            .resetPassword(token, this.model.newPassword)
            .subscribe((u) => {
                this.router.navigate(['anmelden']);
                this.store.dispatch(
                    errorOccured({
                        message: 'Das Passwort wurde zurückgesetzt.',
                        title: 'Info',
                    })
                );
            });
    }
}
