<a
    class="nav-link"
    [attr.data-testid]="getItemInfo(item.id, 'url')"
    [routerLink]="[getItemInfo(item.id, 'url')]"
    [routerLinkActive]="['active', 'accent']"
    [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
    [target]="item.openInNewTab ? '_blank' : '_self'"
    *ngxPermissionsOnly="getPermissions(item.id)"
>
    <span class="mr-8"
        ><mat-icon
            class="nav-item__icon"
            fontSet="fa"
            fontIcon="{{ getItemInfo(item.id, 'fa_icon') }}"
        ></mat-icon
    ></span>

    <span class="nav-link-title">{{
        getItemInfo(item.id, 'title') | transloco
    }}</span>

    <span *ngIf="badges[item.id]" class="nav-link-badge">
        {{ badges![item.id] }}
    </span>
</a>
