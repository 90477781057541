import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { isLoggedIn } from '@sansys/crosslib';

@Component({
    selector: 'sys-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
    isLoggedIn$: Observable<boolean> = new Observable<boolean>();

    constructor(private store: Store<any>) {}

    ngOnInit(): void {
        this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    }
}
