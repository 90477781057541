import { createAction, props } from '@ngrx/store';
import { Device } from './device-entity.model';

export const showAddDevicePage = createAction('[Device] show add page');
export const updateDeviceLocation = createAction(
    '[Device] update device location',
    props<{
        device: Device;
        locationType: 'home' | 'owner';
        selectedLocation?: string;
    }>()
);

export const showDeviceQuickFunctions = createAction(
    '[Device] show quick functions',
    props<{ device: Device; fullScreen?: boolean; showTasks?: boolean }>()
);

export const showDeviceDetailPages = createAction(
    '[Device] show details',
    props<{ deviceId: string }>()
);

export const showDevicesList = createAction('[Device] show list page');
export const showOwnDevicesList = createAction('[Device] show list page own');
export const showNotWorkingDevices = createAction('[Device] show not working');
export const showDeviceDetailsScanner = createAction(
    '[Device] show details scanner'
);
export const setDeviceNotWorking = createAction(
    '[Device] set is broken',
    props<{
        device: Device;
        notWorking: boolean;
        currentUserFullName: string;
        isModal?: boolean;
    }>()
);

export const deviceOwnerChangeHistory = createAction(
    '[Device] show owner change history'
);
