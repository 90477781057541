import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslocoService } from '@ngneat/transloco';
import { Passenger, transportIsOpen } from '@sansys/crosslib';
import * as moment from 'moment';

export const getHealthInsuranceOptions = (
    translocoService: TranslocoService
) => {
    return [
        {
            value: 'basic',
            label: translocoService.translate('Grund-versichert'),
        },
        {
            value: 'halfPrivate',
            label: translocoService.translate('Halb-privat-versichert'),
        },
        {
            value: 'private',
            label: translocoService.translate('Privat-versichert'),
        },
    ];
};

function getDisabled(model: any, isCustomer: boolean): boolean {
    return isCustomer && !transportIsOpen(model);
}

export function getPassengerFormConfig(
    translocoService: TranslocoService,
    isCustomer = false,
    disabled = false,
    isAdmin = false
): FormlyFieldConfig[] {
    return [
        {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
                {
                    className: 'flex-50 pr-12',
                    key: 'firstName',
                    type: 'input',
                    templateOptions: {
                        label: translocoService.translate('Vorname'),
                        placeholder: translocoService.translate('Vorname'),
                        required: true,
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'firstNameInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': (model: any) => {
                            return disabled || getDisabled(model, isCustomer);
                        },
                    },
                },
                {
                    className: 'flex-50',
                    key: 'lastName',
                    type: 'input',
                    templateOptions: {
                        label: translocoService.translate('Nachname'),
                        placeholder: translocoService.translate('Nachname'),
                        required: true,
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'lastNameInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': (model: any) => {
                            return disabled || getDisabled(model, isCustomer);
                        },
                    },
                },
            ],
        },
        {
            key: 'nursingHome',
            type: 'input',
            templateOptions: {
                label: translocoService.translate('Altersheim'),
                placeholder: translocoService.translate('Altersheim'),
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'nursingHomeInput',
                },
            },
            expressionProperties: {
                'templateOptions.disabled': (model: any) => {
                    return disabled || getDisabled(model, isCustomer);
                },
            },
            hideExpression:
                translocoService.translate('passenger') !== 'Patient/in',
        },
        {
            key: 'street',
            type: 'input',
            templateOptions: {
                label: translocoService.translate('Strasse'),
                placeholder: translocoService.translate('Strasse'),
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'streetInput',
                },
            },
            expressionProperties: {
                'templateOptions.disabled': (model: any) => {
                    return disabled || getDisabled(model, isCustomer);
                },
            },
        },
        {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
                {
                    className: 'flex-25 pr-12',
                    key: 'zip',
                    type: 'input',
                    templateOptions: {
                        label: translocoService.translate('PLZ'),
                        placeholder: translocoService.translate('PLZ'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'zipInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': (model: any) => {
                            return disabled || getDisabled(model, isCustomer);
                        },
                    },
                },
                {
                    className: 'flex-75',
                    key: 'city',
                    type: 'input',
                    templateOptions: {
                        label: translocoService.translate('Ort'),
                        placeholder: translocoService.translate('Ort'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'cityInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': (model: any) => {
                            return disabled || getDisabled(model, isCustomer);
                        },
                    },
                },
            ],
        },
        {
            key: 'birthday',
            type: 'datePickerType',
            templateOptions: {
                label: translocoService.translate('Geburtstag'),
                placeholder: translocoService.translate('Geburtstag'),
                appearance: 'outline',
                floatLabel: 'always',
                required: true,
            },
            expressionProperties: {
                'templateOptions.disabled': (model: any) => {
                    return disabled || getDisabled(model, isCustomer);
                },
            },
            defaultValue: null,
            validators: {
                mustBePlausible: {
                    expression: (field: any) =>
                        moment(field.value).diff(moment('1900-01-01')) > 0,
                    message: (error: any, field: FormlyFieldConfig): string => {
                        if (field.formControl) {
                            return translocoService.translate(
                                'Geburtstag nach 1900 sein.'
                            );
                        }
                        return '';
                    },
                },
                mustBePlausible2: {
                    expression: (field: any) =>
                        moment(field.value).diff(moment()) < 0,
                    message: (error: any, field: FormlyFieldConfig): string => {
                        if (field.formControl) {
                            return translocoService.translate(
                                'Geburtstag muss in der Vergangenheit sein.'
                            );
                        }
                        return '';
                    },
                },
            },
        },
        {
            key: 'warningMessage',
            type: 'input',
            templateOptions: {
                label: translocoService.translate('Warnungstext'),
                placeholder: translocoService.translate('Warnungstext'),
                appearance: 'outline',
                floatLabel: 'always',
            },
            hideExpression: isCustomer,
        },
        {
            key: 'employer',
            type: 'input',
            templateOptions: {
                label: translocoService.translate('Arbeitgeber'),
                placeholder: translocoService.translate('Arbeitgeber'),
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'employerInput',
                },
            },
            expressionProperties: {
                'templateOptions.disabled': (model: any) => {
                    return disabled || getDisabled(model, isCustomer);
                },
            },
        },
        {
            key: 'representative',
            type: 'textarea',
            templateOptions: {
                label: translocoService.translate(
                    'Beistand / Rechnungsempfänger (schreibgeschützt)'
                ),
                placeholder: translocoService.translate(
                    'Beistand / Rechnungsempfänger'
                ),
                appearance: 'outline',
                floatLabel: 'always',
                autosize: true,
                autosizeMinRows: 2,
                autosizeMaxRows: 6,
                attributes: {
                    'data-testid': 'representativeInput',
                },
            },
            hideExpression: (model: Passenger) => {
                return !model.representative;
            },
            expressionProperties: {
                'templateOptions.disabled': () => {
                    return !isAdmin;
                },
            },
        },
        {
            key: 'repInstitution',
            type: 'input',
            templateOptions: {
                label: translocoService.translate(
                    'Beistand / Rechnungsempfänger Institution'
                ),
                placeholder: translocoService.translate(
                    'Beistand / Rechnungsempfänger Institution'
                ),
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'repInstitutionInput',
                },
            },
            expressionProperties: {
                'templateOptions.disabled': (model: any) => {
                    return disabled || getDisabled(model, isCustomer);
                },
            },
        },
        {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
                {
                    className: 'flex-50 pr-12',
                    key: 'repFirstName',
                    type: 'input',
                    templateOptions: {
                        label: translocoService.translate(
                            'Beistand / Rechnungsempfänger Vorname'
                        ),
                        placeholder: translocoService.translate(
                            'Beistand / Rechnungsempfänger Vorname'
                        ),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'repFirstNameInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': (model: any) => {
                            return disabled || getDisabled(model, isCustomer);
                        },
                    },
                },
                {
                    className: 'flex-50',
                    key: 'repLastName',
                    type: 'input',
                    templateOptions: {
                        label: translocoService.translate(
                            'Beistand / Rechnungsempfänger Nachname'
                        ),
                        placeholder: translocoService.translate(
                            'Beistand / Rechnungsempfänger Nachname'
                        ),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'repLastNameInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': (model: any) => {
                            return disabled || getDisabled(model, isCustomer);
                        },
                    },
                },
            ],
        },
        {
            key: 'repStreet',
            type: 'input',
            templateOptions: {
                label: translocoService.translate(
                    'Beistand / Rechnungsempfänger Strasse'
                ),
                placeholder: translocoService.translate(
                    'Beistand / Rechnungsempfänger Strasse'
                ),
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'repStreetInput',
                },
            },
            expressionProperties: {
                'templateOptions.disabled': (model: any) => {
                    return disabled || getDisabled(model, isCustomer);
                },
            },
        },
        {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
                {
                    className: 'flex-25 pr-12',
                    key: 'repZip',
                    type: 'input',
                    templateOptions: {
                        label: translocoService.translate(
                            'Beistand / Rechnungsempfänger PLZ'
                        ),
                        placeholder: translocoService.translate(
                            'Beistand / Rechnungsempfänger PLZ'
                        ),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'repZipInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': (model: any) => {
                            return disabled || getDisabled(model, isCustomer);
                        },
                    },
                },
                {
                    className: 'flex-75',
                    key: 'repCity',
                    type: 'input',
                    templateOptions: {
                        label: translocoService.translate(
                            'Beistand / Rechnungsempfänger Ort'
                        ),
                        placeholder: translocoService.translate(
                            'Beistand / Rechnungsempfänger Ort'
                        ),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'repCityInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': (model: any) => {
                            return disabled || getDisabled(model, isCustomer);
                        },
                    },
                },
            ],
        },
        {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
                {
                    className: 'flex-50 pr-12',
                    key: 'phone',
                    type: 'input',
                    templateOptions: {
                        label: translocoService.translate('Telefon'),
                        placeholder: translocoService.translate('Telefon'),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'phoneInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': (model: any) => {
                            return disabled || getDisabled(model, isCustomer);
                        },
                    },
                },
                {
                    className: 'flex-50',
                    key: 'phoneWork',
                    type: 'input',
                    templateOptions: {
                        label: translocoService.translate('Telefon Arbeit'),
                        placeholder: translocoService.translate(
                            'Telefon Arbeit'
                        ),
                        appearance: 'outline',
                        floatLabel: 'always',
                        attributes: {
                            'data-testid': 'phoneWorkInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': (model: any) => {
                            return disabled || getDisabled(model, isCustomer);
                        },
                    },
                },
            ],
        },
        {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
                {
                    className: 'flex-50 pr-12',
                    key: 'healthInsurance.id',
                    wrappers: ['healthInsuranceAutocompleteWrapper'],
                    templateOptions: {
                        placeholder: translocoService.translate(
                            'Krankenversicherung'
                        ),
                        appearance: 'outline',
                        floatLabel: 'always',
                    },
                    expressionProperties: {
                        'templateOptions.disabled': (model: any) => {
                            return disabled || getDisabled(model, isCustomer);
                        },
                    },
                },
                {
                    className: 'flex-50',
                    key: 'healthInsuranceType',
                    type: 'select',
                    templateOptions: {
                        label: translocoService.translate(
                            'Krankenversicherung Art'
                        ),
                        placeholder: translocoService.translate(
                            'Krankenversicherung Art'
                        ),
                        appearance: 'outline',
                        floatLabel: 'always',
                        options: getHealthInsuranceOptions(translocoService),
                        attributes: {
                            'data-testid': 'healthInsuranceTypeInput',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.disabled': (model: any) => {
                            return disabled || getDisabled(model, isCustomer);
                        },
                    },
                },
            ],
        },
        {
            className: 'flex-50 pr-12',
            key: 'accidentInsurance.id',
            wrappers: ['accidentInsuranceAutocompleteWrapper'],
            templateOptions: {
                placeholder: translocoService.translate('Unfallversicherung'),
                appearance: 'outline',
                floatLabel: 'always',
            },
            expressionProperties: {
                'templateOptions.disabled': (model: any) => {
                    return disabled || getDisabled(model, isCustomer);
                },
            },
        },
    ];
}
