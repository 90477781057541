import { Inject, Injectable } from '@angular/core';
import {
    EntityActionOptions,
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MaterialLocation } from './material-location.model';
import { API_BASE_URL_TOKEN } from '../../../injection-tokens';
import { errorOccured } from '../../../error-handling/error.actions';
import { StockChange } from '../../stock-change/stock-change.model';
import { map, tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { currentEntityUpdated, refresh } from '../../../state';

@Injectable({ providedIn: 'root' })
export class MaterialLocationEntityService extends EntityCollectionServiceBase<MaterialLocation> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private http: HttpClient,
        private action$: Actions,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('MaterialLocation', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.getWithQuery({ filter: 'minReached' });
        })
    );

    entitiesForOrderSuggestionList$ = this.entities$.pipe(
        map((materialLocations) =>
            materialLocations.filter((materialLocation) => {
                if (!materialLocation.minStock) {
                    return false;
                }
                return (
                    !materialLocation.currentStock ||
                    materialLocation.currentStock < materialLocation.minStock
                );
            })
        ),
        map((materialLocations) =>
            materialLocations.map((materialLocation) => {
                const matLoc = JSON.parse(JSON.stringify(materialLocation));
                if (
                    materialLocation.maxStock &&
                    materialLocation.currentStock
                ) {
                    matLoc.orderSuggestion =
                        materialLocation.maxStock -
                        materialLocation.currentStock;
                } else {
                    matLoc.orderSuggestion = 0;
                }
                return JSON.parse(JSON.stringify(matLoc));
            })
        )
    );

    private checkMaterialLocationIntegrity(entity: MaterialLocation): boolean {
        if (!entity.materialId) {
            this.store.dispatch(
                errorOccured({
                    message: 'Lagerort konnte nicht aktualisiert werden.',
                })
            );
            return false;
        }
        if (
            (!entity.currentStock && entity.currentStock !== 0) ||
            entity.currentStock < 0
        ) {
            entity.currentStock = 0;
        }
        return true;
    }

    rebook(
        materialId: string,
        origLocation: MaterialLocation,
        tarLocation: MaterialLocation,
        stockChange: number
    ): Observable<any> {
        const originalLocation = { ...origLocation };
        const targetLocation = { ...tarLocation };
        if (!targetLocation.currentStock) {
            targetLocation.currentStock = 0;
        }
        if (!originalLocation.currentStock) {
            originalLocation.currentStock = 0;
        }
        originalLocation.currentStock =
            originalLocation.currentStock - stockChange;
        targetLocation.currentStock = targetLocation.currentStock + stockChange;
        originalLocation.materialId = materialId;
        targetLocation.materialId = materialId;
        return combineLatest([
            this.update(originalLocation),
            this.update(targetLocation),
        ]).pipe(
            tap(() =>
                this.store.dispatch(
                    currentEntityUpdated({
                        entityType: 'material',
                        id: materialId,
                    })
                )
            )
        );
    }

    update(entity: MaterialLocation): Observable<MaterialLocation> {
        if (!this.checkMaterialLocationIntegrity(entity)) {
            return EMPTY;
        }
        return this.http.put<MaterialLocation>(
            `${this.server}api/materials/${entity.materialId}/locations/${entity.id}`,
            entity
        );
    }

    // updateStock(entity: MaterialLocation): Observable<null> {
    //     return this.http.put<null>(
    //         `${this.server}api/materials/${entity.materialId}/locations/${entity.id}/stock`,
    //         entity
    //     );
    // }

    add(
        entity: MaterialLocation,
        options?: EntityActionOptions
    ): Observable<MaterialLocation> {
        if (!this.checkMaterialLocationIntegrity(entity)) {
            return EMPTY;
        }
        return this.http.post<MaterialLocation>(
            `${this.server}api/materials/${entity.materialId}/locations`,
            entity
        );
    }

    deleteMaterialLocation(entity: MaterialLocation): Observable<null> {
        if (!this.checkMaterialLocationIntegrity(entity)) {
            return EMPTY;
        }
        return this.http.delete<null>(
            `${this.server}api/materials/${entity.materialId}/locations/${entity.id}`
        );
    }

    // getLocationsForStocktaking(): Observable<LocationModel[]> {
    //     return this.http.get<LocationModel[]>(
    //         `${this.environment.server}/locations?type=material`
    //     );
    // }

    getStockChanges(): Observable<StockChange[]> {
        return this.http.get<StockChange[]>(`${this.server}api/stockchanges`);
    }
}
