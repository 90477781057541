import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { navigation } from './navbar/navigation';
import {
    CurrentUser,
    AuthState,
    currentUser,
    isLoggedIn,
} from '@sansys/crosslib';
import { select, Store } from '@ngrx/store';

@Component({
    selector: 'sys-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
    navigation: any;
    currentUser$: Observable<CurrentUser | undefined> = new Observable<
        CurrentUser | undefined
    >();
    isLoggedIn$: Observable<boolean>;

    constructor(private store: Store<AuthState>) {
        this.navigation = navigation;
        this.currentUser$ = this.store.select(currentUser);
        this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    }
}
