import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns/src/drop-down-base/drop-down-base-model';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { filter, first, switchMapTo, tap } from 'rxjs/operators';
import { CustomerAdministrationEntityService } from '@sansys/crosslib';
import { getSearchQuery } from '../../syncfusion/query.functions';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';

@Component({
    selector: 'sys-customer-administration-autocomplete-type',
    template: `
        <ng-container #fieldComponent></ng-container>
        <div class="mb-20">
            <ejs-dropdownlist
                #dropdown
                [showClearButton]="true"
                cssClass="e-outline"
                floatLabelType="Always"
                [dataSource]="data"
                [fields]="fields"
                [formControl]="formControl"
                [placeholder]="getPlaceholder()"
                [allowFiltering]="true"
                (filtering)="onFiltering($event)"
                data-testid="customerAdministrationAutocomplete"
                id="{{ field.key }}"
                [ngClass]="{
                    'required-field':
                        to.required && formControl.status !== 'VALID'
                }"
            ></ejs-dropdownlist>
            <div
                class="mat-error"
                *ngIf="
                    formControl.status !== 'VALID' &&
                    formControl.touched &&
                    to.required
                "
            >
                Pflichtfeld, bitte ausfüllen.
            </div>
        </div>
    `,
})
export class CustomerAdminitrationAutocompleteWrapperComponent
    extends FieldWrapper
    implements OnInit, AfterViewInit {
    @ViewChild('dropdown') public dropdownObject!: DropDownListComponent;
    public data: {
        [key: string]: object;
    }[] = [];
    public formControl!: FormControl;
    // maps the appropriate column to fields property
    public fields: FieldSettingsModel = { text: 'companyName', value: 'id' };
    // set the placeholder to the DropDownList input
    public text = 'Kundenadministration auswählen';
    // Bind the filter event
    public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
        e.updateData(this.data, getSearchQuery('companyName', e.text));
    };

    constructor(
        private customerAdministrationEntityService: CustomerAdministrationEntityService
    ) {
        super();
    }

    ngAfterViewInit(): void {
        const dropdownInput = this.dropdownObject.element.getElementsByTagName(
            'input'
        )[0];
        if (dropdownInput && this.to.placeholder) {
            if (this.to.required) {
                dropdownInput.placeholder =
                    this.to.placeholder + ' wählen (Pflichtfeld)';
            } else {
                dropdownInput.placeholder = this.to.placeholder + ' wählen';
            }
        }
    }

    ngOnInit(): void {
        this.customerAdministrationEntityService.loaded$
            .pipe(
                filter((isLoaded) => isLoaded),
                switchMapTo(this.customerAdministrationEntityService.entities$),
                first()
            )
            .subscribe((customerAdministraions) => {
                this.data.length = 0;
                customerAdministraions.forEach((customerAdministraion) => {
                    this.data.push(customerAdministraion as any);
                });
            });

        this.customerAdministrationEntityService.loaded$
            .pipe(
                filter((isLoaded) => !isLoaded),
                first(),
                tap(() => {
                    this.customerAdministrationEntityService.load();
                })
            )
            .subscribe();
    }

    getPlaceholder(): string {
        if (this.to.placeholder) {
            if (this.to.required) {
                return this.to.placeholder + ' *';
            } else {
                return this.to.placeholder;
            }
        }
        return '';
    }
}
