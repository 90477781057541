import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import localeCh from '@angular/common/locales/de-CH';
import localeFr from '@angular/common/locales/fr-PF';
import { registerLocaleData } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { formlyWrappers, formlyTypes, ErrorEffects } from '@sansys/webapputils';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import {
    DefaultDataServiceConfig,
    EntityDataModule,
    HttpUrlGenerator,
} from '@ngrx/data';
import { LayoutModule } from './layout/layout.module';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { AuthPagesModule } from './auth/pages/auth-pages.module';
import {
    API_BASE_URL_TOKEN,
    AuthModule,
    FEATURE_INFOS,
    JwtInterceptor,
    metaReducers,
    PluralHttpUrlGenerator,
    reducers,
    TranslocoRootModule,
    API_BASE_FILE_SERVER,
    UploadFileStoreModule,
    GlobalErrorHandler,
    PLATFORM,
    NotificationsModule,
    HttpRequestInterceptor,
    MERCURE_URL,
    ENVIRONMENT,
    LocalStorageService,
} from '@sansys/crosslib';
import { featureInfos } from './layout/feature-info';
import { FeatureEffects } from './features/feature.effects';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { AuthEffects } from './auth/auth.effects';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { ToastrModule } from 'ngx-toastr';
import { MAT_DATE_LOCALE } from '@angular/material/core';

const defaultDataServiceConfig: DefaultDataServiceConfig = {
    root: environment.server + 'api/',
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LayoutModule,
        AuthModule,
        NotificationsModule,
        AuthPagesModule,
        NgxMatDatetimePickerModule,
        NgxMatMomentModule,

        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictActionSerializability: false,
                strictStateSerializability: true,
            },
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        NgxPermissionsModule.forRoot(),
        EffectsModule.forRoot([ErrorEffects, FeatureEffects, AuthEffects]),
        EntityDataModule.forRoot({}),
        ToastrModule.forRoot({
            timeOut: 10000,
            extendedTimeOut: 10000,
            progressBar: true,
            positionClass: 'toast-top-center',
        }),

        FormlyModule.forRoot({
            wrappers: formlyWrappers,
            types: formlyTypes,
            validationMessages: [{ name: 'required', message: 'Pflichtfeld' }],
            extras: { checkExpressionOn: 'modelChange' },
        }),
        FormlyMaterialModule,
        FormlyMatDatepickerModule,
        FormlyMatToggleModule,
        TranslocoRootModule,
        UploadFileStoreModule,
        NgHttpLoaderModule.forRoot(),
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: DefaultDataServiceConfig,
            useValue: defaultDataServiceConfig,
        },
        { provide: HttpUrlGenerator, useClass: PluralHttpUrlGenerator },
        {
            provide: API_BASE_URL_TOKEN,
            useValue: environment.server,
        },
        {
            provide: API_BASE_FILE_SERVER,
            useValue: environment.fileServer,
        },
        {
            provide: ENVIRONMENT,
            useValue: environment,
        },
        {
            provide: MERCURE_URL,
            useValue: environment.mercureUrl,
        },
        // {
        //     provide: ONESIGNAL_ID,
        //     useValue: environment.oneSignalId,
        // },
        {
            provide: PLATFORM,
            useValue: 'admin',
        },
        {
            provide: FEATURE_INFOS,
            useValue: featureInfos,
        },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            deps: [LocalStorageService],
            useFactory: (localStorageService: LocalStorageService) => {
                const language = localStorageService.getItem('admin-language');
                if (language === 'fr') {
                    return 'fr-PF';
                }
                return 'de-CH';
            },
        },
        {
            provide: MAT_DATE_LOCALE,
            deps: [LocalStorageService],
            useFactory: (localStorageService: LocalStorageService) => {
                const language = localStorageService.getItem('admin-language');
                if (language === 'fr') {
                    return 'fr-PF';
                }
                return 'de-CH';
            },
        },
    ],
})
export class AppModule {
    constructor() {
        registerLocaleData(localeCh, 'de-CH');
        registerLocaleData(localeFr, 'fr-PF');
    }
}
