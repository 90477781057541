import { Injectable } from '@angular/core';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { refresh, searchQueryChanged } from '../../state';
import { Keyy } from './keyy.model';
import { currentUser } from '../../auth';
import { select } from '@ngrx/store';
import { filterKeyys } from './keyy-helper.functions';
import { currentListOptions } from '../warehouse';

@Injectable({ providedIn: 'root' })
export class KeyyEntityService extends EntityCollectionServiceBase<Keyy> {
    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions
    ) {
        super('Keyy', serviceElementsFactory);
    }

    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    public filteredKeyys$ = combineLatest([
        this.entities$,
        this.action$.pipe(ofType(searchQueryChanged), startWith({ query: '' })),
        this.store.pipe(select(currentListOptions)),
        this.store.select(currentUser),
    ]).pipe(
        map(([entities, filter, listOptions, currentU]) => {
            if (!currentU) {
                return [];
            }
            return filterKeyys(
                entities,
                filter.query,
                listOptions.listOptions,
                currentU.user.firstName + ' ' + currentU.user.lastName
            );
        })
    );

    findKeyysOfEmployee(fullName: string): Observable<Keyy[]> {
        return this.entities$.pipe(
            map((keyys: Keyy[]) => {
                return keyys.filter((keyy) => keyy.home === fullName);
            })
        );
    }
}
