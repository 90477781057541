import { Inject, Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { PLATFORM } from '../injection-tokens';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
    tokenRefreshInProgress = new BehaviorSubject<boolean>(false);

    jwtObservable = new Observable<string>((subscriber) => {
        const now = Math.floor(Date.now() / 1000);
        if (
            this.authService.currentUserValue &&
            this.authService.currentUserValue.exp > now
        ) {
            subscriber.next(this.authService.currentUserValue.token);
            subscriber.complete();
        } else {
            if (this.tokenRefreshInProgress.value) {
                this.tokenRefreshInProgress
                    .pipe(
                        filter((refreshInProgress) => !refreshInProgress),
                        tap(() => {
                            subscriber.next(
                                this.authService.currentUserValue
                                    ? this.authService.currentUserValue.token
                                    : ''
                            );
                            subscriber.complete();
                        }),
                        first()
                    )
                    .subscribe();
            } else {
                this.tokenRefreshInProgress.next(true);
                this.authService.softLogin().subscribe(() => {
                    subscriber.next(
                        this.authService.currentUserValue
                            ? this.authService.currentUserValue.token
                            : ''
                    );
                    this.tokenRefreshInProgress.next(false);
                    subscriber.complete();
                });
            }
        }
    });

    private static getRequest(
        request: HttpRequest<any>,
        currentPlatform: string,
        token: string | null = null
    ): HttpRequest<any> {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
            setParams: {
                source: currentPlatform,
                XDEBUG_SESSION: 'PHPSTORM',
            },
        });
        return request;
    }

    constructor(
        private authService: AuthService,
        @Inject(PLATFORM) public platf: string
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (
            request.url.includes('login') ||
            request.url.includes('logout') ||
            request.url.includes('register') ||
            request.url.includes('refresh') ||
            request.url.includes('assets')
        ) {
            return next.handle(JwtInterceptor.getRequest(request, this.platf));
        }
        return this.jwtObservable.pipe(
            switchMap((token) => {
                return next.handle(
                    JwtInterceptor.getRequest(request, this.platf, token)
                );
            })
        );
    }
}
