import { Inject, Injectable, Injector } from '@angular/core';
import {
    EntityActionOptions,
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { Actions, ofType } from '@ngrx/effects';
import { refresh } from '../../state';
import { map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';
import { errorOccured } from '../../error-handling';
import { Invoice, InvoiceOverview } from './invoice.model';
import { createInvoice } from './create-invoice-entity.function';
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import { JwtInterceptor } from '../../auth';

@Injectable({ providedIn: 'root' })
export class InvoiceEntityService extends EntityCollectionServiceBase<Invoice> {
    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions,
        private http: HttpClient,
        private injector: Injector,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('Invoice', serviceElementsFactory);
    }

    update(
        entity: Partial<Invoice>,
        options?: EntityActionOptions
    ): Observable<Invoice> {
        const invoice = createInvoice(entity);
        if (!invoice) {
            this.store.dispatch(
                errorOccured({ message: 'Invoice could not be updated' })
            );
            // @ts-ignore
            return of(null);
        }
        this.removeOneFromCache(invoice);
        // @ts-ignore
        return of(null);
    }

    updatePositions(invoice: Invoice): Observable<Invoice> {
        return this.http.put<Invoice>(
            this.server + `api/invoices/${invoice.id}/updatepositions`,
            invoice
        );
    }

    getInvoiceHistory(invoiceId: string): Observable<Invoice[]> {
        return this.http.get<Invoice[]>(
            this.server + `api/invoices/${invoiceId}/history`
        );
    }

    public getGridDataSource(onlyLosses: boolean): Observable<DataManager> {
        const jwtInterceptor = this.injector.get(JwtInterceptor);
        return jwtInterceptor.jwtObservable.pipe(
            map((token) => {
                return new DataManager({
                    url:
                        `${this.server}api/invoices/query?onlyLosses=` +
                        onlyLosses,
                    headers: [
                        {
                            Authorization: 'Bearer ' + token,
                        },
                    ],
                    adaptor: new UrlAdaptor(),
                });
            })
        );
    }

    createOverview(
        type: 'pdf' | 'csv' | 'xlsx',
        options: InvoiceOverview
    ): Observable<string> {
        return this.http.post<string>(
            this.server + `api/invoices/overview/${type}`,
            options
        );
    }

    updateTariffVersion(
        invoice: Invoice,
        selectedTariffVersionId: string
    ): Observable<Invoice> {
        return this.http.post<Invoice>(
            this.server + `api/invoices/${invoice.id}/updatetariffversion`,
            { id: selectedTariffVersionId }
        );
    }
}
