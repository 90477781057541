import { Checklist } from './checklist.model';

export function createChecklist(checklist: Checklist): Checklist {
    return {
        id: checklist.id || undefined,
        checklistItems: checklist.checklistItems || undefined,
        repeatAfterDays: checklist.repeatAfterDays || undefined,
        name: checklist.name,
        facilityId: checklist.facilityId || undefined,
        vehicleId: checklist.vehicleId || undefined,
        completed: checklist.completed,
        connectedEntityName: checklist.connectedEntityName || undefined,
        connectedEntityType: checklist.connectedEntityType || undefined,
        checklistTemplateId: checklist.checklistTemplateId || undefined,
    };
}
