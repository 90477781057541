<div class="page-layout carded fullwidth inner-scroll content-wrapper">
    <div class="top-bg header-background"></div>

    <div class="center">
        <ng-content select="sys-feature-header"></ng-content>

        <div class="content-card mat-elevation-z1 h-100-p">
            <ng-content select="[page-content]"></ng-content>
            <ng-content select="[page-footer]"></ng-content>
        </div>
    </div>
</div>
