import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
    selector: 'sys-formly-repeat-section-with-buttons',
    template: `
        <div>
            <div
                *ngFor="let field of field.fieldGroup; let i = index"
                class="row align-items-baseline display-flex"
            >
                <formly-field
                    class="flex-grow-1"
                    [field]="field"
                ></formly-field>
                <div class="d-flex align-items-center">
                    <button
                        mat-raised-button
                        type="button"
                        color="warn"
                        (click)="remove(i)"
                        class="ml-12"
                    >
                        {{ 'Löschen' | transloco }}
                    </button>
                </div>
            </div>
            <button mat-raised-button type="button" (click)="add()">
                {{ 'Hinzufügen' | transloco }}
            </button>
        </div>
    `,
})
export class RepeatSectionWithButtonsTypeComponent extends FieldArrayType {}
