import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AuthService, currentUser } from '@sansys/crosslib';
import { noop } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { translate } from '@ngneat/transloco';

@Component({
    selector: 'sys-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    title = 'Willkommen bei Sansys';
    mainText = 'Bitte geben Sie rechts Ihre Anmelde-Daten ein.';

    model: {
        username: string;
        password: string;
        rememberMe: boolean;
    } = {
        username: '',
        password: '',
        rememberMe: false,
    };

    form = new FormGroup({});
    fields: FormlyFieldConfig[] = [
        {
            key: 'username',
            type: 'input',
            templateOptions: {
                label: translate('Benutzername'),
                placeholder: translate('Benutzername'),
                required: true,
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'username',
                },
            },
        },
        {
            key: 'password',
            type: 'input',
            templateOptions: {
                label: translate('Passwort'),
                type: 'password',
                placeholder: translate('Passwort'),
                required: true,
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'password',
                },
            },
        },
        {
            key: 'rememberMe',
            type: 'checkbox',
            defaultValue: false,
            templateOptions: {
                label: translate('Benutzername merken'),
            },
        },
    ];

    constructor(
        private authService: AuthService,
        private router: Router,
        private store: Store<any>
    ) {}

    ngOnInit(): void {
        this.store.pipe(select(currentUser)).subscribe((currentU) => {
            if (currentU) {
                this.router.navigate(['/home']);
            }
        });
        const rememberMeChecked = !!localStorage.getItem('rememberMeChecked');
        const rememberedUsername = localStorage.getItem('rememberedUsername');
        setTimeout(() => {
            this.form.setValue({
                username: rememberedUsername,
                password: '',
                rememberMe: rememberMeChecked,
            });
        });
    }

    onSubmit(): void {
        if (this.model.rememberMe) {
            localStorage.setItem('rememberedUsername', this.model.username);
            localStorage.setItem('rememberMeChecked', 'checked');
        } else {
            localStorage.removeItem('rememberedUsername');
            localStorage.removeItem('rememberMeChecked');
        }

        this.authService
            .login(this.model.username, this.model.password)
            .pipe(
                tap(() => {
                    this.router.navigate(['/home']);
                })
            )
            .subscribe(noop, (err) => {
                console.error(err);
            });
    }
}
