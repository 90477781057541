<div class="container" fxLayout="row" fxLayoutAlign="start">
    <div class="left-container" fxFlex fxHide fxShow.gt-xs>
        <div class="left-container__logo">
            <img alt="Logo" [src]="logoUrl" />
        </div>
        <div class="left-container__title">
            {{ title | transloco }}
        </div>
        <div class="left-container__description">
            <p>{{ mainText | transloco }}</p>
        </div>
    </div>

    <div class="right-container mat-elevation-z8">
        <button
            mat-stroked-button
            class="float-right m-12"
            (click)="switchLanguage()"
        >
            <mat-icon fontSet="fa" fontIcon="fa-language"></mat-icon>
        </button>
        <div class="right-container__auth-form">
            <ng-content select="[right-content]"></ng-content>
        </div>
    </div>
</div>
