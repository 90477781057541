import { FormlyFieldConfig } from '@ngx-formly/core';
import {
    OptionTariffPosition,
    Tariff,
    transportIsOpen,
    TransportModel,
    UserType,
} from '@sansys/crosslib';
import { translate } from '@ngneat/transloco';

function getPlaceholder(option: OptionTariffPosition): string | undefined {
    if (option.type === 'input') {
        return option.name;
    }
    if (option.type === 'select') {
        return option.mandatory
            ? option.name + ' auswählen (Pflichtfeld)'
            : option.name + ' auswählen';
    }
}

export function getTariffOptionsFormlyFieldConfig(
    tariff: Tariff,
    userType: UserType,
    transport: TransportModel,
    enabled: boolean = false
): FormlyFieldConfig[] {
    const arrayOptions: FormlyFieldConfig[] = [];

    tariff.currentTariffVersion?.options.forEach((option, optionIndex) => {
        if (userType === 'employee' || option.customerForm) {
            const selectOptions: {
                value: string | undefined;
                label: string;
            }[] = [];
            if (option.type === 'select') {
                let currentValue = '';
                if (transport?.tariffOptions) {
                    currentValue =
                        transport?.tariffOptions[escape(option.name)];
                }
                selectOptions.push({
                    value: undefined,
                    label: translate('(Kein Wert)'),
                });
                let found = false;
                option.selectOptions?.forEach((selectOption) => {
                    selectOptions.push({
                        label: selectOption.name,
                        value: escape(selectOption.name),
                    });
                    if (currentValue === escape(selectOption.name)) {
                        found = true;
                    }
                });
                if (!found && currentValue) {
                    selectOptions.push({
                        value: transport?.tariffOptions[escape(option.name)],
                        label: unescape(
                            transport?.tariffOptions[escape(option.name)]
                        ),
                    });
                }
            }
            if (option.type === 'vcReductionMinutes') {
                option = JSON.parse(JSON.stringify(option));
                option.type = 'input';
            }
            arrayOptions.push({
                key: 'tariffOptions.' + escape(option.name),
                type: option.type,
                className: 'mt-20-form',
                templateOptions: {
                    label: option.name,
                    placeholder: getPlaceholder(option),
                    indeterminate: false,
                    required:
                        !!option.mandatory &&
                        transport.tariff?.id === tariff.id,
                    options: selectOptions,
                    appearance:
                        option.type === 'checkbox' ? undefined : 'outline',
                    floatLabel:
                        option.type === 'checkbox' ? undefined : 'always',
                    attributes: {
                        'data-testid': 'option|' + optionIndex,
                    },
                },
                expressionProperties: {
                    'templateOptions.disabled': (model: any) => {
                        if (enabled) {
                            return false;
                        }
                        return (
                            transport.transportStatus === 'Weitergeleitet' ||
                            (userType !== 'employee' &&
                                ((model.id && !transportIsOpen(model)) ||
                                    !!model.plannedStart ||
                                    tariff.id !== transport.tariff?.id))
                        );
                    },
                },
            });
        }
    });

    return arrayOptions;
}
