import { TransportModel } from '../transport-entity.model';
import { translate } from '@ngneat/transloco';
import { CurrentUser } from '../../user';
import * as moment from 'moment';

export function getTransportAfterLocationChanged(
    currentTransport: TransportModel,
    changeType: any,
    currentUser?: CurrentUser
): TransportModel {
    const orderedStart = currentTransport.orderedStart
        ? moment(currentTransport.orderedStart).toISOString(true)
        : undefined;
    const plannedStart = currentTransport.plannedStart
        ? moment(currentTransport.plannedStart).toISOString(true)
        : undefined;
    currentTransport = JSON.parse(JSON.stringify(currentTransport));
    currentTransport.orderedStart = orderedStart;
    currentTransport.plannedStart = plannedStart;

    if (changeType === 'customerType') {
        currentTransport.customerLocation = {
            isManual: true,
            id: undefined,
            tariffs: [],
        };
    }
    if (changeType === 'startLocationType') {
        currentTransport.startStation = undefined;
        if (currentTransport.startLocationType === 'own') {
            if (currentUser?.station) {
                currentTransport.startStation = currentUser.station;
                currentTransport.startLocation =
                    currentUser.station.transportLocation;
            } else if (currentUser?.transportLocation) {
                currentTransport.startLocation = currentUser.transportLocation;
            }
        } else if (currentTransport.startLocationType === 'passenger') {
            if (!currentTransport.passenger) {
                currentTransport.startLocation = {
                    isManual: true,
                    id: undefined,
                    companyName:
                        'Bitte ' + translate('passenger') + ' auswählen.',
                    street: '',
                    zip: '',
                    city: '',
                    tariffs: [],
                };
            } else {
                currentTransport.startLocation = {
                    isManual: true,
                    id: undefined,
                    tariffs: [],
                    companyName:
                        currentTransport.passenger?.firstName +
                        ' ' +
                        currentTransport.passenger?.lastName,
                    street: currentTransport.passenger?.street,
                    zip: currentTransport.passenger?.zip,
                    city: currentTransport.passenger?.city,
                };
            }
            currentTransport.passengerIsStart = true;
        } else {
            currentTransport.startLocation = {
                isManual: true,
                id: undefined,
                tariffs: [],
            };
            currentTransport.passengerIsStart = false;
        }
    }
    if (changeType === 'targetLocationType') {
        currentTransport.targetStation = undefined;
        if (currentTransport.targetLocationType === 'passenger') {
            if (!currentTransport.passenger) {
                currentTransport.targetLocation = {
                    isManual: true,
                    id: undefined,
                    tariffs: [],
                    companyName:
                        'Bitte ' + translate('passenger') + ' auswählen.',
                    street: '',
                    zip: '',
                    city: '',
                };
            } else {
                currentTransport.targetLocation = {
                    isManual: true,
                    id: undefined,
                    tariffs: [],
                    companyName:
                        currentTransport.passenger?.firstName +
                        ' ' +
                        currentTransport.passenger?.lastName,
                    street: currentTransport.passenger?.street,
                    zip: currentTransport.passenger?.zip,
                    city: currentTransport.passenger?.city,
                };
            }
            currentTransport.passengerIsTarget = true;
        } else {
            currentTransport.targetLocation = {
                isManual: true,
                id: undefined,
                tariffs: [],
            };
            currentTransport.passengerIsTarget = false;
        }
        currentTransport.localService =
            currentTransport.targetLocationType === 'localService';
    }
    return JSON.parse(JSON.stringify(currentTransport));
}
