import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns/src/drop-down-base/drop-down-base-model';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { createInsurance, InsuranceEntityService } from '@sansys/crosslib';
import { filter, first, switchMapTo, tap } from 'rxjs/operators';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { Subscription } from 'rxjs';
import { getSearchQuery } from '../../syncfusion/query.functions';

@Component({
    selector: 'sys-accident-insurance-autocomplete-type',
    template: `
        <ng-container #fieldComponent></ng-container>
        <div class="mb-20">
            <ejs-dropdownlist
                [showClearButton]="true"
                cssClass="e-outline"
                floatLabelType="Always"
                [dataSource]="data"
                [fields]="fields"
                [formControl]="formControl"
                [placeholder]="to.placeholder ? to.placeholder : ''"
                [allowFiltering]="true"
                (filtering)="onFiltering($event)"
                #keyyDropdown
                data-testid="accidentInsuranceAutocomplete"
                [ngClass]="{
                    'required-field':
                        to.required && formControl.status !== 'VALID'
                }"
            >
                <ng-template #noRecordsTemplate>
                    <div *ngIf="noDropDownValue()">
                        <div>Neuen Eintrag erstellen?</div>
                        <button class="e-btn" (click)="onClick()" id="add">
                            Eintrag hinzufügen
                        </button>
                    </div>
                </ng-template>
            </ejs-dropdownlist>
            <div
                class="mat-error"
                *ngIf="
                    formControl.status !== 'VALID' &&
                    formControl.touched &&
                    to.required
                "
            >
                Pflichtfeld, bitte ausfüllen.
            </div>
        </div>
    `,
})
export class AccidentInsuranceAutocompleteWrapperComponent extends FieldWrapper
    implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('keyyDropdown') keyyDropdown!: DropDownListComponent;
    private subscription = new Subscription();
    public data: {
        [key: string]: object;
    }[] = [];
    public formControl!: FormControl;
    // maps the appropriate column to fields property
    public fields: FieldSettingsModel = { text: 'name', value: 'id' };
    // set the placeholder to the DropDownList input
    public text = 'KV auswählen';
    public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
        e.updateData(this.data, getSearchQuery('name', e.text));
    };

    constructor(private insuranceEntityService: InsuranceEntityService) {
        super();
    }

    ngAfterViewInit(): void {
        const dropdownInput = this.keyyDropdown.element.getElementsByTagName(
            'input'
        )[0];
        if (dropdownInput && this.to.placeholder) {
            if (this.to.required) {
                dropdownInput.placeholder =
                    this.to.placeholder + ' wählen (Pflichtfeld)';
            } else {
                dropdownInput.placeholder = this.to.placeholder + ' wählen';
            }
        }
    }

    ngOnInit(): void {
        this.subscription.add(
            this.insuranceEntityService.loaded$
                .pipe(
                    filter((isLoaded) => isLoaded),
                    switchMapTo(this.insuranceEntityService.entities$),
                    first()
                )
                .subscribe((insurances) => {
                    this.data.length = 0;
                    insurances.forEach((insurance) => {
                        if (insurance.insuranceType === 'UV') {
                            this.data.push(insurance as any);
                        }
                    });
                })
        );

        this.insuranceEntityService.loaded$
            .pipe(
                filter((isLoaded) => !isLoaded),
                first(),
                tap(() => {
                    this.insuranceEntityService.load();
                })
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onClick(): void {
        const newVal = (this.keyyDropdown as any).filterInput.value;

        const newInsurance = createInsurance({
            name: newVal,
            insuranceType: 'UV',
        });
        this.insuranceEntityService.add(newInsurance).subscribe((insurance) => {
            this.keyyDropdown.addItem(insurance as any);
            this.keyyDropdown.value = insurance as any;
        });
    }

    noDropDownValue(): boolean {
        return !!(this.keyyDropdown as any).filterInput?.value;
    }
}
