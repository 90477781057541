import { Passenger } from '@sansys/crosslib';
import * as moment from 'moment';

export function getPassengerAddress(passenger?: Passenger): string {
    let passengerAddress = '';
    if (!passenger) {
        return passengerAddress;
    }
    if (passenger.street) {
        passengerAddress += passenger.street + ',';
    }
    if (passenger.zip) {
        passengerAddress += ' ' + passenger.zip;
    }
    if (passenger.city) {
        passengerAddress += ' ' + passenger.city;
    }
    if (!passengerAddress) {
        passengerAddress = 'Ohne Adresse';
    }
    return passengerAddress;
}

export function getPassengerNameWithBirthday(passenger?: Passenger): string {
    if (!passenger) {
        return '';
    }
    return (
        passenger.firstName +
        ' ' +
        passenger.lastName +
        ' (' +
        moment(passenger.birthday).format('DD.MM.YYYY') +
        ')'
    );
}
