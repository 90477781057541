import { ToolbarItem } from '@syncfusion/ej2-angular-grids';
import { ToolbarItems } from '@syncfusion/ej2-grids/src/grid/base/enum';
import { ItemModel } from '@syncfusion/ej2-navigations';

export const transportToolbarOptions: (
    | ToolbarItems
    | string
    | ItemModel
    | ToolbarItem
)[] = [
    {
        text: 'Neu',
        tooltipText: 'Neuer Transport',
        prefixIcon: 'e-add',
        id: 'toolbarNew',
    },
    {
        text: 'Bearbeiten',
        tooltipText: 'Transport bearbeiten',
        prefixIcon: 'e-edit',
        id: 'toolbarEdit',
    },
    {
        text: 'Löschen',
        tooltipText: 'Transport(e) löschen',
        prefixIcon: 'e-delete',
        id: 'toolbarDelete',
    },
    'ExcelExport',
    'Search',
];

export const closedTransportToolbarOptions: (
    | ToolbarItems
    | string
    | ItemModel
    | ToolbarItem
)[] = ['ExcelExport', 'Search'];
