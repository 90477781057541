import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Badges } from './badges.model';
import { CompanyService } from '../features/company/company.service';

@Injectable({ providedIn: 'root' })
export class BadgesService {
    badges$ = new BehaviorSubject<Badges>({
        schedulingOrders: 0,
        scheduling: 0,
    });

    constructor(private companyService: CompanyService) {}

    // updateBadges(badges: Badges): void {
    //     this.transportEntityService.getOrders().subscribe(
    //         orders => this.badges$.next(badges)
    //     )
    // }

    initializeBadges(): void {
        this.companyService.getBadges().subscribe((badges) => {
            this.badges$.next(badges);
        });
    }
}
