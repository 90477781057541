import { TariffRepeaterFormlyTypeComponent } from './tariff-repeater-formly-type.component';
import { DateTimeTypeComponent } from './date-time-type.component';
import { DateTypeComponent } from './date-type.component';
import { OptionRepeaterFormlyTypeComponent } from './option-repeater-formly-type.component';
import { EditButtonTypeComponent } from './edit-button-type.component';
import { RepeatSectionTypeComponent } from './repeat-section-type.component';
import { DragDropRepeatSectionTypeComponent } from './drag-drop-repeat-section-type.component';
import { ProtocolRepeaterFormlyTypeComponent } from './protocol-repeater-formly-type.component';
import { TimeTypeComponent } from './time-type.component';
import { FormlyFieldButtonComponent } from './formly-field-button.component';
import { InvoiceItemRepeaterFormlyTypeComponent } from './invoice-item-repeater-formly-type.component';
import { RepeatSectionWithButtonsTypeComponent } from './repeat-section-with-buttons-type.component';

export const formlyTypes = [
    {
        name: 'dateTimePicker',
        component: DateTimeTypeComponent,
        wrappers: ['form-field'],
    },
    {
        name: 'timePicker',
        component: TimeTypeComponent,
        wrappers: ['form-field'],
    },
    {
        name: 'datePickerType',
        component: DateTypeComponent,
        wrappers: ['form-field'],
    },
    {
        name: 'sectionRepeater',
        component: TariffRepeaterFormlyTypeComponent,
        wrappers: ['form-field'],
    },
    {
        name: 'optionRepeater',
        component: OptionRepeaterFormlyTypeComponent,
        wrappers: ['form-field'],
    },
    {
        name: 'editButton',
        component: EditButtonTypeComponent,
        wrappers: ['form-field'],
    },
    {
        name: 'repeatSection',
        component: RepeatSectionTypeComponent,
        wrappers: ['form-field'],
    },
    {
        name: 'invoiceItemRepeatSection',
        component: InvoiceItemRepeaterFormlyTypeComponent,
        wrappers: ['form-field'],
    },
    {
        name: 'dragDropRepeatSection',
        component: DragDropRepeatSectionTypeComponent,
        wrappers: ['form-field'],
    },
    {
        name: 'protocolRepeater',
        component: ProtocolRepeaterFormlyTypeComponent,
        wrappers: ['form-field'],
    },
    {
        name: 'button',
        component: FormlyFieldButtonComponent,
        wrappers: ['form-field'],
    },
    {
        name: 'repeatWithButtons',
        component: RepeatSectionWithButtonsTypeComponent,
        wrappers: ['form-field'],
    },
];
