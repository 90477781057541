import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { LocalStorageService } from '@sansys/crosslib';

@Component({
    selector: 'sys-date-time-type',
    template: `
        <ejs-datepicker
            [format]="'dd.MM.yyyy'"
            [formControl]="formControl"
            (change)="onInit()"
            (created)="onInit()"
            (ngModelChange)="onInit()"
            [openOnFocus]="true"
            id="{{ field.key }}"
        ></ejs-datepicker>
    `,
})
export class DateTypeComponent extends FieldType {
    public formControl!: FormControl;

    constructor(private readonly localStorageService: LocalStorageService) {
        super();
    }

    private initialized = false;

    onInit(): void {
        if (!this.initialized && this.formControl.value) {
            this.initialized = true;
            this.formControl.setValue(
                moment(this.formControl.value).toISOString(true)
            );
        }
    }
}
