import * as moment from 'moment';
import { Moment } from 'moment';

export function minuteToTimeString(minutes: number): string {
    const sHour = Math.floor(minutes / 60);
    const sMinute = Math.round(minutes % 60);
    if (sMinute < 10) {
        return sHour + ':' + '0' + sMinute.toString();
    } else {
        return sHour + ':' + sMinute;
    }
}

export function minuteToMomentSameDay(minutes: number): Moment {
    const sHour = Math.floor(minutes / 60);
    const sMinute = minutes - sHour * 60;
    return moment().hours(sHour).minutes(sMinute);
}
