import { FormlyFieldConfig } from '@ngx-formly/core';
import { translate } from '@ngneat/transloco';

export function getRegistrationFormConfig(): FormlyFieldConfig[] {
    return [
        {
            key: 'firstName',
            type: 'input',
            templateOptions: {
                label: translate('Vorname'),
                placeholder: translate('Vorname'),
                required: true,
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'firstName',
                },
            },
        },
        {
            key: 'lastName',
            type: 'input',
            templateOptions: {
                label: translate('Nachname'),
                placeholder: translate('Nachname'),
                required: true,
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'lastName',
                },
            },
        },
        {
            key: 'username',
            type: 'input',
            templateOptions: {
                label: translate('Benutzername'),
                placeholder: translate('Benutzername'),
                required: true,
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'username',
                },
            },
        },
        {
            key: 'email',
            type: 'input',
            templateOptions: {
                label: translate('E-Mail'),
                placeholder: translate('E-Mail'),
                type: 'email',
                required: true,
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'email',
                },
            },
            validators: {
                email: {
                    expression: (c: any) =>
                        !c.value ||
                        /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(
                            c.value
                        ),
                    message: () =>
                        translate(
                            'Bitte eine gültige E-Mail Adresse eingeben.'
                        ),
                },
            },
        },
        {
            key: 'password',
            type: 'input',
            templateOptions: {
                label: translate('Passwort'),
                type: 'password',
                placeholder: translate('Passwort'),
                required: true,
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'password',
                },
            },
            validators: {
                passwordLength: {
                    expression: (c: any) => !c.value || c.value.length > 7,
                    message: (error: any, field: FormlyFieldConfig): string => {
                        if (field.formControl) {
                            return `${
                                field.formControl.value.length
                            } ${translate('Zeichen von')} 8`;
                        }
                        return '';
                    },
                },
                characterTypes: {
                    expression: (c: any) => {
                        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
                        return c.value && regex.exec(c.value);
                    },
                    message: (error: any, field: FormlyFieldConfig): string => {
                        if (field.formControl) {
                            return translate(
                                'Muss Klein-, Grossbuchstabe und Zahl enthalten'
                            );
                        }
                        return '';
                    },
                },
            },
        },
        {
            key: 'companyName',
            type: 'input',
            templateOptions: {
                label: translate('Unternehmen'),
                placeholder: translate('Unternehmen'),
                required: true,
                appearance: 'outline',
                floatLabel: 'always',
                attributes: {
                    'data-testid': 'companyName',
                },
            },
        },
        // {
        //     key: 'medicalCompany',
        //     type: 'checkbox',
        //     defaultValue: false,
        //     templateOptions: {
        //         label: translate('Medizinisches Unternehmen'),
        //         attributes: {
        //             'data-testid': 'medicalCompany',
        //         },
        //     },
        // },
        {
            key: 'agbAccepted',
            type: 'checkbox',
            defaultValue: false,
            id: 'agbAccepted',
            templateOptions: {
                label: translate('AGB akzeptieren'),
                required: true,
                attributes: {
                    'data-testid': 'agbAccepted',
                },
            },
        },
    ];
}
