import { TransportModel } from '../transport-entity.model';

export function getTransportCustomer(
    transportModel: TransportModel
): string | undefined {
    switch (transportModel.customerType) {
        case 'startLocation':
            return transportModel.startLocation?.companyName;
        case 'targetLocation':
            return transportModel.targetLocation?.companyName;
        case 'insurance':
            return transportModel.customerInsurance?.name;
        case 'fromList':
            return transportModel.customerLocation?.companyName;
        case 'manual':
            return transportModel.customerLocation?.companyName;
        case 'passenger':
            return (
                transportModel.passenger?.firstName +
                ' ' +
                transportModel.passenger?.lastName
            );
    }
}
