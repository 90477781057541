import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'sys-time-type',
    template: `
        <ejs-timepicker
            [formControl]="formControl"
            id="{{ field.key }}"
            (change)="onChange($event)"
        ></ejs-timepicker>
    `,
})
export class TimeTypeComponent
    extends FieldType
    implements OnInit, AfterViewInit {
    public formControl!: FormControl;
    constructor() {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    onChange(args: any): void {
        if (
            this.field &&
            this.field.templateOptions &&
            this.field.templateOptions.changeCallback
        ) {
            this.field.templateOptions.changeCallback(args.value);
        }
    }
}
