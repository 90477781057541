import { Inject, Injectable } from '@angular/core';
import {
    EntityActionOptions,
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { EMPTY, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { refresh } from '../../state/app.actions';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL_TOKEN } from '../../injection-tokens';
import * as moment from 'moment';
import { Employee } from './employee-entity.model';
import { WorktimeBreak } from '../worktime/worktime.model';
import { TrainingParticipant } from '../trainings';

@Injectable({ providedIn: 'root' })
export class EmployeeEntityService extends EntityCollectionServiceBase<Employee> {
    public refresh$ = this.action$.pipe(
        ofType(refresh),
        tap(() => {
            this.load();
        })
    );

    private static parseEmployeeDates(entity: Partial<Employee>): Employee {
        const employee: Employee = JSON.parse(JSON.stringify(entity));
        employee.birthday = moment(employee.birthday).format('YYYY-MM-DD');
        if (employee.entryDate) {
            employee.entryDate = moment(employee.entryDate).format(
                'YYYY-MM-DD'
            );
        }
        if (employee.exitDate) {
            employee.exitDate = moment(employee.exitDate).format('YYYY-MM-DD');
        }
        return employee;
    }

    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private action$: Actions,
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string
    ) {
        super('Employee', serviceElementsFactory);
    }

    findEmployee(employeeId: string): Observable<Employee | undefined> {
        return this.entities$.pipe(
            map((employees: Employee[]) => {
                return employees.find((employee) => employee.id === employeeId);
            })
        );
    }

    updloadProfileImage(employee: Partial<Employee>): Observable<Employee> {
        if (!employee.profileImageFile || !employee.id) {
            return EMPTY;
        }
        const employeeData = new FormData();

        employeeData.append('employeeId', employee.id);
        employeeData.append(
            'single_file_upload[file]',
            employee.profileImageFile,
            employee.profileImageFile.name
        );

        return this.http.post<Employee>(
            `${this.server}api/employees/${employee.id}/profileimage`,
            employeeData
        );
    }

    updateBreak(
        employee: Employee,
        type: 'start' | 'end'
    ): Observable<WorktimeBreak> {
        return this.http.put<WorktimeBreak>(
            `${this.server}api/employees/${employee.id}/break/${type}`,
            employee
        );
    }

    add(entity: Partial<Employee>): Observable<Employee> {
        const emp = EmployeeEntityService.parseEmployeeDates(entity);
        return super.add(emp);
    }

    update(
        entity: Partial<Employee>,
        options?: EntityActionOptions
    ): Observable<Employee> {
        const emp = EmployeeEntityService.parseEmployeeDates(entity);
        return super.update(emp, options);
    }

    getOwn(): Observable<Employee> {
        return this.http.get<Employee>(`${this.server}api/employees/own`);
    }

    getEmployeesWithDailyWorktimeForDay(day: string): Observable<Employee[]> {
        return this.http.get<Employee[]>(
            `${this.server}api/employees/daily/${day}`
        );
    }

    getBookMainLocation(): Observable<boolean> {
        return this.http.get<boolean>(
            `${this.server}api/employees/canbookmainlocation`
        );
    }

    getForShiftSchedule(year: number, month: number): Observable<Employee[]> {
        return this.http.get<Employee[]>(
            `${this.server}api/employees/shiftschedule/${year}-${month}`
        );
    }

    checkPassword(password: string): Observable<boolean> {
        return this.http.post<boolean>(
            `${this.server}api/employees/checkmypassword`,
            { password }
        );
    }

    getEmployeesForSelect(): Observable<Employee[]> {
        return this.http.get<Employee[]>(
            `${this.server}api/employees/forselect`
        );
    }

    getActiveEmployees(): Observable<Employee[]> {
        return this.http.get<Employee[]>(`${this.server}api/employees/active`);
    }

    getEmployeeTrainingParticipants(
        employee: Employee
    ): Observable<TrainingParticipant[]> {
        return this.http.get<TrainingParticipant[]>(
            `${this.server}api/trainingparticipants?employeeId=${employee.id}`
        );
    }

    getTrainingsUrl(ids: string[]): Observable<{ url: string }> {
        return this.http.post<{ url: string }>(
            `${this.server}api/trainingparticipants/print`,
            { ids }
        );
    }

    printTrainings(
        year: number,
        employees: Employee[]
    ): Observable<{ url: string }> {
        const ids = employees.map((employee) => employee.id);
        return this.http.post<{ url: string }>(
            `${this.server}api/trainingparticipants/printyear/${year}`,
            { ids }
        );
    }
}
