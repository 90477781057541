import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    private readonly prefix: string;
    private readonly window: Window;

    constructor(@Inject(DOCUMENT) readonly document: Document) {
        this.prefix = '';
        this.window = document.defaultView as Window;
    }

    private useKey(key: string): string {
        if (this.prefix) {
            return `${this.prefix}-${key}`;
        }

        return key;
    }

    setItem(key: string, value: any): void {
        this.window.localStorage.setItem(
            this.useKey(key),
            JSON.stringify({ value })
        );
    }

    getItem<T>(key: string): T | undefined {
        const storedData = this.window.localStorage.getItem(this.useKey(key));

        return storedData ? JSON.parse(storedData).value : undefined;
    }

    removeItem(key: string): void {
        this.window.localStorage.removeItem(this.useKey(key));
    }

    clear(): void {
        this.window.localStorage.clear();
    }
}
