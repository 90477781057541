import { ChecklistTemplate } from './checklist-template.model';

export function createChecklistTemplate(
    checklistTemplate: ChecklistTemplate
): ChecklistTemplate {
    return {
        id: checklistTemplate.id || undefined,
        checklistTemplateItems:
            checklistTemplate.checklistTemplateItems || undefined,
        repeatAfterDays: checklistTemplate.repeatAfterDays || undefined,
        name: checklistTemplate.name,
        resetOnTeamChange: checklistTemplate.resetOnTeamChange || undefined,
    };
}
