import {
    ChangeDetectorRef,
    Component,
    HostBinding,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { merge, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { fuseAnimations } from '../../../animations';
import { FuseNavigationService } from '../navigation.service';
import { Badges, BadgesService, FEATURE_INFOS } from '@sansys/crosslib';
import { NavigationItem } from '../navigation.type';

@Component({
    selector: 'sys-nav-vertical-collapsable',
    templateUrl: './collapsable.component.html',
    styleUrls: ['./collapsable.component.scss'],
    animations: fuseAnimations,
})
export class FuseNavVerticalCollapsableComponent implements OnInit, OnDestroy {
    @Input() item!: NavigationItem;
    @HostBinding('class') classes = 'nav-collapsable nav-item';
    @HostBinding('class.open') public isOpen = false;

    badges: Badges = {
        schedulingOrders: 0,
        scheduling: 0,
    };

    private subscription = new Subscription();

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _router: Router,
        private badgesService: BadgesService,
        @Inject(FEATURE_INFOS) private featureInfos: string
    ) {}

    ngOnInit(): void {
        this.subscription.add(
            this.badgesService.badges$.subscribe((badges) => {
                this.badges = badges;
                this._changeDetectorRef.markForCheck();
            })
        );
        this.subscription.add(
            this._router.events
                .pipe(filter((event) => event instanceof NavigationEnd))
                .subscribe((event) => {
                    if (
                        this.isUrlInChildren(
                            this.item,
                            (event as NavigationEnd).urlAfterRedirects
                        )
                    ) {
                        this.expand();
                    } else {
                        this.collapse();
                    }
                })
        );

        this.subscription.add(
            this._fuseNavigationService.onItemCollapsed.subscribe(
                (clickedItem) => {
                    if (clickedItem && clickedItem.children) {
                        if (this.isChildrenOf(this.item, clickedItem)) {
                            return;
                        }

                        if (this.isUrlInChildren(this.item, this._router.url)) {
                            return;
                        }

                        if (this.item !== clickedItem) {
                            this.collapse();
                        }
                    }
                }
            )
        );

        if (this.isUrlInChildren(this.item, this._router.url)) {
            this.expand();
        } else {
            this.collapse();
        }

        this.subscription.add(
            merge(
                this._fuseNavigationService.onNavigationItemAdded,
                this._fuseNavigationService.onNavigationItemUpdated,
                this._fuseNavigationService.onNavigationItemRemoved
            ).subscribe(() => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
            })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    toggleOpen(ev: MouseEvent): void {
        ev.preventDefault();

        this.isOpen = !this.isOpen;

        // Navigation collapse toggled...
        this._fuseNavigationService.onItemCollapsed.next(this.item);
        this._fuseNavigationService.onItemCollapseToggled.next();
    }

    expand(): void {
        if (this.isOpen) {
            return;
        }

        this.isOpen = true;

        // Mark for check
        this._changeDetectorRef.markForCheck();

        this._fuseNavigationService.onItemCollapseToggled.next();
    }

    collapse(): void {
        if (!this.isOpen) {
            return;
        }

        this.isOpen = false;

        // Mark for check
        this._changeDetectorRef.markForCheck();

        this._fuseNavigationService.onItemCollapseToggled.next();
    }

    isChildrenOf(parent: NavigationItem, item: NavigationItem): boolean {
        const children = parent.children;

        if (!children) {
            return false;
        }

        if (children.indexOf(item) > -1) {
            return true;
        }

        for (const child of children) {
            if (child.children) {
                if (this.isChildrenOf(child, item)) {
                    return true;
                }
            }
        }

        return false;
    }

    isUrlInChildren(parent: NavigationItem, url: string): boolean {
        const children = parent.children;

        if (!children) {
            return false;
        }

        for (const child of children) {
            if (child.children) {
                if (this.isUrlInChildren(child, url)) {
                    return true;
                }
            }

            if (child.url === url || (child.url && url.includes(child.url))) {
                return true;
            }
        }

        return false;
    }

    getPermission(id: string): string[] {
        const foundInfo = (this.featureInfos as any)[id]['permission'];
        const perm = ['admin'];
        const p = foundInfo as string[];
        return perm.concat(p);
    }

    public getItemInfo(id: string, type: string): string {
        return (this.featureInfos as any)[id][type];
    }
}
