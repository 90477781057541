import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BugReportData } from '@sansys/crosslib';

@Component({
    selector: 'sys-bug-report',
    templateUrl: './bug-report.component.html',
    styleUrls: ['./bug-report.component.scss'],
})
export class BugReportComponent {
    bugurl: string | ArrayBuffer | undefined | null;
    constructor(
        public dialogRef: MatDialogRef<BugReportComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BugReportData
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

    getFileFromInput(event: any): void {
        const files = event.target['files'];
        if (!files) {
            return;
        }
        const file = files.item(0);
        if (!file) {
            return;
        }

        const reader = new FileReader();

        reader.onload = (e) => {
            this.bugurl = e.target?.result;
        };

        reader.readAsDataURL(file);

        this.data.screenshot = file;
    }
}
