<ng-container *ngIf="!item.hidden">
    <div class="group-title">
        <span class="hint-text">{{
            item.title ? (item.title | transloco) : ''
        }}</span>
    </div>

    <div class="group-items">
        <ng-container *ngFor="let item of item.children">
            <ng-container>
                <sys-nav-vertical-group
                    *ngIf="item.type == 'group'"
                    [item]="item"
                ></sys-nav-vertical-group>
                <sys-nav-vertical-collapsable
                    *ngIf="item.type == 'collapsable'"
                    [item]="item"
                ></sys-nav-vertical-collapsable>
                <sys-nav-vertical-item
                    *ngIf="item.type == 'item'"
                    [item]="item"
                ></sys-nav-vertical-item>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
