import { AccidentInsuranceAutocompleteWrapperComponent } from './accident-insurance-autocomplete-wrapper.component';
import { EmployeeAutocompleteWrapperComponent } from './employee-autocomplete-wrapper.component';
import { FacilityAutocompleteWrapperComponent } from './facility-autocomplete-wrapper.component';
import { PassengerAutocompleteWrapperComponent } from './passenger-autocomplete-wrapper.component';
import { HealthInsuranceAutocompleteWrapperComponent } from './health-insurance-autocomplete-wrapper.component';
import { TransportLocationAutocompleteWrapperComponent } from './transport-location-autocomplete-wrapper.component';
import { VehicleAutocompleteWrapperComponent } from './vehicle-autocomplete-wrapper.component';
import { CustomerAdminitrationAutocompleteWrapperComponent } from './customer-adminitration-autocomplete-wrapper.component';
import { PanelWrapperComponent } from './panel-wrapper.component';
import { TariffAutocompleteWrapperComponent } from './tariff-autocomplete-wrapper.component';
import { PassengerTransferReasonAutocompleteWrapperComponent } from './passenger-transfer-reason-autocomplete-wrapper.component';
import { TransportTimingsWrapperComponent } from './transport-timings-wrapper.component';
import { ProtocolWrapperComponent } from './protocol-wrapper.component';
import { DefaultLocationsAutocompleteWrapperComponent } from './default-locations-autocomplete-wrapper.component';
import { AssignedAutocompleteWrapperComponent } from './assigned-autocomplete-wrapper.component';
import { BvgWrapperComponent } from './bvg-wrapper.component';
import { ParentDocumentCategoryAutocompleteWrapperComponent } from './parent-document-category-autocomplete-wrapper.component';
import { InsuranceAutocompleteWrapperComponent } from './insurance-autocomplete-wrapper.component';
import { StationAutocompleteWrapperComponent } from './station-autocomplete-wrapper.component';
import { TransportTimingsCustomerWrapperComponent } from './transport-timings-customer-wrapper.component';
import { TooltipWrapperComponent } from './tooltip-wrapper.component';
import { PassengerDetailsWrapperComponent } from './passenger-details-wrapper.component';
import { TransportLocationDetailsWrapperComponent } from './transport-location-details-wrapper.component';
import { AutocompleteWrapperComponent } from './autocomplete-wrapper.component';

export const formlyWrappers = [
    {
        name: 'transportLocationAutocompleteWrapper',
        component: TransportLocationAutocompleteWrapperComponent,
    },
    {
        name: 'passengerAutocompleteWrapper',
        component: PassengerAutocompleteWrapperComponent,
    },
    {
        name: 'employeeAutocompleteWrapper',
        component: EmployeeAutocompleteWrapperComponent,
    },
    {
        name: 'vehicleAutocompleteWrapper',
        component: VehicleAutocompleteWrapperComponent,
    },
    {
        name: 'facilityAutocompleteWrapper',
        component: FacilityAutocompleteWrapperComponent,
    },
    {
        name: 'healthInsuranceAutocompleteWrapper',
        component: HealthInsuranceAutocompleteWrapperComponent,
    },
    {
        name: 'accidentInsuranceAutocompleteWrapper',
        component: AccidentInsuranceAutocompleteWrapperComponent,
    },
    {
        name: 'insuranceAutocompleteWrapper',
        component: InsuranceAutocompleteWrapperComponent,
    },
    {
        name: 'customerAdministrationAutocompleteWrapper',
        component: CustomerAdminitrationAutocompleteWrapperComponent,
    },
    {
        name: 'tariffAutocompleteWrapper',
        component: TariffAutocompleteWrapperComponent,
    },
    {
        name: 'panelWrapper',
        component: PanelWrapperComponent,
    },
    {
        name: 'passengerTransferReason',
        component: PassengerTransferReasonAutocompleteWrapperComponent,
    },
    {
        name: 'transportTimingsWrapper',
        component: TransportTimingsWrapperComponent,
    },
    {
        name: 'transportTimingsCustomerWrapper',
        component: TransportTimingsCustomerWrapperComponent,
    },
    {
        name: 'protocolWrapper',
        component: ProtocolWrapperComponent,
    },
    {
        name: 'defaultLocationsAutocompleteWrapper',
        component: DefaultLocationsAutocompleteWrapperComponent,
    },
    {
        name: 'assignedAutocompleteWrapper',
        component: AssignedAutocompleteWrapperComponent,
    },
    {
        name: 'parentDocumentCategoryAutocompleteWrapper',
        component: ParentDocumentCategoryAutocompleteWrapperComponent,
    },
    {
        name: 'bvgWrapper',
        component: BvgWrapperComponent,
    },
    {
        name: 'stationAutocompleteWrapper',
        component: StationAutocompleteWrapperComponent,
    },
    {
        name: 'tooltipWrapper',
        component: TooltipWrapperComponent,
    },
    {
        name: 'passengerDetailsWrapper',
        component: PassengerDetailsWrapperComponent,
    },
    {
        name: 'transportLocationDetailsWrapper',
        component: TransportLocationDetailsWrapperComponent,
    },
    {
        name: 'autocompleteWrapper',
        component: AutocompleteWrapperComponent,
    },
];
